import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../../../../../context/AuthContext'
import GeneralPortalSystem from '../../../../generalPortalSystem/GeneralPortalSystem'
import IssueCardContent from '../../../../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectDepartmentsComponents/IssuesComponents/IssueCardContent'
import { IssueProfile } from '../../../../windowViewer/WindowViewerSystemComps'
import i18next from 'i18next'

const MyIssues = (props) => {
  const { user } = useContext(AuthContext)
  const { t } = useTranslation()
  const lang = i18next.language
  const rtl = lang === 'ar'
  const propsII = {
    ...props,
    noFSPanelPropsFirstView: { hideNoFileSystemPanel: true },
    noFSPanelPropsSecondView: {
      deleteTippy: rtl ? 'أزل هذه المشكلة' : 'End this Issue',
      confirmmationTitles: rtl ? 'تم انهاء المشكله' : 'Issue has been ended',
    },
    placeholderValue: t('My_Issues'),
    ElementProfile: IssueProfile,
    StandardCardContent: IssueCardContent,
    deletePath: 'issues',
    cardsPropName: 'issues',
    getPath: `issues/userIssues/${user._id}`,
    sect_sub_ind: '2/1',
    initialContent: rtl
      ? 'هنا يمكنك الوصول إلى مشاكلك وقد تشارك بعضها للسماح لمتابعيك بالتفاعل معهم من خلال التصويت لصالحك.'
      : 'Here you can access your issues and may share some to let your followers interact with them by upvoting for your sake.',
  }
  return <GeneralPortalSystem {...propsII} />
}

export default MyIssues
