import React, { useContext, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  TextField,
  Button,
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from '@mui/material'
import axios from 'axios'
import CountriesPhoneKeys from './CountriesPhoneKeys'
import { AuthContext } from '../../../context/AuthContext'
import { toast } from 'react-toastify'
import i18next from 'i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    width: '100%',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1rem',
    '& > *': {
      marginBottom: '1rem',
    },
    '& [dir="rtl"]': {
      textAlign: 'right',
    },
  },
  label: {
    fontWeight: 'bold',
  },
  button: {
    backgroundColor: '#1877f2',
    color: 'white',
    padding: '0.5rem 1rem',
    borderRadius: '4px',
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: '#1656a4',
    },
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    borderRadius: '4px',
    textAlign: 'center',
  },
  select: {
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
  },
}))

const ProfileInfo = ({ DCGProps }) => {
  const { isDarkModeActive, setClickAwayState } = DCGProps
  const { user, dispatch } = useContext(AuthContext)
  const classes = useStyles()
  const [firstName, setFirstName] = useState(user?.firstName || '')
  const [lastName, setLastName] = useState(user?.lastName || '')
  const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || '')
  const [dateOfBirth, setDateOfBirth] = useState(user?.dateOfBirth || '')
  const [gender, setGender] = useState(user?.gender || '')
  const [countryCode, setCountryCode] = useState(user?.countryCode || '')
  const lang = i18next.language
  const rtl = lang === 'ar'

  const handleSaveChanges = async (event) => {
    event.preventDefault()
    if (typeof setClickAwayState === 'function') setClickAwayState(false)
    try {
      const res = await axios.patch(`/users/${user._id}`, {
        userId: user?._id,
        firstName,
        lastName,
        phoneNumber,
        dateOfBirth,
        gender,
        countryCode,
      })
      dispatch({ type: 'UPDATE', payload: res.data.user })
      toast.success(
        rtl ? 'تم تحديث بيانات الحساب بنجاح' : 'Profile updated successfully!'
      )
    } catch (error) {
      console.error(error)
      toast.error(
        rtl
          ? 'حدث خطأ أثناء تحديث ملفك الشخصي. الرجاء معاودة المحاولة في وقت لاحق.'
          : 'An error occurred while updating your profile. Please try again later.'
      )
    }
  }

  return (
    <Box className={classes.root} sx={{ color: isDarkModeActive && 'white' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <label htmlFor='email' className={classes.label}>
            {rtl ? 'البريد الالكترونى' : 'Email'}
          </label>
          <TextField
            id='email'
            name='email'
            type='email'
            value={user?.email || ''}
            disabled
            fullWidth
            dir={document.body.dir}
            inputProps={{
              style: { color: isDarkModeActive && 'white' },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <label htmlFor='firstName' className={classes.label}>
            {rtl ? 'الاسم الأول' : 'First Name'}
          </label>
          <TextField
            id='firstName'
            name='firstName'
            type='text'
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
            fullWidth
            dir={document.body.dir}
            inputProps={{
              style: { color: isDarkModeActive && 'white' },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <label htmlFor='lastName' className={classes.label}>
            {rtl ? 'اسم العائلة' : 'Last Name'}
          </label>
          <TextField
            id='lastName'
            name='lastName'
            type='text'
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
            fullWidth
            sx={{ color: isDarkModeActive && 'white' }}
            dir={document.body.dir}
            inputProps={{
              style: { color: isDarkModeActive && 'white' },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <label htmlFor='phone' className={classes.label}>
            {rtl ? 'رقم الهاتف' : 'Phone Number'}
          </label>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth required>
                <InputLabel
                  htmlFor='countryCode'
                  style={{ color: isDarkModeActive && 'white' }}
                >
                  {rtl ? 'رمز البلد' : 'Country Code'}
                </InputLabel>
                <Select
                  native
                  value={countryCode}
                  onChange={(event) => setCountryCode(event.target.value)}
                  inputProps={{
                    name: 'countryCode',
                    id: 'countryCode',
                  }}
                  className={classes.select}
                  style={{ color: isDarkModeActive && 'white' }}
                >
                  <option aria-label='None' value='' />
                  {CountriesPhoneKeys().map((country, i) => (
                    <option
                      key={country.code + i}
                      value={country.code}
                      style={{ color: 'black' }}
                    >
                      {country.name} (+{country.code})
                    </option>
                  ))}
                </Select>
                <FormHelperText style={{ color: isDarkModeActive && 'white' }}>
                  {rtl
                    ? 'يرجى اختيار رمز البلد'
                    : 'Please select a country code'}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                id='phone'
                name='phone'
                type='tel'
                value={phoneNumber}
                onChange={(event) => setPhoneNumber(event.target.value)}
                fullWidth
                dir={document.body.dir}
                inputProps={{
                  style: { color: isDarkModeActive && 'white' },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <label htmlFor='dateOfBirth' className={classes.label}>
            {rtl ? 'تاريخ الميلاد' : 'Date of Birth'}
          </label>
          <TextField
            id='dateOfBirth'
            name='dateOfBirth'
            type='date'
            value={dateOfBirth}
            onChange={(event) => setDateOfBirth(event.target.value)}
            fullWidth
            dir={document.body.dir}
            inputProps={{
              style: { color: isDarkModeActive && 'white' },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <label htmlFor='gender' className={classes.label}>
            {rtl ? 'الجنس' : 'Gender'}
          </label>
          <FormControl fullWidth>
            <InputLabel
              htmlFor='gender'
              style={{ color: isDarkModeActive && 'white' }}
            >
              {rtl ? 'اختر الجنس' : 'Select Gender'}
            </InputLabel>
            <Select
              native
              value={gender}
              onChange={(event) => setGender(event.target.value)}
              inputProps={{
                name: 'gender',
                id: 'gender',
              }}
              className={classes.select}
              onOpen={() => setClickAwayState(true)}
              style={{ color: isDarkModeActive && 'white' }}
            >
              <option style={{ color: 'black' }} aria-label='None' value='' />
              <option value='Male' style={{ color: 'black' }}>
                {rtl ? 'ذكر' : 'Male'}
              </option>
              <option value='Female' style={{ color: 'black' }}>
                {rtl ? 'أنثى' : 'Female'}
              </option>
              <option value='Other' style={{ color: 'black' }}>
                {rtl ? 'آخر' : 'Other'}
              </option>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Button
        onClick={handleSaveChanges}
        variant='contained'
        className={classes.button}
        disabled={
          user?.firstName === firstName &&
          user?.lastName === lastName &&
          user?.phoneNumber === phoneNumber &&
          user?.dateOfBirth === dateOfBirth &&
          user?.gender === gender &&
          user?.countryCode === countryCode
        }
      >
        {rtl ? 'حفظ' : 'Save'}
      </Button>
    </Box>
  )
}

export default ProfileInfo
