import React, { useContext, useEffect } from 'react'
import TopBar from '../components/TopBar/TopBar'
import PageBody from '../components/PageBody'
import Media from 'react-media'
import { AuthContext } from '../context/AuthContext'
import FetchNotifications from './HutupiaPageModelComps/FetchNotifications'
import FetchChats from './HutupiaPageModelComps/FetchChats'
import { v4 as uuidv4 } from 'uuid'
import FetchDefaultChart from './HutupiaPageModelComps/FetchDefaultChart'
import { SettingsContext } from '../context/SettingsContext/SettingsContext'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import WindowSize from '../components/smallComponents/WindowSize'
import i18next from 'i18next'
import MTopbar from '../components/mobileViewComponents/MTopbar'

const PageModel = ({
  MainComponent,
  MainComponentProps,
  cardsPage,
  dynamicMenuArray,
  projectMode,
}) => {
  const { socket } = MainComponentProps
  const { settings } = useContext(SettingsContext)
  const isDarkModeActive = settings?.Mode === 'Dark'
  const { user } = useContext(AuthContext)
  const notifsProps = FetchNotifications({ toast, isDarkModeActive })
  const chatsProps = FetchChats({ toast, isDarkModeActive })
  const { setArrivalMessage } = chatsProps
  const { setArrivalNotification } = notifsProps

  const defaultChartProps = FetchDefaultChart()

  useEffect(() => {
    socket?.current?.on('getMessage', (data) => {
      setArrivalMessage({
        ...data,
        createdAt: Date.now(),
        _id: uuidv4(),
      })
    })
    socket?.current?.on('getNotification', (data) => {
      setArrivalNotification({
        ...data,
        createdAt: Date.now(),
        _id: uuidv4(),
      })
    })
  }, [user?._id])

  useEffect(() => {
    socket?.current?.emit('addUser', user?._id)
    socket?.current?.emit('addNotifUser', user?._id)
  }, [user?._id])
  const pageProps = {
    socket,
    isDarkModeActive,
    ...notifsProps,
    ...chatsProps,
  }

  const lang = i18next.language
  const rtl = lang === 'ar'
  const windowSize = WindowSize()

  return (
    <>
      <ToastContainer
        position={windowSize > 600 ? 'bottom-right' : 'top-center'}
        autoClose='5000'
        hideProgressBar={true}
        newestOnTop
        closeOnClick
        rtl={rtl}
        draggable
        theme={isDarkModeActive ? 'dark' : 'light'}
      />
      <Media query='(max-width: 600px)'>
        {(matches) => {
          return matches ? (
            <MTopbar
              MainComponent={MainComponent}
              MainComponentProps={MainComponentProps}
              pageProps={pageProps}
              defaultChartProps={defaultChartProps}
              cardsPage={cardsPage}
              dynamicMenuArray={dynamicMenuArray}
            />
          ) : (
            <>
              <TopBar
                pageProps={pageProps}
                defaultChartProps={defaultChartProps}
              />
              <PageBody
                MainComponent={MainComponent}
                MainComponentProps={MainComponentProps}
                pageProps={pageProps}
                cardsPage={cardsPage}
                dynamicMenuArray={dynamicMenuArray}
                projectMode={projectMode}
              />
            </>
          )
        }}
      </Media>
    </>
  )
}

export default PageModel
