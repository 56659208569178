import React, { useState } from 'react'
import MiniFileSystem from '../../../../../fileSystem/MiniFileSystem'
import CustomCircularProgress from '../../../../../smallComponents/CustomCircularProgress'
import DNALoader from '../../../../../smallComponents/DNALoader'
import LoadMoreButton from '../../../../../smallComponents/LoadMoreButton'
import WindowSize from '../../../../../smallComponents/WindowSize'
import WindowProps from '../../../../../windowViewer/WindowProps'
import WindowViewerSystem from '../../../../../windowViewer/WindowViewerSystem'
import ContractCardContent from './ContractsPortalComps/ContractCardContent'

const ContractsPortal = ({
  DCGProps,
  searchSystemProps,
  InternalComponent,
  contracts,
  placeholderValue,
  currentResponse,
  page,
  setPage,
  isLoading,
}) => {
  const { setClickAwayState } = DCGProps
  const [cardId, setCardId] = useState()
  const contract = contracts.filter((con) => con.id === cardId)[0]
  const { isWindowOpen, windowOpener, windowCloser } = WindowProps()
  const MFSGProps = {
    setCardId,
    cardId,
    cards: contracts,
    MiniFSModelIndex: 6,
    cardIdDependencyIndex: 0,
    ...DCGProps,
  }
  const searchSystemPropsII = {
    ...searchSystemProps,
    placeholderValue,
    FirstViewSearch: true,
    firstLetterSearch: true,
    firstViewSearchIdtentifiers: ['Title'],
  }
  const windowSize = WindowSize()
  const modelProps = {
    StandardCardContent: ContractCardContent,
    imageKey: 'contract',
    InternalComponent: InternalComponent ? <InternalComponent /> : null,
    windowOpener: () => {
      windowOpener()
      if (typeof setClickAwayState === 'function') setClickAwayState(true)
    },
    disablePorfileOpener: true,
    InfiniteScrollProps: {
      next: () => setPage((prev) => prev + 1),
      hasMore: currentResponse,
      scrollableTarget: 'dialog-model-unit',
      loader:
        (page === 1 && currentResponse) ||
        (windowSize <= 600 && currentResponse) ? (
          <LoadMoreButton setPage={setPage} />
        ) : (
          <DNALoader />
        ),
    },
  }
  return (
    <>
      {isLoading && page === 1 ? (
        <CustomCircularProgress />
      ) : (
        <MiniFileSystem
          MFSGProps={MFSGProps}
          modelProps={modelProps}
          searchSystemProps={searchSystemPropsII}
          folderPathProps={{}}
          noFSPanelPropsSecondView={{ hideNoFileSystemPanel: true }}
          noFSPanelPropsFirstView={{ hideNoFileSystemPanel: true }}
        />
      )}
      {contract && (
        <WindowViewerSystem
          isWindowOpen={isWindowOpen}
          windowCloser={() => {
            windowCloser()
            if (typeof setClickAwayState === 'function')
              setClickAwayState(false)
          }}
          {...DCGProps}
          windowType='contract'
          props={{
            contract,
            DCGProps,
          }}
        />
      )}
    </>
  )
}

export default ContractsPortal
