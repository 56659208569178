import StateGenerator from './StateGenerator'

const StateEngine = ({ StatesArray }) => {
  return StatesArray.map((object) => {
    const { isGeneralDialogOpen, GeneralDialogOpener, GeneralDialogClose } =
      StateGenerator({
        GeneralProps: true,
      })
    return {
      ...object,
      closeFun: GeneralDialogClose,
      openFun: GeneralDialogOpener,
      open: isGeneralDialogOpen,
    }
  })
}

export default StateEngine
