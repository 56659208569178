import React, { useEffect, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import MiniFileSystem from '../../fileSystem/MiniFileSystem'
import MemberProfile from './ProjectControlPanelComponents/MemberProfile'
import ElementInfoCard from '../../generalPortalSystem/ElementInfoCard'
import i18next from 'i18next'

const styles = () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'row wrap',
    justifyContent: 'space-around',
    width: '100%',
  },
})

const MembersPortalModel = ({
  classes,
  folderPathProps,
  searchSystemProps,
  DCGProps,
  placeholderValue,
  cards,
  noFSPanelPropsFirstView,
  noFSPanelPropsSecondView,
  memberOptions,
  showMemberDepartment,
  modelProps,
  CardProfileComponent,
  profileProps,
  setCards,
  initialContent,
  sect_sub_ind,
  hideInfoCard,
  departmentId,
  subDepId,
}) => {
  const [memberId, setMemberId] = useState()
  const member = cards?.filter((mem) => mem.id === memberId)[0]
  const lang = i18next.language
  const rtl = lang === 'ar'
  const noFSPanelPropsSecondViewII = {
    downloadFolderTippy: rtl ? 'تحميل ملف العضو' : 'Download Member Profile',
    showDownloadIcon: true,
    setCardId: setMemberId,
    ...DCGProps,
    ...noFSPanelPropsSecondView,
    member,
  }

  const noFSPanelPropsFirstViewII = {
    hideCloseArrow: true,
    addContributorTippy: rtl ? 'أضف عضواُ جديداً' : 'Add New Member',
    DCGProps,
    setCards,
    ...noFSPanelPropsFirstView,
  }
  const searchSystemPropsII = {
    ...searchSystemProps,
    placeholderValue,
    FirstViewSearch: true,
    firstLetterSearch: true,
    firstViewSearchIdtentifiers: [
      'name',
      'title',
      'managerRule',
      'ruleDesc',
      'membershipId',
      'memberShares',
      'info',
      'startDate',
      'admissionDate',
    ],
  }
  const modelPropsII = {
    InternalComponent: !hideInfoCard && (
      <ElementInfoCard
        initialContent={initialContent}
        sect_sub_ind={sect_sub_ind}
        {...DCGProps}
      />
    ),
    memberOptions,
    showMemberDepartment: true,
    ...modelProps,
    ...DCGProps,
  }
  const MFSGProps = {
    setCardId: setMemberId,
    cardId: memberId,
    cards,
    MiniFSModelIndex: 3,
    DCGProps,
    CardProfileComponent: CardProfileComponent ? (
      <CardProfileComponent
        member={member}
        showMemberDepartment={showMemberDepartment}
        {...DCGProps}
        DCGProps={DCGProps}
        {...profileProps}
      />
    ) : (
      <MemberProfile
        member={member}
        showMemberDepartment={showMemberDepartment}
        {...DCGProps}
        DCGProps={DCGProps}
        {...profileProps}
        departmentId={departmentId}
        subDepId={subDepId}
        members={cards}
      />
    ),
    cardIdDependencyIndex: 0,
    ...DCGProps,
  }

  return (
    <article className={classes.wrapper}>
      <MiniFileSystem
        MFSGProps={MFSGProps}
        searchSystemProps={searchSystemPropsII}
        modelProps={modelPropsII}
        folderPathProps={folderPathProps}
        noFSPanelPropsSecondView={noFSPanelPropsSecondViewII}
        noFSPanelPropsFirstView={noFSPanelPropsFirstViewII}
      />
    </article>
  )
}

const WrappedMembersPortalModel = withStyles(styles)(MembersPortalModel)

export default WrappedMembersPortalModel
