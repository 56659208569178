import React from 'react'
import AdsManagementPageMain from './AdsManagementHomeComps/AdsManagementPageMain'
import AdsManagmentModel from './AdsManagmentModel'
import DocumentTitleChanger from '../../components/smallComponents/DocumentTitleChanger'
import i18next from 'i18next'

const AdsManagementHome = (props) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  DocumentTitleChanger(rtl ? 'الاعلانات - الرئيسية' : 'Campiagns Home')
  return (
    <AdsManagmentModel
      Component={AdsManagementPageMain}
      ComponentProps={props}
    />
  )
}

export default AdsManagementHome
