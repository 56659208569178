import React, { useContext } from 'react'
import withStyles from '@mui/styles/withStyles'
import { Card, Grid } from '@mui/material'
import { SettingsContext } from '../../../../../context/SettingsContext/SettingsContext'
import DarkModeColorCoder from '../../../../smallComponents/DarkModeColorCoder'

const styles = () => ({
  gridConatiner: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },

  controlPanelWrappingCard: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    margin: '0 10px 10px 10px',
    flexFlow: 'row wrap',
    borderRadius: '25px !important',
  },

  '@media (max-width: 600px)': {
    controlPanelWrappingCard: {
      width: '100%',
      margin: '0 0 5px 0',
    },
  },
})

const ControlButtonsWrapper = ({ buttons, classes, hide }) => {
  const { settings } = useContext(SettingsContext)
  const isDarkModeActive = settings?.Mode === 'Dark'
  return (
    <Card
      className={classes.controlPanelWrappingCard}
      style={{
        display: hide ? 'none' : 'flex',
        background: isDarkModeActive ? DarkModeColorCoder('Grey_light') : null,
      }}
    >
      <Grid container className={classes.gridConatiner}>
        {buttons}
      </Grid>
    </Card>
  )
}

const WrapepdControlButtonsWrapper = withStyles(styles)(ControlButtonsWrapper)

export default WrapepdControlButtonsWrapper
