import axios from 'axios'
import React, { useEffect, useState } from 'react'
import CommentsDialog from '../commentsSystems/CommentsDialog'
import PostProps from '../homeFeed/homeFeedComponents/HomeFeedPostModelComponents/PostProps'
import ShareSystem from '../sharingSystem/ShareSystem'
import CommentButtonConfig from './FeedPostBottomModelComps/CommentButtonConfig'
import LikeButtonConfig from './FeedPostBottomModelComps/LikeButtonConfig'
import PBCardsModel from './FeedPostBottomModelComps/PBCardsModel'
import ShareButtonConfig from './FeedPostBottomModelComps/ShareButtonConfig'
import { Divider } from '@mui/material'
import DarkModeColorCoder from '../smallComponents/DarkModeColorCoder'
import PostBottomInfoDisplay from './FeedPostBottomModelComps/PostBottomInfoDisplay'
import PostLikesUsersList from './FeedPostBottomModelComps/PostLikesUsersList'
import WindowProps from '../windowViewer/WindowProps'

const FeedPostBottomModel = ({ postProps, shareSystemProps }) => {
  const {
    postBottomModelIndex,
    isDarkModeActive,
    buttons,
    setIsCommentingSystemActive,
    hideBottom,
    socket,
    post,
    setPosts,
    user,
  } = postProps

  const [sharesCount, setSharesCount] = useState(post?.shares.length)
  const [projectAdmins, setProjectAdmins] = useState([])
  useEffect(() => {
    const fetchProject = async () => {
      try {
        const adminsRes = await axios.get(
          `/projects/o/projectAdmins/${post?.userId}`
        )
        setProjectAdmins(adminsRes.data.admins)
      } catch (error) {
        console.log(error)
      }
    }

    if (post?.feedsSystemCategory === 'page') fetchProject()
  }, [post?.userId])
  const styles = {
    bottomIcon: {
      cursor: 'pointer',
      marginLeft: 5,
      marginRight: 5,
      color: isDarkModeActive
        ? DarkModeColorCoder('Text_white')
        : postBottomModelIndex === 0
        ? '#333333'
        : null,
    },
    counterValue: {
      fontSize: '15px',
      cursor: 'pointer',
      margin: '5px',
      color: isDarkModeActive
        ? DarkModeColorCoder('Text_white')
        : postBottomModelIndex === 0
        ? '#333333'
        : null,
    },
  }

  const {
    commentsDialogOpener,
    commentsDialogClose,
    isCommentsDialogOpen,
    shareDialogOpener,
    shareDialogClose,
    isShareDialogOpen,
  } = PostProps(
    { setIsCommentingSystemActive },
    {
      commentsDialogProps: true,
      shareSystemProps: true,
    }
  )

  const {
    isWindowOpen: isLikesListOpen,
    windowOpener: likesListOpener,
    windowCloser: likesListCloser,
  } = WindowProps()

  const postPropsII = {
    ...postProps,
    styles,
    shareDialogOpener,
    commentsDialogOpener,
    sharesCount,
    projectAdmins,
    likesListOpener,
  }

  const buttonsConfigs = [
    {
      title: 'like',
      button: LikeButtonConfig(postPropsII),
    },
    {
      title: 'comment',
      button: CommentButtonConfig(postPropsII),
    },
    {
      title: 'share',
      button: ShareButtonConfig(postPropsII),
    },
  ]
  const buttonsArray = buttonsConfigs
    .filter((config) => buttons.includes(config.title))
    .map((config) => config.button)

  return (
    post?.postTypeIndex !== 3 && (
      <>
        <PostBottomInfoDisplay
          buttonsArray={buttonsArray}
          isDarkModeActive={isDarkModeActive}
          hideBottom={hideBottom}
        />
        <PostLikesUsersList
          isLikesListOpen={isLikesListOpen}
          likesListCloser={likesListCloser}
          post={post}
        />
        <Divider
          style={{
            backgroundColor:
              isDarkModeActive && DarkModeColorCoder('Grey_2x_light'),
            width: '90%',
          }}
        />
        <PBCardsModel
          buttonsArray={buttonsArray}
          isDarkModeActive={isDarkModeActive}
          hideBottom={hideBottom}
        />
        <CommentsDialog
          isCommentsDialogOpen={isCommentsDialogOpen}
          commentsDialogClose={commentsDialogClose}
          {...postProps}
        />
        <ShareSystem
          shareSystemProps={{
            ...shareSystemProps,
            shareDialogClose,
            isShareDialogOpen,
            socket,
            setSharesCount,
            sharesCount,
            setPosts,
          }}
        />
      </>
    )
  )
}

export default FeedPostBottomModel
