import React from 'react'
import { useTranslation } from 'react-i18next'
import DefaultViewModel from '../../projectProfile/ProjectProfileComponents/DefaultViewModel'
import DefaultImageGrapper from '../../smallComponents/DefaultImageGrapper'

const SettingsDeafultView = () => {
  const { t } = useTranslation()
  return (
    <DefaultViewModel
      image={DefaultImageGrapper('settings')}
      title={t('Settings')}
    />
  )
}

export default SettingsDeafultView
