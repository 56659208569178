import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../../context/AuthContext'
import ButtonsSystem from '../../../buttonsSystem/ButtonsSystem'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import FormDataLangModulator from '../../../formsSystem/FormDataLangModulator'
import { SettingsContext } from '../../../../context/SettingsContext/SettingsContext'
import { toast } from 'react-toastify'

const SaveUserGeneralChanges = ({ formData, DCGProps }) => {
  const [isClicked, setIsClicked] = useState(false)
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)
  const { dispatch: setDispatch, settings } = useContext(SettingsContext)
  const newFormData = FormDataLangModulator(formData, t)

  const isModified =
    (newFormData?.Language !== settings?.Language &&
      newFormData?.Language !== undefined) ||
    (newFormData?.Mode !== settings?.Mode && newFormData?.Mode !== undefined)

  useEffect(() => {
    if (!isModified) setIsClicked(false)
  }, [isModified])
  const lang = i18next.language
  const rtl = lang === 'ar'
  const buttonsArray = [
    {
      title:
        !isModified || isClicked
          ? rtl
            ? 'تم الحفظ'
            : 'Saved'
          : rtl
          ? 'حفظ التغييرات'
          : 'Save Changes',
      funs: async () => {
        setIsClicked(true)
        try {
          const resSet = await axios.patch(`/userSettings/${user?._id}`, {
            ...newFormData,
            userId: user?._id,
          })
          const langCode = resSet.data.settings.Language.toLowerCase().slice(
            0,
            2
          )
          i18next.changeLanguage(langCode)
          setDispatch({
            type: 'UPDATE',
            payload: resSet.data.settings,
          })
          toast.success(
            rtl
              ? 'تم حفظ التغييرات بنجاح'
              : 'Changes have been saved successfully'
          )
        } catch (error) {
          console.log(error)
        }
      },
      disabled: !isModified || isClicked,
    },
  ]
  return <ButtonsSystem buttonsArray={buttonsArray} {...DCGProps} />
}

export default SaveUserGeneralChanges
