import React, { useContext, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import FormsComponents from '../../../../formsSystem/FormsComponents'
import axios from 'axios'
import { AuthContext } from '../../../../../context/AuthContext'
import { useTranslation } from 'react-i18next'
import ToastifyGeneralError from '../../../../smallComponents/ToastifyGeneralError'

const styles = () => ({
  container: {
    width: 'auto',
    display: 'flex',
    justifyContent: 'center',
  },
  panelWrapper: {
    position: 'absolute !important',
    zIndex: '2 !important',
    display: 'flex',
    bottom: 10,
    justifyContent: 'space-around !important',
    alignItems: 'center !important',
    alignContent: 'center !important',
    width: '70%',
    borderRadius: 15,
  },
})

const PCNewMessage = ({
  classes,
  isDarkModeActive,
  chat,
  setChatMessages,
  pageProps,
  disabledInput,
}) => {
  const { socket } = pageProps
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)
  const [textContent, setTextContent] = useState('')
  const [chosenHashTagElements, setChosenHashTagElements] = useState([])
  const [images, setImages] = useState([])
  const [playerLink, setPlayerLink] = useState()

  const newMessage = {
    conversationId: chat._id,
    senderId: user._id,
    text: textContent,
    chosenHashTagElements,
    images,
    playerLink,
  }
  const receiverId = chat.members.find((id) => id !== user._id)
  const onSend = async () => {
    if (chat._id && user._id) {
      socket.current.emit('sendMessage', {
        senderId: user._id,
        receiverId,
        newMessage,
      })
      try {
        const res = await axios.post(`/messages`, newMessage)
        setChatMessages((mssgs) => [...mssgs, res.data])
      } catch (error) {
        ToastifyGeneralError(error)
      }
    }
  }
  const formsCompsProps = {
    formComponentIndex: 3,
    chosenHashTagElements,
    setChosenHashTagElements,
    textContent,
    setTextContent,
    placeholder: t('New_Message'),
    images,
    setImages,
    isDarkModeActive,
    onSend,
    pathName: `/chats/${chat._id}`,
    onKeyDown: (e) => {
      if (e.key === 'Enter') {
        onSend()
        setTextContent('')
        setImages([])
        e.preventDefault()
      }
    },
    link: playerLink,
    setLink: setPlayerLink,
    disabled_chat: disabledInput,
  }
  return (
    <section className={classes.container}>
      <section
        className={classes.panelWrapper}
        style={{
          backgroundColor: isDarkModeActive ? 'rgba(49, 48, 53, 0.9)' : 'white',
          color: isDarkModeActive ? 'white' : null,
        }}
      >
        <FormsComponents formsCompsProps={formsCompsProps} />
      </section>
    </section>
  )
}

const WrappedPCNewMessage = withStyles(styles)(PCNewMessage)

export default WrappedPCNewMessage
