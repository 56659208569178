const ProfileGeneralSettingsConfigs = ({ settings }) => {
  return [
    {
      title: 'Language',
      formComponentType: 'select',
      value: settings?.Language,
      menuOptions: ['English', 'Arabic'],
    },
    {
      title: 'Mode',
      formComponentType: 'select',
      value: settings?.Mode,
      menuOptions: ['Dark', 'Normal'],
    },
  ]
}

export default ProfileGeneralSettingsConfigs
