import { Card } from '@mui/material'
import React from 'react'
import { AiFillCheckCircle } from 'react-icons/ai'
import withStyles from '@mui/styles/withStyles'
import i18next from 'i18next'

const styles = () => ({
  mssgCard: {
    borderRadius: '20px !important',
    padding: 10,
    fontSize: 15,
    margin: '10px auto 0px auto',
    display: 'flex',
    alognItems: 'center',
  },
  mssgText: {
    fontWeight: 500,
  },
  checkIcon: {
    color: 'green',
  },
})

const MessageCardModel = ({ classes, messageText, isDarkModeActive }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <Card
      className={classes.mssgCard}
      sx={{
        background: isDarkModeActive ? 'rgba(0,0,0,0.5)' : 'inherit',
        color: isDarkModeActive ? 'white' : 'inherit',
      }}
    >
      <AiFillCheckCircle
        className={classes.checkIcon}
        style={{
          margin: rtl ? '3px 0px -3px 10px' : '3px 10px -3px 0',
        }}
      />
      <span className={classes.mssgText}>{messageText}</span>
    </Card>
  )
}

const WrappedMessageCardModel = withStyles(styles)(MessageCardModel)

export default WrappedMessageCardModel
