import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Avatar, Divider, ListItem } from '@mui/material'
import StateGenerator from '../smallComponents/StateGenerator'
import axios from 'axios'
import { AuthContext } from '../../context/AuthContext'
import i18next from 'i18next'
import { useNavigate } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import DarkModeColorCoder from '../smallComponents/DarkModeColorCoder'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginTop: '16px',
    position: 'relative',
    overflowY: 'hidden',
    height: '100vh',
    '&:hover': {
      overflowY: 'auto',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  sectionTitle: {
    color: '#65676B',
    fontSize: 17,
    fontFamily: '"Segoe UI Historic", Segoe UI, Helvetica, Arial, sans-serif',
    fontWeight: 'bold',
  },
  RightbarList: {
    padding: 0,
    margin: 0,
    listStyle: 'none',
    height: '100% !important',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center !important',
    justifyContent: 'center !important',
  },
  RightbarItemText: {
    fontFamily: '"Segoe UI Historic", Segoe UI, Helvetica, Arial, sans-serif',
    fontSize: 15,
    cursor: 'pointer',
    fontWeight: 'bold',
    color: '#050505',
    margin: 'auto 15px',
  },
  RightbarIcons: {
    fontSize: 25,
    cursor: 'pointer',
  },
}))

const Rightbar = ({ isDarkModeActive }) => {
  const classes = useStyles()
  const { user } = useContext(AuthContext)
  const { isGeneralDialogOpen, GeneralDialogOpener, GeneralDialogClose } =
    StateGenerator({ GeneralProps: true })
  const [followings, setFollowings] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    const fetchUserFollowings = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(`/users/followings/${user?._id}`)
        setFollowings(res.data.followings)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    fetchUserFollowings()
  }, [user?._id])
  const modFollowings = followings.map((fol) => ({
    ...fol,
    name: fol.firstName + ' ' + fol.lastName,
    id: fol._id,
    image: fol.profilePicture,
  }))

  const lang = i18next.language
  const darkColor = DarkModeColorCoder('Text_white')
  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        {/* <span
          className={classes.sectionTitle}
          style={{ color: isDarkModeActive && darkColor }}
        >
          {lang === 'ar' ? 'مُمول' : 'Sponsored'}
        </span>
        some ads - will disappear if no ad exists
        <Divider
          style={{
            backgroundColor: isDarkModeActive && darkColor,
            margin: '10px',
          }}
        /> */}
        <span
          className={classes.sectionTitle}
          style={{ color: isDarkModeActive && darkColor }}
        >
          {lang === 'ar' ? 'المتابَعون' : 'Followings'}
        </span>
        <nav id='nav' role='navigation'>
          <ul className={classes.RightbarList}>
            {/* make an infinite scrolling effect here  - remember this requires adjusting the backend to support the pagination */}
            {/* <InfiniteScroll
              dataLength={modFollowings?.length}
              next={() => setPage((prev) => prev + 1)}
              hasMore={currentResponse}
              pullDownToRefresh
              refreshFunction={() => setPage(1)}
              pullDownToRefreshThreshold={10}
              scrollThreshold={'200px'}
              endMessage={<FeedFooter />}
              loader={
                <div style={{ overflow: 'hidden' }}>
                  <FeedSkeleton />
                </div>
              }
            >
              {isLoading && page === 1 ? (
                <Skeleton type={'feed'} />
              ) : (
                RepetionRemover(posts)?.map((post, index) => (
                  <HomeFeedPostModel
                    isDarkModeActive={isDarkModeActive}
                    key={post?._id + index}
                    post={post}
                    isUsedIn={isUsedIn}
                    posts={posts}
                    socket={socket}
                    setPosts={setPosts}
                    postsUsers={postsUsers}
                    sharedPosts={sharedPosts}
                    sharedPostsUsers={sharedPostsUsers}
                    hideAddUserIcon={true}
                  />
                ))
              )}
            </InfiniteScroll> */}
            {modFollowings.map((fol, i) => {
              return (
                <ListItem
                  key={i}
                  sx={{ borderRadius: 25 }}
                  style={{
                    width: '100%',
                    margin: '2px 0',
                  }}
                  button
                  onClick={() => navigate(`/profile/${fol?.id}`)}
                >
                  <Avatar style={{ width: 30, height: 30 }} src={fol?.image} />
                  <span
                    className={classes.RightbarItemText}
                    style={{ color: isDarkModeActive && 'white' }}
                  >
                    {fol?.name}
                  </span>
                </ListItem>
              )
            })}
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default Rightbar
