import React from 'react'
import { FcNext, FcPrevious } from 'react-icons/fc'
import withStyles from '@mui/styles/withStyles'
import i18next from 'i18next'

const styles = () => ({
  nextIcon: {
    margin: '0px 3px 0px 3px',
  },
  path: {
    fontWeight: 500,
    fontSize: 15,
    cursor: 'pointer',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    transition: '0.3s',
    '&:hover': {
      fontSize: 16,
      opacity: 0.5,
    },
  },
  iconWrapper: {
    display: 'flex',
    alignContent: 'center  ',
    alignItems: 'center  ',
    justifyContent: 'center',
  },
  titleWrapper: {
    display: 'flex',
    alignContent: 'center  ',
    alignItems: 'center  ',
    justifyContent: 'center',
  },
  '@media (max-width: 600px)': {
    path: {
      fontWeight: 500,
      fontSize: 12,
      '&:hover': {
        fontSize: 13,
      },
    },
  },
})

const PathModelUnit = ({
  classes,
  folderPath,
  isMenuClicked,
  isDarkModeActive,
}) => {
  const lang = i18next.language
  return (
    <>
      <span
        onClick={folderPath.pathFuns}
        className={classes.path}
        style={{
          display: isMenuClicked ? 'flex' : 'none',
          color: isDarkModeActive ? 'white' : 'inherit',
        }}
      >
        <span className={classes.iconWrapper}>
          {/* this will be the case untill i manage to make all paths in form of objects , as any path further than the first one is a string, i might keep it like this, , i mean that in any way i will not add icons to the paths other htan the first one */}
          {typeof folderPath.pathValue === 'object'
            ? folderPath.pathValue?.icon
            : null}
        </span>
        <span className={classes.titleWrapper}>
          {typeof folderPath.pathValue === 'object'
            ? folderPath.pathValue?.title
            : folderPath.pathValue}
        </span>
      </span>
      {lang === 'ar' ? (
        <FcPrevious
          size={14}
          style={{
            display: !folderPath.pathValue || !isMenuClicked ? 'none' : null,
          }}
          className={classes.nextIcon}
        />
      ) : (
        <FcNext
          size={14}
          style={{
            display: !folderPath.pathValue || !isMenuClicked ? 'none' : null,
          }}
          className={classes.nextIcon}
        />
      )}
    </>
  )
}

const WrappedPathModelUnit = withStyles(styles)(PathModelUnit)

export default WrappedPathModelUnit
