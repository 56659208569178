import React, { useContext } from 'react'
import { Card, CardActionArea, Grid } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import DarkModeColorCoder from '../../../smallComponents/DarkModeColorCoder'
import { SettingsContext } from '../../../../context/SettingsContext/SettingsContext'

const styles = () => ({
  controlPanelButtonCard: {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
      opacity: '0.7',
    },
    borderRadius: '20px !important',
    backgroundColor: 'rgb(65, 92,139) !important',
  },
  clickedControlPanelButtonCard: {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: '20px !important',
    background:
      'linear-gradient(75deg, rgb(94, 94, 173) 15%, rgb(105, 113, 122) 100%)',
  },

  titleIconWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    fontWeight: 700,
    fontSize: 17,
    padding: 5,
    alignContent: 'center',
  },

  controlPanelItemText: {
    color: 'white',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
  },
  '@media (max-width: 1000px)': {
    controlPanelItemText: {
      display: 'none',
    },
  },
})

const ControlPanelButtonModel = ({ classes, button }) => {
  const { settings } = useContext(SettingsContext)
  const isDarkModeActive = settings?.Mode === 'Dark'
  return (
    <Grid item xs={3}>
      <Card
        className={classes.controlPanelButtonCard}
        style={{
          margin: button.margin,
          background: isDarkModeActive
            ? DarkModeColorCoder('Blue_gradient_transp')
            : null,
        }}
        onClick={() => button.openFun()}
      >
        <CardActionArea
          onClick={() => button.openFun()}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '5px',
            background: isDarkModeActive
              ? DarkModeColorCoder('Blue_gradient_transp')
              : null,
          }}
          className={classes.controlPanelButtonCard}
        >
          <span className={classes.titleIconWrapper}>
            <span>{button.icon}</span>
            <span className={classes.controlPanelItemText}>{button.name}</span>
          </span>
        </CardActionArea>
      </Card>
    </Grid>
  )
}

const WrappedControlPanelButtonModel = withStyles(styles)(
  ControlPanelButtonModel
)

export default WrappedControlPanelButtonModel
