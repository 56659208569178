import React, { useContext, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import { Card } from '@mui/material'
import ShareSystem from '../../sharingSystem/ShareSystem'
import NewProject from '../../newProject/NewProject'
import HomeInteractionsBarButtons from './HomeInteractionsBarComponents/HomeInteractionsBarButtons'
import PostProps from './HomeFeedPostModelComponents/PostProps'
import NewFormDialogModel from '../../formsSystem/NewFormDialogModel'
import NewIdeaForm from '../../profile/ProfileComponents/ProfileControlPanelComponents/ControlPanelDialogsContentComponents/IdeasInquiriesComponents/MyIdeasComponents/NewIdeaForm'
import StateGenerator from '../../smallComponents/StateGenerator'
import { AuthContext } from '../../../context/AuthContext'
import WindowSize from '../../smallComponents/WindowSize'
import DarkModeColorCoder from '../../smallComponents/DarkModeColorCoder'
import i18next from 'i18next'

const styles = () => ({
  shareContainer: {
    width: '85%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '30px !important',
    '&:focus-within': {
      boxShadow: '0px 16px 70px -12.125px rgba(0, 0, 0, 0.3)',
    },
  },

  '@media (max-width: 600px)': {
    shareContainer: {
      borderRadius: '0 !important',
      width: '100%',
    },
  },
})

const HomeInteractionsBar = ({
  classes,
  setIsPublishClicked,
  isDarkModeActive,
  userId,
  showInteractionBar,
  socket,
  setPosts,
  setPostsUsers,
  profileMode,
}) => {
  const { user } = useContext(AuthContext)
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { shareDialogOpener, shareDialogClose, isShareDialogOpen } = PostProps(
    {},
    {
      shareSystemProps: true,
    }
  )
  const { isGeneralDialogOpen, GeneralDialogOpener, GeneralDialogClose } =
    StateGenerator({
      GeneralProps: true,
    })
  //new project dialoge funs
  const [isNewProjectDialogOpen, setIsNewProjectDialogOpen] = useState(false)
  const newProjectDialogOpener = () => {
    setIsNewProjectDialogOpen(true)
  }
  const newProjectDialogClose = () => {
    setIsNewProjectDialogOpen(!isNewProjectDialogOpen)
  }

  const shareSystemProps = {
    shareDialogClose: shareDialogClose,
    isShareDialogOpen: isShareDialogOpen,
    shareTypeIndex: 1,
    setIsPublishClicked,
    user,
    socket,
    setPosts,
    setPostsUsers,
    isDarkModeActive,
  }
  const windowSize = WindowSize()
  return (
    <Card
      className={classes.shareContainer}
      sx={{
        background: isDarkModeActive && DarkModeColorCoder('Grey_light'),
        color: isDarkModeActive ? 'white' : null,
      }}
      style={{ display: userId !== user._id && !showInteractionBar && 'none' }}
    >
      {isShareDialogOpen && <ShareSystem shareSystemProps={shareSystemProps} />}
      {isNewProjectDialogOpen && (
        <NewProject
          newProjectDialogClose={newProjectDialogClose}
          isNewProjectDialogOpen={isNewProjectDialogOpen}
          isDarkModeActive={isDarkModeActive}
          socket={socket}
        />
      )}
      <HomeInteractionsBarButtons
        shareDialogFormOpener={shareDialogOpener}
        newProjectDialogOpener={newProjectDialogOpener}
        GeneralDialogOpener={GeneralDialogOpener}
        user={user}
        isDarkModeActive={isDarkModeActive}
      />
      {isGeneralDialogOpen && (
        <NewFormDialogModel
          NewFormComponent={
            <NewIdeaForm
              DCGProps={{ isDarkModeActive }}
              GeneralDialogClose={GeneralDialogClose}
            />
          }
          newFormDialogClose={GeneralDialogClose}
          isNewFormDialogOpen={isGeneralDialogOpen}
          dialogTitle={rtl ? 'فكرة جديدة' : 'New Idea'}
          isDarkModeActive={isDarkModeActive}
        />
      )}
    </Card>
  )
}

const WrappedHomeInteractionsBar = withStyles(styles)(HomeInteractionsBar)
export default WrappedHomeInteractionsBar
