import React from 'react'
import { useState } from 'react'
import StepperBottom from './StepperSystemComponents/StepperBottom'
import StepperContent from './StepperSystemComponents/StepperContent'
import StepperTop from './StepperSystemComponents/StepperTop'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  stepperContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
})

const StepperComponent = ({
  classes,
  stepperStepsContentArray,
  isDarkModeActive,
}) => {
  // stepper funs
  const [activeLevel, setActiveLevel] = useState(0)
  const [skipped, setSkipped] = useState(new Set())
  const isLevelSkipped = (step) => {
    return skipped.has(step)
  }

  const handleNext = () => {
    let newSkipped = skipped
    if (isLevelSkipped(activeLevel)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeLevel)
    }

    setActiveLevel((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
  }

  const handleEdit = () => {
    setActiveLevel((prevActiveStep) => prevActiveStep - 1)
  }

  // stepper data
  const setpperContent = stepperStepsContentArray.filter(
    (_, stepIndex) => stepIndex < stepperStepsContentArray.length - 1
  )

  const finalView =
    stepperStepsContentArray[stepperStepsContentArray.length - 1]

  return (
    <article className={classes.stepperContainer}>
      <StepperTop
        activeLevel={activeLevel}
        setpperContent={setpperContent}
        isDarkModeActive={isDarkModeActive}
      />
      <StepperContent
        activeLevel={activeLevel}
        finalView={finalView}
        setpperContent={setpperContent}
        isDarkModeActive={isDarkModeActive}
      />
      <StepperBottom
        handleEdit={handleEdit}
        activeLevel={activeLevel}
        setpperContent={setpperContent}
        handleNext={handleNext}
        isDarkModeActive={isDarkModeActive}
      />
    </article>
  )
}

const WrappedStepperComponent = withStyles(styles)(StepperComponent)

export default WrappedStepperComponent
