import React, { useState, useEffect } from 'react'
import DialogModelUnit from './DialogModelUnit'

const DialogModelIFunctionalUnit = ({
  isSwipeableOpen,
  setIsSwipeableOpen,
  DialogModelUnitMenu,
  DialogModelUnitContentArray,
  swibableHeaderCardComponent,
  showSearchBar,
  hideSecondPathSearch,
  hideThirdPathSearch,
  hideFourthPathSearch,
  hideDialogModelUnitMenu,
  setDMFUProps,
  isDarkModeActive,
}) => {
  const [secondLevelFolderPath, setSecondLevelFolderPath] = useState()
  const [thirdLevelFolderPath, setThirdLevelFolderPath] = useState()

  // the idea behind this function is that it sends its value and setter to two places, the first is the folderPath system where it checks if the button is clicked or not, and send back the result to the file system to control the excution of the closing function of folders.
  const [stepBackFun, setStepBackFun] = useState(false)
  // same as 1 but for sub-department model, 3rd path
  const [stepBackFun2, setStepBackFun2] = useState(false)

  // this will be the fourth level for the products and services-tasks profiles
  const [fourthLevelFolderPath, setFourthLevelFolderPath] = useState()
  const [stepBackFun3, setStepBackFun3] = useState(false)

  // search query, holding the search input value and sending it to deps so affect the view based on result
  const [searchQuery, setSearchQuery] = useState('')
  // this state should go side by side with query state, to enhance the prformance of the input
  const [searchInputValue, setSearchInputValue] = useState(searchQuery)

  // search placeholder
  const [placeholder, setPlaceholder] = useState('')

  // controlling state of appearnce and disappearance of search icons and associated results in folderPathSystem
  const [showSearchInput, setShowSearchInput] = useState(false)

  const [menuItemId, setMenuItemId] = useState()

  // for alphabetic ordering icon
  const [alphabeticOrderDir, setAlphabeticOrderDir] = useState(true)

  const folderPathProps = {
    secondLevelFolderPath,
    stepBackFun,
    setStepBackFun,
    thirdLevelFolderPath,
    stepBackFun2,
    setStepBackFun2,
    setSecondLevelFolderPath,
    setThirdLevelFolderPath,
    fourthLevelFolderPath,
    setFourthLevelFolderPath,
    stepBackFun3,
    setStepBackFun3,
  }

  const searchSystemProps = {
    setSearchQuery,
    searchQuery,
    placeholder,
    showSearchInput,
    setShowSearchInput,
    showSearchBar,
    // for search input performance
    searchInputValue,
    setSearchInputValue,
    // for the second view search icon removal
    hideSecondPathSearch,
    setPlaceholder,
    alphabeticOrderDir,
    hideThirdPathSearch,
    hideFourthPathSearch,
  }

  const DMUSystemProps = {
    DialogModelUnitMenu,
    DialogModelUnitContentArray,
    // swibable controllers
    isSwipeableOpen,
    setIsSwipeableOpen,
    setMenuItemId,
    // for the top part of the mobile view swibable drawer
    swibableHeaderCardComponent,
    // dark mode
    isDarkModeActive,
    // for alphebetic order
    alphabeticOrderDir,
    setAlphabeticOrderDir,
    hideDialogModelUnitMenu,
  }

  //   if any problems happen to be associated with the rendering of the useEffect and useState,you may test the variables, but exclude any variable contains components like DialogModelUnitContentArray as it causes infinite rendering.
  useEffect(() => {
    setDMFUProps({
      folderPathProps: folderPathProps,
      searchSystemProps: searchSystemProps,
      menuItemId: menuItemId,
    })
  }, [
    stepBackFun,
    stepBackFun2,
    stepBackFun3,
    searchQuery,
    isSwipeableOpen,
    menuItemId,
    showSearchInput,
    setIsSwipeableOpen,
    setMenuItemId,
    alphabeticOrderDir,
  ])

  return (
    <DialogModelUnit
      DMUSystemProps={DMUSystemProps}
      folderPathProps={folderPathProps}
      searchSystemProps={searchSystemProps}
    />
  )
}

export default DialogModelIFunctionalUnit
