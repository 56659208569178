import React, { useEffect } from 'react'
import withStyles from '@mui/styles/withStyles'
import FormsComponents from '../../formsSystem/FormsComponents'
import i18next from 'i18next'

const lang = i18next.language
const rtl = lang === 'ar'

const styles = () => ({
  searchInputWrapper: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
  },
  searchInput: {
    border: 'none',
    width: '70%',
    opacity: 0.4,
    borderRadius: 10,
    marginRight: rtl && 10,
    marginLeft: rtl && 10,
    '&:focus': {
      outline: 'none',
      opacity: 0.8,
    },
    '&::placeholder': {
      color: 'rgb(26, 23, 23)',
      fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
      fontWeight: 200,
      fontSize: 13.2,
    },
  },

  '@media (max-width: 600px)': {},
})

const FolderPathInput = ({
  classes,
  searchSystemProps,
  folderPathProps,
  showSearchBar,
  //-------------- not defined
  project,
  isDarkModeActive,
}) => {
  const { stepBackFun, stepBackFun2 } = folderPathProps
  const {
    showSearchInput,
    setSearchQuery,
    placeholder,
    searchInputValue,
    setSearchInputValue,
  } = searchSystemProps

  useEffect(() => {
    if (typeof setSearchQuery === 'function' && !showSearchBar)
      setSearchQuery('')
    if (typeof setSearchInputValue === 'function') setSearchInputValue('')
  }, [showSearchBar, stepBackFun2, stepBackFun])

  const formsCompsProps = {
    formComponentIndex: 2,
    textContent: searchInputValue,
    setSearchQuery: setSearchQuery,
    setTextContent: setSearchInputValue,
    searchInputClass: classes.searchInput,
    placeholder: ' ' + placeholder,
    project,
    isDarkModeActive,
  }
  return (
    <section
      className={classes.searchInputWrapper}
      style={{ display: showSearchInput ? null : 'none' }}
    >
      <FormsComponents formsCompsProps={formsCompsProps} />
    </section>
  )
}

const WrappedFolderPathInput = withStyles(styles)(FolderPathInput)

export default WrappedFolderPathInput
