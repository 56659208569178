import React from 'react'
import UserFollowers from './UserContributorsComponents/UserFollowers'
import UserFollowings from './UserContributorsComponents/UserFollowings'
import UserProjectContributors from './UserContributorsComponents/UserProjectContributors'
import ContributorsDefaultView from './UserContributorsComponents/ContributorsDefaultView'

const UserContributors = (props) => {
  return [
    <UserFollowers {...props} />,
    <UserFollowings {...props} />,
    <UserProjectContributors {...props} />,
    <ContributorsDefaultView {...props} />,
  ]
}

export default UserContributors
