import React, { useContext, useEffect, useState } from 'react'
import ModelDialogWrappingII from '../../../../dialogModelUnit/ModelDialogWrappingII'
import MChatDialogHeader from './MChatDialogComponents/MChatDialogHeader'
import MChatBody from './MChatDialogComponents/MChatBody'
import axios from 'axios'
import { AuthContext } from '../../../../../context/AuthContext'

const MChatDialog = ({
  chat,
  chatDialogClose,
  isChatDialogOpen,
  isDarkModeActive2,
  pageProps,
}) => {
  const [isDarkModeActive, setIsDarkModeActive] = useState(isDarkModeActive2)
  const [chattedUser, setChattedUser] = useState({})
  const { user } = useContext(AuthContext)

  useEffect(() => {
    const otherId = chat?.members?.find((id) => id !== user._id)
    const chattedUser = async () => {
      try {
        const res = await axios.get(`/users/oneUser/${otherId}`)
        let us = res.data.user
        setChattedUser({
          ...us,
          name: us.firstName + ' ' + us.lastName,
          image: us.profilePicture,
        })
      } catch (error) {
        console.log(error)
      }
    }
    if (chat?.members?.length > 1 && otherId) chattedUser()
  }, [chat?.members])

  const [onlineUsers, setOnlineUsers] = useState(null)

  return (
    <ModelDialogWrappingII
      isDarkModeActive={isDarkModeActive}
      setIsDarkModeActive={setIsDarkModeActive}
      dialogsArray={[
        {
          title: (
            <MChatDialogHeader
              chat={{
                ...chat,
                chatter: chattedUser,
              }}
              pageProps={pageProps}
              isDarkModeActive={isDarkModeActive}
              chatDialogClose={chatDialogClose}
              // to be defined
              onlineUsers={onlineUsers}
            />
          ),
          closeFun: () => {
            chatDialogClose()
          },
          open: isChatDialogOpen,
          content: (
            <MChatBody
              isDarkModeActive={isDarkModeActive}
              chat={{
                ...chat,
                chatter: chattedUser,
              }}
              pageProps={pageProps}
              setOnlineUsers={setOnlineUsers}
            />
          ),
        },
      ]}
      mdialogMargins={'auto -24px auto -24px'}
    />
  )
}

export default MChatDialog
