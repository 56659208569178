import { ListItem, Avatar, Divider, Card } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import TextEngine from '../../../textProcessingEngine/TextEngine'
import GlassLayer from '../../../smallComponents/GlassLayer'
import moment from 'moment'
import { AuthContext } from '../../../../context/AuthContext'
import axios from 'axios'
import i18next from 'i18next'

const styles = () => ({
  notificationUnit: {
    margin: '10px',
    borderRadius: '10px !important',
  },

  listItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between !important',
    width: '100% !important',
  },
  avatar: {
    height: '50px !important',
    width: '50px !important',
  },
  messageWrapper: {
    width: '100%',
    height: '100%',
    padding: '3px !important',
    fontSize: '15px',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '54px',
    borderRadius: '10px !important',
    '&:hover': {
      filter: 'brightness(80%)',
    },
  },

  userMessageName: {
    fontSize: 13,
    fontWeight: 650,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRadius: 10,
    padding: '2px 5px',
    opacity: 0.9,
    backgroundColor: 'rgb(182, 192, 238)',
    color: 'white',
  },
  lastMessage: {
    fontSize: 12,
    fontFamily: 'inherit',
    fontWeight: 500,
    overflow: 'hidden',
    height: 20,
  },
  chatFriendCompanyName: {
    borderRadius: 10,
    padding: 4,
    fontSize: 10,
    fontWeight: 700,
    fontFamily:
      "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande','Lucida Sans Unicode', Geneva, Verdana, sans-serif",
  },
  dateWrapper: {
    fontSize: '9px',
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
})

const MChatListUnit = ({ classes, props, element }) => {
  const {
    chatDialogOpener,
    isDarkModeActive,
    setChosenChat,
    socket,
    chatsUsers,
    lastMessages,
    arrivalMessage,
  } = props
  const { user } = useContext(AuthContext)
  const currentChatter =
    chatsUsers?.find(
      (u) => u?._id !== user?._id && element?.members?.includes(u?._id)
    ) || element?.chatter
  const [chattedUser, setChattedUser] = useState(currentChatter)
  const inlastChatMessage = lastMessages?.find(
    (m) => m?.conversationId === element?._id
  )
  const [lastChatMessage, setLastMessage] = useState(inlastChatMessage)
  useEffect(() => {
    setLastMessage(
      arrivalMessage?.conversationId === element?._id
        ? arrivalMessage
        : lastMessages?.find((m) => m?.conversationId === element?._id)
    )
  }, [element, arrivalMessage])

  const chattedUserId = element?.members?.filter((id) => id !== user._id)[0]

  useEffect(() => {
    const fetchChattedUser = async () => {
      try {
        const res = await axios.get(`/users/${chattedUserId}`)
        setChattedUser({
          image: res.data.image,
          name: res.data.firstName + ' ' + res.data.lastName,
        })
      } catch (error) {
        console.log(error)
      }
    }
    if (chattedUserId && !currentChatter) fetchChattedUser()
  }, [chattedUserId, currentChatter])

  const lastMessage = (
    <TextEngine
      textContent={lastChatMessage?.text || ''}
      textPackage={lastChatMessage}
      textFuns=''
      socket={socket}
    />
  )

  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <Card
      className={classes.notificationUnit}
      sx={{
        color: isDarkModeActive ? 'white' : null,
        background: isDarkModeActive
          ? 'rgba(255, 255, 255, 0.2) !important'
          : null,
        display: lastChatMessage ? null : 'none',
      }}
    >
      <ListItem
        onClick={() => {
          chatDialogOpener()
          setChosenChat(element)
        }}
      >
        <span className={classes.container} onClick={chatDialogOpener}>
          <Avatar
            src={chattedUser?.image}
            alt={`${chattedUser?.name}'s Image`}
            className={classes.avatar}
          />

          <span className={classes.messageWrapper}>
            <div className={classes.userMessageName}>
              <span> {chattedUser?.name}</span>
            </div>
            <Divider />
            <div className={classes.lastMessage}>{lastMessage}</div>
            <GlassLayer />
            <span className={classes.dateWrapper}>
              {' '}
              {moment(lastChatMessage?.createdAt).calendar()}
            </span>
          </span>
        </span>
      </ListItem>
    </Card>
  )
}

const WrappedMChatListUnit = withStyles(styles)(MChatListUnit)

export default WrappedMChatListUnit
