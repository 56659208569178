import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Divider } from '@mui/material'
import TopBarProfileAvatar from './TopBarComps/TopBarProfileAvatar'
import Search from '../rightbar/rightbar-Components/Search'
import TopBarNotificationsIcons from './TopBarComps/TopBarNotificationsIcons'
import TopBarDirectIcons from './TopBarComps/TopBarDirectIcons'
import TopBarLogo from './TopBarComps/TopBarLogo'

const useStyles = makeStyles((theme) => ({
  container: {
    height: '52px',
    position: 'sticky',
    top: 0,
    zIndex: 9,
  },
  wrapper: {
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    padding: '0 24px',
    // backgroundColor: 'white !important',
    boxShadow: 'rgba(0, 0, 0, 0.10) 1.95px 1.95px 2.6px',
  },
  hutupiaLogo: {
    width: 50,
    height: 50,
    background: 'rgb(118, 118, 148)',
    borderRadius: '50%',
  },
  logoTitle: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    fontFamily: "'Inter', sans-serif",
    // fontFamily: '"Segoe UI Historic", Segoe UI, Helvetica, Arial, sans-serif',
    color: 'rgb(0, 119, 181)',
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer',
    margin: 'auto 20px',
  },
  iconsWrapper: {
    display: 'flex',
    justifyItems: 'space-between',
  },
  iconsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  logoWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  searchWrapper: {
    width: '30vw',
    height: 30,
    background: 'rgba(200, 208, 219, 0.2)',
    padding: 5,
    borderRadius: 15,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    alignContent: 'center',
    margin: '6px auto',
  },
}))

const TopBar = (props) => {
  const { pageProps } = props
  const { isDarkModeActive } = pageProps
  const classes = useStyles()

  const dividerStyle = {
    margin: '0 15px',
  }

  return (
    <header className={classes.container}>
      <div
        className={classes.wrapper}
        style={{
          backgroundColor: isDarkModeActive ? '#232526' : 'white',
          color: isDarkModeActive ? '#BCC0C4' : 'white',
        }}
      >
        <div className={classes.logoWrapper}>
          <TopBarLogo {...pageProps} />
          <div className={classes.searchWrapper}>
            <Search pageProps={pageProps} />
          </div>
          <TopBarDirectIcons {...props} />
        </div>
        <div className={classes.iconsContainer}>
          <TopBarNotificationsIcons {...props} />
          <Divider
            light
            flexItem
            orientation='vertical'
            variant='middle'
            style={dividerStyle}
          />
          <TopBarProfileAvatar {...props} />
        </div>
      </div>
    </header>
  )
}

export default TopBar
