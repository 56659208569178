import React from 'react'
import { useNavigate } from 'react-router-dom'
import withStyles from '@mui/styles/withStyles'
import { RiBuilding2Fill } from 'react-icons/ri'
import { Avatar } from '@mui/material'

const styles = () => ({
  postProfileImage: {
    borderRadius: ' 50%',
    objectFit: 'cover',
    cursor: 'pointer',
  },
  avatar: {
    cursor: 'pointer',
    margin: '10px 10px 10px 0 !important',
  },
})

const PTLImage = ({ classes, postProps, user: genUser, wz }) => {
  const PF = process.env.REACT_APP_PUBLIC_FOLDER
  const { post, project, user } = postProps
  const navigate = useNavigate()
  return post.feedsSystemCategory === 'home' ? (
    <img
      className={classes.postProfileImage}
      style={{
        width: wz ? 40 : 30,
        height: wz ? 40 : 30,
      }}
      src={
        user?.profilePicture ||
        genUser?.profilePicture ||
        `${PF}person/noAvatar.png`
      }
      alt='Profile Picture'
      onClick={() => navigate(`/profile/${post?.userId}`)}
    />
  ) : (
    <Avatar
      style={{
        width: wz ? '40px !important' : '30px !important',
        height: wz ? '40px !important' : '30px !important',
      }}
      src={project?.projectPhoto || user?.projectPhoto || genUser?.projectPhoto}
      className={classes.avatar}
      onClick={() => navigate(`/projectProfile/${post?.userId}`)}
    >
      <RiBuilding2Fill size={wz ? 25 : 20} />
    </Avatar>
  )
}

const WrappedPTLImage = withStyles(styles)(PTLImage)

export default WrappedPTLImage
