import React, { useContext, useState } from 'react'
import ButtonsSystem from '../../../../../buttonsSystem/ButtonsSystem'
import ShareSystem from '../../../../../sharingSystem/ShareSystem'
import PostProps from '../../../../../homeFeed/homeFeedComponents/HomeFeedPostModelComponents/PostProps'
import axios from 'axios'
import { AuthContext } from '../../../../../../context/AuthContext'
import { useTranslation } from 'react-i18next'
import { SettingsContext } from '../../../../../../context/SettingsContext/SettingsContext'

const NavigatorChartUnitButtons = ({ chartData, unit }) => {
  const { settings } = useContext(SettingsContext)
  const isDarkModeActive = settings?.Mode === 'Dark'
  const { user, dispatch } = useContext(AuthContext)
  const { t } = useTranslation()
  const { shareDialogOpener, shareDialogClose, isShareDialogOpen } = PostProps(
    {},
    {
      shareSystemProps: true,
    }
  )
  const shareSystemProps = {
    shareDialogClose,
    isShareDialogOpen,
    shareTypeIndex: 2,
    isDarkModeActive,
    post: {
      postTypeIndex: 1,
      postTextContent: '',
      chartData,
      feedsSystemCategory: 'home',
      homePostFeedModelIndex: 1,
      projectId: unit?._id,
    },
    user,
  }

  const isDefault = user?.defaultProjectId === unit?._id
  const [isDefaultClicked, setIsDefaultClicked] = useState(isDefault)
  const buttonsArray = [
    {
      title: t('Share'),
      funs: () => shareDialogOpener(),
    },
    {
      title: isDefault ? t('Chosen') : t('Default'),
      funs: async () => {
        setIsDefaultClicked(true)
        if (user?._id)
          try {
            await axios.patch(`/users/${user?._id}`, {
              userId: user?._id,
              defaultProjectId: unit?._id,
            })
          } catch (error) {}
        dispatch({
          type: 'LOGIN_SUCCESS',
          payload: {
            ...user,
            defaultProjectId: unit?._id,
          },
        })
      },
      disabled: isDefaultClicked,
    },
  ]
  return (
    <>
      <ButtonsSystem
        buttonsArray={buttonsArray}
        isDarkModeActive={isDarkModeActive}
      />
      <ShareSystem shareSystemProps={shareSystemProps} />
    </>
  )
}

export default NavigatorChartUnitButtons
