import React, { useContext, useState } from 'react'
import { AuthContext } from '../../../../context/AuthContext'
import ControlButtonsWrapper from './ControlButtonsComps/ControlButtonsWrapper'
import ControlButtonUnit from './ControlButtonsComps/ControlButtonUnit'

const ControlButtons = (props) => {
  const { modelCardsContentArray, DialogsComponentContent, profileProps } =
    props
  const [isSwipeableOpen, setIsSwipeableOpen] = useState(false)
  const [clickAwayState, setClickAwayState] = useState(false)
  const [isDialogFullWidth, setIsDialogFullWidth] = useState(false)
  const propsII = {
    ...props,
    isSwipeableOpen,
    setIsSwipeableOpen,
    clickAwayState,
    setClickAwayState,
    isDialogFullWidth,
    setIsDialogFullWidth,
  }
  const { userId } = profileProps
  const { user } = useContext(AuthContext)
  const hide = userId !== user?._id

  return (
    <ControlButtonsWrapper
      hide={hide}
      buttons={modelCardsContentArray.map((button, buttonIndex) => (
        <ControlButtonUnit
          button={button}
          key={buttonIndex}
          propsII={propsII}
          buttonIndex={buttonIndex}
          DialogsComponentContent={DialogsComponentContent}
        />
      ))}
    />
  )
}

export default ControlButtons
