const GPNoFSPanelPropsFirstView = (props) => {
  const {
    GeneralDialogOpener,
    dialogTitle,
    noFSPanelPropsFirstView,
    DCGProps,
  } = props
  return {
    hideCloseArrow: true,
    showGeneralAdd: true,
    GeneralDialogOpener,
    tippyContent: dialogTitle,
    ...noFSPanelPropsFirstView,
    ...DCGProps,
  }
}

export default GPNoFSPanelPropsFirstView
