import React, { useContext } from 'react'
import withStyles from '@mui/styles/withStyles'
import { Card } from '@mui/material'
import UserProfileFollowUnfollow from '../profilesMicroComponents/UserProfileFollowUnfollow'
import UserProfileChatIcon from '../profilesMicroComponents/UserProfileChatIcon'
import UserProfileAddContributor from '../profilesMicroComponents/UserProfileAddContributor'
import MProfilePhoto from './MProfilePhotoCommunicationComponents/MProfilePhoto'
import { AuthContext } from '../../context/AuthContext'
import DarkModeColorCoder from '../smallComponents/DarkModeColorCoder'
import { MdVerified } from 'react-icons/md'
import i18next from 'i18next'

const styles = () => ({
  container: {
    flexDirection: 'column',
    width: '100%',
    display: 'flex',
  },
  name: {
    fontWeight: '600',
    fontSize: '20px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },

  followChatOptionsIcons: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '5px auto 0 auto',
    width: '80%',
  },
  followChatOptionsIconsItem: {
    fontSize: 21,
    padding: 5,
    cursor: 'pointer',
  },

  middleCardWrapper: {
    display: 'flex',
    marginTop: 10,
    flexDirection: 'column',
    height: 80,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
})

const MProfilePhotoCommunication = ({ classes, profileProps }) => {
  const { user, isDarkModeActive } = profileProps
  const { user: currentUser } = useContext(AuthContext)
  const hide = user?._id === currentUser?._id
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <article className={classes.container}>
      <MProfilePhoto {...profileProps} />
      <Card
        className={classes.middleCardWrapper}
        style={{
          background: isDarkModeActive
            ? DarkModeColorCoder('Grey_light')
            : null,
          color: isDarkModeActive ? 'white' : null,
          paddingLeft: !rtl && 170,
          paddingRight: rtl && 170,
        }}
      >
        <div className={classes.name}>
          <span>
            {user?.firstName + ' ' + user?.lastName}{' '}
            {user?.verified && (
              <MdVerified color='blue' style={{ margin: '2px 0 -2px 0' }} />
            )}
          </span>
        </div>
        <section
          className={classes.followChatOptionsIcons}
          style={{ display: hide ? 'none' : 'flex' }}
        >
          <UserProfileFollowUnfollow {...profileProps} />
          <UserProfileChatIcon {...profileProps} chattedUserId={user?._id} />
          <UserProfileAddContributor {...profileProps} />
        </section>
      </Card>
    </article>
  )
}

const WrappedMProfilePhotoCommunication = withStyles(styles)(
  MProfilePhotoCommunication
)

export default WrappedMProfilePhotoCommunication
