import React, { useContext } from 'react'
import withStyles from '@mui/styles/withStyles'
import {
  Avatar,
  Box,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material'
import MTopBarDirect from './MTopBarMenuContentComps/MTopBarDirect'
import MQuickAccess from './MTopBarMenuContentComps/MQuickAccess'
import { AuthContext } from '../../../context/AuthContext'
import MControlPanel from '../../profile/ProfileComponents/MControlPanel'
import TitleHeader from '../../smallComponents/Header'
import { t } from 'i18next'
import { Link } from 'react-router-dom'
import moment from 'moment'

const styles = () => ({
  wrapper: {
    width: '100%',
  },
  contentWrapper: {
    overflowY: 'auto',
  },
  footer: {
    // background: '#f1f1f1',
    padding: '20px',
    marginTop: '20px',
    textAlign: 'center',
  },
  footerLink: {
    margin: '0 10px',
  },
})

const MTopBarMenuContent = ({ classes, pageProps, defaultChartProps }) => {
  const { user } = useContext(AuthContext)
  const { isDarkModeActive } = pageProps
  return (
    <div
      className={classes.wrapper}
      style={{ background: !isDarkModeActive && 'white' }}
    >
      <Paper
        sx={{
          background: 'rgba(255,255,255,0.1)',
          borderRadius: '10px',
          color: isDarkModeActive && 'white',
          margin: '10px',
        }}
      >
        <ListItem button>
          <ListItemIcon>
            <Avatar src={user?.profilePicture} />
          </ListItemIcon>
          <ListItemText
            style={{
              fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
              fontWeight: 500,
            }}
          >
            {user.firstName + ' ' + user.lastName}
          </ListItemText>
        </ListItem>
      </Paper>
      <div className={classes.contentWrapper}>
        <MTopBarDirect
          pageProps={pageProps}
          defaultChartProps={defaultChartProps}
        />
        <TitleHeader headerText={t('Tools')} />
        <MQuickAccess pageProps={pageProps} />
      </div>
      <TitleHeader headerText={t('Control_Panel')} />
      <MControlPanel
        profileProps={{
          ...pageProps,
          user,
          userId: user?._id,
          pageProps,
        }}
      />

      <Box className={classes.footer}>
        <Typography variant='body2'>
          &copy; {t('Copyright')} {moment(Date.now()).format('YYYY')} &bull;{' '}
          {t('Hutupia')} &bull; {t('All_Rights_Reserved')}
        </Typography>
        <Link to='/privacy-policy' className={classes.footerLink}>
          {t('Privacy_Policy')}
        </Link>
        <Link to='/terms-of-service' className={classes.footerLink}>
          {t('Terms_of_Service')}
        </Link>
      </Box>
    </div>
  )
}

const WrappedMTopBarMenuContent = withStyles(styles)(MTopBarMenuContent)

export default WrappedMTopBarMenuContent
