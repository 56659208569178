import axios from 'axios'
import React, { useContext, useState } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import UserPostLikeIcon from './LikeButtonConfigComps/UserPostLikeIcon'
import ProjectPostLikeIcon from './LikeButtonConfigComps/ProjectPostLikeIcon'
import MultiMillify from '../../smallComponents/MultiMillify'
import ToastifyGeneralError from '../../smallComponents/ToastifyGeneralError'
import { useTranslation } from 'react-i18next'

const LikeButtonConfig = (props) => {
  const { post, styles, likesListOpener } = props
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)
  const [likes, setLikes] = useState(post?.likes.length)
  const [isLiked, setIsLiked] = useState(post?.likes.includes(user?._id))
  const likeNumberHandler = async () => {
    setLikes((likes) => (isLiked ? likes - 1 : likes + 1))
    setIsLiked(!isLiked)
    try {
      if (user?._id && post?._id)
        await axios.put(`/posts/${post?._id}/like`, { userId: user?._id })
    } catch (error) {
      console.log(error)
      ToastifyGeneralError(error)
    }
  }

  let likeIconToggle =
    post?.feedsSystemCategory === 'page'
      ? ProjectPostLikeIcon({
        ...props,
        likeNumberHandler,
        likes,
        isLiked,
        user,
      })
      : UserPostLikeIcon({
        ...props,
        likeNumberHandler,
        likes,
        isLiked,
        user,
      })
  let likeIconHandler = !isLiked ? likeIconToggle[0] : likeIconToggle[1]

  return {
    icon: likeIconHandler,
    icon_text: t('Like'),
    value: (
      <span
        style={{
          display: likes > 0 ? null : 'none',
          ...styles?.counterValue,
        }}
      >
        {MultiMillify(likes)}
      </span>
    ),
    openFun: '',
    info: {
      content: (
        <span
          style={{
            display: likes > 0 ? null : 'none',
            ...styles?.counterValue,
          }}
          onClick={likesListOpener}
        >
          {MultiMillify(likes)} {t('Likes')}
        </span>
      ),
    },
  }
}

export default LikeButtonConfig
