import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../context/AuthContext'

const FetchDefaultChart = () => {
  const { user } = useContext(AuthContext)
  const [defaultProject, setDefaultProject] = useState(null)
  const [projectLoading, setProjectLoading] = useState(true)

  useEffect(() => {
    const fetchDefaultProject = async () => {
      setProjectLoading(true)
      try {
        const res = await axios.get(
          `/projects/userProjects/defaultProject/${user?._id}`
        )
        setDefaultProject(res.data.defaultProject)
      } catch (error) {
        console.log(error)
      }
      setProjectLoading(false)
    }
    if (user?.defaultProjectId) fetchDefaultProject()
  }, [user?._id, user?.defaultProjectId])

  const [contributorsChart, setContributorsChart] = useState()
  const [hashtagsChart, setHashtagsChart] = useState()
  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const contribRes = await axios.get(
          `/projectContributors/online/onlineMembers/${user?.defaultProjectId}/chart`
        )
        const hashRes = await axios.get(
          `/searchSystems/hashtags/chart/${user?.defaultProjectId}`
        )
        setContributorsChart(contribRes.data.timeSeriesData)
        setHashtagsChart(hashRes.data.timeSeriesData)
      } catch (error) {
        console.log(error)
      }
    }
    if (user?.defaultProjectId) fetchChartData()
  }, [user?.defaultProjectId])

  const parametersDataArray = [hashtagsChart, contributorsChart]
  const [parameterId, setParameterId] = useState(0)
  const chooseParameter = (event) => {
    setParameterId(Number(event.target.id))
  }
  return {
    defaultProject,
    projectLoading,
    contributorsChart,
    hashtagsChart,
    parametersDataArray,
    parameterId,
    chooseParameter,
  }
}

export default FetchDefaultChart
