import React, { useEffect, useState } from 'react'
import MultiSelectViewerSystem from '../../../../multiSelectViewerSystem/MultiSelectViewerSystem'
import NewProjectChosenContributorModel from './NewProjectChosenContributorModel'
import i18next from 'i18next'

const NewProjectMultiSelectComp = ({
  contributors,
  departmentStates,
  departmentIndex,
  voidElementsAlertText,
  setClickAwayState,
  isDarkModeActive,
}) => {
  const [selectedElements, setSelectedElements] = useState([])

  useEffect(() => {
    departmentStates[departmentIndex].setSelectedContributors(
      contributors.filter((cont) => selectedElements.includes(cont?._id))
    )
  }, [selectedElements])
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <MultiSelectViewerSystem
      setClickAwayState={setClickAwayState}
      elements={contributors}
      ChosenElementModel={NewProjectChosenContributorModel}
      elementTitle={rtl ? 'مشارك' : 'Contributor'}
      selectedElements={selectedElements}
      setSelectedElements={setSelectedElements}
      voidElementsAlertText={voidElementsAlertText}
      isDarkModeActive={isDarkModeActive}
    />
  )
}

export default NewProjectMultiSelectComp
