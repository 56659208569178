import React from 'react'
import { useTranslation } from 'react-i18next'
import { AiFillHome } from 'react-icons/ai'
import { GiCargoShip, GiKnot, GiStoneTower, GiWhirlwind } from 'react-icons/gi'
import { Card, Paper, Tooltip } from '@mui/material'
import { Link } from 'react-router-dom'
import withStyles from '@mui/styles/withStyles'
import RightbarChart from '../../../rightbar/rightbar-Components/RightbarChart'
import TitleHeader from '../../../smallComponents/Header'

const styles = () => ({
  wrapper: {
    display: 'flex',
    flexFlow: 'row wrap',
  },
  card: {
    textAlign: 'center',
    cursor: 'pointer',
    borderRadius: '10px !important',
  },
  chartCard: {
    width: 'calc(100% - 42px)',
    padding: '10px',
    margin: '10px',
  },
  regularCard: {
    width: 'calc(50% - 22px)',
    padding: '5px',
    margin: '5px',
  },
})

const MTopBarDirect = ({ classes, pageProps, defaultChartProps }) => {
  const { t } = useTranslation()
  const { isDarkModeActive } = pageProps
  const pager = [
    {
      name: t('Home'),
      icon: AiFillHome,
      path: '/home',
    },
    {
      name: t('Global_Vision'),
      icon: GiCargoShip,
      path: '/globalVision',
    },
    {
      name: t('Visions_and_Inquiries'),
      icon: GiWhirlwind,
      path: '/VisionsAndInquiriesFeed',
    },
    {
      name: t('Shared_Resources'),
      icon: GiKnot,
      path: '/sharedResourcesFeed',
    },
    {
      name: t('Projects_News'),
      icon: GiStoneTower,
      path: '/projectsNews',
    },
  ]

  return (
    <>
      <Paper
        className={`${classes.card} ${classes.chartCard}`}
        sx={{ background: 'rgba(255,255,255,0.1)' }}
      >
        <RightbarChart
          pageProps={pageProps}
          defaultChartProps={defaultChartProps}
        />
      </Paper>
      <TitleHeader headerText={t('Feeds')} />
      <div className={classes.wrapper}>
        {pager.map((item, index) => (
          <Link
            key={index}
            to={item.path}
            className={`${classes.card} ${classes.regularCard}`}
          >
            <Paper
              sx={{
                background: 'rgba(255,255,255,0.1)',
                borderRadius: '10px',
                color: isDarkModeActive && 'white',
                padding: '5px',
                fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
              }}
            >
              <item.icon size={30} />
              <div>{item.name}</div>
            </Paper>
          </Link>
        ))}
      </div>
    </>
  )
}

const WrappedMTopBarDirect = withStyles(styles)(MTopBarDirect)

export default WrappedMTopBarDirect
