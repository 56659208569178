import React, { useEffect } from 'react'
import FormsSystem from '../../../formsSystem/FormsSystem'
import NameLogoObjectivesConfigs from './NameLogoObjectivesComponents/NameLogoObjectivesConfigs'

const NameLogoObjectives = (props) => {
  const { setNewProjectData, newProjectData, dataHistory, isDarkModeActive } =
    props
  useEffect(() => {
    if (dataHistory?.current && newProjectData?.Project_Name)
      dataHistory.current.newProjectData = newProjectData
  }, [newProjectData])
  return (
    <article
      style={{
        padding: '10px auto',
        width: '100%',
        height: '100%',
        color: isDarkModeActive && 'white',
      }}
    >
      <FormsSystem
        formComponentsArray={NameLogoObjectivesConfigs()}
        formComponentsValues={newProjectData}
        setFormComponentsValues={setNewProjectData}
        {...props}
      />
    </article>
  )
}

export default NameLogoObjectives
