import { Button } from '@mui/material'
import React from 'react'
import withStyles from '@mui/styles/withStyles'
import i18next from 'i18next'

const styles = () => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: 10,
    padding: 10,
  },
  button: {
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    fontWeight: '550 !important',
    width: '100% !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
})

const ChatAttachSwitchingButtons = ({ setViewIndex, viewIndex, classes }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <div className={classes.wrapper}>
      <Button
        className={classes.button}
        disabled={viewIndex === 0}
        onClick={() => setViewIndex(0)}
      >
        {rtl ? 'الصور' : 'Images'}
      </Button>
      <Button
        className={classes.button}
        disabled={viewIndex === 1}
        onClick={() => {
          setViewIndex(1)
        }}
      >
        {rtl ? 'عناصر مُشار اليها' : 'Mentioned Elements'}
      </Button>
    </div>
  )
}

const WrappedChatAttachSwitchingButtons = withStyles(styles)(
  ChatAttachSwitchingButtons
)

export default WrappedChatAttachSwitchingButtons
