import axios from 'axios'
import { useEffect, useState } from 'react'
import RepetionRemover from '../../../components/smallComponents/RepetionRemover'

const FetchGeneralNotifications = ({
  arrivalNotification,
  user,
  post,
  setIsLoading,
  pageNumber,
}) => {
  const [generalNotifications, setGeneralNotifications] = useState([])
  const [generalNotificationsSenders, setGeneralNotificationsSenders] =
    useState([])
  const [generalPage, setGeneralPage] = useState(1)
  const [currentResponse, setCurrentResponse] = useState(true)

  useEffect(() => {
    const fetchGeneralNotifications = async () => {
      setIsLoading(true)
      try {
        const generalNotificationsRes = await axios.get(
          `/notifications/userGeneralNotifications/${
            user?._id
          }/?page=${generalPage}&limit=${pageNumber()}`
        )
        setCurrentResponse(
          Boolean(generalNotificationsRes.data.notifications.length)
        )
        setGeneralNotifications([
          ...generalNotifications,
          ...generalNotificationsRes.data.notifications,
        ])
        setGeneralNotificationsSenders([
          ...generalNotificationsSenders,
          ...generalNotificationsRes.data.senders,
        ])
      } catch (err) {}
      setIsLoading(false)
    }
    fetchGeneralNotifications()
  }, [arrivalNotification?.userId === user?._id, user?._id, post, generalPage])

  useEffect(() => {
    if (user?._id === arrivalNotification?.userId) {
      if (arrivalNotification?.category === 'general')
        setGeneralNotifications([arrivalNotification, ...generalNotifications])
    }
  }, [arrivalNotification?.userId === user?._id, user?._id])

  const similarNots = RepetionRemover(generalNotifications)
    ?.filter(
      (not) =>
        not?.associativity === arrivalNotification?.associativity &&
        not?.relation === arrivalNotification?.relation &&
        not?.category === arrivalNotification?.category &&
        not?.conditionIndex === arrivalNotification?.conditionIndex &&
        not?.accompaniedData?.postId ===
          arrivalNotification?.accompaniedData?.postId
    )
    ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

  useEffect(() => {
    setGeneralNotifications((notifs) => [
      similarNots[0],
      ...(notifs?.filter(
        (notif) => !similarNots?.find((not) => not?._id === notif?._id)
      ) || []),
    ])
  }, [
    similarNots[0],
    similarNots?.length,
    arrivalNotification?.userId === user?._id,
  ])

  return {
    generalNotifications: RepetionRemover(generalNotifications),
    generalNotificationsSenders: RepetionRemover(generalNotificationsSenders),
    setGeneralPage,
    generalPage,
    setGeneralNotifications,
    generalCurrentResponse: currentResponse,
  }
}

export default FetchGeneralNotifications
