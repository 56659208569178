import React, { useState } from 'react'
import PostingMessage from '../../feedsSystem/PostingMessage'
import HomeChartPost from './HomeFeedPostModelComponents/HomeChartPost'
import SharedPost from './HomeFeedPostModelComponents/SharedPost'
import SocialPost from './HomeFeedPostModelComponents/SocialPost'
import HomeGenerlPostModel from './HomeFeedPostModelComponents/HomeGenerlPostModel'

const HomeFeedPostModel = ({
  post,
  isUsedIn,
  // sharing system
  hideTopAndBottom,
  isDarkModeActive,
  posts,
  socket,
  setPosts,
  postsUsers,
  sharedPosts,
  sharedPostsUsers,
  hideAddUserIcon,
}) => {
  // for post messages that appears at the top of the post
  const [isPostShared, setIsPostShared] = useState(false)
  const [isPostDeleted, setIsPostDeleted] = useState(false)
  const [isPostUpdated, setIsPostUpdated] = useState(false)
  // to control some features in TextPost
  const [isCommentingSystemActive, setIsCommentingSystemActive] =
    useState(false)
  const postUser = postsUsers?.find((user) => user._id === post.userId)
  const postProps = {
    post,
    user: postUser,
    isUsedIn,
    hideAddUserIcon,
    // for the PostingMessage
    setIsPostShared,
    setIsPostDeleted,
    setIsPostUpdated,
    isPostShared,
    isPostDeleted,
    isPostUpdated,
    // for TextPost
    isCommentingSystemActive,
    setIsCommentingSystemActive,
    isDarkModeActive,
    hideTopAndBottom,
    posts,
    socket,
    setPosts,
    sharedPosts,
    sharedPostsUsers,
  }

  const feedPostTypesArray = [
    <SocialPost postProps={postProps} />,
    <HomeChartPost postProps={postProps} />,
    <SharedPost postProps={postProps} />,
  ]
  const element = post?.postElement
  return (
    <>
      <PostingMessage {...postProps} />
      {post?.feedsSystemCategory === 'page' ? (
        post?.Promoted && <HomeGenerlPostModel postProps={postProps} />
      ) : element || post.postTypeIndex === 2 || post.postTypeIndex === 3 ? (
        <HomeGenerlPostModel postProps={postProps} />
      ) : (
        feedPostTypesArray[post.homePostFeedModelIndex]
      )}
    </>
  )
}

export default HomeFeedPostModel
