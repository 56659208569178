import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { List } from '@mui/material'
import InfiniteScroll from 'react-infinite-scroll-component'
import { SettingsContext } from '../../../../context/SettingsContext/SettingsContext'
import i18next from 'i18next'
import NotificationsSkeleton from '../../../skeleton/SkeletonComponents/NotificationsSkeleton'
import Skeleton from '../../../skeleton/Skeleton'
import NotificationSysUnit from '../../../rightbar/rightbar-Components/ToggleComponents/ToggleModelComponents/NotificationSysUnit'
import CustomEmpty from '../../../smallComponents/CustomEmpty'
import DarkModeColorCoder from '../../../smallComponents/DarkModeColorCoder'

const useStyles = makeStyles(() => ({
  contentWrapper: {
    width: '350px',
    height: '500px',
    position: 'relative',
  },
  top_part: {
    // height: 50,
    paddingRight: 15,
    paddingLeft: 15,
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  body_part: {
    overflowY: 'auto',
    height: '100%',
  },
  NotifTitle: {
    fontSize: '2em',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  notificationsArea: {
    overflowY: 'auto',
    borderRadius: '15px !important',
    position: 'relative !important',
  },
  notificationsWrapper: {
    opacity: 0.95,
    width: '100%',
    borderRadius: 15,
    height: '100%',
  },
  '@media (max-width: 600px)': {
    notificationsArea: {
      borderRadius: '0 !important',
      height: 'auto',
    },
    notificationsWrapper: {
      borderRadius: 0,
    },
  },
}))

const NotifsPopoverCompModel = (props) => {
  const classes = useStyles()
  const {
    data,
    clickEeventHandler,
    isLoading,
    page,
    setPage,
    currentResponse,
    customOrder,
    cardModelIndex,
    notificationType,
  } = props
  const { settings } = useContext(SettingsContext)
  const isDarkModeActive = settings?.Mode === 'Dark'
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <div
      className={classes.contentWrapper}
      style={{
        backgroundColor: isDarkModeActive && DarkModeColorCoder('Grey_light'),
        color: isDarkModeActive & 'white',
      }}
    >
      <div className={classes.top_part}>
        <span
          className={classes.NotifTitle}
          style={{ color: isDarkModeActive & 'white' }}
        >
          {notificationType}
        </span>
      </div>
      <div className={classes.body_part}>
        {data?.length > 0 ? (
          <List className={classes.notificationsArea} id={'scrollableTarget'}>
            <InfiniteScroll
              dataLength={data?.length}
              next={() => {
                setPage((prev) => prev + 1)
              }}
              hasMore={currentResponse}
              scrollThreshold={'500px'}
              scrollableTarget={'scrollableTarget'}
              loader={<NotificationsSkeleton />}
            >
              {isLoading && page === 1 ? (
                <Skeleton type={'notificationList'} />
              ) : (
                (customOrder
                  ? data
                  : data?.sort(
                      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                    )
                )?.map((notification, notificationIndex) => (
                  <NotificationSysUnit
                    key={notification?._id + notificationIndex}
                    notification={notification}
                    notificationIndex={notificationIndex}
                    cardHandler={clickEeventHandler}
                    {...props}
                  />
                ))
              )}
            </InfiniteScroll>
          </List>
        ) : (
          <div
            style={{
              minWidth: 300,
              minHeight: 200,
              display: 'flex',
              justifyContent: 'space-around',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <CustomEmpty
              emptyMssg={
                cardModelIndex === 2
                  ? rtl
                    ? 'ليس لديك اى محادثات'
                    : 'No Chats Yet'
                  : rtl
                  ? 'ليس لديك أى إشعارات'
                  : 'No Notifications Yet'
              }
              imageKey='noManager'
              isDarkModeActive={true}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default NotifsPopoverCompModel
