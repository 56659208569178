import React, { useContext } from 'react'
import withStyles from '@mui/styles/withStyles'
import { Avatar } from '@mui/material'
import { AuthContext } from '../../../../../context/AuthContext'
import PCChatHeaderPopover from './PCChatHeaderComps/ChatHeaderPopover'
import { FaCircle } from 'react-icons/fa'
import i18next from 'i18next'

const styles = () => ({
  wrappper: {
    flexDirection: 'row',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    position: 'sticky',
    justifyContent: 'space-between',
    zIndex: 2,
    top: 0,
    borderRadius: 15,
    padding: 5,
  },
  nameAvatarWrapper: {
    flexDirection: 'row',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  userName: {
    fontWeight: 500,
    fontSize: 18,
  },
  icon: {
    cursor: 'pointer',
    fontSize: 18,
    '&:hover': {
      opacity: 0.8,
    },
  },
  avatar: {
    width: '50px !important',
    height: '50px !important',
  },
})

const PCChatHeader = ({ classes, ...props }) => {
  const { chat, isDarkModeActive, pageProps, onlineUsers } = props
  const { chatsUsers } = pageProps
  const { user } = useContext(AuthContext)
  const chattedUser =
    chatsUsers?.find(
      (u) => u?._id !== user?._id && chat?.members?.includes(u?._id)
    ) || chat?.chatter

  const showOnline = onlineUsers?.find((id) => id === chattedUser?._id)
  const lang = i18next.language
  const rtl = lang === 'ar'
  const ltr = lang !== 'ar'
  return (
    chattedUser && (
      <section
        className={classes.wrappper}
        style={{
          backgroundColor: isDarkModeActive
            ? 'rgba(255, 255, 255, 0.6) !important'
            : 'rgba(210, 221, 240, 0.6)',
          backdropFilter: 'blur(4px)',
        }}
      >
        <section className={classes.nameAvatarWrapper}>
          <Avatar
            className={classes.avatar}
            src={chattedUser?.image}
            alt={`${chattedUser?.name}'s Photo`}
          />
          <span
            className={classes.userName}
            style={{ marginLeft: ltr && 10, marginRight: rtl && 10 }}
          >
            {chattedUser?.name}
          </span>
          <FaCircle
            size={12}
            color='green'
            style={{
              marginTop: '2px',
              marginBottom: '-2px',
              marginLeft: !rtl && 10,
              marginRight: rtl && 10,
              display: !showOnline && 'none',
            }}
          />
        </section>
        <PCChatHeaderPopover {...props} />
      </section>
    )
  )
}

const WrappedPCChatHeader = withStyles(styles)(PCChatHeader)

export default WrappedPCChatHeader
