import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../../../../context/AuthContext'
import NavigatorGenerator from '../../../../../navigatorCardsModel/NavigatorGenerator'
import DefaultImageGrapper from '../../../../../smallComponents/DefaultImageGrapper'
import EmptyComponentModel from '../../../../../emptyComponentModel/EmptyComponentModel'
import i18next from 'i18next'

const MyProjects = ({ DCGProps }) => {
  const { user } = useContext(AuthContext)
  const lang = i18next.language
  const rtl = lang === 'ar'
  const [EstablishedProjects, setEstablishedProjects] = useState([])
  const [ContractedProjects, setContractedProjects] = useState([])
  const [FollowedProjects, setFollowedProjects] = useState([])

  useEffect(() => {
    const fetchEstablishedProjects = async () => {
      const res = await axios.get(`/projects/userProjects/${user?._id}`)
      setEstablishedProjects(res.data.establishedProjects)
    }
    const fetchContractedProjects = async () => {
      const res = await axios.get(
        `/projects/userProjects/contractedProjects/${user?._id}`
      )
      setContractedProjects(res.data.contractedProjects)
    }
    const fetchFollowedProjects = async () => {
      const res = await axios.get(
        `/projects/userProjects/followedProjects/${user?._id}`
      )
      setFollowedProjects(res.data.followedProjects)
    }
    fetchEstablishedProjects()
    fetchContractedProjects()
    fetchFollowedProjects()
  }, [user?._id])

  const pathNameGen = (project) => `/projectProfile/${project?._id}`
  const navigatorsArray = [
    {
      headerText: rtl ? 'مشروعات قمت بإنشائها' : 'Established Projects',
      unitsArray: EstablishedProjects,
      elementsImages: EstablishedProjects?.map((project) => ({
        id: project?._id,
        image: project?.projectPhoto || DefaultImageGrapper('noProjectAvatar'),
      })),
      modelUnitProps: {
        pathName: pathNameGen,
      },
    },
    {
      headerText: rtl ? 'مشروعات تعاقدت معها' : 'Contracted Projects',
      unitsArray: ContractedProjects,
      elementsImages: ContractedProjects?.map((project) => ({
        id: project?._id,
        image: project?.projectPhoto || DefaultImageGrapper('noProjectAvatar'),
      })),
      modelUnitProps: {
        pathName: pathNameGen,
      },
    },
    {
      headerText: rtl ? 'مشروعات اتابعها' : 'Followed Projects',
      unitsArray: FollowedProjects,
      elementsImages: FollowedProjects?.map((project) => ({
        id: project?._id,
        image: project?.projectPhoto || DefaultImageGrapper('noProjectAvatar'),
      })),
      modelUnitProps: {
        pathName: pathNameGen,
      },
    },
  ]

  const empty =
    EstablishedProjects?.length +
      ContractedProjects?.length +
      FollowedProjects?.length ===
    0

  return empty ? (
    <EmptyComponentModel
      emptySentence={rtl ? `لا يوجد بيانات` : `No Data Found`}
      fontSize={16}
      fontWeight={500}
    />
  ) : (
    <article
      style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
    >
      <NavigatorGenerator
        DCGProps={DCGProps}
        navigatorsArray={navigatorsArray}
      />
    </article>
  )
}

export default MyProjects
