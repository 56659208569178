import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { SettingsContext } from '../../context/SettingsContext/SettingsContext'
import {
  ContractsNotifications,
  ContributorsNotifications,
  GeneralNotifications,
} from './NotificationsComponents'

const NotificationSystem = (props) => {
  const { notification, notificationComponentIndex, senders, sender } = props
  const { settings } = useContext(SettingsContext)
  const [isDarkModeActive, setIsDarkModeActive] = useState(
    settings?.Mode === 'Dark'
  )
  const { user } = useContext(AuthContext)
  const [post, setPost] = useState(null)
  const [postsUsers, setPostsUsers] = useState([])
  const [sharedPosts, setSharedPosts] = useState([])
  const [sharedPostsUsers, setSharedPostsUsers] = useState([])
 const [clickAwayState, setClickAwayState] = useState(false)
  // for m view
  const senderII = senders?.find(
    (se) => se._id === notification?.accompaniedData?.senderId
  )
  useEffect(() => {
    const fetchPost = async () => {
      try {
        const res = await axios.get(
          `/posts/${notification?.accompaniedData?.postId}`
        )
        setPost(res.data.post)
        setPostsUsers(res.data.postsUsers)
        setSharedPosts(res.data.sharedPosts)
        setSharedPostsUsers(res.data.sharedPostsUsers)
      } catch (error) {
        console.log(error)
      }
    }
    if (notification?.accompaniedData?.postId) fetchPost()
  }, [notification?.accompaniedData?.postId])

  const notifProps = {
    isDarkModeActive,
    setIsDarkModeActive,
    post,
    user,
    ...props,
    sender: sender ? sender : senderII,
    clickAwayState,
    setClickAwayState,
  }
  const [comment, setComment] = useState()
  const [commentUser, setCommentUser] = useState()
  const commentId = notification?.accompaniedData?.commentId
  useEffect(() => {
    const fetchComment = async () => {
      try {
        const res = await axios.get(`/comments/${commentId}`)
        setComment(res.data.comment)
        setCommentUser(res.data.commentUser)
      } catch (error) {
        console.log(error)
      }
    }
    if (commentId) fetchComment()
  }, [commentId])
  const [showPost, setShowPost] = useState(false)
  const notificationsComponents = [
    notificationComponentIndex === 0 && (
      <ContributorsNotifications {...notifProps} />
    ),
    notificationComponentIndex === 1 && (
      <ContractsNotifications {...notifProps} />
    ),
    notificationComponentIndex === 2 && (
      <GeneralNotifications
        {...notifProps}
        postsUsers={postsUsers}
        sharedPosts={sharedPosts}
        sharedPostsUsers={sharedPostsUsers}
        comment={comment}
        commentsUsers={[commentUser]}
        setShowPost={setShowPost}
        commentId={commentId}
        showPost={showPost}
      />
    ),
  ]

  return <div>{notificationsComponents[notificationComponentIndex]}</div>
}

export default NotificationSystem
