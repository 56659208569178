import axios from 'axios'
import React, { useState } from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../../../../context/AuthContext'
import FormDataInitialValueExtractor from '../../../formsSystem/FormDataInitialValueExtractor'
import FormDataLangModulator from '../../../formsSystem/FormDataLangModulator'
import FormsSystem from '../../../formsSystem/FormsSystem'
import NotificationCreator from '../../../notificationsSystem/NotificationCreator'
import lastViewModelConfigs from './LastViewModelComponents/LastViewModelConfigs'
import NewProjectButtons from './LastViewModelComponents/NewProjectButtons'

const LastViewModel = (props) => {
  const {
    newProjectData,
    newProjectDialogClose,
    members,
    socket,
    setProjectContributors,
    setNewProjectData,
    image,
    isDarkModeActive,
  } = props

  const { user, dispatch } = useContext(AuthContext)
  const { t } = useTranslation()
  // order matters
  const [formData, setFormData] = useState({}) //1
  const newformData = FormDataLangModulator(formData, t) //2
  FormDataInitialValueExtractor(lastViewModelConfigs(), setFormData) //3
  const newProject = {
    ...newProjectData,
    projectPhoto: image,
    userId: user?._id,
    followers: [user?._id],
  }
  const newProjectPost = {
    userId: user?._id,
    feedsSystemCategory: 'home',
    postTypeIndex: 2,
    postTextContent: newformData?.InitationPostText,
    privacyType: newformData?.InitationPostPrivacy,
    projectPhoto: image,
  }

  const projectZeroPost = {
    feedsSystemCategory: 'page',
    postTypeIndex: 3,
  }

  const [isLoading, setIsLoading] = useState()
  const [chosenHashTagElements, setChosenHashTagElements] = useState([])

  const createProjectHandler = async () => {
    setIsLoading(true)
    try {
      // create new project
      const res = await axios.post(`/projects`, newProject)
      // create post for new project
      await axios.post(`/posts`, {
        ...newProjectPost,
        projectId: res.data.project?._id,
        chosenHashTagElements,
      })
      // project zero post
      await axios.post(`/posts`, {
        ...projectZeroPost,
        userId: res.data.project?._id,
        projectId: res.data.project?._id,
      })
      // updaing thr user data
      const resUser = await axios.patch(`/users/${user?._id}`, {
        userId: user?._id,
        createdProjects: [...user?.createdProjects, res.data.project?._id],
        followedProjects: [...user?.followedProjects, res.data.project?._id],
      })

      dispatch({
        type: 'UPDATE',
        payload: resUser.data.user,
      })

      // push notifications to the members of the project
      members?.map((m) => {
        socket.current.emit('addNotifUser', m?._id)
        NotificationCreator({
          associativity: 'contribution',
          relation: 'projectMemberCandidate',
          conditionIndex: 0,
          accompaniedData: {
            projectId: res.data.project?._id,
            departmentId: m?.departmentId,
          },
          category: 'contributors',
          userId: m?._id,
          currentUser: user,
          socket,
        })
      })
    } catch (error) {
      console.log(error)
    }
    setProjectContributors([])
    setNewProjectData()
    setIsLoading(false)
    newProjectDialogClose()
  }
  return (
    <article style={{ width: '100%', color: isDarkModeActive && 'white' }}>
      <FormsSystem
        formComponentsArray={lastViewModelConfigs()}
        formComponentsValues={formData}
        setFormComponentsValues={setFormData}
        setChosenHashTagElements={setChosenHashTagElements}
        chosenHashTagElements={chosenHashTagElements}
        {...props}
      />
      <NewProjectButtons
        createProjectHandler={createProjectHandler}
        isLoading={isLoading}
      />
    </article>
  )
}

export default LastViewModel
