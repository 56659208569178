import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { Card } from '@mui/material'

const styles = () => ({
  dialogLeft: {
    margin: 10,
    display: 'flex',
    flexDirection: 'row',
    fontStyle: 'oblique !important',
    borderRadius: '10px !important',
    backgroundColor: 'rgba(217,224,252, 0.2) !important',
  },
  image: {
    objectFit: 'cover',
    width: '120px',
    height: '120px',
    borderRadius: '10px',
  },
  defaultDialogView: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '100%',
  },
  summaryInfo: {
    display: 'flex',
    flexDirection: 'column',
    margin: 10,
  },
  title: {
    fontWeight: 500,
    fontSize: 18,
    fontStyle: 'normal',
  },
  '@media (max-width: 600)': {
    dialogLeft: {
      fontSize: '12px !important',
    },
  },
})

const DefaultViewModel = ({
  image,
  classes,
  itemsNumber,
  itemsNumberUnit,
  title,
  titleHelper,
  summary,
  PureIcon,
  isDarkModeActive,
}) => {
  return (
    <article className={classes.defaultDialogView}>
      <section className={classes.dialogLeft}>
        {PureIcon ? (
          <PureIcon className={classes.image} />
        ) : (
          <img
            src={image}
            alt={`${title + titleHelper}'s Image`}
            className={classes.image}
          />
        )}
        <span
          className={classes.summaryInfo}
          style={{ color: isDarkModeActive ? 'white' : 'inherit' }}
        >
          <span className={classes.title}>
            {title} {titleHelper}
          </span>
          <span>
            {itemsNumber} {itemsNumberUnit}
          </span>
        </span>
      </section>
      <Card
        className={classes.dialogLeft}
        sx={{
          padding: '10px',
          color: isDarkModeActive ? 'white' : 'inherit',
          display: !summary && 'none',
        }}
      >
        {summary}
      </Card>
    </article>
  )
}
const WrappedDefaultViewModel = withStyles(styles)(DefaultViewModel)

export default WrappedDefaultViewModel
