import React from 'react'
import PathModelUnit from './FolderPathsComponents/PathModelUnit'
import HomePath from './FolderPathsComponents/HomePath'
import PathContainer from './FolderPathsComponents/PathContainer'

const FolderPaths = ({
  path,
  path2,
  path3,
  path4,
  getToDefaultView,
  isMenuClicked,
  searchSystemProps,
  folderPathProps,
  isDarkModeActive,
}) => {
  const {
    stepBackFun,
    setStepBackFun,
    setStepBackFun2,
    stepBackFun2,
    setThirdLevelFolderPath,
    setFourthLevelFolderPath,
    stepBackFun3,
    setStepBackFun3,
  } = folderPathProps
  const { setShowSearchInput, setSearchQuery } = searchSystemProps
  const pathsArray = [
    {
      pathValue: path,
      pathFuns: () => {
        setStepBackFun(!stepBackFun)
        setStepBackFun2(!stepBackFun2)
        setStepBackFun3(!stepBackFun3)

        setThirdLevelFolderPath('')
        setFourthLevelFolderPath('')

        setShowSearchInput(false)
        setSearchQuery('')
      },
    },
    {
      pathValue: path2,
      pathFuns: () => {
        setStepBackFun2(!stepBackFun2)
        setStepBackFun3(!stepBackFun3)

        setThirdLevelFolderPath('')
        setFourthLevelFolderPath('')

        setShowSearchInput(false)
        setSearchQuery('')
      },
    },
    {
      pathValue: path3,
      pathFuns: () => {
        setStepBackFun3(!stepBackFun3)

        setFourthLevelFolderPath('')

        setShowSearchInput(false)
        setSearchQuery('')
      },
    },
    {
      pathValue: path4,
      pathFuns: () => {
        setSearchQuery('')
      },
    },
  ]

  return (
    <PathContainer
      pathSystem={
        <>
          <HomePath
            folderPathProps={folderPathProps}
            searchSystemProps={searchSystemProps}
            getToDefaultView={getToDefaultView}
          />
          {pathsArray.map((folderPath, index) => (
            <PathModelUnit
              key={index}
              folderPath={folderPath}
              isMenuClicked={isMenuClicked}
              isDarkModeActive={isDarkModeActive}
            />
          ))}
        </>
      }
      path={path}
      path2={path2}
      path3={path3}
      path4={path4}
    />
  )
}

export default FolderPaths
