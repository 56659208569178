import React from 'react'
import TimeAgoFormattor from '../../../smallComponents/TimeAgoFormattor'
import PostPrivacyFlag from './PTLeftFlagComps/PostPrivacyFlag'
import PostTypeFlag from './PTLeftFlagComps/PostTypeFlag'
import i18next from 'i18next'
import DarkModeColorCoder from '../../../smallComponents/DarkModeColorCoder'

const PTLeftFlags = ({ postProps, wz }) => {
  const { post, isDarkModeActive } = postProps
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <div
      style={{
        fontSize: wz ? 11 : 9,
        color: isDarkModeActive
          ? DarkModeColorCoder('Grey_2x_light')
          : DarkModeColorCoder('Grey_1x_light'),
      }}
    >
      <PostTypeFlag postProps={postProps} />
      {post?.Promoted ? (
        <>{rtl ? 'مُمٌول' : 'Sponsered'}</>
      ) : (
        <>
          <TimeAgoFormattor date={post?.createdAt} />
          <PostPrivacyFlag postProps={postProps} />
        </>
      )}
    </div>
  )
}

export default PTLeftFlags
