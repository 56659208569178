import React, { useContext, useEffect, useRef, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import PCUserMessage from './PCChatBodyComponents/PCUserMessage'
import PCReplyMessage from './PCChatBodyComponents/PCReplyMessage'
import PCNewMessage from './PCChatBodyComponents/PCNewMessage'
import PCChatHeader from './PCChatBodyComponents/PCChatHeader'
import NoChatMessagesDefaultView from '../NoChatMessagesDefaultView'
import { AuthContext } from '../../../../context/AuthContext'
import axios from 'axios'
import CustomCircularProgress from '../../../smallComponents/CustomCircularProgress'
import RepetionRemover from '../../../smallComponents/RepetionRemover'
import LoadMoreButton from '../../../smallComponents/LoadMoreButton'

const styles = () => ({
  wrapper: {
    padding: '0 5px 5px 5px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  chatMessages: {
    display: 'flex',
    flexDirection: 'column',
    justifyContnet: 'space-around',
  },
})

const PCChatBody = ({
  classes,
  chat,
  isDarkModeActive,
  chatDialogClose,
  pageProps,
  scrollRef,
  isDialogFullWidth,
  onlineUsers,
  setOnlineUsers,
}) => {
  const { arrivalMessage, socket } = pageProps
  const [chatMessages, setChatMessages] = useState([])
  const [page, setPage] = useState(1)
  const [isLoading, setIsLoading] = useState(true)
  const [currentResponse, setCurrentResponse] = useState(true)
  const { user } = useContext(AuthContext)
  const [disabledInput, setDisabledInput] = useState()

  useEffect(() => {
    arrivalMessage &&
      chat?.members.includes(arrivalMessage.senderId) &&
      setChatMessages((prev) => [...prev, arrivalMessage])
  }, [arrivalMessage, chat])

  useEffect(() => {
    socket.current.emit('addUser', user._id)
    socket.current.on('getUsers', (users) => {
      setOnlineUsers(
        user.followings.filter((f) => users.some((u) => u.userId === f))
      )
    })
  }, [user])

  useEffect(() => {
    const fetchChatMessages = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(
          `/messages/conversationMessages/${chat?._id}/?page=${page}&limit=15&userId=${user?._id}`
        )
        setCurrentResponse(Boolean(res.data.messages.length))
        setChatMessages([...chatMessages, ...res.data.messages])
        setDisabledInput(res.data.disabledInput)
      } catch (error) {}
      setIsLoading(false)
    }
    if (chat?._id) fetchChatMessages()
  }, [chat?._id, page])

  const [isClicked, setIsClicked] = useState(false)

  const mssgScroll = useRef()
  useEffect(() => {
    if (isClicked) {
      scrollRef?.current?.scrollTo(0, 0)
      setIsClicked(false)
    } else {
      mssgScroll?.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [chatMessages])

  const scrollToUp = () => {
    setIsClicked(true)
    scrollRef?.current?.scrollTo(0, 0)
  }

  const isMainUser = (msg) => {
    return msg?.senderId === user?._id
  }

  return chat ? (
    <>
      <article
        className={classes.wrapper}
        style={{
          color: isDarkModeActive ? 'white' : null,
        }}
      >
        <PCChatHeader
          chat={chat}
          isDarkModeActive={isDarkModeActive}
          chatDialogClose={chatDialogClose}
          pageProps={pageProps}
          onlineUsers={onlineUsers}
        />
        <div
          className={classes.chatMessages}
          style={{
            color: isDarkModeActive ? 'white' : null,
            overflowY: isLoading ? 'none' : 'auto',
            marginBottom: isDialogFullWidth ? 40 : 50,
          }}
        >
          {currentResponse &&
            (isLoading && page > 1 ? (
              <CustomCircularProgress />
            ) : (
              !isLoading && (
                <LoadMoreButton setPage={setPage} onClick={scrollToUp} />
              )
            ))}
          {isLoading && page === 1 ? (
            <CustomCircularProgress />
          ) : chatMessages?.length > 0 ? (
            RepetionRemover(chatMessages)
              ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
              .map((msg, msgIndex) =>
                isMainUser(msg) ? (
                  <PCUserMessage
                    msg={msg}
                    key={msgIndex}
                    isDarkModeActive={isDarkModeActive}
                    mssgScroll={mssgScroll}
                    socket={socket}
                  />
                ) : (
                  <PCReplyMessage
                    msg={msg}
                    key={msgIndex}
                    isDarkModeActive={isDarkModeActive}
                    mssgScroll={mssgScroll}
                    socket={socket}
                  />
                )
              )
          ) : (
            <NoChatMessagesDefaultView
              isDarkModeActive={isDarkModeActive}
              pageProps={pageProps}
              chat={chat}
            />
          )}
        </div>
      </article>
      <PCNewMessage
        isDarkModeActive={isDarkModeActive}
        chat={chat}
        setChatMessages={setChatMessages}
        pageProps={pageProps}
        disabledInput={disabledInput}
      />
    </>
  ) : (
    <CustomCircularProgress />
  )
}

const WrappedPCChatBody = withStyles(styles)(PCChatBody)

export default WrappedPCChatBody
