import React from 'react'
import ProfileOverview from './ProfileTopComponents/ProfileOverview'
import withStyles from '@mui/styles/withStyles'
import ProfilePhotoCommunication from './ProfileTopComponents/ProfilePhotoCommunication'

const styles = () => ({
  profileTopWrapper: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
    margin: '0 10px 0 10px',
    height: 'auto',
  },
  defaultViewWrapper: {
    width: '100%',
    height: '270.5px',
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'row',
  },

  '@media (max-width: 600px)': {
    defaultViewWrapper: {
      flexDirection: 'column',
      height: 'auto',
      width: '100%',
    },
    profileTopWrapper: {
      margin: 0,
    },
  },
})

const ProfileTop = ({ classes, profileProps }) => {
  return (
    <article className={classes.profileTopWrapper}>
      <section className={classes.defaultViewWrapper}>
        <ProfilePhotoCommunication profileProps={profileProps} />
        <ProfileOverview profileProps={profileProps} />
      </section>
    </article>
  )
}

const WrappedProfileTop = withStyles(styles)(ProfileTop)
export default WrappedProfileTop
