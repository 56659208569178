import React, { useState, useEffect } from 'react'
import withStyles from '@mui/styles/withStyles'
import ModelDialogWrappingIII from '../dialogModelUnit/ModelDialogWrappingIII'
import { MdArrowLeft, MdArrowRight } from 'react-icons/md'
import { IconButton } from '@mui/material'
import StepperComponent from './StepperComponent'
import GeneralConfirmationMssg from '../confirmationSystem/GeneralConfirmationMssg'
import i18next from 'i18next'

const styles = () => ({
  backIcon: {
    fontSize: 20,
    cursor: 'pointer',
  },
})

const StepperSystem = ({
  classes,
  isStepperDialogOpen,
  stepperDialogClose,
  stepperStepsContentArray,
  stepperTitle,
  FrontViewComponent,
  showFrondView,
  FrontViewComponentProps,
  setShowFrontView,
  noFrontView,
  isDarkModeActive,
}) => {
  const stepperViewComponents = [
    // stepper view
    <StepperComponent
      stepperStepsContentArray={stepperStepsContentArray}
      isDarkModeActive={isDarkModeActive}
    />,
    //front view
    <FrontViewComponent
      StepperComponent={
        <StepperComponent stepperStepsContentArray={stepperStepsContentArray} />
      }
      props={FrontViewComponentProps}
    />,
  ]
  const lang = i18next.language
  const rtl = lang === 'ar'
  const dialogsArray = [
    {
      open: isStepperDialogOpen,
      closeFun: () => stepperDialogClose(),
      content: stepperViewComponents[showFrondView ? 1 : 0],
    },
  ]
  // alert dialog funs
  const [isGeneralConfirmDialogOpen, setIsGeneralConfirmDialogOpen] =
    useState(false)
  return (
    <>
      <ModelDialogWrappingIII
        dialogsArray={dialogsArray}
        dialogTitle={stepperTitle}
        inactivateClickAway
        isDarkModeActive={isDarkModeActive}
        dialogIcon={
          <IconButton
            edge='start'
            color='inherit'
            onClick={() => {
              if (showFrondView) stepperDialogClose()
              if (!showFrondView) setIsGeneralConfirmDialogOpen(true)
            }}
          >
            {rtl ? (
              <MdArrowLeft className={classes.backIcon} />
            ) : (
              <MdArrowRight className={classes.backIcon} />
            )}
          </IconButton>
        }
      />
      <GeneralConfirmationMssg
        confirmMessg={
          rtl
            ? 'سيتم حذف البيانات بمجرد الخروج'
            : 'All editing data will be earsed once get out.'
        }
        confirmTitle={rtl ? 'حذف البيانات' : 'Data will be Lost'}
        generalConfirmDialogClose={() => setIsGeneralConfirmDialogOpen(false)}
        generalConfirmAction={() => {
          if (typeof setShowFrontView === 'function')
            setShowFrontView(undefined)
          if (noFrontView) stepperDialogClose()
        }}
        isGeneralConfirmDialogOpen={isGeneralConfirmDialogOpen}
        isDarkModeActive={isDarkModeActive}
      />
    </>
  )
}

const WrappedStepperSystem = withStyles(styles)(StepperSystem)

export default WrappedStepperSystem
