import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import MessageCardModel from './PostinMessageComponents/MessageCardModel'

const PostingMessage = ({
  // for the feed
  isPublishClicked,
  setIsPublishClicked,
  // for single posts
  isPostShared,
  setIsPostShared,
  isPostDeleted,
  setIsPostDeleted,
  isPostUpdated,
  setIsPostUpdated,
  isDarkModeActive,
}) => {
  //  for the whole feed
  useEffect(() => {
    if (typeof setIsPublishClicked === 'function')
      setTimeout(() => setIsPublishClicked(false), 10000)
  }, [isPublishClicked === true])

  //  for sharing each post
  useEffect(() => {
    if (typeof setIsPostShared === 'function')
      setTimeout(() => setIsPostShared(false), 10000)
  }, [isPostShared === true])

  // for deleting every post
  useEffect(() => {
    if (typeof setIsPostDeleted === 'function')
      setTimeout(() => setIsPostDeleted(false), 10000)
  }, [isPostDeleted === true])

  // for updating every post
  useEffect(() => {
    if (typeof setIsPostUpdated === 'function')
      setTimeout(() => setIsPostUpdated(false), 10000)
  }, [isPostUpdated === true])

  const { t } = useTranslation()
  return isPublishClicked ? (
    <MessageCardModel
      messageText={t('publish_mssg')}
      isDarkModeActive={isDarkModeActive}
    />
  ) : isPostShared ? (
    <MessageCardModel
      messageText={t('share_mssg')}
      isDarkModeActive={isDarkModeActive}
    />
  ) : isPostDeleted ? (
    <MessageCardModel
      messageText={t('delete_mssg')}
      isDarkModeActive={isDarkModeActive}
    />
  ) : isPostUpdated ? (
    <MessageCardModel
      messageText={t('update_mssg')}
      isDarkModeActive={isDarkModeActive}
    />
  ) : null
}

export default PostingMessage
