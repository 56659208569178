import React, { useContext } from 'react'
import '../skeleton.css'
import withStyles from '@mui/styles/withStyles'
import ProfileTopSkeleton from './UserProfileSkeletonComps/ProfileTopSkeleton'
import Media from 'react-media'
import ControlPanelSkeleton from './UserProfileSkeletonComps/ControlPanelSkeleton'
import MProfileTopSkeleton from './UserProfileSkeletonComps/MProfileTopSkeleton'
import ProjectFeedLeftSkeleton from './ProjectProfileSkeletonComps/ProjectFeedLeftSkeleton'
import ProjectFeedRightSkeleton from './ProjectProfileSkeletonComps/ProjectFeedRightSkeleton'
import WindowSize from '../../smallComponents/WindowSize'
import { SettingsContext } from '../../../context/SettingsContext/SettingsContext'
import i18next from 'i18next'

const styles = () => ({
  projectWallPaperWrapper: {
    height: 170,
    margin: '0 -12% 10px -12%',
    borderRadius: 15,
    position: 'relative',
    animation: 'skeleton 1s ease infinite alternate',
    backgroundColor: '#313131',
  },

  projectProfileWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },

  '@media (max-width: 600px)': {
    projectWallPaperWrapper: {
      borderRadius: 0,
      margin: 0,
      width: '100vw',
    },
    projectProfileWrapper: {
      flexDirection: 'column-reverse',
    },
  },
})

const UserProfileSkeleton = ({ classes }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { settings } = useContext(SettingsContext)
  const isDarkModeActive = settings?.Mode === 'Dark'
  const windowSize = WindowSize()
  const mediaComponents = [
    <div style={{ marginLeft: '-12%', marginRight: '-12%' }}>
      <ProfileTopSkeleton isDarkModeActive={isDarkModeActive} />
      <ControlPanelSkeleton isDarkModeActive={isDarkModeActive} />
    </div>,
    <MProfileTopSkeleton isDarkModeActive={isDarkModeActive} />,
  ]
  return (
    <div className={classes.profileWrapper}>
      <div className={classes.projectWallPaperWrapper}></div>
      <Media query='(max-width: 600px)'>
        {(matches) => {
          return matches ? mediaComponents[1] : mediaComponents[0]
        }}
      </Media>
      <article
        className={classes.projectProfileWrapper}
        style={{
          marginLeft: !rtl && (windowSize > 600 ? 10 : 0),
          marginRight: rtl && (windowSize > 600 ? 10 : 0),
        }}
      >
        <ProjectFeedLeftSkeleton />
        <ProjectFeedRightSkeleton />
      </article>
    </div>
  )
}

const WrappedUserProfileSkeleton = withStyles(styles)(UserProfileSkeleton)

export default WrappedUserProfileSkeleton
