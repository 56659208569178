import React from 'react'
import DefaultViewModel from '../../../../../projectProfile/ProjectProfileComponents/DefaultViewModel'

const MyProjectsDealsDefaultView = ({ DCGProps }) => {
  const { modelCardsContentArray } = DCGProps
  return (
    <DefaultViewModel
      PureIcon={modelCardsContentArray[3]?.pureIcon}
      title={modelCardsContentArray[3]?.name}
    />
  )
}

export default MyProjectsDealsDefaultView