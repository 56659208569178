import React, { useEffect, useState } from 'react'
import { Avatar, Divider } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import TextEngine from '../../../../textProcessingEngine/TextEngine'
import GlassLayer from '../../../../smallComponents/GlassLayer'
import axios from 'axios'
import { useContext } from 'react'
import { AuthContext } from '../../../../../context/AuthContext'
import moment from 'moment'

const styles = () => ({
  container: {
    width: 300,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
  },
  messageWrapper: {
    width: '100%',
    height: '100%',
    padding: '3px !important',
    fontSize: '15px',
    margingLeft: '10px',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '54px',
    borderRadius: '10px !important',
    '&:hover': {
      filter: 'brightness(80%)',
    },
  },

  userMessageName: {
    fontSize: 13,
    fontWeight: 650,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRadius: 10,
    padding: '2px 5px',
    opacity: 0.9,
    backgroundColor: 'rgb(182, 192, 238)',
    color: 'white',
  },
  lastMessage: {
    fontSize: 12,
    fontFamily: 'inherit',
    fontWeight: 500,
    overflow: 'hidden',
    height: 20,
  },
  chatFriendCompanyName: {
    borderRadius: 10,
    padding: 4,
    fontSize: 10,
    fontWeight: 700,
    fontFamily:
      "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande','Lucida Sans Unicode', Geneva, Verdana, sans-serif",
  },

  avatar: {
    height: '50px !important',
    width: '50px !important',
  },
  dateWrapper: {
    fontSize: '9px',
  },
})

const ChatInteriorCardComponent = ({
  notification,
  classes,
  cardHandler,
  setChosenChat,
  socket,
  chatsUsers,
  lastMessages,
  arrivalMessage,
}) => {
  const element = notification
  const { user } = useContext(AuthContext)
  const chattedUser =
    chatsUsers?.find(
      (u) => u?._id !== user?._id && element?.members?.includes(u?._id)
    ) || element?.chatter
  const inlastChatMessage = lastMessages?.find(
    (m) => m?.conversationId === element?._id
  )
  const [lastChatMessage, setLastMessage] = useState(inlastChatMessage)
  useEffect(() => {
    setLastMessage(
      arrivalMessage?.conversationId === element?._id
        ? arrivalMessage
        : lastMessages?.find((m) => m?.conversationId === element?._id)
    )
  }, [element, arrivalMessage])

  const lastMessage = (
    <TextEngine
      socket={socket}
      textContent={lastChatMessage?.text || ''}
      textPackage={lastChatMessage}
    />
  )
  return (
    <span
      className={classes.container}
      style={{
        fontWeight: !element?.openedByUserIds?.includes(user._id) && 'bold',
      }}
      onClick={async () => {
        cardHandler()
        setChosenChat(element)
        if (element?._id) {
          try {
            await axios.patch(`/conversations/${element?._id}`, {
              openedByUserIds: [...element.openedByUserIds, user._id],
            })
          } catch (error) {
            console.log(error)
          }
        }
      }}
    >
      <Avatar
        src={chattedUser?.image}
        alt={`${chattedUser?.name}'s Image`}
        className={classes.avatar}
      />

      <span
        className={classes.messageWrapper}
        style={{
          fontWeight: !element?.openedByUserIds?.includes(user._id) && 'bold',
        }}
      >
        <div className={classes.userMessageName}>
          <span>{chattedUser?.name}</span>
        </div>
        <Divider />
        <div className={classes.lastMessage}>{lastMessage}</div>
        <GlassLayer />
        <span className={classes.dateWrapper}>
          {' '}
          {moment(lastChatMessage?.createdAt).calendar()}
        </span>
      </span>
    </span>
  )
}

const WrappedChatInteriorCardComponent = withStyles(styles)(
  ChatInteriorCardComponent
)

export default WrappedChatInteriorCardComponent
