import React from 'react'
import { ListItemScreenViewModels, ProfileFollowNotifCover } from '../../'
import ProfileContribAddNotifCover from './UserProfileNotificationModelComps/ProfileContribAddNotifCover'

const UserProfileNotificationModel = (props) => {
  const models = [
    {
      relation: 'owner',
      conditions: [
        <ProfileFollowNotifCover {...props} />,
        <ProfileContribAddNotifCover {...props} />,
      ],
    },
  ]
  return <ListItemScreenViewModels {...props} models={models} />
}

export default UserProfileNotificationModel
