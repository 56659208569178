import axios from 'axios'
import React, { useEffect, useState } from 'react'
import NotificationSystem from '../../../../notificationsSystem/NotificationSystem'
import NotificationCardUnit from './NotificationCardUnit'

const NotificationSysUnit = (props) => {
  const {
    InteriorCardComponent,
    cardModelIndex,
    notification,
    notificationComponentIndex,
    isDarkModeActive,
    senders,
    projects,
    // mChat
    cardHandler,
    data,
  } = props
  const [isNotificationCardOpen, setIsNotificationCardOpen] = useState(false)
  const notificationCardDialogOpener = () => {
    setIsNotificationCardOpen(true)
  }
  const notificationCardDialogClose = () => {
    setIsNotificationCardOpen(false)
  }

  const [sender, setSender] = useState()
  const [project, setProject] = useState()

  useEffect(() => {
    setSender(
      senders?.find((se) => se._id === notification?.accompaniedData?.senderId)
    )
  }, [notification?.accompaniedData?.senderId])

  useEffect(() => {
    setProject(
      projects?.find(
        (pro) => pro._id === notification?.accompaniedData?.projectId
      )
    )
  }, [notification?.accompaniedData?.projectId])

  const [repUser, setRepUser] = useState()
  useEffect(() => {
    const fetchRepUser = async () => {
      try {
        const res = await axios.get(
          `/users/oneUser/${notification?.accompaniedData?.senderId}`
        )
        setRepUser(res.data.user)
      } catch (error) {
        console.log(error)
      }
    }

    if (!sender && notification?.accompaniedData?.senderId) fetchRepUser()
  }, [notification.accompaniedData?.senderId, sender])
  return (
    <>
      <NotificationCardUnit
        notification={notification}
        interiorCardComponent={
          <InteriorCardComponent
            {...props}
            props={{
              setChosenNotification: cardHandler,
              isDarkModeActive,
              sender: sender || repUser,
              project,
              ...props,
            }}
            element={notification}
          />
        }
        clickEeventHandler={notificationCardDialogOpener}
        cardModelIndex={cardModelIndex}
      />
      {notification && (
        <NotificationSystem
          isNotificationCardOpen={isNotificationCardOpen}
          notificationCardDialogClose={notificationCardDialogClose}
          notificationComponentIndex={notificationComponentIndex}
          sender={sender || repUser}
          project={project}
          notifications={data}
          {...props}
        />
      )}
    </>
  )
}

export default NotificationSysUnit
