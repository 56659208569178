import React, { useState } from 'react'
import MNotificationsListModel from './MNotificationsListModel'
import MDealsNotifDialog from './MDealsNotificationsListComps/MDealsNotifDialog'
import ContractNotifListItem from '../../../notificationsSystem/NotificationsComponents/ContractsNotificationsComponents/ContractsNotifsDialogLeftComponents/ContractNotifListItem'
import StateGenerator from '../../../smallComponents/StateGenerator'

const MDealsNotificationsList = ({
  isDarkModeActive,
  pageProps,
  contractsNotifDialogClose,
}) => {
  const {
    dealsNotifications,
    dealsNotificationsSenders,
    dealsNotificationsProjects,
    socket,
    setContractPage,
    contractPage,
    contractsCurrentResponse,
  } = pageProps
  const { isGeneralDialogOpen, GeneralDialogOpener, GeneralDialogClose } =
    StateGenerator({ GeneralProps: true })
  const [chosenNotification, setChosenNotification] = useState()
  return (
    <>
      <MNotificationsListModel
        data={dealsNotifications}
        NotificationListItem={ContractNotifListItem}
        isDarkModeActive={isDarkModeActive}
        setChosenNotification={setChosenNotification}
        notificationDialogOpener={GeneralDialogOpener}
        socket={socket}
        senders={dealsNotificationsSenders}
        projects={dealsNotificationsProjects}
        setPage={setContractPage}
        page={contractPage}
        {...pageProps}
        pageProps={pageProps}
        currentResponse={contractsCurrentResponse}
      />
      <MDealsNotifDialog
        isNotificationDialogOpen={isGeneralDialogOpen}
        notificationDialogClose={GeneralDialogClose}
        notification={chosenNotification}
        pageProps={pageProps}
        DialogCloser={contractsNotifDialogClose}
      />
    </>
  )
}

export default MDealsNotificationsList
