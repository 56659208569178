import React, { useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import MessageCore from './MessageCore'
import TextDirectionDetector from '../../../../textProcessingEngine/TextEngineComponents/TextProcessorComponents/DetectionCoreComponents/TextDirectionDetector'
import moment from 'moment'

const styles = () => ({
  mssgWrapper: {
    backgroundColor: 'rgb(95, 95, 168)',
    color: 'white',
    display: 'inline-block',
    maxWidth: '70%',
    padding: 12,
    borderRadius: 20,
    transition: '0.3s',
    float: 'right',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    cursor: 'pointer',
  },
  mssgContainer: {
    maxWidth: '70%',
  },
  dateWrapper: {
    maxWidth: '70%',
    fontSize: 11,
    fontWeight: 500,
    paddingRight: 10,
    margin: 10,
  },
})

const PCUserMessage = ({
  classes,
  msg,
  isDarkModeActive,
  mssgScroll,
  socket,
}) => {
  const [showMssgDate, setShowMssgDate] = useState(false)
  return (
    <span style={{ display: 'flex', flexDirection: 'column' }} ref={mssgScroll}>
      <section style={{ width: '100%' }}>
        <span
          dir={TextDirectionDetector(msg.msgContent)}
          className={classes.mssgWrapper}
          style={{
            filter: showMssgDate ? 'brightness(70%)' : 'brightness(100%)',
            margin: showMssgDate ? '10px 10px 0 10px' : '2px 10px 2px 2px',
          }}
          onClick={() => setShowMssgDate(!showMssgDate)}
        >
          <MessageCore
            msg={msg}
            isDarkModeActive={isDarkModeActive}
            socket={socket}
          />
        </span>
      </section>
      <section style={{ width: '100%' }}>
        <span
          className={classes.dateWrapper}
          style={{
            display: showMssgDate ? 'inline-block' : 'none',
            float: 'right',
            color: isDarkModeActive ? 'white' : null,
          }}
        >
          {moment(msg.createdAt).calendar()}
        </span>
      </section>
    </span>
  )
}

const WrappedPCUserMessage = withStyles(styles)(PCUserMessage)

export default WrappedPCUserMessage
