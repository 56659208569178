import axios from 'axios'
import { useEffect, useState } from 'react'
import RepetionRemover from '../../../components/smallComponents/RepetionRemover'

const FetchContractsNotifications = ({
  pageNumber,
  post,
  arrivalNotification,
  user,
  setIsLoading,
}) => {
  const [dealsNotifications, setDealsNotifications] = useState([])
  const [dealsNotificationsSenders, setDealsNotificationsSenders] = useState([])
  const [dealsNotificationsProjects, setDealsNotificationsProjects] = useState(
    []
  )
  const [currentResponse, setCurrentResponse] = useState(true)
  const [contractPage, setContractPage] = useState(1)
  useEffect(() => {
    const fetchDealsNotifications = async () => {
      setIsLoading(true)
      try {
        const dealsNotificationsRes = await axios.get(
          `/notifications/userDealsNotifications/${
            user?._id
          }?page=${contractPage}&limit=${pageNumber()}`
        )
        setCurrentResponse(
          Boolean(dealsNotificationsRes.data.notifications.length)
        )
        setDealsNotifications([
          ...dealsNotifications,
          ...dealsNotificationsRes.data.notifications,
        ])
        setDealsNotificationsSenders([
          ...dealsNotificationsSenders,
          ...dealsNotificationsRes.data.senders,
        ])
        setDealsNotificationsProjects([
          ...dealsNotificationsProjects,
          ...dealsNotificationsRes.data.projects,
        ])
      } catch (err) {}
      setIsLoading(false)
    }
    fetchDealsNotifications()
  }, [user?._id, user?._id === arrivalNotification?.userId, post, contractPage])

  useEffect(() => {
    if (user?._id === arrivalNotification?.userId) {
      if (arrivalNotification.category === 'deals')
        setDealsNotifications([arrivalNotification, ...dealsNotifications])
    }
  }, [user?._id === arrivalNotification?.userId, user?._id])
  return {
    dealsNotifications: RepetionRemover(dealsNotifications),
    dealsNotificationsSenders: RepetionRemover(dealsNotificationsSenders),
    dealsNotificationsProjects: RepetionRemover(dealsNotificationsProjects),
    setContractPage,
    contractPage,
    setDealsNotifications,
    contractsCurrentResponse: currentResponse,
  }
}

export default FetchContractsNotifications
