import { IconButton, ListItem } from '@mui/material'
import React, { useContext, useState } from 'react'
import ModelDialogWrappingII from '../../../dialogModelUnit/ModelDialogWrappingII'
import MenuIcon from '@material-ui/icons/Menu'
import i18next from 'i18next'
import withStyles from '@mui/styles/withStyles'
import { SettingsContext } from '../../../../context/SettingsContext/SettingsContext'

const lang = i18next.language
const rtl = lang === 'ar'

const styles = () => ({
  '@media (max-width: 1000px)': {
    menuIcon: {
      marginRight: !rtl && '20px !important',
      marginLeft: rtl && '20px !important',
    },
  },

  '@media (min-width: 1000px)': {
    menuIcon2: {
      display: 'none',
    },
  },
})

const LeftbarControlUnit = ({
  unit,
  propsII,
  DialogsComponentContent,
  classes,
  unitIndex,
}) => {
  const { setIsSwipeableOpen, clickAwayState } = propsII
  const { settings } = useContext(SettingsContext)
  const [isDarkModeActive, setIsDarkModeActive] = useState(
    settings?.Mode === 'Dark'
  )
  const props = {
    ...propsII,
    isDarkModeActive,
    setIsDarkModeActive,
  }
  return (
    <>
      <ListItem
        sx={{ borderRadius: 25 }}
        style={{ width: '100%', margin: '2px 0' }}
        onClick={() => unit.openFun()}
      >
        {unit.icon}
        {unit.name}
      </ListItem>
      <ModelDialogWrappingII
        {...props}
        inactivateClickAway={clickAwayState}
        dialogsArray={[
          {
            title: unit.name,
            open: unit.open,
            closeFun: unit.closeFun,
            icon: (
              <>
                <IconButton
                  edge='start'
                  color='inherit'
                  aria-label='open drawer'
                  onClick={() => setIsSwipeableOpen(true)}
                  className={classes.menuIcon}
                  sx={{ marginLeft: rtl && '10px' }}
                >
                  <MenuIcon className={classes.menuIcon2} />
                </IconButton>
                {unit.icon}
              </>
            ),
            content: (
              <DialogsComponentContent cardIndex={unitIndex} {...props} />
            ),
          },
        ]}
      />
    </>
  )
}

const WrappedLeftbarControlUnit = withStyles(styles)(LeftbarControlUnit)

export default WrappedLeftbarControlUnit
