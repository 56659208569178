import React, { useContext, useEffect, useState } from 'react'
import UsersContributorProfile from './UsersContributorProfile'
import MembersPortalModel from '../../../../../projectProfile/ProjectProfileComponents/MembersPortalModel'
import { AuthContext } from '../../../../../../context/AuthContext'
import axios from 'axios'
import BlocksLoader from '../../../../../smallComponents/BlocksLoader'
import i18next from 'i18next'

const UserFollowings = (props) => {
  const noFSPanelPropsSecondView = {
    showDownloadIcon: false,
  }

  const { user } = useContext(AuthContext)
  const [followings, setFollowings] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchUserFollowings = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(`/users/followings/${user?._id}`)
        setFollowings(res.data.followings)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    fetchUserFollowings()
  }, [user?._id])

  const modFollowings = followings.map((fol) => ({
    ...fol,
    name: fol.firstName + ' ' + fol.lastName,
    id: fol._id,
    image: fol.profilePicture,
  }))

  const lang = i18next.language
  const rtl = lang === 'ar'
  return isLoading ? (
    <BlocksLoader {...props?.DCGProps} />
  ) : (
    <MembersPortalModel
      {...props}
      placeholderValue={rtl ? 'متابعاتى' : 'My Followings'}
      cards={modFollowings}
      noFSPanelPropsSecondView={noFSPanelPropsSecondView}
      noFSPanelPropsFirstView={{ hideNoFileSystemPanel: true }}
      CardProfileComponent={UsersContributorProfile}
      hideInfoCard
    />
  )
}

export default UserFollowings
