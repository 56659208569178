import React, { useContext, useEffect, useState } from 'react'
import GeneralSettings from './SettingsComponents/GeneralSettings'
import SettingsDeafultView from './SettingsComponents/SettingsDeafultView'
import DialogModelIFunctionalUnit from '../dialogModelUnit/DialogModelIFunctionalUnit'
import ProfileTermination from './SettingsComponents/ProfileTermination'
import DefaultImageGrapper from '../smallComponents/DefaultImageGrapper'
import axios from 'axios'
import { AuthContext } from '../../context/AuthContext'
import { useTranslation } from 'react-i18next'
import ProfileInfo from './SettingsComponents/ProfileInfo'
import AdvertismentAccess from './SettingsComponents/AdvertismentAccess'

const SettingsDialogContent = ({
  isSwipeableOpen,
  setIsSwipeableOpen,
  isDarkModeActive,
  setClickAwayState,
}) => {
  const [DMFUProps, setDMFUProps] = useState({
    folderPathProps: {},
    searchSystemProps: {},
    menuItemId: undefined,
  })
  const { menuItemId } = DMFUProps
  const DCGProps = {
    setClickAwayState,
    isDarkModeActive,
    menuItemId,
  }

  const { user } = useContext(AuthContext)

  const [settings, setSettings] = useState(null)
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const res = await axios.get(`/userSettings/${user?._id}`)
        setSettings(res.data.settings)
      } catch (error) {
        console.log(error)
      }
    }
    fetchSettings()
  }, [user?._id])

  const props = {
    ...DMFUProps,
    DCGProps,
    settings,
    setSettings,
  }

  const { t } = useTranslation()
  const settingsMenu = [
    t('General'),
    t('User_Info'),
    t('Advertisment'),
    t('Termination'),
  ]

  const settingsContentArray = [
    <GeneralSettings {...props} />,
    <ProfileInfo {...props} />,
    <AdvertismentAccess {...props} />,
    <ProfileTermination {...props} />,
    <SettingsDeafultView {...props} />,
  ]

  return (
    <DialogModelIFunctionalUnit
      DialogModelUnitMenu={settingsMenu}
      DialogModelUnitContentArray={settingsContentArray}
      isSwipeableOpen={isSwipeableOpen}
      setIsSwipeableOpen={setIsSwipeableOpen}
      swibableHeaderCardComponent={{
        title: 'Settings',
        image: DefaultImageGrapper('settings'),
      }}
      showSearchBar={true}
      hideSecondPathSearch={true}
      setDMFUProps={setDMFUProps}
      isDarkModeActive={isDarkModeActive}
    />
  )
}

export default SettingsDialogContent
