import React from 'react'
import DNALoader from '../../smallComponents/DNALoader'
import LoadMoreButton from '../../smallComponents/LoadMoreButton'
import WindowSize from '../../smallComponents/WindowSize'
import GeneralCardContent from './GeneralCardContent'
import InternalComponent from './InternalComponent'

const GPModelProps = (props) => {
  const {
    StandardCardContent,
    imageKey,
    modCards,
    searchSystemProps,
    navigatorsObject,
    setCards,
    setPage,
    fetchUserCards,
    currentResponse,
    cards,
    page,
    customElementsImages,
    setIsUpdated,
  } = props
  const { searchQuery, setSearchQuery, setSearchInputValue } = searchSystemProps
  const elementsImages = customElementsImages
    ? customElementsImages
    : modCards.map((card) => ({
        id: card.id,
        image: (card?.images && card?.images[0]) || card?.image,
      }))
  const windowSize = WindowSize()
  const InfiniteScrollProps = {
    next: () => setPage((prev) => prev + 1),
    hasMore: currentResponse,
    scrollableTarget: 'dialog-model-unit',
    loader:
      (page === 1 && currentResponse) ||
      (windowSize <= 600 && currentResponse) ? (
        <LoadMoreButton setPage={setPage} />
      ) : (
        <DNALoader />
      ),
  }
  return {
    StandardCardContent: StandardCardContent || GeneralCardContent,
    imageKey,
    elementsImages,
    InternalComponent: (
      <InternalComponent
        {...props}
        setCards={setCards}
        setPage={setPage}
        fetchUserCards={fetchUserCards}
        currentResponse={currentResponse}
        searchQuery={searchQuery}
        cards={cards}
        setSearchQuery={setSearchQuery}
        setSearchInputValue={setSearchInputValue}
        setIsUpdated={setIsUpdated}
      />
    ),
    InfiniteScrollProps,
  }
}

export default GPModelProps
