import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import RepetionRemover from '../../components/smallComponents/RepetionRemover'
import { AuthContext } from '../../context/AuthContext'
import NotificationSoundAlert from '../../components/smallComponents/NotificationSoundAlert'
import ChatListItem from '../../components/chatSystem/ChatSystemComponents/PCChatSystemComponents/ChartListComponents/ChatListItem'
import DarkModeColorCoder from '../../components/smallComponents/DarkModeColorCoder'
import i18next from 'i18next'

const FetchChats = ({ toast, isDarkModeActive }) => {
  const [chats, setChats] = useState([])
  const [chatPage, setChatPage] = useState(1)
  const [isLoading, setIsLoading] = useState(true)
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { user } = useContext(AuthContext)
  const [arrivalMessage, setArrivalMessage] = useState(null)
  const [chatsUsers, setChatsUsers] = useState([])
  const [lastMessages, setLastMessages] = useState([])
  const [chatCurrentResponse, setChatCurrentResponse] = useState(true)
  const [isChatOpen, setIsChatOpen] = useState(false)

  const fetchChats = async () => {
    setIsLoading(true)
    try {
      const res = await axios.get(
        `/conversations/userConversations/${user?._id}/?page=${chatPage}&limit=15`
      )
      setChatCurrentResponse(Boolean(res.data.conversations.length))
      setChats([...chats, ...res.data.conversations])
      setChatsUsers([...chatsUsers, ...res.data.chatsUsers])
      setLastMessages([...lastMessages, ...res.data.lastMessages])
    } catch (error) {
      console.log(error)
    }
    setIsLoading(false)
  }
  useEffect(() => {
    fetchChats()
  }, [user?._id, chatPage, chatPage, arrivalMessage])

  const [sender, setSender] = useState()
  useEffect(() => {
    const fetchPaired = async () => {
      try {
        const res = await axios.get(
          `/users/oneUser/${arrivalMessage?.senderId}`
        )
        setSender(res.data.user)
      } catch (error) {
        console.log(error)
      }
    }
    if (arrivalMessage?.senderId) fetchPaired()
  }, [arrivalMessage?.senderId])

  useEffect(() => {
    const notify = () =>
      toast(
        <ChatListItem
          props={{
            isDarkModeActive,
            chatsUsers: [
              {
                name: sender?.firstName + ' ' + sender?.lastName,
                image: sender?.profilePicture,
                _id: sender?._id,
              },
            ],
            lastMessages: [arrivalMessage],
          }}
          element={{
            ...chats?.find((c) =>
              c?.members?.includes(arrivalMessage?.senderId)
            ),
            members: [arrivalMessage?.senderId],
          }}
        />,
        {
          position: 'bottom-right',
          autoClose: '5000',
          hideProgressBar: true,
          newestOnTop: true,
          closeOnClick: true,
          draggable: true,
          rtl,
          style: {
            borderRadius: 15,
            margin: 10,
            background: isDarkModeActive
              ? DarkModeColorCoder('Grey_light')
              : null,
          },
          theme: isDarkModeActive ? 'dark' : 'light',
        }
      )
    if (arrivalMessage && sender?._id === arrivalMessage?.senderId) {
      if (!isChatOpen) notify()
      NotificationSoundAlert()
    }
  }, [arrivalMessage, sender?._id === arrivalMessage?.senderId])

  return {
    chats: RepetionRemover(chats),
    setChatPage,
    chatPage,
    isLoading,
    arrivalMessage,
    setArrivalMessage,
    chatsUsers,
    lastMessages,
    chatCurrentResponse,
    setIsChatOpen,
    setChats,
  }
}

export default FetchChats
