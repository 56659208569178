import axios from 'axios'
import { useEffect, useState } from 'react'
import RepetionRemover from '../../../components/smallComponents/RepetionRemover'

const FetchContributorsNotifications = ({
  pageNumber,
  post,
  arrivalNotification,
  user,
  setIsLoading,
}) => {
  const [contribNotifications, setContribNotifications] = useState([])
  const [contribNotificationsSenders, setContribNotificationsSenders] =
    useState([])
  const [contribNotificationsProjects, setContribNotificationsProjects] =
    useState([])
  const [contribPage, setContribPage] = useState(1)
   const [currentResponse, setCurrentResponse] = useState(true)

  useEffect(() => {
    const fetchNContribotifications = async () => {
      setIsLoading(true)
      try {
        const contribNotificationsRes = await axios.get(
          `/notifications/userContribNotifications/${
            user?._id
          }/?page=${contribPage}&limit=${pageNumber()}`
        )
        setCurrentResponse(
          Boolean(contribNotificationsRes.data.notifications.length)
        )
        setContribNotifications([
          ...contribNotifications,
          ...contribNotificationsRes.data.notifications,
        ])
        setContribNotificationsSenders([
          ...contribNotificationsSenders,
          ...contribNotificationsRes.data.senders,
        ])
        setContribNotificationsProjects([
          ...contribNotificationsProjects,
          ...contribNotificationsRes.data.projects,
        ])
      } catch (err) {}
      setIsLoading(false)
    }
    fetchNContribotifications()
  }, [user?._id, user?._id === arrivalNotification?.userId, post, contribPage])
  useEffect(() => {
    if (user?._id === arrivalNotification?.userId) {
      if (arrivalNotification?.category === 'contributors')
        setContribNotifications([arrivalNotification, ...contribNotifications])
    }
  }, [user?._id === arrivalNotification?.userId, user?._id])

  const similarNots = RepetionRemover(contribNotifications)
    ?.filter(
      (not) =>
        not?.associativity === arrivalNotification?.associativity &&
        not?.relation === arrivalNotification?.relation &&
        not?.category === arrivalNotification?.category &&
        not?.conditionIndex === arrivalNotification?.conditionIndex &&
        not?.accompaniedData?.postId ===
          arrivalNotification?.accompaniedData?.postId
    )
    ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

  useEffect(() => {
    setContribNotifications((notifs) => [
      similarNots[0],
      ...(notifs?.filter(
        (notif) => !similarNots?.find((not) => not?._id === notif?._id)
      ) || []),
    ])
  }, [
    similarNots[0],
    similarNots?.length,
    user?._id === arrivalNotification?.userId,
  ])

  return {
    contribNotifications: RepetionRemover(contribNotifications),
    contribNotificationsSenders: RepetionRemover(contribNotificationsSenders),
    contribNotificationsProjects: RepetionRemover(contribNotificationsProjects),
    setContribPage,
    contribPage,
    setContribNotifications,
    contribCurrentResponse: currentResponse,
  }
}

export default FetchContributorsNotifications
