import React, { useContext, useState } from 'react'
import { AuthContext } from '../../../../context/AuthContext'
import DialogModelIFunctionalUnit from '../../../dialogModelUnit/DialogModelIFunctionalUnit'
import ControlPanelContentsArray from './ControlPanelContentsArray'
import ControlPanelMenuArray from './ControlPanelMenuArray'

const ControlPanelDialogsContent = (props) => {
  const { cardIndex, modelCardsContentArray, profileProps } = props
  const [DMFUProps, setDMFUProps] = useState({
    folderPathProps: {},
    searchSystemProps: {},
    menuItemId: undefined,
  })
  const { user } = useContext(AuthContext)
  const { menuItemId } = DMFUProps
  const DCGProps = {
    menuItemId,
    ...props,
    ...profileProps,
  }
  const propsII = {
    ...DMFUProps,
    DCGProps,
    user,
  }

  return (
    <DialogModelIFunctionalUnit
      DialogModelUnitMenu={ControlPanelMenuArray()[cardIndex]}
      DialogModelUnitContentArray={
        ControlPanelContentsArray(propsII)[cardIndex]
      }
      swibableHeaderCardComponent={{
        title: modelCardsContentArray[cardIndex].name,
        icon: modelCardsContentArray[cardIndex].pureIcon,
      }}
      showSearchBar={true}
      hideSecondPathSearch={true}
      hideFourthPathSearch={true}
      hideThirdPathSearch={true}
      setDMFUProps={setDMFUProps}
      {...props}
    />
  )
}

export default ControlPanelDialogsContent
