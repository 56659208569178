import React, { useState } from 'react'
import NewFormDialogModel from '../../../../../formsSystem/NewFormDialogModel'
import PopoverMenu from '../../../../../popoverMenu/PopoverMenu'
import WindowProps from '../../../../../windowViewer/WindowProps'
import ChatAttatchmentsDialogContent from './PCChatHeaderPopoverComps/ChatAttatchmentsDialogContent'
import ChatSettingsDialogContent from './PCChatHeaderPopoverComps/ChatSettingsDialogContent'
import i18next from 'i18next'

const PCChatHeaderPopover = (props) => {
  const { isWindowOpen, windowOpener, windowCloser } = WindowProps()
  const [options, setoptions] = useState(0)

  const optionsArray = [
    {
      type: 'settings',
      funs: () => {
        setoptions(0)
        windowOpener()
      },
    },
    {
      type: 'attachments',
      funs: () => {
        setoptions(1)
        windowOpener()
      },
    },
  ]

  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <>
      <PopoverMenu optionsArray={optionsArray} />
      <NewFormDialogModel
        NewFormComponent={
          options === 0 ? (
            <ChatSettingsDialogContent {...props} />
          ) : (
            <ChatAttatchmentsDialogContent {...props} />
          )
        }
        newFormDialogClose={windowCloser}
        isNewFormDialogOpen={isWindowOpen}
        {...props}
        dialogTitle={
          options === 0
            ? rtl
              ? 'اعدادات'
              : 'Settings'
            : rtl
            ? 'مرفقات'
            : 'Attachments'
        }
        clickAwayState={true}
      />
    </>
  )
}

export default PCChatHeaderPopover
