import React, { useContext, useEffect, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import ImageUpdator from '../../../../uploadImageSystem/ImageUpdator'
import ImagesSystem from '../../../../postSystem/ImagesSystem'
import PostProps from '../../../../homeFeed/homeFeedComponents/HomeFeedPostModelComponents/PostProps'
import axios from 'axios'
import { AuthContext } from '../../../../../context/AuthContext'
import { MdVerified } from 'react-icons/md'
import DefaultImageGrapper from '../../../../smallComponents/DefaultImageGrapper'

const styles = () => ({
  typo: {
    padding: 5,
  },
  profileUserImage: {
    width: '150px',
    height: '150px',
    borderRadius: '50%',
    objectFit: 'cover',
    border: '2px solid white',
    margin: '10px 10px 0 10px',
    position: 'relative',
    display: 'block',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0px 0px 4px 3px rgba(105, 112, 112, 0.2)',
    },
  },
  name: {
    fontWeight: '600',
    fontSize: '20px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  blockAuthIcon: {
    cursor: 'pointer',
    color: 'blue',
  },
})

const ProfilePhotoName = ({ classes, profileProps }) => {
  const { user, isDarkModeActive } = profileProps
  const { user: currentUser, dispatch } = useContext(AuthContext)
  const [image, setImage] = useState(
    user?.profilePicture || DefaultImageGrapper('noUserAvatar')
  )
  const {
    imageViewerDialogOpener,
    imageViewerDialogClose,
    isImageViewerDialogOpen,
  } = PostProps(
    {},
    {
      imageViewerProps: true,
    }
  )

  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    const updateProfilePicture = async () => {
      setIsLoading(true)
      try {
        const res = await axios.patch(`/users/${user?._id}`, {
          profilePicture: image,
          userId: currentUser?._id,
        })
        dispatch({
          type: 'UPDATE',
          payload: res.data.user,
        })
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (image && image !== user?.profilePicture) updateProfilePicture()
  }, [image])
  const ResizerProps = {
    maxWidth: 1000,
  }
  return (
    <>
      <span style={{ position: 'relative' }}>
        <ImageUpdator
          setImage={setImage}
          bottom={20}
          right={20}
          pathName={`/users/${user?._id}`}
          hide={user?._id !== currentUser?._id}
          ResizerProps={ResizerProps}
          updateProps={{
            path: `/users/${user?._id}`,
            payload: { profilePicture: '', userId: user?._id },
          }}
          remImage={image}
        />
        <img
          className={classes.profileUserImage}
          src={image}
          alt={user?.firstName}
          onClick={user?.profilePicture ? imageViewerDialogOpener : undefined}
        />
        <ImagesSystem
          imageViewer={true}
          imagesArray={[image]}
          imageViewerDialogClose={imageViewerDialogClose}
          isImageViewerDialogOpen={isImageViewerDialogOpen}
          isDarkModeActive={isDarkModeActive}
        />
      </span>
      <span className={classes.typo}>
        <span className={classes.name}>
          <span>{user?.firstName + ' ' + user?.lastName}</span>
          {user?.verified && (
            <MdVerified color='blue' style={{ margin: '2px 5px -2px 5px' }} />
          )}
        </span>
      </span>
    </>
  )
}

const WrappedProfilePhotoName = withStyles(styles)(ProfilePhotoName)

export default WrappedProfilePhotoName
