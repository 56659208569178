import React, { useContext } from 'react'
import withStyles from '@mui/styles/withStyles'
import ChartBody from './RightbarChartComponents/ChartBody'
import ChartTop from './RightbarChartComponents/ChartTop'
import ChartParameters from './RightbarChartComponents/ChartParameters'
import RightbarExpandedDialog from './RightbarExpandedDialog'
import StateGenerator from '../../smallComponents/StateGenerator'
import CustomEmpty from '../../smallComponents/CustomEmpty'
import { useTranslation } from 'react-i18next'
import { SettingsContext } from '../../../context/SettingsContext/SettingsContext'

const styles = () => ({
  chartArticle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '1rem',
    padding: '1px 0 1px 0',
    margin: '5px 5px 18px 5px',
    borderRadius: 15,
    filter: 'brightness(130%)',
  },
  chartBody: {
    height: '70%',
  },
})

const RightbarChart = ({ classes, defaultChartProps }) => {
  const { defaultProject } = defaultChartProps
  const { isGeneralDialogOpen, GeneralDialogOpener, GeneralDialogClose } =
    StateGenerator({
      GeneralProps: true,
    })
  const { settings } = useContext(SettingsContext)
  const isDarkModeActive = settings?.Mode === 'Dark'
  const { t } = useTranslation()

  return (
    <article>
      <div>
        <article
          className={classes.chartArticle}
          style={
            {
              // background: isDarkModeActive
              //   ? null
              //   : 'linear-gradient(75deg, rgb(5, 5, 26) 15%, rgb(79, 95, 114) 100%)',
            }
          }
        >
          <ChartTop
            {...defaultChartProps}
            viewPopViewerIcon={true}
            GeneralDialogOpener={GeneralDialogOpener}
            isDarkModeActive={isDarkModeActive}
          />
          {defaultProject ? (
            <>
              <div className={classes.chartBody}>
                <ChartBody
                  {...defaultChartProps}
                  // containerWidth={300}
                  containerHeight={100}
                  isDarkModeActive={isDarkModeActive}
                />
              </div>
              <ChartParameters
                {...defaultChartProps}
                showDatePicker={false}
                showProgressParams={false}
                inputName='rightbarChart'
              />
            </>
          ) : (
            <article style={{ width: 300 }}>
              <CustomEmpty
                emptyMssg={t('no_default_project')}
                imageKey='chart'
                style={{
                  fontSize: 15,
                  margin: '15px auto',
                  color: 'white',
                  filter: 'brightness(0.5)',
                }}
                isDarkModeActive={isDarkModeActive}
              />
            </article>
          )}

          <RightbarExpandedDialog
            rightbarExpDialogClose={GeneralDialogClose}
            isRightbarExpDialogOpen={isGeneralDialogOpen}
          />
        </article>
      </div>
    </article>
  )
}

const WrappedRightbarChart = withStyles(styles)(RightbarChart)

export default WrappedRightbarChart
