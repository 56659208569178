import UserContributors from './ControlPanelDialogsContentComponents/UserContributors'
import UserControl from './ControlPanelDialogsContentComponents/UserControl'
import UserIdeasInquiries from './ControlPanelDialogsContentComponents/UserIdeasInquiries'
import UserProjectsDeals from './ControlPanelDialogsContentComponents/UserProjectsDeals'

const ControlPanelContentsArray = (props) => {
  return [
    UserContributors(props),
    UserControl(props),
    UserIdeasInquiries(props),
    UserProjectsDeals(props),
  ]
}

export default ControlPanelContentsArray
