import React, { useState } from 'react'
import NewResourceFormButtons from './NewResourceFormComps/NewResourceFormButtons'
import FormsSystem from '../../../formsSystem/FormsSystem'
import newSharedResourceConfig from '../../../leftbarDirectoryComponents/feedsModels/PostBodyModelComponents/SharedResourcePostModelComps/SharedResourceConfigs'
import FullFormChecker from '../../../formsSystem/FullFormChecker'
import FormImagesUploader from '../../../uploadImageSystem/FormImagesUploader'
import TextEngineToolsFooter from '../../../smallComponents/TextEngineToolsFooter'
import FormDataLangModulator from '../../../formsSystem/FormDataLangModulator'
import FormDataInitialValueExtractor from '../../../formsSystem/FormDataInitialValueExtractor'
import { useTranslation } from 'react-i18next'

const NewResourceForm = ({ GeneralDialogClose, DCGProps, setCards, onAdd }) => {
  const { t } = useTranslation()
  const { isDarkModeActive } = DCGProps
  const [formData, setFormData] = useState({}) //1
  const newformData = FormDataLangModulator(formData, t) //2
  FormDataInitialValueExtractor(newSharedResourceConfig(), setFormData) //3
  const [chosenHashTagElements, setChosenHashTagElements] = useState([])
  const [images, setImages] = useState([])
  return (
    <div style={{ color: isDarkModeActive && 'white' }}>
      <FormImagesUploader images={images} setImages={setImages} />
      <FormsSystem
        formComponentsArray={newSharedResourceConfig()}
        formComponentsValues={formData}
        setFormComponentsValues={setFormData}
        setChosenHashTagElements={setChosenHashTagElements}
        chosenHashTagElements={chosenHashTagElements}
        {...DCGProps}
      />
      <NewResourceFormButtons
        chosenHashTagElements={chosenHashTagElements}
        DCGProps={DCGProps}
        formData={newformData}
        newResourceClose={GeneralDialogClose}
        isFormDataFilled={FullFormChecker({
          FormConfig: newSharedResourceConfig(),
          formData,
        })}
        setCards={setCards}
        images={images}
        onAdd={onAdd}
      />
      <TextEngineToolsFooter {...DCGProps} />
    </div>
  )
}

export default NewResourceForm
