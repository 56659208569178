import React from 'react'
import { MdArrowUpward, MdSearch } from 'react-icons/md'
import withStyles from '@mui/styles/withStyles'
import i18next from 'i18next'

const styles = () => ({
  searchIcon: {
    cursor: 'pointer',
    cursor: 'pointer',
    fontSize: 15,
    transition: '0.3s',
    '&:hover': {
      fontSize: 16,
      opacity: 0.5,
    },
  },
  '@media (max-width: 600px)': {
    searchIcon: {
      cursor: 'pointer',
    },
  },
})

const FolderPathSerachIconToggle = ({
  classes,
  searchSystemProps,
  path2,
  path3,
  path4,
  isDarkModeActive,
  folderPathProps,
  showSearchBar,
}) => {
  const { stepBackFun } = folderPathProps
  const {
    setShowSearchInput,
    showSearchInput,
    hideSecondPathSearch,
    hideThirdPathSearch,
    hideFourthPathSearch,
  } = searchSystemProps
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <>
      {!showSearchInput && (
        <MdSearch
          onClick={() => setShowSearchInput(true)}
          className={classes.searchIcon}
          style={{
            marginLeft: !rtl && 10,
            marginRight: rtl && 10,
            display:
              // showSearchBar is for the first path, while hideSecondPathSearch is for the second path
              showSearchBar || (path2 && !stepBackFun) || path3
                ? (hideSecondPathSearch && path2 && !path3) ||
                  (hideThirdPathSearch && path3 && !path4) ||
                  (hideFourthPathSearch && path4)
                  ? 'none'
                  : null
                : 'none',
            color: isDarkModeActive ? 'white' : 'inherit',
          }}
        />
      )}
      {showSearchInput && (
        <MdArrowUpward
          onClick={() => setShowSearchInput(false)}
          className={classes.searchIcon}
          style={{
            color: isDarkModeActive ? 'white' : 'inherit',
          }}
        />
      )}
    </>
  )
}

const WrappedFolderPathSerachIconToggle = withStyles(styles)(
  FolderPathSerachIconToggle
)

export default WrappedFolderPathSerachIconToggle
