import React from 'react'
import {
  FcDepartment,
  FcReadingEbook,
  FcConferenceCall,
  FcDam,
} from 'react-icons/fc'
import MControlButtons from './MControlPanelComponents/MControlButtons'
import ControlPanelDialogsContent from './ProfileControlPanelComponents/ControlPanelDialogsContent'
import StateEngine from '../../smallComponents/StateEngine'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

const MControlPanel = ({ profileProps }) => {
  const { t } = useTranslation()
  const lang = i18next.language
  const rtl = lang === 'ar'

  const iconStyle = {
    fontSize: 25,
    marginRight: !rtl && 10,
    marginLeft: rtl && 10,
  }
  const controlPanelButtonsII = [
    {
      name: t('Contributors'),
      icon: <FcConferenceCall style={iconStyle} />,
      pureIcon: FcConferenceCall,
      margin: '0 2px 0 0',
    },
    {
      name: t('Control'),
      icon: <FcDepartment style={iconStyle} />,
      pureIcon: FcDepartment,
      margin: '0 2px 0 2px',
    },
    {
      name: t('Ideas_&_Inquiries'),
      icon: <FcReadingEbook style={iconStyle} />,
      pureIcon: FcReadingEbook,
      margin: '0 2px 0 2px',
    },
    {
      name: t('Projects_&_Deals'),
      icon: <FcDam style={iconStyle} />,
      pureIcon: FcDam,
      margin: '0 0 0 4px',
    },
  ]

  const controlPanelButtons = StateEngine({
    StatesArray: controlPanelButtonsII,
  })

  return (
    <MControlButtons
      profileProps={profileProps}
      modelCardsContentArray={controlPanelButtons}
      DialogsComponentContent={ControlPanelDialogsContent}
    />
  )
}

export default MControlPanel
