const TranslationFilesNamesList = [
  'translation',
  'AddOnBusinessModel',
  'AffiliationBusinessModel',
  'AikidoBusinessModel',
  'AnynoumousMarket',
  'AuctionBusinessModel',
  'ReverseAuction',
  'BarterBusinessModel',
  'CrossSellingBusinessModel',
  'CashMachineBusinessModel',
  'Crowdfunding',
  'Crowdsourcing',
  'CustomerLoyaltyBusinessModel',
  'AugmentedNetworkBusinessModel',
  'DigitisationBusinessModel',
  'DirectSellingBusinessModel',
  'ECommerceBusinessModel',
  'OnlineOfflineHybrid',
  'FreemiumBusinessModel',
  'ExperienceSellingBusinessModel',
  'FlatRateBusinessModel',
  'FractionalOwnershipBusinessModel',
  'FromPushToPullBusinessModel',
  'GuaranteedAvailabilityBusinessModel',
  'HiddenRevenueBusinessModel',
  'IngredientBrandingBusinessModel',
  'IntegratorBusinessModel',
  'FranchisingBusinessModel',
  'LayerPlayerBusinessModel',
  'LeverageCustomerDataBusinessModel',
  'LicensingBusinessModel',
  'LockInBusinessModel',
  'LongTailBusinessModel',
  'MakeMoreofIt',
  'MassCustomisationBusinessModel',
  'MyAlternative',
  'NoFrillsBusinessModel',
  'OpenBusiness',
  'OpenSourceBusinessModel',
  'OrchestratorBusinessModel',
  'PayPerUse',
  'PayWhatYouWantBusinessModel',
  'PeertoPeerBusinessModel',
  'PerformanceBasedContracting',
  'RazorAndBladeBusinessModel',
  'RentInsteadOfBuyBusinessModel',
  'RevenueSharing',
  'ReverseEngineeringBusinessModel',
  'ReverseInnovationBusinessModel',
  'RobinHoodBusinessModel',
  'ComplementaryShopsBusinessModel',
  'SelfService',
  'ShopInShopBusinessModel',
  'SolutionProviderBusinessModel',
  'SubscriptionBusinessModel',
  'SupermarketBusinessModel',
  'TargetthePoorBusinessModel',
  'TrashToCashBusinessModel',
  'TwoSidedMarketBusinessModel',
  'UltimateLuxuryBusinessModel',
  'UserDesignBusinessModel',
  'WhiteLabelBusinessModel',
  'DropshippingBusinessModel',
  'MembershipBusinessModel',
  'PayPerClickBusinessModel',
  'PureBundlingBusinessModel',
  'TimeShareBusinessModel',
  'TiredPrcingBusinessModel',
  'UpsellingBusinessModel',
  'EcosystemBusinessModel',
  'MultiLevelMarketingBusinessModel',
  'NetworkBusinessModel',
  'SponsorshipBusinessModel',
  'TransactionBasedBusinessModel',
  'ConsultingAndProfessionalServices',
  'FunctionAsAService',
  'HybridCloud',
  'InfrastructureAsAService',
  'PlatformAsAService',
  'ResellerAndReferralPrograms',
  'SoftwareAsAService',
  'SoftwareLicensing',
  'Barriers',
  'BusinessDefinition',
  'BusinessPolarity',
  'BuyerPower',
  'Choice',
  'CommoditySlide',
  'CustomerBehaviour',
  'CustomerCatchingAndKeeping',
  'CustomerDescription',
  'CustomerHistory',
  'CustomerMigration',
  'CustomerMotivation',
  'CustomerPerception',
  'CustomerSatisfaction',
  'Differentiation',
  'DirectSaleSegments',
  'ForecastingTomorrow',
  'FuturePositions',
  'LatentNeeds',
  'MarketingDifferentSegments',
  'MarketingInformationSystems',
  'MarketingRevenues',
  'NewEntrants',
  'Positioning',
  'Relationships',
  'RetentionAsLoyalty',
  'SegmentLockOut',
  'SegmentMigration',
  'SegmentPriotirization',
  'SegmentationEffects',
  'ShareholdersvsCustomerRetention',
  'Substitutes',
  'SupplierPower',
  'TheSegmentOfOne',
  'ValueMigration',
  'BrandBreaking',
  'BrandCosts',
  'Branding',
  'BrandLeadership',
  'BrandSecrets',
  'BrandsManagment',
]

export default TranslationFilesNamesList
