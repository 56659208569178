import React, { useContext, useEffect } from 'react'
import withStyle from '@mui/styles/withStyles'
import { useState } from 'react'
import { Divider } from '@mui/material'
import MTopBarIcons from './MTopbarComps/MTopBarIcons'
import MCollectiveNotifications from './MTopbarComps/MCollectiveNotifications'
import axios from 'axios'
import { AuthContext } from '../../context/AuthContext'
import MTopBarMenuContent from './MTopbarComps/MTopBarMenuContent'
import TitleOppositeIcons from './MTopbarComps/TitleOppositeIcons'
import MTopBarLogo from './MTopbarComps/MTopBarLogo'
import Profile from '../../components/profile/Profile'
import PageBody from '../PageBody'

const styles = () => ({
  container: {
    position: 'sticky',
    top: 0,
    zIndex: 19,
    width: '100vw',
    overflowX: 'hidden',
    transition: 'transform 0.3s ease-in-out', // Adjusted transition duration
  },
  wrapper: {
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 5px',
    boxShadow: 'rgba(0, 0, 0, 0.10) 1.95px 1.95px 2.6px',
    transition: 'background-color 0.1s ease-in-out', // Add smooth transition
  },
  hutupiaLogo: {
    width: 50,
    height: 50,
    background: 'rgb(118, 118, 148)',
    borderRadius: '50%',
  },
  logoTitle: {
    fontSize: '2rem',
    fontWeight: 'bold',
    fontFamily: "'Inter', sans-serif",
    color: 'rgb(0, 119, 181)',
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer',
    margin: 'auto 20px',
  },
  iconsWrapper: {
    display: 'flex',
    justifyItems: 'space-between',
    margin: 'auto 20px',
  },
  iconsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    transition: 'transform 0.3s ease-in-out, background-color 0.3s ease-in-out', // Adjusted transition duration
  },
  logoWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    transition: 'transform 0.1s ease-in-out, background-color 0.1s ease-in-out', // Add smooth transition
    justifyContent: 'space-between',
  },
  searchWrapper: {
    width: '30vw',
    height: 30,
    background: 'rgba(200, 208, 219, 0.2)',
    padding: 5,
    borderRadius: 15,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    alignContent: 'center',
    margin: '6px auto',
  },
  hide: {
    transform: 'translateY(-100%)',
    backgroundColor: 'transparent',
  },
  marginTopZero: {
    marginTop: 0,
  },
  marginTopNegative: {
    transform: 'translateY(-100%)', // Use transform for smoother animations
    transition: 'transform 0.3s ease-in-out', // Adjusted transition duration
  },
  scrolled: {
    transform: 'translateY(-100%)',
    transition: 'transform 0.1s ease-in-out',
  },
  shrink: {
    transform: 'translateY(-50%)',
  },
})

const debounce = (func, delay) => {
  let timeoutId
  return (...args) => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      func(...args)
    }, delay)
  }
}

const MTopbar = ({ classes, ...props }) => {
  const {
    pageProps,
    MainComponent,
    MainComponentProps,
    cardsPage,
    dynamicMenuArray,
  } = props
  const {
    contribNotifications,
    generalNotifications,
    dealsNotifications,
    chats,
    isDarkModeActive,
  } = pageProps

  const { user } = useContext(AuthContext)
  const [clickedIconId, setClickedIconId] = useState()
  const [contributrosNotNumb, setContributrosNotNumb] = useState(0)
  const [chatNotNumb, setChatNotNumb] = useState(0)
  const [contractsNotNumb, setContractsNotNumb] = useState(0)
  const [generalNotNumb, setGeneralNotNumb] = useState(0)

  const notifProps = {
    clickedIconId,
    contributrosNotNumb,
    contractsNotNumb,
    generalNotNumb,
    setClickedIconId,
    setChatNotNumb,
    setContributrosNotNumb,
    setContractsNotNumb,
    setGeneralNotNumb,
    pageProps,
  }

  useEffect(() => {
    const fetchcontribNotifCount = async () => {
      try {
        const res = await axios.get(`/notifications/counts/o/${user?._id}`)
        setContributrosNotNumb(res.data.contribNotifCount)
        setContractsNotNumb(res.data.contractNotifCount)
        setGeneralNotNumb(res.data.genNotifCount)
      } catch (error) {
        console.log(error)
      }
    }
    fetchcontribNotifCount()
  }, [
    user?._id,
    contribNotifications,
    dealsNotifications,
    generalNotifications,
  ])

  useEffect(() => {
    const fetchChatsCount = async () => {
      try {
        const res = await axios.get(`/conversations/counts/o/${user?._id}`)
        setChatNotNumb(res.data.chatsCount)
      } catch (error) {
        console.log(error)
      }
    }
    fetchChatsCount()
  }, [chats, user?._id])

  const [mviewCompIndex, setMviewCompIndex] = useState(0)
  const mviewComps = [
    <PageBody
      MainComponent={MainComponent}
      MainComponentProps={MainComponentProps}
      pageProps={pageProps}
      cardsPage={cardsPage}
      dynamicMenuArray={dynamicMenuArray}
    />,
    // collective notifs
    <MCollectiveNotifications {...notifProps} />,
    // make sure that socket is connected
    <Profile pageProps={pageProps} user={user} />,
    <MTopBarMenuContent {...props} />,
  ]

  // for scrolling
  const [isScrolled, setIsScrolled] = useState(false)
  const [prevScrollPosition, setPrevScrollPosition] = useState(0)

  const handleScroll = debounce(() => {
    const currentScrollPosition = window.scrollY
    const isScrollingUp = currentScrollPosition > prevScrollPosition

    const isScrollDeltaGreaterThanThreshold =
      Math.abs(currentScrollPosition - prevScrollPosition) > 0

    if (isScrollDeltaGreaterThanThreshold) {
      setIsScrolled(isScrollingUp)
      setPrevScrollPosition(currentScrollPosition)
    }
  }, 0) // Adjust debounce delay

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [prevScrollPosition])

  return (
    <>
      <header
        className={`${classes.container} ${isScrolled ? classes.shrink : ''}`}
        style={{
          backgroundColor: isDarkModeActive ? '#232526' : 'white',
          color: isDarkModeActive ? '#BCC0C4' : 'white',
        }}
      >
        <div className={classes.logoWrapper}>
          <MTopBarLogo {...pageProps} />
          <TitleOppositeIcons {...props} />
        </div>
        <div
          className={classes.iconsContainer}
          style={{
            borderBottom: `1px solid ${
              isDarkModeActive ? 'white' : 'rgb(0, 119, 181)'
            }`,
          }}
        >
          <Divider light flexItem orientation='vertical' variant='middle' />
          <MTopBarIcons
            setMviewCompIndex={setMviewCompIndex}
            mviewCompIndex={mviewCompIndex}
            isDarkModeActive={isDarkModeActive}
            user={user}
          />
        </div>
      </header>
      {mviewComps[mviewCompIndex]}
    </>
  )
}

const WrappedMTopbar = withStyle(styles)(MTopbar)

export default WrappedMTopbar
