import React from 'react'
import { IconButton, Tooltip } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'
import { FaUserFriends, FaHandshake } from 'react-icons/fa'
import { BsFillChatFill } from 'react-icons/bs'
import { RiNotification2Fill } from 'react-icons/ri'
import { useContext, useState, useEffect } from 'react'
import axios from 'axios'
import { AuthContext } from '../../../context/AuthContext'
import PopoverModel from '../../smallComponents/PopoverModel'
import ContribNotifsPopoverComp from './TopBarNotificationsIconsComps/ContribNotifsPopoverComp'
import GeneralNotifsPopoverComp from './TopBarNotificationsIconsComps/GeneralNotifsPopoverComp'
import ContractsNotifsPopoverComp from './TopBarNotificationsIconsComps/ContractsNotifsPopoverComp'
import ChatsNotifsPopoverComp from './TopBarNotificationsIconsComps/ChatsNotifsPopoverComp'
import i18next from 'i18next'

const useStyles = makeStyles((theme) => ({
  iconsWrapper: {
    display: 'flex',
    justifyItems: 'space-between',
  },
  iconBadge: {
    minWidth: 20,
    height: 20,
    backgroundColor: 'rgb(139, 0, 0)',
    borderRadius: '50%',
    color: 'white',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    fontSize: 11,
    top: 10,
    right: 5,
  },
  iconWrapper: {
    cursor: 'pointer',
    margin: 'auto 3px',
    position: 'relative',
  },
  icon: {
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    padding: '10px',
  },
}))

const TopBarNotificationsIcons = (props) => {
  const classes = useStyles()
  const lang = i18next.language
  const { pageProps } = props
  // const PF = process.env.REACT_APP_PUBLIC_FOLDER
  const {
    contribNotifications,
    generalNotifications,
    dealsNotifications,
    chats,
    isDarkModeActive,
  } = pageProps || {}
  const { user } = useContext(AuthContext)
  const [clickedIconId, setClickedIconId] = useState()
  const [contributrosNotNumb, setContributrosNotNumb] = useState(0)
  const [chatNotNumb, setChatNotNumb] = useState(0)
  const [contractsNotNumb, setContractsNotNumb] = useState(0)
  const [generalNotNumb, setGeneralNotNumb] = useState(0)

  // pppover
  const [index, setIndex] = useState(null)

  const [anchorEl, setAnchorEl] = useState(null)
  const popoverOpener = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
    setIndex(null)
  }

  useEffect(() => {
    const fetchcontribNotifCount = async () => {
      try {
        const res = await axios.get(`/notifications/counts/o/${user?._id}`)
        setContributrosNotNumb(res.data.contribNotifCount)
        setContractsNotNumb(res.data.contractNotifCount)
        setGeneralNotNumb(res.data.genNotifCount)
      } catch (error) {
        console.log(error)
      }
    }
    fetchcontribNotifCount()
  }, [
    user?._id,
    contribNotifications,
    dealsNotifications,
    generalNotifications,
  ])

  useEffect(() => {
    const fetchChatsCount = async () => {
      try {
        const res = await axios.get(`/conversations/counts/o/${user?._id}`)
        setChatNotNumb(res.data.chatsCount)
      } catch (error) {
        console.log(error)
      }
    }
    fetchChatsCount()
  }, [chats, user?._id])

  const notificationsArray = [
    {
      title: lang === 'ar' ? 'إشعارات المساهمين' : 'Contributors Notifications',
      icon: <FaUserFriends style={{ fontSize: '25' }} />,
      notiNumb: contributrosNotNumb > 99 ? '99+' : contributrosNotNumb || 0,
      fun: async () => {
        setContributrosNotNumb(Number(clickedIconId) === 0 && 0)
        try {
          await axios.patch(
            `/notifications/userContribNotifications/${user._id}`,
            { shown: true }
          )
        } catch (error) {
          console.log(error)
        }
      },
      PopoverComponent: <ContribNotifsPopoverComp {...pageProps} user={user} />,
    },
    {
      title: lang === 'ar' ? 'رسائل' : 'Messages',
      icon: <BsFillChatFill size={23} />,
      notiNumb: chatNotNumb > 99 ? '99+' : chatNotNumb || 0,
      fun: async () => {
        setChatNotNumb(Number(clickedIconId) === 1 && 0)
        try {
          await axios.patch(`/conversations/userConversations/${user._id}`, {
            shown: true,
          })
        } catch (error) {
          console.log(error)
        }
      },
      PopoverComponent: <ChatsNotifsPopoverComp {...pageProps} user={user} />,
    },
    {
      title:
        lang === 'ar'
          ? 'إشعارات العقود والصفقات'
          : 'Contracts and Deals Notifications',
      icon: <FaHandshake size={23} />,
      notiNumb: contractsNotNumb > 99 ? '99+' : contractsNotNumb || 0,
      fun: async () => {
        setContractsNotNumb(Number(clickedIconId) === 2 && 0)
        try {
          await axios.patch(
            `/notifications/userDealsNotifications/${user._id}`,
            { shown: true }
          )
        } catch (error) {
          console.log(error)
        }
      },
      PopoverComponent: (
        <ContractsNotifsPopoverComp {...pageProps} user={user} />
      ),
    },
    {
      title: lang === 'ar' ? 'إشعارات' : 'Notifications',
      icon: <RiNotification2Fill size={23} />,
      notiNumb: generalNotNumb > 99 ? '99+' : generalNotNumb || 0,
      fun: async () => {
        setGeneralNotNumb(Number(clickedIconId) === 3 && 0)
        try {
          await axios.patch(
            `/notifications/userGeneralNotifications/${user._id}`,
            { shown: true }
          )
        } catch (error) {
          console.log(error)
        }
      },
      PopoverComponent: <GeneralNotifsPopoverComp {...pageProps} user={user} />,
    },
  ]
  return (
    <div className={classes.iconsWrapper}>
      {notificationsArray.map((item, notiIndex) => (
        <Tooltip title={item.title} key={notiIndex}>
          <span
            className={classes.iconWrapper}
            onClick={(e) => {
              setClickedIconId(notiIndex)
              popoverOpener(e)
              setIndex(notiIndex)
            }}
          >
            <IconButton
              edge='start'
              color='inherit'
              aria-label='open drawer'
              onClick={item.fun}
            >
              <div
                className={classes.icon}
                style={{
                  background: isDarkModeActive
                    ? 'rgba(200, 208, 219, 0.2)'
                    : 'rgba(83, 166, 200, 0.6)',
                }}
              >
                {item.icon}
              </div>
              <div
                className={classes.iconBadge}
                style={{ display: item.notiNumb === 0 ? 'none' : null }}
              >
                {item.notiNumb}
              </div>
            </IconButton>
          </span>
        </Tooltip>
      ))}
      <PopoverModel
        anchorEl={anchorEl}
        handlePopoverClose={handlePopoverClose}
        PopoverComponent={notificationsArray[index]?.PopoverComponent}
      />
    </div>
  )
}

export default TopBarNotificationsIcons
