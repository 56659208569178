import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { AuthContextProvider } from './context/AuthContext'
import { FeedContextProvider } from './context/FeedContext/FeedContext'
import { BrowserRouter as Router } from 'react-router-dom'
import { SettingsContextProvider } from './context/SettingsContext/SettingsContext'
import { createGenerateClassName, StylesProvider } from '@mui/styles'
import { v4 as uuidv4 } from 'uuid'

const generateClassName = createGenerateClassName({
  productionPrefix: `${uuidv4()}_Hutupia_${Math.random()}`,
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Router>
      <AuthContextProvider>
        <SettingsContextProvider>
          <FeedContextProvider>
            <StylesProvider generateClassName={generateClassName}>
              <App />
            </StylesProvider>
          </FeedContextProvider>
        </SettingsContextProvider>
      </AuthContextProvider>
    </Router>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
