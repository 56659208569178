import i18next from "i18next"

const NameLogoObjectivesConfigs = () => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return [
    {
      title: 'Project_Name',
      formComponentType: 'text',
      value: '',
      placeholder: rtl ? 'إختر اسما مناسباً' : 'Choose Proper Name',
      required: true,
    },
    {
      title: 'Industry_Type',
      formComponentType: 'select',
      value: '',
      menuOptions: [
        'Agriculture',
        'Arts_&_Entertainment',
        'Automotive',
        'Beauty_&_Personal_Care',
        'Construction',
        'Education',
        'Energy_&_Utilities',
        'Food_&_Beverage',
        'Healthcare',
        'Hospitality_&_Tourism',
        'Information_Technology',
        'Legal_Services',
        'Manufacturing',
        'Marketing_&_Advertising',
        'Non-profit',
        'Real_Estate',
        'Retail',
        'Sports_&_Recreation',
        'Transportation_&_Logistics',
      ],
    },
    {
      title: 'Project_Phase',
      formComponentType: 'select',
      value: '',
      menuOptions: ['Incubation', 'Start-up', 'Scale-up', 'Enterprise'],
    },
  ]
}

export default NameLogoObjectivesConfigs
