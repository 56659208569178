import { Avatar, ListItem } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../../../context/AuthContext'
import GlassLayer from '../../../../smallComponents/GlassLayer'
import TextEngine from '../../../../textProcessingEngine/TextEngine'
import { FaCircle } from 'react-icons/fa'
import i18next from 'i18next'

const styles = () => ({
  wrappper: {
    flexDirection: 'row',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 5,
    padding: 5,
  },
  avatarNameWrappper: {
    flexDirection: 'row',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
  },
  userName: {
    fontWeight: 500,
  },
  lastMessage: {
    fontSize: 12,
    fontFamily: 'inherit',
    fontWeight: 500,
    overflow: 'hidden',
    height: 20,
  },
  dateWrapper: {
    fontSize: '9px',
  },
  messageWrapper: {
    background: 'rgba(106,151,222,0.6)',
    height: '100%',
    padding: 5,
    margin: 3,
    fontSize: '15px',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '54px',
    borderRadius: '10px !important',
    '&:hover': {
      filter: 'brightness(80%)',
    },
  },
  clickedChat: {
    backgroundColor: 'rgba(217,224,252, 0.6) !important',
  },
})

const ChatListItem = ({ classes, props, element }) => {
  const {
    isDarkModeActive,
    setChosenChat,
    chosenChat,
    socket,
    chatsUsers,
    lastMessages,
    onlineUsers,
    arrivalMessage,
  } = props
  const { user } = useContext(AuthContext)
  const chattedUser = chatsUsers?.find(
    (u) => u?._id !== user?._id && element?.members?.includes(u?._id)
  )
  const inlastChatMessage = lastMessages?.find(
    (m) => m?.conversationId === element?._id
  )
  const [lastChatMessage, setLastMessage] = useState(inlastChatMessage)
  useEffect(() => {
    setLastMessage(
      arrivalMessage?.conversationId === element?._id
        ? arrivalMessage
        : lastMessages?.find((m) => m?.conversationId === element?._id)
    )
  }, [element, arrivalMessage])
  const lastMessage = (
    <TextEngine
      socket={socket}
      textContent={lastChatMessage?.text || ''}
      textPackage={lastChatMessage}
    />
  )
  const showOnline = onlineUsers?.find((id) => id === chattedUser?._id)

  const lang = i18next.language
  const rtl = lang === 'ar'
  const ltr = lang !== 'ar'
  return (
    <ListItem
      sx={{
        color: isDarkModeActive ? 'white' : null,
        overflow: 'hidden',
        cursor: 'pointer',
      }}
      onClick={() => setChosenChat(element)}
      className={element?._id === chosenChat?._id ? classes.clickedChat : null}
    >
      <section
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          borderRadius: 10,
          background: 'rgba(106,151,222,0.2)',
        }}
      >
        <section className={classes.wrappper}>
          <span className={classes.avatarNameWrappper}>
            <Avatar
              src={chattedUser?.image}
              alt={`${chattedUser?.name}'s Image`}
            />
            <span
              className={classes.userName}
              style={{ marginLeft: ltr && 10, marginRight: rtl && 10 }}
            >
              {chattedUser?.name}
            </span>
            <FaCircle
              size={12}
              color='green'
              style={{
                marginTop: '2px',
                marginBottom: '-2px',
                marginLeft: !rtl && 10,
                marginRight: rtl && 10,
                display: !showOnline && 'none',
              }}
            />
          </span>
        </section>
        <div className={classes.messageWrapper}>
          <div className={classes.lastMessage}>{lastMessage}</div>
          <GlassLayer />
          <span className={classes.dateWrapper}>
            {' '}
            {moment(lastChatMessage?.createdAt).calendar()}
          </span>
        </div>
      </section>
    </ListItem>
  )
}

const WrappedChatListItem = withStyles(styles)(ChatListItem)

export default WrappedChatListItem
