import React, { useContext, useState } from 'react'
import DialogModelIFunctionalUnit from '../dialogModelUnit/DialogModelIFunctionalUnit'
import { AuthContext } from '../../context/AuthContext'

const CardsPageModel = (props) => {
  const {
    cardIndex,
    modelCardsContentArray,
    profileProps,
    ModelUnitMenu,
    ModelUnitContentArray,
  } = props
  const [DMFUProps, setDMFUProps] = useState({
    folderPathProps: {},
    searchSystemProps: {},
    menuItemId: undefined,
  })
  const { user } = useContext(AuthContext)
  const { menuItemId } = DMFUProps
  const DCGProps = {
    menuItemId,
    ...props,
    ...profileProps,
  }
  const propsII = {
    ...DMFUProps,
    DCGProps,
    user,
  }

  return (
    <DialogModelIFunctionalUnit
      DialogModelUnitContentArray={ModelUnitContentArray(propsII)}
      showSearchBar={true}
      // hideSecondPathSearch={true}
      // hideFourthPathSearch={true}
      // hideThirdPathSearch={true}
      hideDialogModelUnitMenu={true}
      // swibableHeaderCardComponent={{
      //   title: modelCardsContentArray[0].name,
      //   icon: modelCardsContentArray[0].pureIcon,
      // }}
      setDMFUProps={setDMFUProps}
      {...props}
    />
  )
}

export default CardsPageModel
