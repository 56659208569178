import React from 'react'
import NotifsPopoverCompModel from './NotifsPopoverCompModel'
import { useTranslation } from 'react-i18next'
import GeneralNotifListItem from '../../../notificationsSystem/NotificationsComponents/GeneralNotificationsComponents/GeneralNotifListItem'

const GeneralNotifsPopoverComp = (props) => {
  const { t } = useTranslation()
  const {
    setGeneralPage,
    generalNotifications,
    generalNotificationsSenders,
    generalPage,
    generalCurrentResponse,
  } = props
  const pps = {
    ...props,
    notificationType: t('Notifications'),
    data: generalNotifications,
    InteriorCardComponent: GeneralNotifListItem,
    cardModelIndex: 1,
    notificationComponentIndex: 2,
    senders: generalNotificationsSenders,
    setPage: setGeneralPage,
    page: generalPage,
    currentResponse: generalCurrentResponse,
  }
  return <NotifsPopoverCompModel {...pps} />
}

export default GeneralNotifsPopoverComp
