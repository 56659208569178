import React, { useContext, useEffect, useState } from 'react'
import UsersContributorProfile from './UsersContributorProfile'
import MembersPortalModel from '../../../../../projectProfile/ProjectProfileComponents/MembersPortalModel'
import { AuthContext } from '../../../../../../context/AuthContext'
import CustomCircularProgress from '../../../../../smallComponents/CustomCircularProgress'
import axios from 'axios'
import BlocksLoader from '../../../../../smallComponents/BlocksLoader'
import i18next from 'i18next'

const UserProjectContributors = (props) => {
  const noFSPanelPropsSecondView = {
    showDownloadIcon: false,
  }

  const { user } = useContext(AuthContext)
  const [contributors, setContributors] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchUserContributors = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(`/users/contributors/${user?._id}`)
        setContributors(res.data.contributors)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    fetchUserContributors()
  }, [user?._id])

  const modContribs = contributors.map((fol) => ({
    ...fol,
    name: fol.firstName + ' ' + fol.lastName,
    id: fol._id,
    image: fol.profilePicture,
  }))

  const lang = i18next.language
  const rtl = lang === 'ar'
  return isLoading ? (
    <BlocksLoader {...props?.DCGProps} />
  ) : (
    <MembersPortalModel
      {...props}
      placeholderValue={rtl ? 'مشاركينى' : 'My Contributors'}
      cards={modContribs}
      noFSPanelPropsSecondView={noFSPanelPropsSecondView}
      noFSPanelPropsFirstView={{ hideNoFileSystemPanel: true }}
      CardProfileComponent={UsersContributorProfile}
      hideInfoCard
    />
  )
}

export default UserProjectContributors
