import axios from 'axios'
import React, { Suspense, useContext, useState } from 'react'
import { AuthContext } from '../../../../../../../context/AuthContext'
import ButtonsSystem from '../../../../../../buttonsSystem/ButtonsSystem'
import { NewIdeaFormsConfigs } from '../../../../../../feedsSystem'
import FullFormChecker from '../../../../../../formsSystem/FullFormChecker'
import ElementInfoCard from '../../../../../../generalPortalSystem/ElementInfoCard'
import IdeaContent from '../../../../../../sharingSystem/ShareDialogContentComponents/ShareModels/NewIdeaGeneratorComps/IdeaContent'
import TextEngineToolsFooter from '../../../../../../smallComponents/TextEngineToolsFooter'
import FormImagesUploader from '../../../../../../uploadImageSystem/FormImagesUploader'
import BlocksLoader from '../../../../../../smallComponents/BlocksLoader'
import { useTranslation } from 'react-i18next'
import FormDataLangModulator from '../../../../../../formsSystem/FormDataLangModulator'
import FormDataInitialValueExtractor from '../../../../../../formsSystem/FormDataInitialValueExtractor'
import { toast } from 'react-toastify'
import i18next from 'i18next'
const NewIdeaGenerator = React.lazy(() =>
  import(
    '../../../../../../sharingSystem/ShareDialogContentComponents/ShareModels/NewIdeaGenerator'
  )
)

const NewIdeaForm = (props) => {
  const { DCGProps, GeneralDialogClose, setCards } = props
  const { t } = useTranslation()
  const { isDarkModeActive } = DCGProps
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { user } = useContext(AuthContext)
  const [chosenCards, setChosenCards] = useState([])
  const modelTempsIds = chosenCards.map((card) => card?.id)
  const [images, setImages] = useState([])
  // order matters
  const [formData, setFormData] = useState({}) //1
  const newformData = FormDataLangModulator(formData, t) //2
  FormDataInitialValueExtractor(NewIdeaFormsConfigs(), setFormData) //3

  const [infoData, setInfoData] = useState({
    Content: '',
    secured: true,
  })
  const [checked, setChecked] = useState(true)
  const [chosenHashTagElements, setChosenHashTagElements] = useState([])
  const handleChange = () => {
    setChecked(!checked)
    setInfoData({ ...infoData, secured: !checked })
  }
  const ideaGeneratorProps = {
    formComponentsArray: NewIdeaFormsConfigs(),
    formComponentsValues: formData,
    setFormComponentsValues: setFormData,
    chosenCards,
    setChosenCards,
    DCGProps,
    ...DCGProps,
    hideTETFooter: true,
    stopInitiator: true,
    setChosenHashTagElements,
    chosenHashTagElements,
  }
  const compData = {
    ...newformData,
    ...infoData,
  }
  const [isCreating, setIsCreating] = useState(false)
  const buttonsArray = [
    {
      title: rtl ? 'إلغاء' : 'Cancel',
      funs: GeneralDialogClose,
      disabled: isCreating,
    },
    {
      title: rtl
        ? isCreating
          ? 'جارى الإنشاء'
          : 'إنشاء'
        : isCreating
        ? 'Creating...'
        : 'Create',
      disabled:
        !FullFormChecker({
          FormConfig: [
            {
              title: 'Title',
              value: '',
            },
            {
              title: 'Content',
              value: '',
            },
          ],
          formData: compData,
        }) || isCreating,
      funs: async () => {
        setIsCreating(true)
        if (images)
          try {
            const res = await axios.post(`/ideas`, {
              ...newformData,
              ...infoData,
              modelTempsIds,
              images,
              chosenHashTagElements,
              userId: user._id,
            })
            if (typeof setCards === 'function')
              setCards((ideas) => [res.data.idea, ...ideas])
          } catch (error) {
            console.log(error)
          }
        setIsCreating(false)
        GeneralDialogClose()
        toast.success(
          rtl
            ? 'تم إنشاء فكرة بنجاح'
            : 'A new idea has been added successfully to your ideas'
        )
      },
    },
  ]

  const pathName = `/ideas/${user?._id}`
  return (
    <article style={{ color: isDarkModeActive ? 'white' : 'black' }}>
      <section style={{ marginRight: !rtl && 20, marginLeft: rtl && 20 }}>
        <ElementInfoCard
          sect_sub_ind='idea'
          initialContent={
            rtl
              ? 'لتوليد أفكار جديدة ، من الضروري أولاً وضع تصور لفكرتك وتحديد الطريقة الأكثر فعالية للتعامل معها. بمجرد أن يكون لديك فهم واضح لمفهومك ، يمكنك بعد ذلك تحديد المزيج المثالي من نماذج الأعمال واستراتيجيات التسويق اللازمة لتحقيقه. '
              : 'To generate new ideas, it is essential to first conceptualize your idea and determine the most effective way to approach it. Once you have a clear understanding of your concept, you can then identify the ideal combination of business models and marketing strategies needed to bring it to fruition. '
          }
        />
      </section>
      <Suspense
        fallback={
          <BlocksLoader
            loaderText={
              rtl ? 'جارى إنشاء نموذج فكرة جديده' : 'Generating a New Idea Form'
            }
            {...DCGProps}
          />
        }
      >
        <FormImagesUploader
          images={images}
          setImages={setImages}
          pathName={pathName}
        />
        <NewIdeaGenerator ideaGeneratorProps={ideaGeneratorProps} />
        <IdeaContent
          infoData={infoData}
          setInfoData={setInfoData}
          handleChange={handleChange}
          checked={checked}
          chosenHashTagElements={chosenHashTagElements}
          setChosenHashTagElements={setChosenHashTagElements}
          DCGProps={DCGProps}
          newIdeaMode
        />
        <ButtonsSystem buttonsArray={buttonsArray} {...DCGProps} />
        <TextEngineToolsFooter {...DCGProps} />
      </Suspense>
    </article>
  )
}

export default NewIdeaForm
