import axios from 'axios'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../../../../../../context/AuthContext'
import deleteFile from '../../../../../../firebase/deleteFile'
import GeneralPortalSystem from '../../../../../generalPortalSystem/GeneralPortalSystem'
import NewChannelForm from '../../../../../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectPartnershipsComponents/ProjectPartnershipsDialogContentComps/ProjectPartnershipsContentArraysComps/ProjectChannelsComps/ProjectPartnershipChannelsComps/NewChannelForm'
import { PartnershipChannelProfile } from '../../../../../windowViewer/WindowViewerSystemComps'
import i18next from 'i18next'

const MyChannels = (props) => {
  const { user } = useContext(AuthContext)
  const { t } = useTranslation()
  const lang = i18next.language
  const rtl = lang === 'ar'
  const propsII = {
    ...props,
    elementProfileProps: { hideCreator: true },
    noFSPanelPropsFirstView: {},
    placeholderValue: t('Channels'),
    NewElementForm: NewChannelForm,
    ElementProfile: PartnershipChannelProfile,
    dialogTitle: `${t('_New')} ${t('Channel')}`,
    noFSPanelPropsSecondView: {
      showAddToChannel: false,
      showPDFDownloader: false,
      deleteTippy: `${t('Delete')} ${t('Channel')}`,
      confirmmationTitles: [
        { title: `${t('Channel')} ${t('has_been_deleted')}` },
      ],
    },
    onDelete: async (channel) => {
      // deleting urls of images of the channel feed
      const resUrls = await axios.get(`/urls/channel/${channel?._id}`)
      if (resUrls?.data?.urls) {
        Promise.all(
          resUrls?.data?.urls?.map((image) => {
            if (image) deleteFile(image)
          })
        )
      }
    },
    deletePath: 'channels',
    cardsPropName: 'channels',
    getPath: `channels/userChannels/${user._id}`,
    sect_sub_ind: 'channel',
    initialContent: rtl
      ? 'فى قسم "قنوات التشارك" يمكنك التحكم فى جميع الافكار المضافة أو إضافة المزيد كما يمكنك تحديث أو مسح غيرهم. بالإضافة للتعامل مع طلبات  المشاركة'
      : 'In "My Channels" section you can control all your channels, like creating new ones, deleting or updating others. You may also accept contribution requests',
  }
  return <GeneralPortalSystem {...propsII} />
}

export default MyChannels
