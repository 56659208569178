import React, { useContext } from 'react'
import { Avatar, AvatarGroup } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { AuthContext } from '../../../context/AuthContext'
import { useTranslation } from 'react-i18next'

const styles = () => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    marginTop: '18%',
    alignItems: 'center',
    flexDirection: 'column',
  },
  avatar: {
    width: '100px !important',
    height: '100px !important',
  },
  text: {
    fontWeight: 500,
    fontSize: 24,
    marginTop: 15,
  },
  '@media (max-width: 600px)': {
    avatar: {
      width: '60px !important',
      height: '60px !important',
    },
  },
})

const NoChatMessagesDefaultView = ({
  classes,
  isDarkModeActive,
  pageProps,
  chat,
}) => {
  const { user } = useContext(AuthContext)
  const { chatsUsers } = pageProps
  const currentChatter = chatsUsers?.find(
    (u) => u?._id !== user?._id && chat?.members.includes(u?._id)
  )
  const { t } = useTranslation()
  return (
    <article className={classes.wrapper}>
      <AvatarGroup>
        <Avatar className={classes.avatar} src={user?.profilePicture} />
        <Avatar className={classes.avatar} src={currentChatter?.image} />
      </AvatarGroup>
      <section
        style={{ color: isDarkModeActive ? 'white' : null }}
        className={classes.text}
      >
        {t('Start_Your_First_Chat!')}
      </section>
    </article>
  )
}

const WrappedNoChatMessagesDefaultView = withStyles(styles)(
  NoChatMessagesDefaultView
)

export default WrappedNoChatMessagesDefaultView
