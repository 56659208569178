import {
  MdArrowBackIosNew,
  MdArrowForwardIos,
  MdArrowLeft,
} from 'react-icons/md'
import React, { useState, useEffect } from 'react'
import GallerySideButton from '../PostImagesGalleryViewComponents/GallerySideButton'
import withStyles from '@mui/styles/withStyles'
import { Dialog, DialogContent, IconButton } from '@mui/material'
import DarkModeColorCoder from '../../../smallComponents/DarkModeColorCoder'

const styles = () => ({
  image: {
    width: 'auto',
    height: '100%',
    borderRadius: 10,
    position: 'relative',
    objectFit: 'cover',
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    overflowY: 'hidden',
    overflowX: 'hidden',
  },
  backIcon: {
    fontSize: 20,
  },

  iconButton: {
    height: '30px !important',
    borderRadius: '50% !important',
    cursor: 'pointer !important',
    fontSize: '20 !important',
    position: 'absolute !important',
    top: '20px !important ',
    left: '20px !important',
    zIndex: '2 !important',
  },
})

const ImageViewerPCView = ({
  classes,
  viewedImageIndex,
  setViewedImageIndex,
  imagesArray,
  imageViewerDialogClose,
  isImageViewerDialogOpen,
  isDarkModeActive,
}) => {
  const ActiveComp = () => {
    const [viewedImage, setViewedImage] = useState(
      typeof imagesArray === 'object' ? imagesArray[viewedImageIndex] : []
    )

    useEffect(() => {
      if (typeof imagesArray === 'object')
        setViewedImage(imagesArray[viewedImageIndex])
    }, [viewedImageIndex])

    // to control the color of the buttons
    const [windowSize, setWindowSize] = useState(window.innerWidth)

    useEffect(() => {
      setWindowSize(window.innerWidth)
    }, [window.innerWidth])
    return (
      <Dialog
        disableScrollLock
        fullScreen={true}
        open={Boolean(isImageViewerDialogOpen)}
        PaperProps={{
          style: {
            transition: '0.3s',
            background: isDarkModeActive
              ? DarkModeColorCoder('Grey_light')
              : 'white',
          },
        }}
      >
        <DialogContent
          className={classes.imageWrapper}
          sx={{ color: isDarkModeActive ? 'white' : 'inherit' }}
        >
          <IconButton
            edge='start'
            color='inherit'
            className={classes.iconButton}
            onClick={() => imageViewerDialogClose()}
          >
            <MdArrowLeft className={classes.backIcon} />
          </IconButton>
          <GallerySideButton
            shiftFun={() =>
              setViewedImageIndex(
                viewedImageIndex > 0 ? viewedImageIndex - 1 : viewedImageIndex
              )
            }
            rightLeftPosisionting={{ left: 10 }}
            ShiftIcon={MdArrowBackIosNew}
            iconColor={windowSize > 600 ? 'black' : 'white'}
            hideIconButton={viewedImageIndex === 0}
            iconButtonEdge='start'
            isDarkModeActive={isDarkModeActive}
          />
          <img
            src={viewedImage}
            alt='Images Viewer System'
            className={classes.image}
          />
          <GallerySideButton
            shiftFun={() =>
              setViewedImageIndex(
                viewedImageIndex < imagesArray?.length - 1
                  ? viewedImageIndex + 1
                  : viewedImageIndex
              )
            }
            rightLeftPosisionting={{ right: 0 }}
            hideIconButton={viewedImageIndex === imagesArray?.length - 1}
            iconColor={windowSize > 600 ? 'black' : 'white'}
            ShiftIcon={MdArrowForwardIos}
            iconButtonEdge='start'
            isDarkModeActive={isDarkModeActive}
          />
        </DialogContent>
      </Dialog>
    )
  }
  return isImageViewerDialogOpen && <ActiveComp />
}

const WrappedImageViewerPCView = withStyles(styles)(ImageViewerPCView)

export default WrappedImageViewerPCView
