import React from 'react'

const GPMFSGProps = (props) => {
  const {
    setCardId,
    cardId,
    modCards,
    ElementProfile,
    elementProfileProps,
    DCGProps,
    setIsUpdated,
    setCards,
    windowProps,
    element,
    users,
    projects,
    folderPathProps,
    searchSystemProps,
  } = props
  const profProps = {
    [windowProps?.windowCardName]: element,
  }
  return {
    setCardId,
    cardId,
    cards: modCards,
    MiniFSModelIndex: 6,
    CardProfileComponent: (
      <ElementProfile
        {...profProps}
        {...elementProfileProps}
        DCGProps={DCGProps}
        setIsUpdated={setIsUpdated}
        setCards={setCards}
        cards={modCards}
        users={users}
        projects={projects}
        // for channel getout
        folderPathProps={folderPathProps}
        searchSystemProps={searchSystemProps}
        setCardId={setCardId}
      />
    ),
    cardIdDependencyIndex: 0,
    ...DCGProps,
  }
}

export default GPMFSGProps
