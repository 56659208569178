import React, { useContext, useEffect, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import { IconButton } from '@mui/material'
import { MdArrowLeft, MdArrowRight } from 'react-icons/md'
import ModelDialogWrappingIII from '../../dialogModelUnit/ModelDialogWrappingIII'
import ProjectsChartsNavigator from './RightabrExpandedDialogComps/ProjectsChartsNavigator'
import axios from 'axios'
import { AuthContext } from '../../../context/AuthContext'
import { SettingsContext } from '../../../context/SettingsContext/SettingsContext'
import i18next from 'i18next'

const styles = () => ({
  categoryIcon: {
    margin: ' 0 30px 0 0',
    fontSize: 28,
  },
  title: {
    fontWeight: 500,
  },
  backIcon: {
    fontSize: 20,
    cursor: 'pointer',
  },
})

const RightbarExpandedDialog = ({
  classes,
  rightbarExpDialogClose,
  isRightbarExpDialogOpen,
}) => {
  const [projects, setProjects] = useState([])
  const { user } = useContext(AuthContext)
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const res = await axios.get(`/projects/userProjects/${user?._id}`)
        setProjects([...projects, ...res.data.establishedProjects])
      } catch (error) {}
    }
    if (user?._id) fetchProjects()
    // i will add the rest of projects like contracted, followed by concatination
  }, [user?._id])

  const unitsArray = projects.map((project) => ({
    ...project,
    name: project?.ProjectName,
  }))

  const { settings } = useContext(SettingsContext)
  const isDarkModeActive = settings?.Mode === 'Dark'
  const dialogsArray = [
    {
      open: isRightbarExpDialogOpen,
      content: (
        <ProjectsChartsNavigator
          unitsArray={unitsArray}
          modelUnitProps={{}}
          isDarkModeActive={isDarkModeActive}
        />
      ),
    },
  ]

  const lang = i18next.language
  const rtl = lang === 'ar'

  return (
    <ModelDialogWrappingIII
      dialogsArray={dialogsArray}
      isDarkModeActive={isDarkModeActive}
      dialogWidth='600px'
      dialogHeight='400px'
      dialogIcon={
        <IconButton
          edge='start'
          color='inherit'
          onClick={rightbarExpDialogClose}
        >
          {rtl ? (
            <MdArrowRight className={classes.backIcon} />
          ) : (
            <MdArrowLeft className={classes.backIcon} />
          )}
        </IconButton>
      }
    />
  )
}

const WrappedRightbarExpandedDialog = withStyles(styles)(RightbarExpandedDialog)

export default WrappedRightbarExpandedDialog
