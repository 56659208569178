import { Grid } from '@mui/material'
import React from 'react'

const PostBottomInfoDisplay = ({ buttonsArray, hideBottom }) => {
  return buttonsArray.map((button, buttonIndex) => (
    <Grid
      item
      xs={12 / buttonsArray.length}
      key={buttonIndex}
      style={{
        width: '100%',
        display: hideBottom ? 'none' : 'flex',
        justifyContent: 'center',
      }}
    >
      {/* to be designed */}
      {button?.info?.content}
    </Grid>
  ))
}

export default PostBottomInfoDisplay
