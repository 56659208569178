import axios from 'axios'
import { useEffect, useState } from 'react'

const GPCore = (props) => {
  const { searchSystemProps, getPath, cardsPropName, customCards } = props
  const [isUpdated, setIsUpdated] = useState(false)
  const [isLoading, setIsLoading] = useState()
  const [page, setPage] = useState(1)
  const [currentResponse, setCurrentResponse] = useState()
  const [lastPage, setLastPage] = useState(0)
  const [cards, setCards] = useState(customCards ? customCards : [])
  const { searchQuery } = searchSystemProps
  const [users, setUsers] = useState([])
  const [projects, setProjects] = useState([])

  const fetchUserCards = async () => {
    setIsLoading(true)
    try {
      const res = await axios.get(
        searchQuery
          ? `/${getPath}/?searchQuery=${searchQuery}`
          : `/${getPath}/?page=${page}&limit=4`
      )
      setLastPage(page)
      setCurrentResponse(
        searchQuery ? false : Boolean(res.data[cardsPropName].length)
      )
      setCards(
        searchQuery
          ? res.data[cardsPropName]
          : [...cards, ...res.data[cardsPropName]]
      )
      if (res.data.projects)
        setProjects(
          searchQuery ? res.data.projects : [...projects, ...res.data.projects]
        )
      if (res.data.users)
        setUsers(searchQuery ? res.data.users : [...users, ...res.data.users])
    } catch (error) {
      console.log(error)
    }
    setIsLoading(false)
  }
  useEffect(() => {
    if (getPath && cardsPropName)
      // if (lastPage !== page || searchQuery)
      fetchUserCards()
  }, [isUpdated, page, searchQuery, cardsPropName, getPath])
  return {
    isLoading,
    page,
    setPage,
    currentResponse,
    isUpdated,
    setIsUpdated,
    fetchUserCards,
    cards,
    setCards,
    users,
    projects,
  }
}

export default GPCore
