import React from 'react'
import { useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../../context/AuthContext'
import { useContext } from 'react'
import { SettingsContext } from '../../context/SettingsContext/SettingsContext'
import Footer from './Footer'
import i18next from 'i18next'
import { Divider } from '@material-ui/core'
import DarkModeColorCoder from '../smallComponents/DarkModeColorCoder'
import LeftbarControl from './LeftbarComps/LeftbarControl'
import LeftbarLinks from './LeftbarComps/LeftbarLinks'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginTop: '16px',
    position: 'relative',
    overflowY: 'hidden',
    height: '100vh',
    '&:hover': {
      overflowY: 'auto',
    },
  },
  '@media only screen and (max-width: 1000px)': {
    container: {
      display: 'none',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  // -----
  leftbarList: {
    padding: 0,
    margin: 0,
    listStyle: 'none',
    height: '100% !important',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center !important',
    justifyContent: 'center !important',
  },
  leftbarItemText: {
    fontFamily: '"Segoe UI Historic", Segoe UI, Helvetica, Arial, sans-serif',
    fontSize: 15,
    cursor: 'pointer',
    fontWeight: 'bold',
    color: '#050505',
  },
  leftbarIcons: {
    fontSize: 25,
    cursor: 'pointer',
  },
  leftbarListItem: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    filter: 'brightness(80%)',
    width: '100%',
    transition: '0.3s !important',
    margin: '0px 5px !important',
    '&:hover': {
      filter: 'brightness(130%)',
    },
  },
  activeLink: {
    background: 'grey',
    color: 'white',
    filter: 'brightness(130%)',
  },
}))

const Leftbar = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)
  const { settings } = useContext(SettingsContext)
  const isDarkModeActive = settings?.Mode === 'Dark'
  const pathname = useLocation().pathname

  const lang = i18next.language
  const rtl = lang === 'ar'

  const leftbarItemTextCond = rtl
    ? { marginRight: 15, color: isDarkModeActive && 'white' }
    : { marginLeft: 15, color: isDarkModeActive && 'white' }



  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <LeftbarControl />
        <LeftbarLinks/>
        <Divider
          style={{
            margin: '0 10px',
            backgroundColor: DarkModeColorCoder('Text_white'),
          }}
        />
        <Footer />
      </div>
    </div>
  )
}

export default Leftbar
