import i18next from "i18next"

export default function lastViewModelConfigs() {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return [
    {
      title: 'Initation_Post_Text',
      formComponentType: 'text',
      value: '',
      multiLine: true,
      minRows: 3,
      placeholder: rtl
        ? 'قل شيئاً عن المشروع'
        : 'Say something about the project',
    },
    {
      title: 'Initation_Post_Privacy',
      formComponentType: 'select',
      value: 'Local',
      menuOptions: ['Local', 'Global'],
    },
  ]
}
