import React, { useContext } from 'react'
import withStyles from '@mui/styles/withStyles'
import { Button } from '@mui/material'
import StateGenerator from '../../../../smallComponents/StateGenerator'
import UserPortfolioContent from './UserPortfolioComps/UserPortfolioContent'
import NewFormDialogModel from '../../../../formsSystem/NewFormDialogModel'
import { SettingsContext } from '../../../../../context/SettingsContext/SettingsContext'
import ExternalLinkSharing from '../../../../fileSystem/NoFileSystemPanelComponents/ExternalLinkSharing'
import i18next from 'i18next'

const styles = () => ({
  overviewTitle: {
    transition: '0.3s !important',
    backdropFilter: 'blur(4px)',
    '&:hover': {
      color: 'black',
    },
  },
  button: {
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    fontWeight: '550 !important',
    width: '100% !important',
    color: 'white !important',
    backdropFilter: 'blur(4px) !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
  '@media (max-width: 600px)': {
    button: {
      margin: 'auto !important',
    },
  },
})

const UserPortfolio = ({ classes, profileProps }) => {
  const { isGeneralDialogOpen, GeneralDialogOpener, GeneralDialogClose } =
    StateGenerator({ GeneralProps: true })
  const { user } = profileProps
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { settings } = useContext(SettingsContext)
  const isDarkModeActive = settings?.Mode === 'Dark'
  return (
    <>
      <span className={classes.overviewTitle}>
        <Button
          variant='text'
          onClick={GeneralDialogOpener}
          className={classes.button}
        >
          {rtl
            ? `الملف التعريفى لـ ${user?.firstName}`
            : user?.firstName + "'s Portfolio"}
        </Button>
      </span>
      <ExternalLinkSharing
        noFSPanelProps={{
          element: user,
          userProfile: true,
          showExternalLinkShare: true,
          isDarkModeActive,
        }}
      />
      {isGeneralDialogOpen && (
        <NewFormDialogModel
          NewFormComponent={
            <UserPortfolioContent
              {...profileProps}
              isDarkModeActive={isDarkModeActive}
            />
          }
          newFormDialogClose={GeneralDialogClose}
          isNewFormDialogOpen={isGeneralDialogOpen}
          isDarkModeActive={isDarkModeActive}
          dialogTitle={
            rtl
              ? `الملف التعريفى لـ ${user?.firstName}`
              : user?.firstName + "'s Portfolio"
          }
          clickAwayState={true}
        />
      )}
    </>
  )
}

const WrappedUserPortfolio = withStyles(styles)(UserPortfolio)

export default WrappedUserPortfolio
