import React from 'react'
import { Link } from 'react-router-dom'
import withStyles from '@mui/styles/withStyles'
import { MdOpenInNew } from 'react-icons/md'
import { Tooltip } from '@mui/material'
import { Bars } from 'react-loader-spinner'
import { GoArrowLeft, GoArrowRight } from 'react-icons/go'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

const styles = () => ({
  chartViewAndProjectsDirectoryIcons: {
    display: 'flex',
    alignSelf: 'flex-end',
    padding: ' 10px 10px 0 0px',
  },
  viewIcon: {
    filter: 'brightness(80%)',
    cursor: 'pointer',
    fontSize: 21,
    color: 'white',
    '&:hover': {
      filter: 'brightness(130%)',
    },
  },
  chartTop: {
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginTop: -23,
  },
  chartInfo: {
    color: 'rgb(205, 219, 219)',
    display: 'flex',
    alignItems: 'center',
    filter: 'brightness(80%)',
    cursor: 'pointer',
    borderRadius: 10,
    flexDirection: 'row',
    alignItems: 'center',
    '&:hover': {
      filter: 'brightness(130%)',
    },
    padding: 'auto 20px',
  },
  chartName: {
    color: 'white',
    filter: 'brightness(70%)',
  },
  mdArrow: {
    filter: 'brightness(70%)',
    fontSize: 15,
    '&:hover': {
      fontSize: 20,
    },
  },
})

const ChartTop = ({
  classes,
  viewPopViewerIcon,
  GeneralDialogOpener,
  defaultProject,
  projectLoading,
  setOpen,
  isDarkModeActive,
}) => {
  const { t } = useTranslation()
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <>
      <div
        className={classes.chartViewAndProjectsDirectoryIcons}
        style={{ display: viewPopViewerIcon ? null : 'none' }}
      >
        <span
          className={classes.viewIcon}
          style={{ marginLeft: rtl && 15, marginRight: !rtl && 15 }}
        >
          <MdOpenInNew
            onClick={() => {
              GeneralDialogOpener()
              if (typeof setOpen === 'function') setOpen(false)
            }}
            style={{ color: isDarkModeActive ? 'white' : 'black' }}
          />
        </span>
      </div>
      <Tooltip
        title={`${t('Go_to')} ${defaultProject?.ProjectName}`}
        placement='top'
      >
        <div
          className={classes.chartTop}
          style={{ display: !defaultProject && 'none' }}
        >
          {projectLoading ? (
            <Bars
              height='35'
              width='35'
              color='#00FFFF'
              ariaLabel='bars-loading'
              wrapperStyle={{}}
              wrapperClass=''
              visible={true}
            />
          ) : (
            <Link
              className={classes.chartName}
              to={`/projectProfile/${defaultProject?._id}`}
            >
              <section className={classes.chartInfo}>
                <h1
                  className={classes.chartName}
                  style={{ marginLeft: !rtl && 5, marginRight: rtl && 5 }}
                >
                  {defaultProject?.ProjectName}
                </h1>
                {rtl ? (
                  <GoArrowLeft
                    className={classes.mdArrow}
                    style={{ marginRight: 10 }}
                  />
                ) : (
                  <GoArrowRight
                    className={classes.mdArrow}
                    style={{ marginLeft: 10 }}
                  />
                )}
              </section>
            </Link>
          )}
        </div>
      </Tooltip>
    </>
  )
}

const WrappedChartTop = withStyles(styles)(ChartTop)

export default WrappedChartTop
