import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { List } from '@mui/material'
import SearchEngine from '../../../searchSystem/SearchEngine'
import MChatListUnit from './MChatListUnit'
import ReactScrollWheelHandler from 'react-scroll-wheel-handler'
import LoadMoreButton from '../../../smallComponents/LoadMoreButton'
import FallingLinesLoader from '../../../smallComponents/FallingLinesLoader'
import CustomEmpty from '../../../smallComponents/CustomEmpty'
import RepetionRemover from '../../../smallComponents/RepetionRemover'
import i18next from 'i18next'

const styles = () => ({
  notificationsArea: {
    height: 'auto',
    overflowY: 'auto',
    borderRadius: '0 !important',
  },
})

const MChatListModel = ({
  classes,
  chatDialogOpener,
  isDarkModeActive,
  setChosenChat,
  socket,
  currentResponse,
  setPage,
  page,
  pageProps,
  chats,
  lastMessages,
  arrivalMessage,
}) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const ordered = lastMessages
    .map((message) => {
      const chat = chats.find((ch) => ch?._id === message?.conversationId)
      return {
        ...chat,
        lastMessage: message,
      }
    })
    .sort(
      (a, b) =>
        new Date(b?.lastMessage?.createdAt) - new Date(a.lastMessage?.createdAt)
    )
  return ordered?.length > 0 ? (
    <List
      className={classes.notificationsArea}
      sx={{ color: isDarkModeActive ? 'white' : null, height: '100%' }}
    >
      <ReactScrollWheelHandler
        downHandler={(e) => {
          setPage((prev) => prev + 1)
        }}
      >
        <SearchEngine
          searchQuery={''}
          searchElements={RepetionRemover(ordered) || []}
          SearchedElementComponent={MChatListUnit}
          SearchedElementComponentProps={{
            chatDialogOpener,
            isDarkModeActive,
            setChosenChat,
            socket,
            ...pageProps,
          }}
          searchIdtentifiers={[]}
        />
        {currentResponse &&
          (page === 1 ? (
            <LoadMoreButton setPage={setPage} />
          ) : (
            <FallingLinesLoader />
          ))}
      </ReactScrollWheelHandler>
    </List>
  ) : (
    <div className={classes.emptyWrapper}>
      <CustomEmpty
        emptyMssg={rtl ? 'ليس لديك أى محادثات' : 'No Chats Yet'}
        imageKey='noManager'
        isDarkModeActive={isDarkModeActive}
      />
    </div>
  )
}

const WrappedMChatListModel = withStyles(styles)(MChatListModel)

export default WrappedMChatListModel
