import React from 'react'
import DefaultViewModel from '../../../../../projectProfile/ProjectProfileComponents/DefaultViewModel'

const MyProjectsDefaultView = ({ DCGProps }) => {
  const { modelCardsContentArray } = DCGProps
  return (
    <DefaultViewModel
      PureIcon={modelCardsContentArray[1]?.pureIcon}
      title={modelCardsContentArray[1]?.name}
    />
  )
}

export default MyProjectsDefaultView
