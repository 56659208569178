import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import i18next from 'i18next'

const AdvertismentAccess = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const lang = i18next.language
  const rtl = lang === 'ar'

  const handleClick = () => {
    navigate('/ads_management/home')
  }

  const buttonStyle = {
    borderRadius: 20,
    fontWeight: 'bold',
    fontSize: isDesktop ? 24 : 16,
    padding: isDesktop ? '16px 32px' : '12px 24px',
    backgroundColor: '#3f51b5',
    color: '#fff',
    textTransform: 'none',
    textAlign: 'center',
    width: '100%',
    margin: 'auto',
    fontFamily: 'Arial, sans-serif',
    letterSpacing: '0.5px',
    boxShadow: 'none',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    transition: 'transform 0.2s ease-in-out',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    ':hover': {
      transform: 'scale(1.1)',
    },
    ':active': {
      transform: 'scale(0.9)',
    },
  }

  const buttonText = rtl ? 'صفحة الإعلانات' : 'Go to Advertisement Page'
  const statement = rtl
    ? 'يمكنك الآن الوصول إلى صفحة الإعلانات لإنشاء وتحرير حملات الإعلانات الخاصة بك ومراقبتها من خلال عرض التقارير والتحليلات.'
    : 'You can now access the Advertisement Page to create, edit, and monitor your ad campaigns through viewing reports and analytics.'

  return (
    <div style={{ textAlign: rtl ? 'right' : 'left', width: '100%' }}>
      <p style={{ margin: '2rem 0' }}>{statement}</p>
      <Button style={buttonStyle} onClick={handleClick}>
        {buttonText}
      </Button>
    </div>
  )
}

export default AdvertismentAccess
