import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { List } from '@mui/material'
import SearchEngine from '../../../searchSystem/SearchEngine'
import ReactScrollWheelHandler from 'react-scroll-wheel-handler'
import LoadMoreButton from '../../../smallComponents/LoadMoreButton'
import CustomEmpty from '../../../smallComponents/CustomEmpty'
import FallingLinesLoader from '../../../smallComponents/FallingLinesLoader'
import i18next from 'i18next'

const styles = () => ({
  notificationsArea: {
    height: 'auto',
    overflowY: 'auto !important',
    borderRadius: '0 !important',
  },
  emptyWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignContent: 'center',
    alignItems: 'center',
  },
})

const MNotificationsListModel = ({
  classes,
  data,
  NotificationListItem,
  isDarkModeActive,
  setChosenNotification,
  notificationDialogOpener,
  socket,
  senders,
  projects,
  currentResponse,
  setPage,
  page,
  pageProps,
}) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return data.length > 0 ? (
    <List className={classes.notificationsArea}>
      <ReactScrollWheelHandler
        downHandler={(e) => {
          setPage((prev) => prev + 1)
        }}
      >
        <SearchEngine
          searchQuery={''}
          searchElements={data}
          SearchedElementComponent={NotificationListItem}
          SearchedElementComponentProps={{
            notificationDialogOpener,
            isDarkModeActive,
            setChosenNotification,
            socket,
            senders,
            projects,
            ...pageProps,
          }}
          searchIdtentifiers={[]}
        />
        {currentResponse &&
          (page === 1 ? (
            <LoadMoreButton setPage={setPage} />
          ) : (
            <FallingLinesLoader />
          ))}
      </ReactScrollWheelHandler>
    </List>
  ) : (
    <div className={classes.emptyWrapper}>
      <CustomEmpty
        emptyMssg={rtl ? 'ليس لديك أى إشعارات' : 'No Notifications Yet'}
        imageKey='noManager'
        isDarkModeActive={isDarkModeActive}
      />
    </div>
  )
}

const WrappedMNotificationsListModel = withStyles(styles)(
  MNotificationsListModel
)

export default WrappedMNotificationsListModel
