import React, { useEffect, useState } from 'react'
import ModelDialogWrappingIII from '../../dialogModelUnit/ModelDialogWrappingIII'
import CustomCircularProgress from '../../smallComponents/CustomCircularProgress'
import { MdArrowLeft, MdArrowRight } from 'react-icons/md'
import { IconButton } from '@mui/material'
import i18next from 'i18next'
import ListUnitModel from '../../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectDepartmentsComponents/DeparmentModels/ManagerModelComponents/ManagerNotesFeedComponents/ManagerNoteModelComponents/NoteTopComponents/ListUnitModel'
import ListedDialogModelContent from '../../gridedModels/ListedDialogModelComps/ListedDialogModelContent'
import axios from 'axios'

const PostLikesUsersList = ({
  isLikesListOpen,
  likesListCloser,
  post,
  //   npt defined yet
  clickAwayState,
  isDarkModeActive,
  socket,
}) => {
  const [isLoading, setIsLoading] = useState()
  const lang = i18next.language
  const rtl = lang === 'ar'

  const [likesUsers, setLikesUsers] = useState([])

  useEffect(() => {
    const fetchPostLikeUsers = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(`/posts/likesUsersList/${post?._id}`)
        setLikesUsers(res.data.likesUsers)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (post?._id && isLikesListOpen) fetchPostLikeUsers()
  }, [post?._id, isLikesListOpen])

  const dialogsArray = [
    {
      open: isLikesListOpen,
      content: isLoading ? (
        <CustomCircularProgress />
      ) : (
        <ListedDialogModelContent
          list={likesUsers}
          ListUnitModelComponent={ListUnitModel}
          ListUnitModelComponentProps={{}}
          socket={socket}
          isDarkModeActive={isDarkModeActive}
        />
      ),
    },
  ]
  return (
    <ModelDialogWrappingIII
      inactivateClickAway={clickAwayState}
      dialogsArray={dialogsArray}
      dialogWidth='400px'
      dialogHeight='600px'
      hideCloseIcon={true}
      isDarkModeActive={isDarkModeActive}
      dialogIcon={
        <IconButton
          edge='start'
          color='inherit'
          onClick={() => {
            likesListCloser()
          }}
        >
          {rtl ? (
            <MdArrowLeft style={{ fontSize: 20, cursor: 'pointer' }} />
          ) : (
            <MdArrowRight style={{ fontSize: 20, cursor: 'pointer' }} />
          )}
        </IconButton>
      }
      dialogTitle={lang === 'ar' ? 'قائمة الإعجابات' : 'Likes List'}
    />
  )
}

export default PostLikesUsersList
