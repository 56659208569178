import React, { useContext, useState } from 'react'
import { Avatar, Tooltip } from '@mui/material'
import TopBarProfileAvatarPopOver from './TopBarProfileAvatarComps/TopBarProfileAvatarPopOver'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../../../context/AuthContext'

const useStyles = makeStyles((theme) => ({
  container: {
    width: 50,
    width: 50,
    borderRadius: '50%',
    position: 'relative',
    cursor: 'pointer',
    paddingRight: 5,
    paddingLeft: 5,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    alignContent: 'center',
  },
  avatar: {
    width: 45,
    width: 45,
    borderRadius: '50%',
  },
  arrow: {
    position: 'absolute',
    bottom: 10,
    right: 10,
    background: 'rgb(200, 208, 219)',
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'space-around',
    alignContent: 'center',
    outline: 'solid white 1px',
    '&:hover': {
      width: '16px',
      height: '16px',
    },
  },
}))

const TopBarProfileAvatar = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { user } = useContext(AuthContext)
  const [anchorEl, setAnchorEl] = useState(null)
  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      <Tooltip title={t('Profile')} style={{ fontSize: 20 }}>
        <div className={classes.container} onClick={handleAvatarClick}>
          <Avatar src={user?.profilePicture || '/assets/person/noAvatar.png'} />
          <div className={classes.arrow}>
            {Boolean(anchorEl) ? (
              <MdKeyboardArrowUp color='black' size={16} />
            ) : (
              <MdKeyboardArrowDown color='black' size={16} />
            )}
          </div>
        </div>
      </Tooltip>
      <TopBarProfileAvatarPopOver
        anchorEl={anchorEl}
        handlePopoverClose={handlePopoverClose}
      />
    </>
  )
}

export default TopBarProfileAvatar
