import React from 'react'
import MyProjectsDealsDefaultView from './UserProjectsDealsComps/MyProjectsDealsDefaultView'
import MyProjects from './UserControlComponents/MyProjects'
import MyContracts from './UserControlComponents/MyContracts'
import MyIssues from './MyIssues'

const UserProjectsDeals = (props) => {
  return [
    <MyProjects {...props} />,
    <MyContracts {...props} />,
    <MyIssues {...props} />,
    <MyProjectsDealsDefaultView {...props} />,
  ]
}

export default UserProjectsDeals
