import React from 'react'
import AdsManagmentModel from './AdsManagmentModel'
import AdsExplorerPage from './AdsExplorerComps/AdsExplorerPage'
import DocumentTitleChanger from '../../components/smallComponents/DocumentTitleChanger'
import i18next from 'i18next'

const AdsExplorer = (props) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  DocumentTitleChanger(rtl ? 'متصفح الحملات الاعلانية' : 'Campaigns Explorer')
  return (
    <AdsManagmentModel Component={AdsExplorerPage} ComponentProps={props} />
  )
}

export default AdsExplorer
