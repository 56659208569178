import axios from 'axios'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../../../../../../../context/AuthContext'
import ButtonsSystem from '../../../../../../buttonsSystem/ButtonsSystem'
import { NewInquiryFormsConfigs } from '../../../../../../feedsSystem'
import FormDataInitialValueExtractor from '../../../../../../formsSystem/FormDataInitialValueExtractor'
import FormDataLangModulator from '../../../../../../formsSystem/FormDataLangModulator'
import FormsSystem from '../../../../../../formsSystem/FormsSystem'
import FullFormChecker from '../../../../../../formsSystem/FullFormChecker'
import TextEngineToolsFooter from '../../../../../../smallComponents/TextEngineToolsFooter'
import FormImagesUploader from '../../../../../../uploadImageSystem/FormImagesUploader'
import { toast } from 'react-toastify'
import i18next from 'i18next'

const NewInquiryForm = ({ DCGProps, GeneralDialogClose, setCards }) => {
  const { user } = useContext(AuthContext)
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { t } = useTranslation()
  const [isCreating, setIsCreating] = useState(false)
  // order matters
  const [formData, setFormData] = useState({}) //1
  const newformData = FormDataLangModulator(formData, t) //2
  FormDataInitialValueExtractor(NewInquiryFormsConfigs(), setFormData) //3
  const [chosenHashTagElements, setChosenHashTagElements] = useState([])
  const [images, setImages] = useState([])

  const buttonsArray = [
    {
      title: rtl ? 'إلغاء' : 'Cancel',
      funs: GeneralDialogClose,
      disabled: isCreating,
    },
    {
      title: rtl
        ? isCreating
          ? 'جارى الإنشاء'
          : 'إنشاء'
        : isCreating
        ? 'Creating...'
        : 'Create',
      disabled:
        !FullFormChecker({
          FormConfig: NewInquiryFormsConfigs(),
          formData,
        }) || isCreating,
      funs: async () => {
        setIsCreating(true)
        try {
          const res = await axios.post(`/inquiries`, {
            ...newformData,
            images,
            userId: user._id,
            chosenHashTagElements,
          })
          setCards((inqs) => [res.data.inquiry, ...inqs])
        } catch (error) {
          console.log(error)
        }
        setIsCreating(false)
        GeneralDialogClose()
        toast.success(
          rtl
            ? 'تم إنشاء متطلب بنجاح'
            : 'A new inquiry has been added successfully to your inquiries'
        )
      },
    },
  ]
  return (
    <article>
      <FormImagesUploader images={images} setImages={setImages} />
      <FormsSystem
        formComponentsArray={NewInquiryFormsConfigs()}
        formComponentsValues={formData}
        setFormComponentsValues={setFormData}
        setChosenHashTagElements={setChosenHashTagElements}
        chosenHashTagElements={chosenHashTagElements}
        {...DCGProps}
      />
      <ButtonsSystem buttonsArray={buttonsArray} {...DCGProps} />
      <TextEngineToolsFooter {...DCGProps} />
    </article>
  )
}

export default NewInquiryForm
