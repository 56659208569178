import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@mui/material'
import i18next from 'i18next'

const styles = () => ({
  paramsWrapperRow: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 15,
    filter: 'brightness(70%)',
    color: 'white',
    fontWeight: 400,
    paddingTop: 13,
    paddingBottom: 5,
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
  },
  paramTitle: {
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
  },
  paramValue: {
    color: 'rgba(234, 240, 241, 0.801)',
  },
  upIcon: {
    marginBottom: -7,
    color: 'rgb(34, 175, 218)',
    fontSize: 23,
  },
  downIcon: {
    marginBottom: -7,
    color: 'red',
    fontSize: 23,
  },
  '@media (max-width: 600px)': {
    upIcon: {
      fontSize: 20,
      marginBottom: -5,
    },
    downIcon: {
      fontSize: 20,
      marginBottom: -5,
    },
  },
})

const ChartParameters = ({ classes, chooseParameter, inputName }) => {
  const { t } = useTranslation()
  const paramsArray = [
    {
      title: t('Hashtags'),
      tippyContent: t('hashtags_tippy'),
    },
    {
      title: t('Size'),
      tippyContent: t('size_tippy'),
    },
  ]
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <article className={classes.paramsWrapperRow}>
      {paramsArray.map((param, paramIndex) => (
        <div
          style={{
            margin: 10,
            display: 'flex',
            justifyContent: 'space-around',
            width: '100%',
          }}
          key={paramIndex}
        >
          <Tooltip title={param?.tippyContent}>
            <span
              className={classes.paramTitle}
              style={{ marginRight: !rtl && 7, marginLeft: rtl && 7 }}
            >
              <input
                type='radio'
                name={inputName}
                id={paramIndex}
                defaultChecked={paramIndex === 0}
                onClick={(event) => chooseParameter(event)}
              />
              {param.title}
            </span>
          </Tooltip>
        </div>
      ))}
    </article>
  )
}

const WrappedChartParameters = withStyles(styles)(ChartParameters)

export default WrappedChartParameters
