import React, { useState, useEffect } from 'react'
import ProfileTop from './ProfileComponents/ProfileTop'
import ControlPanel from './ProfileComponents/ControlPanel'
import ProfileBottom from './ProfileComponents/ProfileBottom'
import withStyles from '@mui/styles/withStyles'
import MProfileTop from '../mobileViewComponents/MProfileTop'
import Media from 'react-media'
import ProfileCover from './ProfileComponents/ProfileCover'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import BrokenLinkComponent from '../smallComponents/BrokenLinkComponent'
import Skeleton from '../skeleton/Skeleton'
import DocumentTitleChanger from '../smallComponents/DocumentTitleChanger'

const styles = () => ({
  profileContainer: {
    flex: 7.93,
  },
  profileWrapper: {
    flex: 19,
  },
  '@media screen and (max-width: 1000px)': {
    profileContainer: {
      display: 'inline-flex',
      alignContent: 'center',
      textSizeAdjust: 'auto',
      justifyContent: 'space-around',
      margin: 0,
    },
  },
})

const Profile = ({ classes, pageProps, user: defaultUser }) => {
  const userId = useParams().userId
  const [user, setUser] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const fetchUser = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(`/users/${userId}`)
        setUser(res.data)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (userId) {
      fetchUser()
    } else {
      setUser(defaultUser)
      setIsLoading(false)
    }
  }, [userId])

  DocumentTitleChanger(
    user ? `${user?.firstName + ' ' + user?.lastName}` : null
  )

  const profileProps = {
    ...pageProps,
    user,
    userId: userId || defaultUser?._id,
    pageProps,
  }
  const mediaComponents = [
    // pc version
    <div style={{ marginLeft: '-12%', marginRight: '-12%' }}>
      <ProfileTop profileProps={profileProps} />
      <ControlPanel profileProps={profileProps} />
    </div>,
    //  mobile version
    <MProfileTop profileProps={profileProps} />,
  ]

  return (
    <div className={classes.profileContainer}>
      {isLoading ? (
        <Skeleton type={'user-profile'} />
      ) : user && user?._id ? (
        <div className={classes.profileWrapper}>
          <ProfileCover profileProps={profileProps} />
          <Media query='(max-width: 600px)'>
            {(matches) => {
              return matches ? mediaComponents[1] : mediaComponents[0]
            }}
          </Media>
          <ProfileBottom profileProps={profileProps} />
        </div>
      ) : (
        <BrokenLinkComponent pageProps={pageProps} />
      )}
    </div>
  )
}

const WrappedProfile = withStyles(styles)(Profile)

export default WrappedProfile
