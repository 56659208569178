import React, { useContext, useEffect, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import { Avatar } from '@mui/material'
import { AuthContext } from '../../../../../../context/AuthContext'
import PCChatHeaderPopover from '../../../../../chatSystem/ChatSystemComponents/PCChatSystemComponents/PCChatBodyComponents/PCChatHeaderComps/ChatHeaderPopover'
import { FaCircle } from 'react-icons/fa'
import i18next from 'i18next'

const styles = () => ({
  wrappper: {
    flexDirection: 'row',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
  },
  userName: {
    fontWeight: 500,
  },
})

const MChatDialogHeader = ({ classes, ...props }) => {
  const { chat, pageProps, onlineUsers } = props
  const { chatsUsers } = pageProps
  const { user } = useContext(AuthContext)
  const chattedUser =
    chatsUsers?.find(
      (u) => u?._id !== user?._id && chat?.members?.includes(u?._id)
    ) || chat?.chatter
  const showOnline = onlineUsers?.find((id) => id === chattedUser?._id)
  // confirm dialog funs
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const lang = i18next.language
  const rtl = lang === 'ar'
  useEffect(() => {
    setTimeout(() => {
      setIsConfirmDialogOpen(false)
    }, 10000)
  }, [isConfirmDialogOpen === true])
  return (
    <section className={classes.wrappper}>
      <Avatar src={chattedUser?.image} alt={`${chattedUser?.name}'s Photo`} />
      <span
        className={classes.userName}
        style={{ marginLeft: !rtl && 10, marginRight: rtl && 10 }}
      >
        {chattedUser?.name?.split(' ')[0]}
      </span>
      <FaCircle
        size={12}
        color='green'
        style={{
          marginTop: '2px',
          marginBottom: '-2px',
          marginLeft: !rtl && 10,
          marginRight: rtl && 10,
          display: !showOnline && 'none',
        }}
      />
      <div
        style={{
          margin: '10px 10px 0px 10px',
        }}
      >
        <PCChatHeaderPopover {...props} />
      </div>
    </section>
  )
}

const WrappedMChatDialogHeader = withStyles(styles)(MChatDialogHeader)

export default WrappedMChatDialogHeader
