import React from 'react'
import { useTranslation } from 'react-i18next'
import { AiFillHome } from 'react-icons/ai'
import { GiCargoShip, GiKnot, GiStoneTower, GiWhirlwind } from 'react-icons/gi'
import { ListItem, Tooltip } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'

const TopBarDirectIcons = (props) => {
  const { pageProps } = props
  const { isDarkModeActive } = pageProps
  const pathname = useLocation().pathname
  const { t } = useTranslation()
  const navigate = useNavigate()

  const pager = [
    {
      name: t('Home'),
      icon: AiFillHome,
      path: `/home`,
    },

    {
      name: t('Global_Vision'),
      icon: GiCargoShip,
      path: '/globalVision',
    },
    {
      name: `${t('Visions')} | ${t('Inquiries')}`,
      icon: GiWhirlwind,
      path: '/VisionsAndInquiriesFeed',
    },
    {
      name: t('Shared_Resources'),
      icon: GiKnot,
      path: '/sharedResourcesFeed',
    },
    {
      name: t('Projects_News'),
      icon: GiStoneTower,
      path: '/projectsNews',
    },
  ]
  return (
    <div
      style={{
        padding: 'auto 50px',
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-around',
      }}
    >
      {pager.map((item, i) => {
        return (
          <Tooltip key={i} title={item.name}>
            <div
              style={{
                margin: 'auto 10px',
                borderBottom:
                  (pathname === item?.path || pathname?.includes(item?.path)) &&
                  `3px solid ${
                    isDarkModeActive ? 'white' : 'rgb(0, 119, 181)'
                  }`,
                borderBottomLeftRadius: '-5px',
                borderBottomRightRadius: '-5px',
              }}
            >
              <ListItem button onClick={() => navigate(item?.path)}>
                <item.icon
                  size={31}
                  color={
                    pathname === item?.path || pathname?.includes(item?.path)
                      ? isDarkModeActive
                        ? 'white'
                        : 'rgb(0, 119, 181)'
                      : isDarkModeActive
                      ? 'grey'
                      : 'rgb(0, 119, 181)'
                  }
                />
              </ListItem>
            </div>
          </Tooltip>
        )
      })}
    </div>
  )
}

export default TopBarDirectIcons
