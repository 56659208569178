import React, { useContext, useEffect, useState } from 'react'
import NewResourceForm from '../../../../../sharedResources/SharedResourcesComponents/SharedResourcesFeedComponents/NewResourceForm'
import GeneralPortalSystem from '../../../../../generalPortalSystem/GeneralPortalSystem'
import { AuthContext } from '../../../../../../context/AuthContext'
import axios from 'axios'
import ResourceProfile from '../../../../../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectDepartmentsComponents/ProjectResourcesComponents/ResourceProfile'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

const MyResources = (props) => {
  const { user } = useContext(AuthContext)
  const [sharedPostConfigs, setSharedPostConfigs] = useState(null)
  useEffect(() => {
    const addNewResourcePost = async () => {
      try {
        await axios.patch(
          `/resources/resourcePosts/addSharedPost/${sharedPostConfigs?.element?._id}`,
          { postId: sharedPostConfigs?.postId }
        )
      } catch (error) {
        console.log(error)
      }
    }
    if (sharedPostConfigs?.element?._id && sharedPostConfigs?.postId)
      addNewResourcePost()
  }, [sharedPostConfigs?.element?._id, sharedPostConfigs?.postId])
  const { t } = useTranslation()
  const lang = i18next.language
  const propsII = {
    ...props,
    cardsHeaderText: t('My_Resources'),
    placeholderValue: t('My_Resources'),
    NewElementForm: NewResourceForm,
    ElementProfile: ResourceProfile,
    dialogTitle: lang === 'ar' ? 'مورد جديد' : 'New Resource',
    // a resource will be deleted if it has no channels to be used in
    noFSPanelPropsSecondView: {
      showDeleteIcon: true,
      deleteTippy: lang === 'ar' ? 'أزل هذا المورد' : 'Remove Resource',
      confirmmationTitles: [
        {
          title: lang === 'ar' ? 'تم حذف المورد' : 'Resource has been deleted',
        },
      ],
    },
    setSharedPostConfigs,
    deletePath: 'resources',
    cardsPropName: 'resources',
    getPath: `resources/userResources/${user._id}`,
    sect_sub_ind: 'resource',
    initialContent:
      lang === 'ar'
        ? 'فى قسم "مواردى" يمكنك التحكم فى جميع مواردك. كما يمكنك الاضافة و التحديث أو المسح بالإضافة للتعامل مع طلبات المشاركة'
        : 'In "My Resources" section you can control all your resources, like creating new ones, deleting or updating others. You may also accept contribution requests',
  }
  return <GeneralPortalSystem {...propsII} />
}

export default MyResources
