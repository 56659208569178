import React, { useContext, useState } from 'react'
import ModelDialogWrappingII from '../../../../dialogModelUnit/ModelDialogWrappingII'
import ControlPanelButtonModel from '../ControlPanelButtonModel'
import withStyles from '@mui/styles/withStyles'
import { IconButton } from '@mui/material'
import MenuIcon from '@material-ui/icons/Menu'
import { SettingsContext } from '../../../../../context/SettingsContext/SettingsContext'
import i18next from 'i18next'

const lang = i18next.language
const rtl = lang === 'ar'

const styles = () => ({
  '@media (max-width: 1000px)': {
    menuIcon: {
      marginRight: !rtl && '20px !important',
      marginLeft: rtl && '20px !important',
    },
  },

  '@media (min-width: 1000px)': {
    menuIcon2: {
      display: 'none',
    },
  },
})

const ControlButtonUnit = ({
  button,
  propsII,
  buttonIndex,
  DialogsComponentContent,
  classes,
}) => {
  const { setIsSwipeableOpen, clickAwayState } = propsII
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { settings } = useContext(SettingsContext)
  const [isDarkModeActive, setIsDarkModeActive] = useState(
    settings?.Mode === 'Dark'
  )
  const props = {
    ...propsII,
    isDarkModeActive,
    setIsDarkModeActive,
  }
  return (
    <>
      <ControlPanelButtonModel button={button} />
      {button.open && (
        <ModelDialogWrappingII
          {...props}
          inactivateClickAway={clickAwayState}
          dialogsArray={[
            {
              title: button.name,
              open: button.open,
              closeFun: button.closeFun,
              icon: (
                <>
                  <IconButton
                    edge='start'
                    color='inherit'
                    aria-label='open drawer'
                    onClick={() => setIsSwipeableOpen(true)}
                    className={classes.menuIcon}
                    sx={{ marginLeft: rtl && '10px' }}
                  >
                    <MenuIcon className={classes.menuIcon2} />
                  </IconButton>
                  {button.icon}
                </>
              ),
              content: (
                <DialogsComponentContent cardIndex={buttonIndex} {...props} />
              ),
            },
          ]}
        />
      )}
    </>
  )
}

const WrappedControlButtonUnit = withStyles(styles)(ControlButtonUnit)

export default WrappedControlButtonUnit
