import React from 'react'
import withStyles from '@mui/styles/withStyles'
import ProfilePhotoCommunication from '../profile/ProfileComponents/ProfileTopComponents/ProfilePhotoCommunication'
import ProfileOverview from '../profile/ProfileComponents/ProfileTopComponents/ProfileOverview'

const styles = () => ({
  profileTopWrapper: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
    height: 'auto',
  },
  defaultViewWrapper: {
    width: '100%',
    height: 'auto',
    marginBottom: '5px',
    display: 'flex',
    flexDirection: 'column',
  },
})

const MProfileTop = ({ classes, profileProps }) => {
  return (
    <article className={classes.profileTopWrapper}>
      <section className={classes.defaultViewWrapper}>
        <ProfilePhotoCommunication profileProps={profileProps} />
        <ProfileOverview profileProps={profileProps} />
      </section>
    </article>
  )
}

const WrappedMProfileTop = withStyles(styles)(MProfileTop)

export default WrappedMProfileTop
