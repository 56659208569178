import axios from 'axios'
import React, { useEffect, useState } from 'react'
import {
  ListItemScreenViewModels,
  PostLikeNotifCover,
  PostSharingNotifCover,
  PostCommentNotifCover,
  CommentLikeNotifCover,
  MentionNotificationCover,
  CommentMentionNotificationCover,
  NewReplyNotificationCover,
  LikeReplyMentionNotificationCover,
  ReplyMentionNotificationCover,
} from '../../'

const ForAllPostsNotificationModel = (props) => {
  const { notification } = props
  const [post, setPost] = useState()
  useEffect(() => {
    const fetchPost = async () => {
      try {
        const res = await axios.get(
          `/posts/${notification.accompaniedData?.postId}`
        )
        setPost(res.data.post)
      } catch (error) {
        console.log(error)
      }
    }
    if (notification?.accompaniedData?.postId) fetchPost()
  }, [notification?.accompaniedData?.postId])

  const notifProps = {
    ...props,
    post,
  }
  const models = [
    {
      relation: 'creator',
      conditions: [
        <PostSharingNotifCover {...notifProps} />,
        <PostLikeNotifCover {...notifProps} />,
        <PostCommentNotifCover {...notifProps} />,
        <CommentLikeNotifCover {...notifProps} />,
      ],
    },
    {
      relation: 'mentioned',
      conditions: [
        <MentionNotificationCover {...notifProps} />,
        <CommentMentionNotificationCover {...notifProps} />,
        <ReplyMentionNotificationCover {...notifProps} />,
      ],
    },
    {
      relation: 'replies',
      conditions: [
        <NewReplyNotificationCover {...notifProps} />,
        <LikeReplyMentionNotificationCover {...notifProps} />,
      ],
    },
  ]
  return <ListItemScreenViewModels {...notifProps} models={models} />
}

export default ForAllPostsNotificationModel
