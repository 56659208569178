import React, { useState } from 'react'
import StateGenerator from '../smallComponents/StateGenerator'
import DistributedWinCardsConfigurer from '../navigatorCardsModel/NavigatorCardsModelComps/DistributedWinCardsConfigurer'
import NewFormDialogModel from '../formsSystem/NewFormDialogModel'
import MiniFileSystem from '../fileSystem/MiniFileSystem'
import GPNoFSPanelPropsSecondView from './GeneralPortalSystemComponents/GPNoFSPanelPropsSecondView'
import GPNoFSPanelPropsFirstView from './GeneralPortalSystemComponents/GPNoFSPanelPropsFirstView'
import GPModelProps from './GeneralPortalSystemComponents/GPModelProps'
import GPMFSGProps from './GeneralPortalSystemComponents/GPMFSGProps.js'
import GPSearchSystemProps from './GeneralPortalSystemComponents/GPSearchSystemProps'
import GPModCards from './GeneralPortalSystemComponents/GPModCards'
import GPCore from './GeneralPortalSystemComponents/GPCore'
import BlocksLoader from '../smallComponents/BlocksLoader'
import i18next from 'i18next'

const GeneralPortalSystem = (props) => {
  const { DCGProps, folderPathProps, NewElementForm, NewElementFormProps } =
    props
  const lang = i18next.language
  const rtl = lang === 'ar'
  const {
    isLoading,
    page,
    setPage,
    currentResponse,
    setIsUpdated,
    fetchUserCards,
    cards,
    setCards,
    users,
    projects,
  } = GPCore(props)

  const modCards = GPModCards({
    ...props,
    cards,
  })

  const { isGeneralDialogOpen, GeneralDialogOpener, GeneralDialogClose } =
    StateGenerator({
      GeneralProps: true,
    })
  const { imageKey, windowProps } =
    DistributedWinCardsConfigurer(cards)[0] || {}
  const [cardId, setCardId] = useState()
  const element = modCards.filter((el) => el.id === cardId)[0]

  if (typeof setIsUpdated === 'function') {
    setTimeout(() => setIsUpdated(false), 100)
  }

  const MFSGProps = GPMFSGProps({
    ...props,
    setCardId,
    cardId,
    modCards,
    setIsUpdated,
    setCards,
    windowProps,
    element,
    users,
    projects,
  })

  const searchSystemPropsII = GPSearchSystemProps(props)
  const modelProps = GPModelProps({
    ...props,
    imageKey,
    modCards,
    setCards,
    setPage,
    fetchUserCards,
    currentResponse,
    cards,
    page,
    setIsUpdated,
  })

  const noFSPanelPropsSecondView = GPNoFSPanelPropsSecondView({
    ...props,
    setCardId,
    element,
    setCards,
    setIsUpdated,
    users,
    projects,
  })

  const noFSPanelPropsFirstView = GPNoFSPanelPropsFirstView({
    ...props,
    GeneralDialogOpener,
  })
  return isLoading && page === 1 ? (
    <BlocksLoader
      loaderText={rtl ? 'جارى تحضير البيانات' : 'Preparing Data'}
      {...DCGProps}
    />
  ) : (
    <>
      <MiniFileSystem
        MFSGProps={MFSGProps}
        searchSystemProps={searchSystemPropsII}
        modelProps={modelProps}
        folderPathProps={folderPathProps}
        noFSPanelPropsSecondView={noFSPanelPropsSecondView}
        noFSPanelPropsFirstView={noFSPanelPropsFirstView}
      />
      {NewElementForm && (
        <NewFormDialogModel
          NewFormComponent={
            <NewElementForm
              DCGProps={DCGProps}
              GeneralDialogClose={() => {
                GeneralDialogClose()
                if (typeof setIsUpdated === 'function') setIsUpdated()
              }}
              setCards={setCards}
              {...NewElementFormProps}
            />
          }
          newFormDialogClose={GeneralDialogClose}
          isNewFormDialogOpen={isGeneralDialogOpen}
          {...DCGProps}
          {...props}
          clickAwayState={true}
        />
      )}
    </>
  )
}

export default GeneralPortalSystem
