import React, { useContext, useState } from 'react'
import ModelDialogWrappingII from '../../dialogModelUnit/ModelDialogWrappingII'
import { useTranslation } from 'react-i18next'
import { SettingsContext } from '../../../context/SettingsContext/SettingsContext'
import MChat from '../MLeftbarComponents/ChatNotificationsIconsBarComponents/MChat'

const MTopChatDialog = ({ isChatDialogOpen, chatDialogClose, pageProps }) => {
  const { settings } = useContext(SettingsContext)
  const [isDarkModeActive, setIsDarkModeActive] = useState(
    settings?.Mode === 'Dark'
  )
  const { t } = useTranslation()
  const dialogsArray = [
    {
      title: t('Chat'),
      open: isChatDialogOpen,
      closeFun: () => chatDialogClose(),
      icon: '',
      content: (
        <MChat
          isDarkModeActive={isDarkModeActive}
          pageProps={pageProps}
          closeChatsList={chatDialogClose}
        />
      ),
    },
  ]
  return (
    <ModelDialogWrappingII
      dialogsArray={dialogsArray}
      mdialogMargins={'auto -24px auto -24px'}
      isDarkModeActive={isDarkModeActive}
      setIsDarkModeActive={setIsDarkModeActive}
    />
  )
}

export default MTopChatDialog
