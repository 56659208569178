import React from 'react'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  swipeableTopComp: {
    display: 'flex',
    flexDirection: 'column',
    // jsutifyContent: 'center',
    width: '100%',
  },
  image: {
    objectFit: 'cover',
    // width: '100%',
    height: '200px',
    // borderRadius: '10px',
  },

  summaryInfo: {
    display: 'flex',
    flexDirection: 'column',
    margin: 10,
  },

  title: {
    fontWeight: 500,
    fontSize: 18,
    fontStyle: 'normal',
    padding: 10,
    textAlign: 'center'
  },
})

const SwipeableMenuTopComp = ({
  classes,
  swibableHeaderCardComponent,
  isDarkModeActive,
}) => {
  return (
    <section className={classes.swipeableTopComp}>
      {swibableHeaderCardComponent.icon ? (
        <swibableHeaderCardComponent.icon size={230} />
      ) : (
        <img
          src={swibableHeaderCardComponent.image}
          alt={`${swibableHeaderCardComponent.title}'s Image`}
          className={classes.image}
        />
      )}
      <span
        className={classes.title}
        style={{
          backgroundColor: isDarkModeActive
            ? 'rgba(210, 221, 240, 0.6)'
            : 'inherit',
          color: isDarkModeActive ? 'white' : 'inherit',
        }}
      >
        {swibableHeaderCardComponent.title}
      </span>
    </section>
  )
}

const WrappedSwipeableMenuTopComp = withStyles(styles)(SwipeableMenuTopComp)

export default WrappedSwipeableMenuTopComp
