import { Button, Switch } from '@mui/material'
import axios from 'axios'
import React, { useContext, useState } from 'react'
import { AuthContext } from '../../../../../../../context/AuthContext'
import { SettingsContext } from '../../../../../../../context/SettingsContext/SettingsContext'
import deleteFile from '../../../../../../../firebase/deleteFile'
import DeleteConfirmDialog from '../../../../../../confirmationSystem/DeleteConfirmDialog'
import NotificationsSentConfirmDialog from '../../../../../../confirmationSystem/NotificationsSentConfirmDialog'
import withStyles from '@mui/styles/withStyles'
import ToastifyGeneralError from '../../../../../../smallComponents/ToastifyGeneralError'
import i18next from 'i18next'

const styles = () => ({
  button: {
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    fontWeight: '550 !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
})

const ChatSettingsDialogContent = ({
  chat,
  chatDialogClose,
  isDarkModeActive,
  pageProps,
  classes,
}) => {
  const { setChats } = pageProps
  const { user } = useContext(AuthContext)
  const { dispatch: setDispatch, settings } = useContext(SettingsContext)
  const lang = i18next.language
  const rtl = lang === 'ar'
  const confirmmationTitles = [
    {
      title: rtl ? 'تم حذف المحادثة' : 'Chat is Deleted',
    },
  ]
  // ConfirmDelete dialog
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false)
  const ConfirmDeleteDialogOpener = () => {
    setIsConfirmDeleteDialogOpen(true)
  }
  const ConfirmDeleteDialogClose = () => {
    setIsConfirmDeleteDialogOpen(false)
  }
  // confirm dialog funs
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const [checked, setChecked] = useState(settings?.EnterAsSend)
  const handleChange = async () => {
    setChecked(!checked)
    const res = await axios.patch(`/userSettings/${user?._id}`, {
      EnterAsSend: !checked,
      userId: user?._id,
    })
    setDispatch({
      type: 'UPDATE',
      payload: res.data.settings,
    })
  }
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <section style={{ fontSize: 15 }}>
            {rtl ? 'اضغط زر ادخال للإرسال' : 'Enter as Send'}
          </section>
          <Switch
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <span>{rtl ? 'مسح المحادثة' : 'Delete Conversation'}</span>
          <Button
            onClick={ConfirmDeleteDialogOpener}
            className={classes.button}
            variant='contained'
            color='primary'
          >
            {rtl ? 'مسح' : 'Delete'}
          </Button>
        </div>
      </div>
      <DeleteConfirmDialog
        confirmDeleteAction={async () => {
          setIsConfirmDialogOpen(true)
          if (chat?._id)
            try {
              const res = await axios.delete(`/conversations/${chat?._id}`, {
                headers: {},
                data: {
                  userId: user?._id,
                },
              })
              if (res.data.urls) {
                Promise.all(
                  res.data.urls?.map(async (image) => {
                    if (image) await deleteFile(image)
                  })
                )
              }
              setChats((cs) => cs?.filter((c) => c?._id !== chat?._id))
              chatDialogClose()
            } catch (error) {
              ToastifyGeneralError(error)
            }
        }}
        confirmDeleteDialogClose={ConfirmDeleteDialogClose}
        isConfirmDeleteDialogOpen={isConfirmDeleteDialogOpen}
        deletedObjectName={rtl ? 'هذه المحادثة' : 'This Chat'}
        isDarkModeActive={isDarkModeActive}
      />
      <NotificationsSentConfirmDialog
        confirmmationTitles={confirmmationTitles}
        confirmationRTitleIndex={0}
        isConfirmDialogOpen={isConfirmDialogOpen}
        setIsConfirmDialogOpen={setIsConfirmDialogOpen}
        isDarkModeActive={isDarkModeActive}
      />
    </>
  )
}

const WrappedChatSettingsDialogContent = withStyles(styles)(
  ChatSettingsDialogContent
)

export default WrappedChatSettingsDialogContent
