import i18next from "i18next"

const NewChannelFormConfigs = () => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return [
    {
      title: 'Title',
      formComponentType: 'text',
      value: '',
    },
    {
      title: 'Description',
      formComponentType: 'text',
      value: '',
      multiLine: true,
      minRows: 5,
      placeholder: rtl
        ? 'حدد غرض القناة'
        : 'Decsribe the purpose of this channel',
    },
  ]
}

export default NewChannelFormConfigs
