import React from 'react'
import { BiShare } from 'react-icons/bi'
import MultiMillify from '../../smallComponents/MultiMillify'
import { useTranslation } from 'react-i18next'

const ShareButtonConfig = ({ shareDialogOpener, styles, sharesCount }) => {
  const { t } = useTranslation()
  return {
    icon: (
      <BiShare
        size={19}
        style={{
          ...styles?.bottomIcon,
        }}
      />
    ),
    icon_text: t('Share'),
    value: (
      <span
        style={{
          display: sharesCount > 0 ? null : 'none',
          ...styles?.counterValue,
        }}
      >
        {MultiMillify(sharesCount)}
      </span>
    ),
    openFun: () => {
      if (typeof shareDialogOpener === 'function') shareDialogOpener()
    },
    info: {
      // manage language
      content: <span
        style={{
          display: sharesCount > 0 ? null : 'none',
          ...styles?.counterValue,
        }}
      >
        {MultiMillify(sharesCount)} {t('Shares')}
      </span>
    },
  }
}

export default ShareButtonConfig
