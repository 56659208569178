import React, { useContext, useEffect } from 'react'
import withStyles from '@mui/styles/withStyles'
import ImageUpdator from '../../../../uploadImageSystem/ImageUpdator'
import ImageUploaderFrame from '../../../../uploadImageSystem/ImageUploaderFrame'
import { AuthContext } from '../../../../../context/AuthContext'

const styles = () => ({
  imageWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    height: '80%',
  },
  image: {
    width: 200,
    height: 200,
    borderRadius: '50%',
    marginTop: 10,
    objectFit: 'cover',
  },
})

const CompanyLogo = ({
  classes,
  isDarkModeActive,
  image,
  setImage,
  dataHistory,
}) => {
  const ResizerProps = {
    maxWidth: 1000,
  }
  const { user } = useContext(AuthContext)
  useEffect(() => {
    if (dataHistory?.current) dataHistory.current.image = image
  }, [image])
  return image ? (
    <section className={classes.imageWrapper}>
      <div
        style={{
          position: 'relative',
          marginTop: 20,
        }}
      >
        <ImageUpdator
          setImage={setImage}
          bottom={25}
          right={10}
          pathName={`/projects/project_under_creation/${user?._id}`}
          ResizerProps={ResizerProps}
          remImage={image}
        />
        <img src={image} alt='New Project' className={classes.image} />
      </div>
    </section>
  ) : (
    <section style={{ width: '100%' }}>
      <ImageUploaderFrame
        setImage={setImage}
        isDarkModeActive={isDarkModeActive}
        pathName={`/projects/project_under_creation/${user?._id}`}
        ResizerProps={ResizerProps}
        height={200}
      />
    </section>
  )
}

const WrappedCompanyLogo = withStyles(styles)(CompanyLogo)

export default WrappedCompanyLogo
