import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import withStyles from '@mui/styles/withStyles'
import HomeInteractionsBar from '../../homeFeed/homeFeedComponents/HomeInteractionsBar'
import HomeFeedPostModel from '../../homeFeed/homeFeedComponents/HomeFeedPostModel'
import FeedFooter from '../../feedsSystem/FeedFooter'
import PostingMessage from '../../feedsSystem/PostingMessage'
import Skeleton from '../../skeleton/Skeleton'
import InfiniteScroll from 'react-infinite-scroll-component'
import RepetionRemover from '../../smallComponents/RepetionRemover'
import { AuthContext } from '../../../context/AuthContext'
import { SettingsContext } from '../../../context/SettingsContext/SettingsContext'
import FeedSkeleton from '../../skeleton/SkeletonComponents/FeedSkeleton'
import i18next from 'i18next'

const styles = () => ({
  profileBottomWrapper: {
    display: 'flex',
    alignContent: 'center',
    margin: '0 10px',
  },
  profileFeed: {
    flex: 8,
  },
  '@media (max-width: 600px)': {
    profileBottomWrapper: {
      flexDirection: 'column-reverse',
      margin: 0,
    },
    profileFeed: {
      marginTop: 5,
    },
  },
})

const ProfileBottom = ({ classes, profileProps }) => {
  const [isLoading, setIsLoading] = useState(true)
  const { user } = useContext(AuthContext)
  const { settings } = useContext(SettingsContext)
  const [posts, setPosts] = useState([])
  const [currentResponse, setCurrentResponse] = useState(true)
  const [postsUsers, setPostsUsers] = useState([])
  const [sharedPosts, setSharedPosts] = useState([])
  const [sharedPostsUsers, setSharedPostsUsers] = useState([])
  const { userId, socket } = profileProps
  const isDarkModeActive = settings?.Mode === 'Dark'
  const [page, setPage] = useState(1)
  const lang = i18next.language

  const fetchPosts = async () => {
    setIsLoading(true)
    try {
      const res = await axios.get(
        `/posts/profile/${userId}/?page=${page}&limit=15`
      )
      setCurrentResponse(Boolean(res.data.posts.length))
      setPosts((ps) => [...ps, ...res.data.posts])
      setPostsUsers((us) => [...us, ...res.data.postsUsers])
      setSharedPosts((sps) => [...sps, ...res.data.sharedPosts])
      setSharedPostsUsers((sus) => [...sus, ...res.data.sharedPostsUsers])
    } catch (err) {}
    setIsLoading(false)
  }
  useEffect(() => {
    if (userId) fetchPosts()
  }, [userId, page])

  const isUsedIn = ''
  const [isPublishClicked, setIsPublishClicked] = useState(false)

  return (
    <article className={classes.profileBottomWrapper}>
      <section
        className={classes.profileFeed}
        style={{ marginTop: userId !== user?._id && '-10px' }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <HomeInteractionsBar
            setIsPublishClicked={setIsPublishClicked}
            setPosts={setPosts}
            setPostsUsers={setPostsUsers}
            isDarkModeActive={isDarkModeActive}
            profileMode
            {...profileProps}
          />
        </div>
        <PostingMessage
          isPublishClicked={isPublishClicked}
          setIsPublishClicked={setIsPublishClicked}
          isDarkModeActive={isDarkModeActive}
        />
        <InfiniteScroll
          dataLength={posts?.length}
          next={() => setPage((prev) => prev + 1)}
          hasMore={currentResponse}
          pullDownToRefresh
          refreshFunction={() => setPage(1)}
          pullDownToRefreshThreshold={10}
          scrollThreshold={'200px'}
          endMessage={<FeedFooter />}
          loader={
            <div style={{ overflow: 'hidden' }}>
              <FeedSkeleton />
            </div>
          }
        >
          {isLoading && page === 1 ? (
            <Skeleton type={'feed'} />
          ) : (
            RepetionRemover(posts)?.map((post, index) => (
              <HomeFeedPostModel
                isDarkModeActive={isDarkModeActive}
                key={post?._id + index}
                post={post}
                isUsedIn={isUsedIn}
                posts={posts}
                socket={socket}
                setPosts={setPosts}
                postsUsers={postsUsers}
                sharedPosts={sharedPosts}
                sharedPostsUsers={sharedPostsUsers}
                hideAddUserIcon={true}
              />
            ))
          )}
        </InfiniteScroll>
      </section>
    </article>
  )
}

const WrappedProfileBottom = withStyles(styles)(ProfileBottom)

export default WrappedProfileBottom
