import React from 'react'
import NavigatorChartUnit from './ProjectsChartsNavigatorComps/NavigatorChartUnit'
import ProjectsChartsNavigatorMob from './ProjectsChartsNavigatorComps/ProjectsChartsNavigatorMob'
import ProjectsChartsNavigatorPC from './ProjectsChartsNavigatorComps/ProjectsChartsNavigatorPC'
import Media from 'react-media'
import CustomEmpty from '../../../smallComponents/CustomEmpty'

const ProjectsChartsNavigator = (props) => {
  const { unitsArray } = props
  const units = unitsArray?.map((unit, index) => (
    <NavigatorChartUnit key={index} unit={unit} {...props} />
  ))
  const mediaComponents = [
    <ProjectsChartsNavigatorPC {...props} units={units} />,
    <ProjectsChartsNavigatorMob {...props} units={units} />,
  ]
  return units?.length > 0 ? (
    <Media query='(max-width: 600px)'>
      {(matches) => {
        return matches ? mediaComponents[1] : mediaComponents[0]
      }}
    </Media>
  ) : (
    <CustomEmpty
      emptyMssg='Create Your own projects to see their progress charts here'
      imageKey='chart'
    />
  )
}

export default ProjectsChartsNavigator
