import React from 'react'
import DefaultViewModel from '../../../../../projectProfile/ProjectProfileComponents/DefaultViewModel'

const IdeasInquiriesDefaultView = ({ DCGProps }) => {
  const { modelCardsContentArray } = DCGProps
  const summary = ''
  return (
    <DefaultViewModel
      PureIcon={modelCardsContentArray[2].pureIcon}
      title={modelCardsContentArray[2].name}
      summary={summary}
    />
  )
}

export default IdeasInquiriesDefaultView
