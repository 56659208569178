import { useContext, useEffect, useState } from 'react'
import FetchGeneralNotifications from './FetchNotificationsComps/FetchGeneralNotifications'
import FetchContributorsNotifications from './FetchNotificationsComps/FetchContributorsNotifications'
import FetchContractsNotifications from './FetchNotificationsComps/FetchContractsNotifications'
import WindowSize from '../../components/smallComponents/WindowSize'
import { AuthContext } from '../../context/AuthContext'
import { FeedContext } from '../../context/FeedContext/FeedContext'
import NotificationSoundAlert from '../../components/smallComponents/NotificationSoundAlert'
import GeneralNotifListItem from '../../components/notificationsSystem/NotificationsComponents/GeneralNotificationsComponents/GeneralNotifListItem'
import ContractNotifListItem from '../../components/notificationsSystem/NotificationsComponents/ContractsNotificationsComponents/ContractsNotifsDialogLeftComponents/ContractNotifListItem'
import ContribNotifListItem from '../../components/notificationsSystem/NotificationsComponents/ContributorsNotificationsComponents/ContributorsNotifsDialogLeftComponents/ContribNotifListItem'
import axios from 'axios'
import DarkModeColorCoder from '../../components/smallComponents/DarkModeColorCoder'
import i18next from 'i18next'

const FetchNotifications = ({ toast, isDarkModeActive }) => {
  const [arrivalNotification, setArrivalNotification] = useState(null)
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { user } = useContext(AuthContext)
  const { post } = useContext(FeedContext)
  const [isLoading, setIsLoading] = useState(true)
  const windowSize = WindowSize()
  const cat = arrivalNotification?.category

  const pageNumber = () => {
    return windowSize > 600 ? 4 : 15
  }
  const genNotifProps = FetchGeneralNotifications({
    arrivalNotification,
    user,
    post,
    setIsLoading,
    pageNumber,
  })
  const contribNotifProps = FetchContributorsNotifications({
    arrivalNotification,
    user,
    post,
    setIsLoading,
    pageNumber,
  })
  const contractNotifProps = FetchContractsNotifications({
    arrivalNotification,
    user,
    post,
    setIsLoading,
    pageNumber,
  })
  const [sender, setSender] = useState()
  useEffect(() => {
    const fetchPaired = async () => {
      try {
        const res = await axios.get(
          `/users/oneUser/${arrivalNotification?.senderId}`
        )
        setSender(res.data.user)
      } catch (error) {
        console.log(error)
      }
    }
    if (arrivalNotification?.senderId) fetchPaired()
  }, [arrivalNotification?.senderId])
  const notifProps = {
    isDarkModeActive,
    sender: {
      ...sender,
      name: sender?.firstName + ' ' + sender?.lastName,
      id: sender?._id,
      image: sender?.profilePicture,
    },
  }
  useEffect(() => {
    const notify = () =>
      toast(
        cat === 'general' ? (
          <GeneralNotifListItem
            props={notifProps}
            element={arrivalNotification}
            toastDisabledFun
          />
        ) : cat === 'contributors' ? (
          <ContribNotifListItem
            props={notifProps}
            element={arrivalNotification}
            toastDisabledFun
          />
        ) : (
          <ContractNotifListItem
            props={notifProps}
            element={arrivalNotification}
            toastDisabledFun
          />
        ),
        {
          position: windowSize > 600 ? 'bottom-right' : 'top-center',
          autoClose: '5000',
          hideProgressBar: true,
          newestOnTop: true,
          closeOnClick: true,
          draggable: true,
          rtl,
          style: {
            borderRadius: 15,
            margin: 10,
            background: isDarkModeActive
              ? DarkModeColorCoder('Grey_light')
              : null,
          },
          theme: isDarkModeActive ? 'dark' : 'light',
        }
      )
    if (
      arrivalNotification &&
      sender?._id === arrivalNotification?.senderId &&
      user?._id === arrivalNotification?.userId
    ) {
      notify()
      NotificationSoundAlert()
    }
  }, [
    arrivalNotification,
    sender?._id,
    sender?._id === arrivalNotification?.senderId,
    user?._id === arrivalNotification?.userId,
  ])
  return {
    ...genNotifProps,
    ...contribNotifProps,
    ...contractNotifProps,
    isLoading,
    arrivalNotification,
    setArrivalNotification,
  }
}

export default FetchNotifications
