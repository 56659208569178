import React, { useContext, useEffect, useState } from 'react'
import NewInquiryForm from './MyInquiriesComponents/NewInquiryForm'
import InquiryProfile from './MyInquiriesComponents/InquiryProfile'
import GeneralPortalSystem from '../../../../../generalPortalSystem/GeneralPortalSystem'
import { AuthContext } from '../../../../../../context/AuthContext'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

const MyInquiries = (props) => {
  const { user } = useContext(AuthContext)
  const [sharedPostConfigs, setSharedPostConfigs] = useState(null)
  const [contributedInquiries, setContributedInquiries] = useState([])
  const elementsImages = contributedInquiries?.map((inquiry) => ({
    id: inquiry?.id,
    image: inquiry?.images[0],
  }))

  useEffect(() => {
    const addNewInquiryPost = async () => {
      try {
        await axios.patch(
          `/inquiries/inquiryPosts/addSharedPost/${sharedPostConfigs?.element?._id}`,
          { postId: sharedPostConfigs?.postId }
        )
      } catch (error) {
        console.log(error)
      }
    }
    if (sharedPostConfigs?.element?._id && sharedPostConfigs?.postId)
      addNewInquiryPost()
  }, [sharedPostConfigs?.element?._id, sharedPostConfigs?.postId])

  useEffect(() => {
    const fetchContributedInquiries = async () => {
      try {
        const res = await axios.get(`/inquiries/pairedInquiries/${user?._id}`)
        setContributedInquiries(res.data.inquiries)
      } catch (error) {
        console.log(error)
      }
    }
    if (user?._id) fetchContributedInquiries()
  }, [user?._id])

  const { t } = useTranslation()
  const lang = i18next.language
  const propsII = {
    ...props,
    elementProfileProps: { hideOwner: true },
    noFSPanelPropsFirstView: {},
    cardsHeaderText: t('My_Inquiries'),
    placeholderValue: t('Inquiry'),
    NewElementForm: NewInquiryForm,
    ElementProfile: InquiryProfile,
    navigatorsObject: (inqs) => {
      return {
        headerText: lang === 'ar' ? 'متطلبات متشاركة' : 'Contributed Inquiries',
        unitsArray: contributedInquiries,
        elementsImages,
      }
    },
    dialogTitle: lang === 'ar' ? 'متطلب جديد' : `New Inquiry`,
    cardMarkProp: 'inquiryStatus',
    noFSPanelPropsSecondView: {
      showDeleteIcon: true,
      showPDFDownloader: false,
      deleteTippy: lang === 'ar' ? 'أمسح هذا المتطلب' : 'Delete this Inquiry',
      confirmmationTitles: [
        { title: `${t('Inquiry')} ${t('has_been_deleted')}` },
      ],
    },
    setSharedPostConfigs,
    deletePath: 'inquiries',
    cardsPropName: 'inquiries',
    getPath: `inquiries/userInquiries/${user._id}`,
    sect_sub_ind: 'inquiry',
    initialContent:
      lang === 'ar'
        ? 'فى قسم "متطلباتى" يمكنك التحكم فى جميع مواردك. كما يمكنك الاضافة و التحديث أو المسح بالإضافة للتعامل مع طلبات المشاركة'
        : "In 'My Inquiries' section, you can manage any kind of inquiries or help you may want others to help or contribute in resolving, it resembles a task management tool that enables you to interacts with orther users on Hutupia",
  }
  return <GeneralPortalSystem {...propsII} />
}

export default MyInquiries
