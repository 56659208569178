import React from 'react'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  contentContainer: {
    marginTop: 70,
    width: '100%',
    height: '100%',
  },
  stepperContainer: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
    position: 'sticky',
    top: 5,
    height: 'inherit',
    flexDirection: 'column',
    padding: 5,
    alignItems: 'center',
    borderRadius: '25px !important',
    margin: ' auto 10px 10px auto',
  },
  levelContentCard: {
    width: 'auto',
    padding: 10,
    height: 10,
    margin: '0px 0px 4px 0px',
    borderRadius: '10px !important',
    display: 'flex',
    flexDirection: 'column',
  },

  button: {
    backgroundColor: 'rgb(210, 221, 240) !important',
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    color: 'white !important',
    fontWeight: '550 !important',
    margin: '5px !important',
    display: 'flex !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },

  phase: {
    fontSize: 13,
    fontWeight: 500,
  },
  stepper: {
    '& .MuiStepIcon-active': { color: 'red important' },
    '& .MuiStepIcon-completed': { color: 'green !important' },
  },
  contentWrapper: {
    height: 280,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    height: '100%',
  },
  buttonWrapper: {
    display: 'flex',
    width: '94.5%',
    overflowX: 'none',
    position: 'absolute',
    bottom: 30,
  },
})

const StepperContent = ({
  classes,
  activeLevel,
  finalView,
  setpperContent,
}) => {
  return (
    <article className={classes.contentContainer}>
      {activeLevel === setpperContent.length ? (
        <>
          <span className={classes.contentWrapper}>{finalView}</span>
        </>
      ) : (
        <>
          <div className={classes.contentWrapper}>
            {setpperContent[activeLevel].stepContent}
          </div>
        </>
      )}
    </article>
  )
}

const WrappedStepperContent = withStyles(styles)(StepperContent)

export default WrappedStepperContent
