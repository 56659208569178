import i18next from 'i18next'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MContributorsNotificationsList from '../MLeftbarComponents/ChatNotificationsIconsBarComponents/MContributorsNotificationsList'
import MGeneralNotificationsList from '../MLeftbarComponents/ChatNotificationsIconsBarComponents/MGeneralNotificationsList'
import MDealsNotificationsList from '../MLeftbarComponents/ChatNotificationsIconsBarComponents/MDealsNotificationsList'
import { AuthContext } from '../../../context/AuthContext'
import axios from 'axios'

const MCollectiveNotifications = (props) => {
  const [notifCompIndex, setNotifCompIndex] = useState(0)
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { user } = useContext(AuthContext)
  const { t } = useTranslation()
  const {
    clickedIconId,
    contributrosNotNumb,
    contractsNotNumb,
    generalNotNumb,
    setContributrosNotNumb,
    setContractsNotNumb,
    setGeneralNotNumb,
    pageProps,
  } = props

  const { isDarkModeActive } = pageProps

  const notifsComps = [
    {
      title: rtl ? 'المشاركين' : 'Contributors',
      content: <MContributorsNotificationsList {...props} />,
      notiNumb: contributrosNotNumb > 99 ? '99+' : contributrosNotNumb || 0,
      fun: async () => {
        setContributrosNotNumb(Number(clickedIconId) === 1 && 0)
        try {
          await axios.patch(
            `/notifications/userContribNotifications/${user._id}`,
            { shown: true }
          )
        } catch (error) {
          console.log(error)
        }
      },
    },
    {
      title: t('Notifications'),
      content: <MGeneralNotificationsList {...props} />,
      notiNumb: generalNotNumb > 99 ? '99+' : generalNotNumb || 0,
      fun: async () => {
        setGeneralNotNumb(Number(clickedIconId) === 3 && 0)
        try {
          await axios.patch(
            `/notifications/userGeneralNotifications/${user._id}`,
            { shown: true }
          )
        } catch (error) {
          console.log(error)
        }
      },
    },
    {
      title: rtl ? 'الإتفاقات' : 'Deals',
      notiNumb: contractsNotNumb > 99 ? '99+' : contractsNotNumb || 0,
      content: <MDealsNotificationsList {...props} />,
      fun: async () => {
        setContractsNotNumb(Number(clickedIconId) === 2 && 0)
        try {
          await axios.patch(
            `/notifications/userDealsNotifications/${user._id}`,
            { shown: true }
          )
        } catch (error) {
          console.log(error)
        }
      },
    },
  ]

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: !isDarkModeActive && '#f5f5f5',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    width: '80vw',
    maxWidth: '1200px',
    margin: '20px auto',
  }

  const tabStyle = {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    marginBottom: '20px',
  }

  const tabItemStyle = (isActive) => ({
    flex: '1',
    textAlign: 'center',
    padding: '10px 0',
    cursor: 'pointer',
    borderBottom: isActive ? '3px solid #007bff' : '3px solid transparent',
    color: isActive ? '#007bff' : isDarkModeActive ? 'white' : '#333',
    fontWeight: isActive ? 'bold' : 'normal',
    transition: 'all 0.3s ease',
  })

  const contentStyle = {
    width: '100%',
  }

  return (
    <div style={containerStyle}>
      <section style={tabStyle}>
        {notifsComps
          .filter((c) => c.title)
          .map((c, i) => (
            <div
              key={i}
              onClick={() => setNotifCompIndex(i)}
              style={tabItemStyle(i === notifCompIndex)}
            >
              {c.title} ({c.notiNumb})
            </div>
          ))}
      </section>
      <div style={contentStyle}>{notifsComps[notifCompIndex].content}</div>
    </div>
  )
}

export default MCollectiveNotifications
