import React, { useContext } from 'react'
import withStyles from '@mui/styles/withStyles'
import { GiTwoCoins } from 'react-icons/gi'
import UserProfileAddContributor from '../../../../profilesMicroComponents/UserProfileAddContributor'
import UserProfileChatIcon from '../../../../profilesMicroComponents/UserProfileChatIcon'
import UserProfileFollowUnfollow from '../../../../profilesMicroComponents/UserProfileFollowUnfollow'
import { AuthContext } from '../../../../../context/AuthContext'

const styles = () => ({
  followChatOptionsIcons: {
    justifyContent: 'space-between',
    margin: 5,
    width: '80%',
  },
  followChatOptionsIconsItem: {
    fontSize: 21,
    padding: 5,
    cursor: 'pointer',
  },
})

const ProfileCommunicationBar = ({ classes, profileProps }) => {
  const { userId } = profileProps
  const { user } = useContext(AuthContext)
  const hide = user?._id === userId
  return (
    <section
      className={classes.followChatOptionsIcons}
      style={{ display: hide ? 'none' : 'flex' }}
    >
      <UserProfileFollowUnfollow {...profileProps} />
      <UserProfileChatIcon {...profileProps} chattedUserId={userId} />
      <UserProfileAddContributor {...profileProps} />
    </section>
  )
}

const WrappedProfileCommunicationBar = withStyles(styles)(
  ProfileCommunicationBar
)
export default WrappedProfileCommunicationBar
