import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../../../../../../context/AuthContext'
import ContractsPortal from '../../../../../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectDepartmentsComponents/DeparmentModels/ContractsPortal'

const MyContracts = (props) => {
  const { searchSystemProps } = props
  const { user } = useContext(AuthContext)
  const [contracts, setContracts] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [currentResponse, setCurrentResponse] = useState()
  const [lastPage, setLastPage] = useState(0)
  const { searchQuery } = searchSystemProps

  useEffect(() => {
    const fetchUserContracts = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(
          searchQuery
            ? `/contracts/userContracts/${user?._id}/?searchQuery=${searchQuery}`
            : `/contracts/userContracts/${user?._id}/?page=${page}&limit=4`
        )
        setLastPage(page)
        setCurrentResponse(
          searchQuery ? false : Boolean(res.data.contracts.length)
        )
        setContracts(
          searchQuery
            ? res.data.contracts
            : [...contracts, ...res.data.contracts]
        )
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (lastPage !== page || searchQuery) fetchUserContracts()
  }, [user?._id, page, lastPage, searchQuery])
  const { t } = useTranslation()
  const modContracts = contracts.map((contract) => ({
    ...contract,
    id: contract._id,
    title: contract.Title,
  }))
  return (
    <ContractsPortal
      {...props}
      contracts={modContracts}
      placeholderValue={t('My_Contracts')}
      currentResponse={currentResponse}
      page={page}
      setPage={setPage}
      isLoading={isLoading}
    />
  )
}

export default MyContracts
