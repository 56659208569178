import React, { useContext, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { AuthContext } from '../../../../../../context/AuthContext'
import CustomCircularProgress from '../../../../../smallComponents/CustomCircularProgress'
import LoadMoreButton from '../../../../../smallComponents/LoadMoreButton'
import MNewChat from './MChatSystemComponents/MChatBodyComponents/MNewChat'
import MMessagesFlow from './MChatSystemComponents/MChatBodyComponents/MMessagesFlow'

const MChatBody = (props) => {
  const { chat, pageProps, setOnlineUsers } = props
  const { socket, arrivalMessage } = pageProps
  const [chatMessages, setChatMessages] = useState([])
  const [page, setPage] = useState(1)
  const [isLoading, setIsLoading] = useState(true)
  const [currentResponse, setCurrentResponse] = useState(true)
  const [disabledInput, setDisabledInput] = useState()
  const { user } = useContext(AuthContext)
  useEffect(() => {
    if (arrivalMessage && chat?.members.includes(arrivalMessage?.senderId))
      setChatMessages([...chatMessages, arrivalMessage])
  }, [arrivalMessage, chat?.members, arrivalMessage?.senderId])

  useEffect(() => {
    socket.current.emit('addUser', user._id)
    socket.current.on('getUsers', (users) => {
      setOnlineUsers(
        user.followings.filter((f) => users.some((u) => u.userId === f))
      )
    })
  }, [user])
  useEffect(() => {
    const fetchChatMessages = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(
          `/messages/conversationMessages/${chat?._id}/?page=${page}&limit=15&userId=${user?._id}`
        )
        setCurrentResponse(Boolean(res.data.messages.length))
        setChatMessages([...chatMessages, ...res.data.messages])
        setDisabledInput(res.data.disabledInput)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (chat?._id) fetchChatMessages()
  }, [chat?._id, page])

  const mssgScroll = useRef()
  useEffect(() => {
    mssgScroll?.current?.scrollIntoView({ behavior: 'smooth' })
  }, [chatMessages])

  const propsII = {
    ...props,
    chatMessages,
    setChatMessages,
    isLoading,
    page,
    setPage,
    mssgScroll,
    disabledInput,
  }

  return (
    <>
      <section
        style={{
          paddingBottom: 60,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        {currentResponse &&
          (isLoading && page > 1 ? (
            <CustomCircularProgress />
          ) : (
            !isLoading && <LoadMoreButton setPage={setPage} />
          ))}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContnet: 'space-around',
          }}
        >
          <MMessagesFlow {...propsII} />
        </div>
      </section>
      <MNewChat {...propsII} />
    </>
  )
}

export default MChatBody
