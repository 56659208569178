import React, { useContext, useState } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import SettingsFormModel from '../../settingsSystemModel/SettingsFormModel'
import ProfileGeneralSettingsConfigs from './ProfileSettingsConfigs/ProfileGeneralSettingsConfigs'
import withStyles from '@mui/styles/withStyles'
import ModifiedFormChecker from '../../formsSystem/ModifiedFormChecker'
import SaveUserGeneralChanges from './GeneralSettingsComps/SaveUserGeneralChanges'
import FormDataInitialValueExtractor from '../../formsSystem/FormDataInitialValueExtractor'

const styles = () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: 'rgba(217,224,252, 0.1) ',
    margin: 10,
    padding: 10,
    borderRadius: '10px !important',
  },
  '@media (max-width: 600px)': {
    wrapper: {
      width: 'auto',
      backgroundColor: 'rgba(217,224,252, 0.1) ',
      margin: '10px 0 0 0',
      padding: '5px 0 0 0',
      borderRadius: '10px !important',
    },
  },
})

const GeneralSettings = ({ DCGProps, classes, settings }) => {
  const { user } = useContext(AuthContext)
  const [formData, setFormData] = useState({})
  FormDataInitialValueExtractor(
    ProfileGeneralSettingsConfigs({ user }),
    setFormData
  )
return (
    <article className={classes.wrapper}>
      <SettingsFormModel
        SettingsConfigs={ProfileGeneralSettingsConfigs({ settings })}
        formData={formData}
        setFormData={setFormData}
        DCGProps={DCGProps}
      />
      <SaveUserGeneralChanges
        formData={formData}
        DCGProps={DCGProps}
      />
    </article>
  )
}

const WrappedGeneralSettings = withStyles(styles)(GeneralSettings)

export default WrappedGeneralSettings
