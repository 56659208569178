import React, { useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md'
import SideButton from '../../../../suggestionsSystem/SuggestionSystemComponents/SideButton'
import i18next from 'i18next'
import DarkModeColorCoder from '../../../../smallComponents/DarkModeColorCoder'

const styles = () => ({
  suggestCardWrapper: {
    borderRadius: '20px',
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    maxWidth: '100%',
    overflowX: 'hidden',
  },
})

const ProjectsChartsNavigatorPC = ({
  classes,
  isDarkModeActive,
  units,
  unitsArray,
}) => {
  const lang = i18next.language
  const rtl = lang === 'ar'

  const [unitId, setUnitId] = useState(0)

  return (
    <section
      className={classes.suggestCardWrapper}
      style={{
        color: isDarkModeActive ? 'white' : null,
        background: isDarkModeActive ? DarkModeColorCoder('Grey_light') : null,
      }}
    >
      <section>
        <SideButton
          shiftFun={() => setUnitId(unitId - 1 >= 0 ? unitId - 1 : unitId)}
          shiftIcon={
            rtl ? (
              <MdArrowForwardIos
                style={{
                  color: unitId === 0 ? 'rgb(210, 221, 240)' : 'white',
                }}
              />
            ) : (
              <MdArrowBackIosNew
                style={{
                  color: unitId === 0 ? 'rgb(210, 221, 240)' : 'white',
                }}
              />
            )
          }
          iconButtonEdge='start'
        />
      </section>
      {units[unitId]}
      <section>
        <SideButton
          shiftFun={() =>
            setUnitId(unitId + 1 < unitsArray.length ? unitId + 1 : unitId)
          }
          shiftIcon={
            rtl ? (
              <MdArrowBackIosNew
                style={{
                  color:
                    unitId + 1 === unitsArray.length
                      ? 'rgb(210, 221, 240)'
                      : 'white',
                }}
              />
            ) : (
              <MdArrowForwardIos
                style={{
                  color:
                    unitId + 1 === unitsArray.length
                      ? 'rgb(210, 221, 240)'
                      : 'white',
                }}
              />
            )
          }
          iconButtonEdge='end'
        />
      </section>
    </section>
  )
}

const WrappedProjectsChartsNavigatorPC = withStyles(styles)(
  ProjectsChartsNavigatorPC
)

export default WrappedProjectsChartsNavigatorPC
