import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { Card, Step, StepLabel, Stepper } from '@mui/material'
import { FcNext, FcPrevious } from 'react-icons/fc'
import i18next from 'i18next'

const styles = () => ({
  topWrapper: {
    display: 'flex',
    alignContent: 'center !important',
    position: 'absolute !important',
    top: '5 !important',
    flexDirection: 'column',
    padding: 5,
    alignItems: 'center !important',
    borderRadius: '25px !important',
    backgroundColor: 'rgba(255, 255, 255, 0.7) !important',
    backdropFilter: 'blur(2px)  !important',
    width: '80%',
    zIndex: 9,
  },
  phase: {
    fontSize: 13,
    fontWeight: 500,
  },
  levelContentCard: {
    fontWeight: 500,
    margin: 10,
  },
  stepper: {
    '& .MuiStepIcon-active': { color: 'red important' },
    '& .MuiStepIcon-completed': { color: 'green !important' },
  },
})

const StepperTop = ({ classes, activeLevel, setpperContent, handleNext }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <Card className={classes.topWrapper}>
      <Stepper
        activeStep={activeLevel}
        className={classes.stepper}
        connector={rtl ? <FcPrevious /> : <FcNext />}
      >
        {setpperContent.map((_, stepIndex) => {
          return (
            <Step key={stepIndex} onClick={handleNext}>
              <StepLabel>
                <span className={classes.phase}>{''}</span>
              </StepLabel>
            </Step>
          )
        })}
      </Stepper>
      <section className={classes.levelContentCard}>
        {activeLevel === setpperContent.length
          ? null
          : setpperContent[activeLevel].stepDesc}
      </section>
    </Card>
  )
}

const WrappedStepperTop = withStyles(styles)(StepperTop)

export default WrappedStepperTop
