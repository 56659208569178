import React, { useState } from 'react'
import MChatNotificationListModel from './MChatListModel'
import MChatDialog from './MChatComponents/MChatDialog'

const MChat = ({ isDarkModeActive, pageProps, closeChatsList }) => {
  const { setIsChatOpen } = pageProps
  const [isChatDialogOpen, setIsChatDialogOpen] = useState(false)
  const chatDialogOpener = () => {
    setIsChatDialogOpen(true)
    setIsChatOpen(true)
  }
  const chatDialogClose = () => {
    setIsChatDialogOpen(!isChatDialogOpen)
    setIsChatOpen(false)
    if (typeof closeChatsList === 'function') closeChatsList()
  }
  const [chosenChat, setChosenChat] = useState({})
  const { setChatPage, chatPage, chatCurrentResponse } = pageProps

  return (
    <>
      <MChatNotificationListModel
        chatDialogOpener={chatDialogOpener}
        isDarkModeActive={isDarkModeActive}
        setChosenChat={setChosenChat}
        pageProps={pageProps}
        {...pageProps}
        setPage={setChatPage}
        page={chatPage}
        currentResponse={chatCurrentResponse}
      />
      <MChatDialog
        isChatDialogOpen={isChatDialogOpen}
        chatDialogClose={chatDialogClose}
        chat={chosenChat}
        pageProps={pageProps}
        isDarkModeActive2={isDarkModeActive}
      />
    </>
  )
}

export default MChat
