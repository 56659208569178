import React, { useContext, useState } from 'react'
import { SettingsContext } from '../../../../../context/SettingsContext/SettingsContext'
import ModelDialogWrappingII from '../../../../dialogModelUnit/ModelDialogWrappingII'
import ContributorsNotifsDialogRight from '../../../../notificationsSystem/NotificationsComponents/ContributorsNotificationsComponents/ContributorsNotifsDialogRight'
import CustomCircularProgress from '../../../../smallComponents/CustomCircularProgress'

const MContribNotifDialog = ({
  isNotificationDialogOpen,
  notificationDialogClose,
  notification,
  pageProps,
  DialogCloser,
}) => {
  const { contribNotificationsSenders, contribNotificationsProjects } =
    pageProps

  const { settings } = useContext(SettingsContext)
  const [isDarkModeActive, setIsDarkModeActive] = useState(
    settings?.Mode === 'Dark'
  )
  const sender = contribNotificationsSenders?.find(
    (se) => se._id === notification?.accompaniedData?.senderId
  )
  const project = contribNotificationsProjects?.find(
    (pro) => pro._id === notification?.accompaniedData.projectId
  )

  return (
    <ModelDialogWrappingII
      isDarkModeActive={isDarkModeActive}
      setIsDarkModeActive={setIsDarkModeActive}
      dialogsArray={[
        {
          title: '',
          closeFun: () => notificationDialogClose(),
          open: isNotificationDialogOpen,
          content: sender ? (
            <ContributorsNotifsDialogRight
              notifProps={{
                isDarkModeActive,
                notification,
                sender,
                project,
                notificationCardDialogClose: notificationDialogClose,
                DialogCloser,
                ...pageProps,
              }}
            />
          ) : (
            <CustomCircularProgress />
          ),
        },
      ]}
      mdialogMargins={'auto -24px auto -24px'}
    />
  )
}

export default MContribNotifDialog
