import React, { useContext, useState } from 'react'
import FullFormChecker from '../../formsSystem/FullFormChecker'
import LastViewModel from './CommonComponents/LastViewModel'
import NameLogoObjectivesConfigs from './CommonComponents/NameLogoObjectivesComponents/NameLogoObjectivesConfigs'
import {
  NewProjectMembers,
  NameLogoObjectives,
} from './NewBusinessProjectComponents'
import { AuthContext } from '../../../context/AuthContext'
import CompanyLogo from './CommonComponents/NameLogoObjectivesComponents/CompanyLogo'
import FormDataInitialValueExtractor from '../../formsSystem/FormDataInitialValueExtractor'
import FormDataLangModulator from '../../formsSystem/FormDataLangModulator'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

const NewBusinessProject = (props) => {
  const { t } = useTranslation()
  const { dataHistory } = props
  const [newProjectData, setNewProjectData] = useState(
    dataHistory?.current?.newProjectData || {}
  )
  const newformData = FormDataLangModulator(newProjectData, t) //2
  FormDataInitialValueExtractor(NameLogoObjectivesConfigs(), setNewProjectData) //3
  const [projectContributors, setProjectContributors] = useState(
    dataHistory?.current?.projectContributors || []
  )
  const { user } = useContext(AuthContext)
  let members = []
  projectContributors?.map((m) => {
    let depMembers = m?.contributors
    let formated = depMembers?.map((c) => ({
      _id: c?._id,
      departmentId: m?.departmentId,
    }))
    if (formated?.length > 0) members.push(...formated)
  })
  const [image, setImage] = useState(dataHistory?.current?.image || null)
  const lang = i18next.language
  const rtl = lang === 'ar'
  const propsII = {
    ...props,
    setNewProjectData,
    newProjectData: newformData,
    image,
    setImage,
    setProjectContributors,
    projectContributors,
    members,
    dataHistory,
  }
  return user?.contributors?.length > 0
    ? [
        {
          stepContent: <NameLogoObjectives {...propsII} />,
          stepDesc: rtl
            ? 'معلومات أساسية عن مشروعك'
            : 'Basic Info about your project',
          stepCondition:
            newformData?.ProjectName &&
            newformData?.IndustryType &&
            newformData?.ProjectPhase,
        },
        {
          stepContent: <CompanyLogo {...propsII} />,
          stepDesc: rtl ? 'صورة صفحة المشروع' : 'Project Profile Image',
          stepCondition: true,
        },
        {
          stepContent: <NewProjectMembers {...propsII} />,
          stepDesc: rtl ? 'اختر أعضاء مشروعك' : 'Choose Your Project Members',
          stepCondition: true,
        },
        <LastViewModel {...propsII} />,
      ]
    : [
        {
          stepContent: <NameLogoObjectives {...propsII} />,
          stepDesc: rtl
            ? 'معلومات أساسية عن مشروعك'
            : 'Basic Info about your project',
          stepCondition:
            newformData?.ProjectName &&
            newformData?.IndustryType &&
            newformData?.ProjectPhase,
        },
        {
          stepContent: <CompanyLogo {...propsII} />,
          stepDesc: rtl ? 'صورة صفحة المشروع' : 'Project Profile Image',
          stepCondition: true,
        },
        <LastViewModel {...propsII} />,
      ]
}

export default NewBusinessProject
