import React from 'react'
import DefaultViewModel from '../../../../../projectProfile/ProjectProfileComponents/DefaultViewModel'

const ContributorsDefaultView = ({ DCGProps }) => {
  const { modelCardsContentArray } = DCGProps
  return (
    <DefaultViewModel
      PureIcon={modelCardsContentArray[0]?.pureIcon}
      title={modelCardsContentArray[0]?.name}
    />
  )
}

export default ContributorsDefaultView
