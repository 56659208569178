import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../../context/AuthContext'
import deleteFile from '../../../firebase/deleteFile'
import ButtonsSystem from '../../buttonsSystem/ButtonsSystem'
import CentralTitle from '../../smallComponents/CentralTitle'
import cookies from 'js-cookie'
import i18next from 'i18next'

const ProfileTermination = ({ DCGProps }) => {
  const { user, dispatch } = useContext(AuthContext)
  const [ready, setReady] = useState(false)
  const [urls, setUrls] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [counts, setCounts] = useState({})
  useEffect(() => {
    const fetchReady = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(`/urls/userCheck/deleteCheck/${user?._id}`)
        setReady(res.data.ready)
        if (res.data.ready) {
          const resUrls = await axios.get(`/urls/userUrls/${user?._id}`)
          setUrls(resUrls.data.urls)
        }
        const { ready, ...countsRes } = res.data
        setCounts(countsRes)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (user?._id) fetchReady()
  }, [user?._id])

  const lang = i18next.language
  const rtl = lang === 'ar'
  const history = useNavigate()
  const buttonsArray = [
    {
      title: isLoading
        ? rtl
          ? 'جارى تنفيذ بروتوكول المسح'
          : 'Running Deletion Protocol'
        : rtl
        ? 'حذف الحساب'
        : 'Delete My Profile',
      disabled: isLoading || !ready,
      funs: async () => {
        if (ready)
          try {
            setIsLoading(true)
            const res = await axios.delete(`/users/${user?._id}`, {
              headers: {},
              data: { userId: user?._id },
            })
            // spcial case to resolve the issue of lost images and need testing
            if (res.data.chatsUrls)
              Promise.all(
                res.data.chatsUrls?.map((image) => {
                  if (image) deleteFile(image)
                })
              )
            Promise.all(
              urls?.map((image) => {
                if (image) deleteFile(image)
              })
            )
            setIsLoading(false)
            localStorage.removeItem('hutupia_settings')
            localStorage.removeItem('hutupia_user')
            cookies.remove('user', {
              domain: '.hutupia.com',
              expires: 7,
              secure: true,
            })
            dispatch({ type: 'LOGOUT' })
            history('/register')
          } catch (error) {}
      },
    },
  ]
  return (
    <article>
      <CentralTitle title={rtl ? 'حذف الحساب الشخصى' : 'Profile Termination'} />
      <section>
        <p>
          {rtl
            ? 'يمكنك حذف حسابك في هيوتيوبيا في أي وقت، ولكن يجب عليك إنهاء بعض الخطوات الهامة قبل الحذف لتأكيد عدم تأثير حذف حسابك على مجتمع هيوتيوبيا بشكل سلبي.'
            : 'You can delete your profile on Hutupia at any time, but you must complete some important steps before deleting it to ensure that deleting your profile does not negatively affect the Hutupia community.'}
        </p>
        <p>
          {rtl
            ? 'يرجى إكمال الخطوات التالية لحذف حسابك:'
            : 'Please complete the following steps to delete your profile:'}
        </p>
        <ul>
          <li>
            {rtl
              ? 'إنهاء جميع المشاريع الخاصة بك والتي تتوافق مع جميع الشروط المطلوبة'
              : 'Terminate all your projects that comply with all the required conditions'}
          </li>
          <li>
            {rtl
              ? 'إنهاء جميع العقود والاشتراكات المتعلقة بمستخدمين آخرين في هيوتيوبيا'
              : 'Terminate all contracts and subscriptions associated with other users on Hutupia'}
          </li>
          <li>
            {rtl
              ? 'حل جميع المشاكل المتعلقة بك مع مستخدمين آخرين'
              : 'Resolve all your issues with other users'}
          </li>
        </ul>
        <p>
          {rtl
            ? `لا يمكنك الحذف حتى ${
                counts?.issues !== 0 ? `تنهي ${counts?.issues} مشكلة، ` : ''
              }${
                counts?.contracts !== 0 ? `تنهي ${counts?.contracts} عقد، ` : ''
              }${
                counts?.channels !== 0
                  ? `تخرج من ${counts?.channels} قناة شراكة، `
                  : ''
              }${
                counts?.createdProjects !== 0
                  ? `تحذف ${counts?.createdProjects} مشروعًا تم إنشاؤه، `
                  : ''
              }${
                counts?.contractedProjects !== 0
                  ? `تنهي ${counts?.contractedProjects} مشروعًا تم التعاقد معها `
                  : ''
              }لإنهاء عملية الحذف`
            : `You cannot delete your profile until ${
                counts?.issues !== 0
                  ? `you resolve ${counts?.issues} issues, `
                  : ''
              }${
                counts?.contracts !== 0
                  ? `you terminate ${counts?.contracts} contracts, `
                  : ''
              }${
                counts?.channels !== 0
                  ? `you get out of ${counts?.channels} partnership channels, `
                  : ''
              }${
                counts?.createdProjects !== 0
                  ? `you delete ${counts?.createdProjects} owned projects, `
                  : ''
              }${
                counts?.contractedProjects !== 0
                  ? `you end your contracting with ${counts?.contractedProjects} contracted projects, `
                  : ''
              }to complete the deletion process.`}
        </p>
      </section>
      <ButtonsSystem buttonsArray={buttonsArray} {...DCGProps} />
    </article>
  )
}

export default ProfileTermination
