const GPSearchSystemProps = (props) => {
  const { searchSystemProps, placeholderValue, SearchIdtentifiers } = props
  return {
    ...searchSystemProps,
    placeholderValue,
    FirstViewSearch: true,
    firstLetterSearch: true,
    firstViewSearchIdtentifiers: SearchIdtentifiers || ['Title'],
    disableSearch: true,
  }
}

export default GPSearchSystemProps
