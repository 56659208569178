import React from 'react'
import { ListItem } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import SwipeableMenuTopComp from './SwipeableMenuTopComp'
import i18next from 'i18next'

const styles = () => ({
  DialogModelUnitMenu: {
    display: 'flex',
    flex: '1.5',
    flexDirection: 'column',
    padding: '5px',
  },

  ClickedDialogModelUnitMenuItem: {
    padding: '0 10px 0 10px',
    fontSize: '20px',
    background:
      'linear-gradient(75deg, rgb(94, 94, 173) 15%, rgb(105, 113, 122) 100%) !important',
    color: 'white !important',
    borderRadius: 20,
  },

  list: {
    height: '100%',
  },
  listItem: {
    borderRadius: '20px !important',
    margin: '5px auto 5px auto !important',
    display: 'flex',
    alignContent: 'center !important',
    alignItems: 'center !important',
  },
  iconWrapper: {
    display: 'flex',
    alignContent: 'center  ',
    alignItems: 'center  ',
    justifyContent: 'center',
  },
  titleWrapper: {
    display: 'flex',
    alignContent: 'center  ',
    alignItems: 'center  ',
    justifyContent: 'center',
  },
  '@media (max-width: 600px)': {
    ClickedDialogModelUnitMenuItem: {
      padding: 0,
    },
  },
})

const DialogModelMobVersion = ({
  classes,
  DialogModelUnitMenuItemId,
  dialogModelUnitContentDirector,
  DMUSystemProps,
}) => {
  const {
    DialogModelUnitMenu,
    isSwipeableOpen,
    setIsSwipeableOpen,
    swibableHeaderCardComponent,
    isDarkModeActive,
    hideDialogModelUnitMenu,
  } = DMUSystemProps
  const lang = i18next.language
  const rtl = lang === 'ar'
  if (!hideDialogModelUnitMenu)
    return (
      <SwipeableDrawer
        anchor={rtl ? 'right' : 'left'}
        open={isSwipeableOpen}
        onClose={() => setIsSwipeableOpen(false)}
        onOpen={() => setIsSwipeableOpen(true)}
        PaperProps={{
          style: {
            background: isDarkModeActive
              ? 'rgba(49, 48, 53, 0.6)'
              : 'rgba(255, 255, 255, 0.4)',
            color: isDarkModeActive ? 'white' : null,
            backdropFilter: 'blur(4px)',
          },
        }}
      >
        <>
          <SwipeableMenuTopComp
            swibableHeaderCardComponent={swibableHeaderCardComponent}
            isDarkModeActive={isDarkModeActive}
          />
          <div
            className={classes.DialogModelUnitMenu}
            style={{
              backgroundColor: isDarkModeActive
                ? 'rgba(255, 255, 255, 0.6) !important'
                : 'rgba(210, 221, 240, 0.6)',
              color: isDarkModeActive ? 'white' : 'inherit',
              backdropFilter: 'blur(4px)',
            }}
          >
            <span className={classes.list}>
              {DialogModelUnitMenu.map((item, itemId) => {
                return (
                  <div
                    className={
                      DialogModelUnitMenuItemId === itemId
                        ? classes.ClickedDialogModelUnitMenuItem
                        : classes.DialogModelUnitMenuItem
                    }
                    onClick={(event) => {
                      dialogModelUnitContentDirector(event)
                      setIsSwipeableOpen(false)
                    }}
                    id={itemId}
                    key={itemId}
                  >
                    <ListItem button className={classes.listItem}>
                      <span className={classes.iconWrapper}>
                        {typeof item === 'object' ? item.icon : null}
                      </span>
                      <span className={classes.titleWrapper}>
                        {typeof item === 'object' ? item.title : item}
                      </span>
                    </ListItem>
                  </div>
                )
              })}
            </span>
          </div>
        </>
      </SwipeableDrawer>
    )
}

const WrappedDialogModelMobVersion = withStyles(styles)(DialogModelMobVersion)

export default WrappedDialogModelMobVersion
