import RepetionRemover from '../../smallComponents/RepetionRemover'

const GPModCards = (props) => {
  const { cards, customCards, extraModProp } = props
  const preModCards = RepetionRemover(cards)
  return customCards
    ? customCards
    : preModCards.map((card) =>
        typeof extraModProp === 'function'
          ? {
              ...card,
              id: card._id,
              title: card.Title,
              ...extraModProp(card),
            }
          : {
              ...card,
              id: card._id,
              title: card.Title,
            }
      )
}

export default GPModCards
