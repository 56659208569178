import { Avatar } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import InteractionBarModel from '../../../feedsSystem/InteractionBarModel'
import withStyles from '@mui/styles/withStyles'
import { BsFilePost } from 'react-icons/bs'
import { MdCompost } from 'react-icons/md'
import { FaThinkPeaks } from 'react-icons/fa'
import { useContext } from 'react'
import { AuthContext } from '../../../../context/AuthContext'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

const styles = () => ({
  shareImage: {
    width: '40px !important',
    height: '40px !important',
    cursor: 'pointer',
  },
  link: {
    width: '40px !important',
    height: '40px !important',
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    width: '100%',
  },

  icon: {
    margin: 0,
    fontSize: 30,
  },
  '@media (max-width: 600px)': {
    shareImage: {
      width: '30px !important',
      height: '30px !important',
    },
    icon: {
      // margin: 0,
      fontSize: 25,
    },

    link: {
      width: '30px !important',
      height: '30px !important',
      margin: '10px !important',
    },
  },
})

const HomeInteractionsBarButtons = ({
  classes,
  shareDialogFormOpener,
  newProjectDialogOpener,
  GeneralDialogOpener,
  isDarkModeActive,
}) => {
  const { t } = useTranslation()
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { user } = useContext(AuthContext)
  const buttonArray = [
    {
      title: t('Post'),
      icon: <BsFilePost className={classes.icon} />,
      openFun: () => shareDialogFormOpener(),
      displayCondition: true,
    },
    {
      title: t('Project'),
      icon: <MdCompost className={classes.icon} />,
      openFun: () => newProjectDialogOpener(),
      displayCondition: true,
    },
    {
      title: t('Idea'),
      icon: <FaThinkPeaks className={classes.icon} />,
      openFun: () => GeneralDialogOpener(),
      displayCondition: true,
    },
  ]
  return (
    <section style={{ display: 'flex', flexDirection: 'row' }}>
      <Link
        to={`/profile/${user?._id}`}
        className={classes.link}
        style={{
          margin: rtl ? '10px 10px 10px 1px' : '10px 1px 10px 10px',
        }}
      >
        <Avatar
          src={user?.profilePicture}
          alt='Profile'
          className={classes.shareImage}
        />
      </Link>
      <span
        className={classes.buttonsWrapper}
        style={{
          paddingRight: !rtl && 10,
          paddingLeft: rtl && 10,
        }}
      >
        {buttonArray.map((button, buttonIndex) => (
          <InteractionBarModel
            button={button}
            key={buttonIndex}
            isDarkModeActive={isDarkModeActive}
          />
        ))}
      </span>
    </section>
  )
}

const WrappedHomeInteractionsBarButtons = withStyles(styles)(
  HomeInteractionsBarButtons
)

export default WrappedHomeInteractionsBarButtons
