import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  footerWrapper: {
    fontSize: 9,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    margin: 0,
  },
  header: {
    color: '#6e8696',
  },
  '@media (max-width: 600px)': {
    footerWrapper: {},
    header: {
      color: 'white',
    },
  },
}))

const Footer = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <span className={classes.footerWrapper}>
      <h4 className={classes.header}>
        &copy; {t('Copyright')} {moment(Date.now()).format('YYYY')} &bull;{' '}
        {t('Hutupia')} &bull; {t('All_Rights_Reserved')}
      </h4>
    </span>
  )
}

export default Footer
