import React from 'react'
import MyInquiries from './IdeasInquiriesComponents/MyInquiries'
import MyIdeas from './IdeasInquiriesComponents/MyIdeas'
import IdeasInquiriesDefaultView from './IdeasInquiriesComponents/IdeasInquiriesDefaultView'
import MyGlobalInquries from './IdeasInquiriesComponents/MyGlobalInquries'

const UserIdeasInquiries = (props) => {

  return [
    <MyIdeas {...props} />,
    <MyInquiries {...props} />,
    <MyGlobalInquries {...props} />,
    <IdeasInquiriesDefaultView {...props} />,
  ]
}

export default UserIdeasInquiries
