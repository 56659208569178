import React, { useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import TextDirectionDetector from '../../../../../../../textProcessingEngine/TextEngineComponents/TextProcessorComponents/DetectionCoreComponents/TextDirectionDetector'
import MessageCore from '../../../../../../../chatSystem/ChatSystemComponents/PCChatSystemComponents/PCChatBodyComponents/MessageCore'
import moment from 'moment'

const styles = () => ({
  mssgWrapper: {
    backgroundColor: 'rgb(42, 42, 95)',
    color: 'white',
    display: 'inline-block',
    maxWidth: '70vw',
    padding: 12,
    borderRadius: 20,
    transition: '0.3s',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
  },
  mssgContainer: {
    width: '100%',
  },
  dateWrapper: {
    maxWidth: '70vw',
    fontSize: 11,
    fontWeight: 500,
    paddingLeft: 10,
    margin: 10,
    float: 'left',
  },
})

const MReplyMessage = ({
  classes,
  msg,
  isDarkModeActive,
  mssgScroll,
  socket,
}) => {
  const [showMssgDate, setShowMssgDate] = useState(false)

  return (
    <>
      <section
        style={{ width: '100vw' }}
        dir={TextDirectionDetector(msg.msgContent)}
        ref={mssgScroll}
      >
        <span
          className={classes.mssgWrapper}
          style={{
            filter: showMssgDate ? 'brightness(70%)' : 'brightness(100%)',
            margin: showMssgDate ? '10px 10px 0 10px' : '2px 10px 2px 2px',
          }}
          onClick={() => setShowMssgDate(!showMssgDate)}
        >
          <MessageCore
            msg={msg}
            isDarkModeActive={isDarkModeActive}
            socket={socket}
          />
        </span>
      </section>
      <section style={{ width: '100vw' }}>
        <span
          className={classes.dateWrapper}
          style={{
            display: showMssgDate ? 'inline-block' : 'none',
            color: isDarkModeActive ? 'white' : null,
          }}
        >
          {moment(msg.createdAt).calendar()}
        </span>
      </section>
    </>
  )
}

const WrappedMReplyMessage = withStyles(styles)(MReplyMessage)

export default WrappedMReplyMessage
