import React from 'react'
import withStyles from '@mui/styles/withStyles'
import FormsSystem from '../formsSystem/FormsSystem'

const styles = () => ({
  wrapper: {
    backgroundColor: 'rgba(217,224,252, 0.1) ',
    margin: 10,
    padding: 10,
    borderRadius: '10px !important',
  },
})

const SettingsFormModel = ({
  SettingsConfigs,
  DCGProps,
  formData,
  setFormData,
  classes,
}) => {
  return (
    <article className={classes.wrapper}>
      <FormsSystem
        formComponentsArray={SettingsConfigs}
        formComponentsValues={formData}
        setFormComponentsValues={setFormData}
        {...DCGProps}
      />
    </article>
  )
}

const WrappedSettingsFormModel = withStyles(styles)(SettingsFormModel)

export default WrappedSettingsFormModel
