import React from 'react'
import { MdMenu } from 'react-icons/md'
import { RiNotification2Fill, RiNotification2Line } from 'react-icons/ri'
import withStyles from '@mui/styles/withStyles'
import { Avatar, ListItem } from '@mui/material'
import { AiFillHome, AiOutlineHome } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'

const styles = () => ({
  topRow: {
    display: 'flex',
    flexDirection: 'row',
    color: 'white',
    justifyContent: 'space-around',
    width: '100%',
  },
  topIcon: {
    margin: '0px 10px 0px 10px',
    fontSize: 30,
  },
  iconWrapper: {
    cursor: 'pointer',
  },
  iconBadge: {
    minWidth: 15,
    height: 15,
    backgroundColor: 'rgb(25, 4, 90)',
    borderRadius: '50%',
    color: 'white',
    position: 'absolute',
    top: 2,
    right: 6,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    fontSize: 11,
  },
})

const MTopBarIcons = ({
  classes,
  setMviewCompIndex,
  contributrosNotNumb,
  contractsNotNumb,
  generalNotNumb,
  user,
  mviewCompIndex,
  isDarkModeActive,
}) => {
  const allNotifs = contributrosNotNumb + contractsNotNumb + generalNotNumb
  const navigate = useNavigate()
  const iconColor = isDarkModeActive ? 'white' : 'rgb(0, 119, 181)'

  const notificationsArray = [
    {
      icon:
        mviewCompIndex === 0 ? (
          <AiFillHome style={{ fontSize: '30' }} color={iconColor} />
        ) : (
          <AiOutlineHome style={{ fontSize: '30' }} color={iconColor} />
        ),
      notiNumb: 0,
      fun: () => navigate('/home'),
    },
    {
      icon:
        mviewCompIndex === 1 ? (
          <RiNotification2Fill size={25} color={iconColor} />
        ) : (
          <RiNotification2Line size={25} color={iconColor} />
        ),
      notiNumb: allNotifs > 99 ? '99+' : allNotifs || 0,
      fun: '',
    },
    {
      // opens profile
      icon: (
        <Avatar
          style={{ width: 30, height: 30 }}
          color={iconColor}
          src={user?.profilePicture}
        />
      ),
      notiNumb: 0,
      //   this function will direct to profile
      fun: '',
    },
    {
      // opens menu
      icon: <MdMenu size={25} color={iconColor} />,
      notiNumb: 0,
      fun: '',
    },
  ]

  return (
    <section className={classes.topRow}>
      {notificationsArray.map((item, notiIndex) => (
        <span
          key={notiIndex}
          className={classes.iconWrapper}
          onClick={() => setMviewCompIndex(notiIndex)}
          style={{
            borderBottom:
              mviewCompIndex === notiIndex &&
              `3px solid ${isDarkModeActive ? 'white' : 'rgb(0, 119, 181)'}`,
            borderBottomLeftRadius: '-5px',
            borderBottomRightRadius: '-5px',
          }}
        >
          <div style={{ margin: 'auto 10px' }}>
            <ListItem button>
              {item.icon}
              <div
                className={classes.iconBadge}
                style={{ display: item.notiNumb === 0 ? 'none' : null }}
              >
                {item.notiNumb}
              </div>
            </ListItem>
          </div>
        </span>
      ))}
    </section>
  )
}

const WrappedMTopBarIcons = withStyles(styles)(MTopBarIcons)

export default WrappedMTopBarIcons
