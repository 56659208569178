import React, { useState, useEffect } from 'react'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  pathContainer: {
    width: '100%',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      height: 4,
      backgroundColor: 'transparent',
      cursor: 'pointer',
    },
    '&::-webkit-scrollbar-thumb': {
      height: 4,
      color: 'rgba(159, 177, 211, 0.212)',
    },
    '&::-webkit-scrollbar:hover': {
      backgroundColor: 'white',
      height: '3px',
    },
  },
  pathWrapper: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    flexFlow: 'row',
    overflowX: 'auto',
    position: 'relative',
    // paddingBottom: 4,
  },

  '@media (max-width: 600px)': {
    pathWrapper: {
      width: '200vw',
    },
  },
})

const PathContainer = ({ classes, pathSystem, path, path2, path3, path4 }) => {
  // algorithms to solve the problems of the folderpath scrollbar

  const [windowSize, setWindowSize] = useState(window.innerWidth)

  useEffect(() => {
    setWindowSize(window.innerWidth)
  }, [window.innerWidth])

  const paths = [path, path2, path3, path4]
  const activePathsNumber = paths.filter((path) => Boolean(path)).length + 1

  // the theory is simple calculus:
  // remember that this algorithms is valid only in case there is no extra icons further than the first path 'path'

  const oneLetterPxs = windowSize < 600 ? 7 : 8.602222
  const homeIconPxs = 15
  const nextIconPxs = 14
  const extraPathIcon = 25
  const basalValue = homeIconPxs + nextIconPxs * 2
  const totlaPxs = Number(windowSize)
  const vwOverWidthPercent = Number(
    ((basalValue +
      (typeof path?.title?.length === 'number'
        ? Number(path?.title.length) * oneLetterPxs + extraPathIcon
        : Number(path?.length) * oneLetterPxs) +
      Number(path2?.length) * oneLetterPxs +
      Number(path3?.length) * oneLetterPxs +
      Number(path4?.length) * oneLetterPxs +
      nextIconPxs * activePathsNumber) /
      totlaPxs) *
      100
  )

  return (
    <article className={classes.pathContainer}>
      <section
        className={classes.pathWrapper}
        style={{
          width: `${
            // the extra here is due to sub-dep problems
            activePathsNumber < 3 &&
            // this part is due to the extra, this extra is enlarging the effect of the scrollbar in project tools, so to limit its validity is a solution
            path.length < 20
              ? vwOverWidthPercent * 2
              : vwOverWidthPercent
          }vw`,
        }}
      >
        {pathSystem}
      </section>
    </article>
  )
}

const WrappedPathContainer = withStyles(styles)(PathContainer)

export default WrappedPathContainer
