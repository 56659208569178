import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@mui/material'
import { LinkOff } from '@mui/icons-material'
import i18next from 'i18next'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  text: {
    margin: theme.spacing(2),
    fontWeight: 500,
    fontSize: '1.5rem',
    fontFamily: 'Roboto, sans-serif',
    color: '#333',
  },
  icon: {
    margin: theme.spacing(2),
    color: '#f44336',
    fontSize: '5rem',
  },
}))

const BrokenLinkComponent = () => {
  const classes = useStyles()
  const lang = i18next.language
  const isRTL = lang === 'ar'
  return (
    <section className={classes.container}>
      <LinkOff className={classes.icon} />
      <Typography className={classes.text} dir={isRTL ? 'rtl' : 'ltr'}>
        {isRTL
          ? 'عذراً، لم يتم العثور على الصفحة التي تبحث عنها'
          : 'Sorry, the page you are looking for could not be found'}
      </Typography>
    </section>
  )
}

export default BrokenLinkComponent
