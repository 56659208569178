import React from 'react'
import { ListItem } from '@mui/material'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between !important',
    width: '100% !important',
    maxWidth: 400,
  },

  notificationUnitText2: {
    overflow: 'hidden',
    // maxWidth: '400px',
    width: 'auto',
    borderRadius: '10px !important',
  },
})

const GeneralNotifModel = ({
  classes,
  notification,
  clickEeventHandler,
  interiorCardComponent,
}) => {
  return (
    <ListItem
      className={classes.listItem}
      onClick={() => {
        if (
          // profile notifications should not open any post
          notification.associativity !== 'userProfile'
        )
          clickEeventHandler()
      }}
    >
      <section className={classes.notificationUnitText2}>
        {interiorCardComponent}
      </section>
    </ListItem>
  )
}

const WrappedGeneralNotifModel = withStyles(styles)(GeneralNotifModel)

export default WrappedGeneralNotifModel
