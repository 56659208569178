import { Popover } from '@mui/material'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  popover: {
    marginTop: '3rem',
    marginRight: '10rem',
    '& .MuiPaper-root': {
      borderRadius: '10px !important;',
    },
  },
}))

const PopoverModel = ({
  handlePopoverClose,
  anchorEl,
  PopoverComponent,
  anchorOriginVertical,
  anchorOriginHorizontal,
  transformOriginVertical,
  transformOriginHorizontal,
}) => {
  const open = Boolean(anchorEl)
  const classes = useStyles()
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      className={classes.popover}
      anchorOrigin={{
        vertical: anchorOriginVertical,
        horizontal: anchorOriginHorizontal,
      }}
      transformOrigin={{
        vertical: transformOriginVertical,
        horizontal: transformOriginHorizontal,
      }}
    >
      {PopoverComponent}
    </Popover>
  )
}

export default PopoverModel
