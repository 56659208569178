import axios from 'axios'
import React, { useContext, useState } from 'react'
import { AuthContext } from '../../../../../../../../../context/AuthContext'
import ButtonsSystem from '../../../../../../../../buttonsSystem/ButtonsSystem'
import FormsSystem from '../../../../../../../../formsSystem/FormsSystem'
import FullFormChecker from '../../../../../../../../formsSystem/FullFormChecker'
import TextEngineToolsFooter from '../../../../../../../../smallComponents/TextEngineToolsFooter'
import NewChannelFormConfigs from './NewChannelFormComps/NewChannelFormConfigs'
import { toast } from 'react-toastify'
import ToastifyGeneralError from '../../../../../../../../smallComponents/ToastifyGeneralError'
import i18next from 'i18next'

const NewChannelForm = ({ DCGProps, GeneralDialogClose, setCards }) => {
  const { user } = useContext(AuthContext)
  const { project, isDarkModeActive } = DCGProps
  const lang = i18next.language
  const rtl = lang === 'ar'
  const [formData, setFormData] = useState({
    Title: '',
    Description: '',
  })
  const [chosenHashTagElements, setChosenHashTagElements] = useState([])

  const newChannel = project
    ? {
        ...formData,
        projectsIds: project?._id,
      }
    : {
        ...formData,
        usersIds: user?._id,
      }

  const newContract = project
    ? {
        Title: rtl ? `عقد ${formData?.Title}` : `${formData?.Title} Contract`,
        projectsIds: project?._id,
      }
    : {
        usersIds: user?._id,
        Title: rtl ? `عقد ${formData?.Title}` : `${formData?.Title} Contract`,
      }
  const [isCreating, setIsCreating] = useState(false)
  const buttonsArray = [
    {
      title: rtl ? 'إلغاء' : 'Cancel',
      funs: () => GeneralDialogClose(),
    },
    {
      title: rtl
        ? isCreating
          ? 'جارى الإنشاء'
          : 'إنشاء'
        : isCreating
        ? 'Creating...'
        : 'Create',
      disabled:
        !FullFormChecker({
          FormConfig: NewChannelFormConfigs(),
          formData,
        }) || isCreating,
      funs: async () => {
        setIsCreating(true)
        try {
          const resContract = await axios.post(`/contracts`, newContract)
          if (resContract) {
            const res = await axios.post(`/channels`, {
              ...newChannel,
              contractId: resContract?.data?.contract?._id,
              chosenHashTagElements,
            })
            setCards((chs) => [res.data.channel, ...chs])
          }
        } catch (error) {
          ToastifyGeneralError(error)
        }
        setIsCreating(false)
        GeneralDialogClose()
        toast.success(
          rtl
            ? 'تم إنشاء قناة جديدة بنجاح'
            : 'A new channel has been created successfully'
        )
      },
    },
  ]
  return (
    <article style={{ color: isDarkModeActive && 'white' }}>
      <FormsSystem
        formComponentsArray={NewChannelFormConfigs()}
        formComponentsValues={formData}
        setFormComponentsValues={setFormData}
        setChosenHashTagElements={setChosenHashTagElements}
        chosenHashTagElements={chosenHashTagElements}
        {...DCGProps}
      />
      <ButtonsSystem buttonsArray={buttonsArray} />
      <TextEngineToolsFooter {...DCGProps} />
    </article>
  )
}

export default NewChannelForm
