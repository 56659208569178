import axios from 'axios'
import { useEffect, useState } from 'react'
import deleteFile from '../../../firebase/deleteFile'
import ToastifyGeneralError from '../../smallComponents/ToastifyGeneralError'

const GPNoFSPanelPropsSecondView = (props) => {
  const {
    setCardId,
    searchSystemProps,
    folderPathProps,
    noFSPanelPropsSecondView,
    DCGProps,
    element,
    project,
    user,
    setSharedPostConfigs,
    onFeedShare,
    deletePath,
    setCards,
    setIsUpdated,
    users,
    projects,
    onDelete,
  } = props
  // this will be calculations only for channels
  const [channelUrls, setChannelUrls] = useState([])
  useEffect(() => {
    const fecthChannelUrls = async () => {
      try {
        const res = await axios.get(`/urls/channel/${element?._id}`)
        setChannelUrls(res.data.urls)
      } catch (error) {
        console.log(error)
      }
    }
    if (element?._id && element?.hashTagType === 'channel') fecthChannelUrls()
  }, [element?._id, element?.hashTagType])
  return {
    setCardId,
    showShareToFeed: true,
    showAddToChannel: true,
    showPDFDownloader: true,
    showExternalLinkShare: true,
    showDeleteIcon:
      (element?.hashTagType === 'channel' &&
        element?.usersIds?.length + element?.projectsIds?.length === 1) ||
      (element?.hashTagType === 'issue' && element?.userId === user?._id),
    ...searchSystemProps,
    ...folderPathProps,
    ...noFSPanelPropsSecondView,
    ...DCGProps,
    DCGProps,
    element,
    user: user || project,
    Name: element?.Title,
    setSharedPostConfigs,
    onFeedShare,
    users,
    projects,
    onDelete: async (element) => {
      if (element?._id) {
        try {
          await axios.delete(`/${deletePath}/${element?._id}`)
          Promise.all(
            element?.images?.map((image) => {
              if (image) deleteFile(image)
            })
          )
          if (element?.hashTagType === 'channel') {
            Promise.all(
              channelUrls?.map((image) => {
                if (image) deleteFile(image)
              })
            )
          }
          setCards((els) => els.filter((d) => d?._id !== element?._id))
          if (typeof onDelete === 'function') onDelete(element)
        } catch (error) {
          console.log(error)
          ToastifyGeneralError(error)
        }
        setIsUpdated(true)
      }
    },
  }
}

export default GPNoFSPanelPropsSecondView
