import React, { useContext, useState } from 'react'
import { FaPhoenixFramework, FaFileContract } from 'react-icons/fa'
import { GiStoneTower } from 'react-icons/gi'
import { FaPeopleCarry } from 'react-icons/fa'
import {
  FcConferenceCall,
  FcDam,
  FcDepartment,
  FcReadingEbook,
} from 'react-icons/fc'
import { makeStyles } from '@material-ui/core/styles'
import { Avatar } from '@mui/material'
import LeftbarControlUnit from './LeftbarControlComps/LeftbarControlUnit'
import StateEngine from '../../smallComponents/StateEngine'
import ControlPanelDialogsContent from '../../profile/ProfileComponents/ProfileControlPanelComponents/ControlPanelDialogsContent'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../../../context/AuthContext'
import i18next from 'i18next'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginTop: '16px',
    position: 'relative',
    overflowY: 'hidden',
    height: '100vh',
    '&:hover': {
      overflowY: 'auto',
    },
  },
  '@media only screen and (max-width: 1000px)': {
    container: {
      display: 'none',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  // -----
  leftbarList: {
    padding: 0,
    margin: 0,
    listStyle: 'none',
    height: '100% !important',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center !important',
    justifyContent: 'center !important',
  },
  leftbarItemText: {
    fontFamily: '"Segoe UI Historic", Segoe UI, Helvetica, Arial, sans-serif',
    fontSize: 15,
    cursor: 'pointer',
    fontWeight: 'bold',
    color: '#050505',
  },
  leftbarIcons: {
    fontSize: 25,
    cursor: 'pointer',
  },
  leftbarListItem: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    filter: 'brightness(80%)',
    width: '100%',
    transition: '0.3s !important',
    margin: '0px 5px !important',
    '&:hover': {
      filter: 'brightness(130%)',
    },
  },
  activeLink: {
    background: 'grey',
    color: 'white',
    filter: 'brightness(130%)',
  },
}))

const LeftbarControl = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const lang = i18next.language
  const rtl = lang === 'ar'
  const { user } = useContext(AuthContext)
  const iconStyle = {
    marginRight: !rtl && 10,
    marginLeft: rtl && 10,
    fontSize: 20,
  }
  const controlPanelUnitsII = [
    {
      name: t('Contributors'),
      icon: <FcConferenceCall style={iconStyle} />,
      pureIcon: FcConferenceCall,
      margin: rtl ? '10px 10px 10px 5px' : '10px 5px 10px 10px',
    },
    {
      name: t('Control'),
      icon: <FcDepartment style={iconStyle} />,
      pureIcon: FcDepartment,
    },
    {
      name: t('Ideas_&_Inquiries'),
      icon: <FcReadingEbook style={iconStyle} />,
      pureIcon: FcReadingEbook,
    },
    {
      name: t('Projects_&_Deals'),
      icon: <FcDam style={iconStyle} />,
      pureIcon: FcDam,
    },
  ]

  const controlPanelButtons = StateEngine({
    StatesArray: controlPanelUnitsII,
  })

    // const pager = [
    //   {
    //     name: (
    //       <span className={classes.leftbarItemText} style={leftbarItemTextCond}>
    //         {user.firstName + ' ' + user.lastName}
    //       </span>
    //     ),
    //     icon: (
    //       <Avatar style={{ width: 30, height: 30 }} src={user.profilePicture} />
    //     ),
    //     path: `/profile/${user?._id}`,
    //   },
    //   {
    //     name: (
    //       <span className={classes.leftbarItemText} style={leftbarItemTextCond}>
    //         {t('Projects')}
    //       </span>
    //     ),
    //     icon: <FcDam className={classes.leftbarIcons} />,
    //     link: `https://projects.hutupia.com/projects-screen/${user?._id}`,
    //   },
    //   {
    //     name: (
    //       <span className={classes.leftbarItemText} style={leftbarItemTextCond}>
    //         {t('Contributors')}
    //       </span>
    //     ),
    //     icon: <FaPeopleCarry className={classes.leftbarIcons} />,
    //     path: `/contributors/${user?._id}`,
    //   },
    //   {
    //     name: (
    //       <span className={classes.leftbarItemText} style={leftbarItemTextCond}>
    //         {t('Jobs')}
    //       </span>
    //     ),
    //     icon: <FaPhoenixFramework className={classes.leftbarIcons} />,
    //     path: '/jobs',
    //   },
    //   {
    //     name: (
    //       <span className={classes.leftbarItemText} style={leftbarItemTextCond}>
    //         {t('Investments')}
    //       </span>
    //     ),
    //     icon: <FaFileContract className={classes.leftbarIcons} />,
    //     path: '/investments',
    //   },
    //   {
    //     name: (
    //       <span className={classes.leftbarItemText} style={leftbarItemTextCond}>
    //         {t('My_Inquiries')}
    //       </span>
    //     ),
    //     icon: <FaFileContract className={classes.leftbarIcons} />,
    //     path: `/inquiries/${user?._id}`,
    //   },
    //   {
    //     name: (
    //       <span className={classes.leftbarItemText} style={leftbarItemTextCond}>
    //         {t('My_Ideas')}
    //       </span>
    //     ),
    //     icon: <FaFileContract className={classes.leftbarIcons} />,
    //     path: `/ideas/${user?._id}`,
    //   },
    //   {
    //     name: (
    //       <span className={classes.leftbarItemText} style={leftbarItemTextCond}>
    //         {t('My_Resources')}
    //       </span>
    //     ),
    //     icon: <FaFileContract className={classes.leftbarIcons} />,
    //     path: `/resources/${user?._id}`,
    //   },
    //   {
    //     name: (
    //       <span className={classes.leftbarItemText} style={leftbarItemTextCond}>
    //         {t('My_Globals')}
    //       </span>
    //     ),
    //     icon: <FaFileContract className={classes.leftbarIcons} />,
    //     path: `/globals/${user?._id}`,
    //   },
    //   // {
    //   //   name: (
    //   //     <span className={classes.leftbarItemText} style={leftbarItemTextCond}>
    //   //       {t('Guide')}
    //   //     </span>
    //   //   ),
    //   //   icon: <GiStoneTower className={classes.leftbarIcons} />,
    //   //   link: 'https://library.hutupia.com',
    //   // },
    //   // {
    //   //   name: (
    //   //     <span className={classes.leftbarItemText} style={leftbarItemTextCond}>
    //   //       {t('Ads_Manager')}
    //   //     </span>
    //   //   ),
    //   //   icon: <GiStoneTower className={classes.leftbarIcons} />,
    //   //   link: 'https://ads-management.hutupia.com',
    //   // },
    // ]

  const [isSwipeableOpen, setIsSwipeableOpen] = useState(false)
  const [clickAwayState, setClickAwayState] = useState(false)
  const [isDialogFullWidth, setIsDialogFullWidth] = useState(false)
  const propsII = {
    ...props,
    isSwipeableOpen,
    setIsSwipeableOpen,
    clickAwayState,
    setClickAwayState,
    isDialogFullWidth,
    setIsDialogFullWidth,
    modelCardsContentArray: controlPanelUnitsII,
  }
  return (
    <ul className={classes.leftbarList}>
      {controlPanelButtons.map((unit, unitId) => {
        return (
          <LeftbarControlUnit
            id={unitId}
            unit={unit}
            propsII={propsII}
            DialogsComponentContent={ControlPanelDialogsContent}
            unitIndex={unitId}
          />
        )
      })}
    </ul>
  )
}

export default LeftbarControl
