import React, { useState, useRef, useEffect } from 'react'
import withStyles from '@mui/styles/withStyles'
import PCChatBody from './PCChatSystemComponents/PCChatBody'
import ChatList from './PCChatSystemComponents/ChatList'

const styles = () => ({
  bookContainer: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'row',
  },
  BookLeft: {
    display: 'flex',
    flex: '0.5',
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  BookRight: {
    display: 'flex',
    flex: '1.5',
    flexDirection: 'column',
    overflowY: 'auto',
  },

  BookRightWrapper: {
    display: 'flex',
    padding: '0 10px 10px 10px',
    flexDirection: 'column',
  },
})

const PCChatSystem = ({
  classes,
  chat,
  isDarkModeActive,
  setIsSendClicked,
  isDialogFullWidth,
  chatDialogClose,
  pageProps,
}) => {
  const [chosenChat, setChosenChat] = useState(chat)
  const [onlineUsers, setOnlineUsers] = useState(null)
  // control the scrolling bar
  const scrollRef = useRef(null)

  useEffect(() => {
    scrollRef.current.scrollIntoView({
      behaviour: 'smooth',
      block: 'end',
      inline: 'nearest',
    })
      //  scrollRef.current.scrollTo(0, 10000)
  }, [chosenChat?.id, chosenChat?._id])
  return (
    <article
      className={classes.bookContainer}
      style={{
        color: isDarkModeActive ? 'white' : 'inherit',
      }}
    >
      <section
        className={classes.BookLeft}
        style={{ display: isDialogFullWidth ? 'flex' : 'none' }}
      >
        <ChatList
          isDarkModeActive={isDarkModeActive}
          setChosenChat={setChosenChat}
          chosenChat={chosenChat}
          pageProps={pageProps}
          onlineUsers={onlineUsers}
        />
      </section>
      <section
        className={classes.BookRight}
        ref={scrollRef}
        id={'chat_messages'}
      >
        <div className={classes.BookRightWrapper}>
          <PCChatBody
            chat={chosenChat}
            isDarkModeActive={isDarkModeActive}
            setIsSendClicked={setIsSendClicked}
            chatDialogClose={chatDialogClose}
            pageProps={pageProps}
            scrollRef={scrollRef}
            isDialogFullWidth={isDialogFullWidth}
            onlineUsers={onlineUsers}
            setOnlineUsers={setOnlineUsers}
          />
        </div>
      </section>
    </article>
  )
}

const WrappedPCChatSystem = withStyles(styles)(PCChatSystem)

export default WrappedPCChatSystem
