import React from 'react'
import FeedsSystem from '../../feedsSystem/FeedsSystem'
import PostBodyModel from '../../leftbarDirectoryComponents/feedsModels/PostBodyModel'
import PostProps from '../../homeFeed/homeFeedComponents/HomeFeedPostModelComponents/PostProps'
import TextPost from '../../postSystem/TextPost'
// import RoomSMDisCenter from '../../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectDepartmentsComponents/RoomsComponents/RoomStructureModelComponents/RoomSMFeedComponents/RoomPostModelComps/RoomSMDisCenter'
// import ChannelProfileFeedSMDisCenter from '../../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectPartnershipsComponents/ProjectPartnershipsDialogContentComps/ProjectPartnershipsContentArraysComps/ProjectChannelsComps/ProjectPartnershipChannelsComps/PartnershipChaneelProfileComps/ChannelProfileFeedComps/ChannelPostModelComps/ChannelProfileFeedSMDisCenter'

const FeedPostWindowProps = (props) => {
  const imageProps = PostProps({}, { imageViewerProps: true })
 
  return (
    props?.post && {
      isCommentsDialogOpen: props?.isWindowOpen,
      commentsDialogClose: props?.windowCloser,
      postBody:
        props?.post?.feedsSystemCategory === 'home' ? (
          <FeedsSystem
            feedsSystemProps={{
              post: props?.post,
              hideRightOptions: true,
              hideTopAndBottom: true,
              isDarkModeActive: props?.isDarkModeActive,
            }}
          />
        ) 
        // :
        //  props?.post?.feedsSystemCategory === 'room' ? (
        //   <RoomSMDisCenter {...props} />
        // )
        //  : props?.post?.feedsSystemCategory === 'channel' ? (
        //   <ChannelProfileFeedSMDisCenter {...props} />
        // ) 
        :
         (
          <PostBodyModel
            postBodyProps={{
              post: props?.post,
              postTextMargins: 10,
              ...imageProps,
              commentingSystemView: true,
              isCommentingSystemActive: true,
              socket: props?.socket,
            }}
          />
        ),
      postText: (
        <TextPost
          post={props?.post}
          isCommentingSystemActive={true}
          socket={props?.socket}
        />
      ),
      post: props?.post,
      ...props,
    }
  )
}

export default FeedPostWindowProps
