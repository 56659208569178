import React, { useState, useEffect } from 'react'
import FolderSystemPath from '../../fileSystem/FolderSystemPath'

const FolderPathComponent = ({
  path,
  setDialogModelUnitMenuItemId,
  DialogModelUnitMenuItemId,
  DialogModelUnitContentArray,
  showSearchBar,
  folderPathProps,
  searchSystemProps,
  DMUSystemProps,
}) => {
  const {
    secondLevelFolderPath,
    setSecondLevelFolderPath,
    // 3rd path
    thirdLevelFolderPath,
    setThirdLevelFolderPath,
    // 4th path
    fourthLevelFolderPath,
    setFourthLevelFolderPath,
  } = folderPathProps
  const { setShowSearchInput } = searchSystemProps

  // text to hide the other paths when home path is clicked
  const [isMenuClicked, setIsMenuClicked] = useState(
    DialogModelUnitMenuItemId < DialogModelUnitContentArray.length - 1
  )

  // getting to home through the FolderPathSystem home icon
  const getToDefaultView = () => {
    setDialogModelUnitMenuItemId(DialogModelUnitContentArray.length - 1)
  }

  // to prevent paths to inherit values while clicking on menu icons
  useEffect(() => {
    if (typeof setSecondLevelFolderPath === 'function')
      setSecondLevelFolderPath('')
    if (typeof setThirdLevelFolderPath === 'function')
      setThirdLevelFolderPath('')
    if (typeof setFourthLevelFolderPath === 'function')
      setFourthLevelFolderPath('')
    // to hide all search panel when clicing on the menu icons
    if (typeof setShowSearchInput === 'function') setShowSearchInput(false)
  }, [DialogModelUnitMenuItemId])

  useEffect(() => {
    setIsMenuClicked(
      DialogModelUnitMenuItemId < DialogModelUnitContentArray.length - 1
    )
  }, [DialogModelUnitMenuItemId])

  return (
    <FolderSystemPath
      path={path}
      folderPathProps={folderPathProps}
      searchSystemProps={searchSystemProps}
      path2={secondLevelFolderPath}
      path3={thirdLevelFolderPath}
      path4={fourthLevelFolderPath}
      getToDefaultView={getToDefaultView}
      isMenuClicked={isMenuClicked}
      showSearchBar={showSearchBar}
      DMUSystemProps={DMUSystemProps}
    />
  )
}

export default FolderPathComponent
