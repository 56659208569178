import React, { useState } from 'react'
import MContribNotifDialog from './MContributorsNotificationsListComps/MContribNotifDialog'
import MNotificationsListModel from './MNotificationsListModel'
import ContribNotifListItem from '../../../notificationsSystem/NotificationsComponents/ContributorsNotificationsComponents/ContributorsNotifsDialogLeftComponents/ContribNotifListItem'
import StateGenerator from '../../../smallComponents/StateGenerator'

const MContributorsNotificationsList = ({
  isDarkModeActive,
  pageProps,
  ContributorsNotifDialogClose,
}) => {
  const {
    contribNotifications,
    contribNotificationsSenders,
    contribNotificationsProjects,
    setContribPage,
    contribPage,
    contribCurrentResponse,
  } = pageProps
  const { isGeneralDialogOpen, GeneralDialogOpener, GeneralDialogClose } =
    StateGenerator({ GeneralProps: true })
  const [chosenNotification, setChosenNotification] = useState()

  return (
    <>
      <MNotificationsListModel
        data={contribNotifications}
        NotificationListItem={ContribNotifListItem}
        isDarkModeActive={isDarkModeActive}
        setChosenNotification={setChosenNotification}
        notificationDialogOpener={GeneralDialogOpener}
        senders={contribNotificationsSenders}
        projects={contribNotificationsProjects}
        setPage={setContribPage}
        page={contribPage}
        {...pageProps}
        pageProps={pageProps}
        currentResponse={contribCurrentResponse}
      />
      <MContribNotifDialog
        isNotificationDialogOpen={isGeneralDialogOpen}
        notificationDialogClose={GeneralDialogClose}
        notification={chosenNotification}
        pageProps={pageProps}
        DialogCloser={ContributorsNotifDialogClose}
      />
    </>
  )
}

export default MContributorsNotificationsList
