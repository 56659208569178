import { List } from '@mui/material'
import React from 'react'
import withStyles from '@mui/styles/withStyles'
import ChatListItem from './ChartListComponents/ChatListItem'
import SearchEngine from '../../../searchSystem/SearchEngine'
import InfiniteScroll from 'react-infinite-scroll-component'
import LoadMoreButton from '../../../smallComponents/LoadMoreButton'
import FallingLinesLoader from '../../../smallComponents/FallingLinesLoader'
import RepetionRemover from '../../../smallComponents/RepetionRemover'

const styles = () => ({
  notificationsArea: {
    overflowY: 'auto !important',
    position: 'absolute',
  },
})

const ChatList = ({
  classes,
  isDarkModeActive,
  setChosenChat,
  chosenChat,
  pageProps,
  onlineUsers,
}) => {
  const { chats, setPage, currentResponse, page, lastMessages } = pageProps
  const ordered = lastMessages
    .map((message) => {
      const chat = chats.find((ch) => ch?._id === message?.conversationId)
      return {
        ...chat,
        lastMessage: message,
      }
    })
    .sort(
      (a, b) =>
        new Date(b?.lastMessage?.createdAt) - new Date(a.lastMessage?.createdAt)
    )
  return (
    <section
      style={{
        overflowY: 'auto',
      }}
    >
      <List
        className={classes.notificationsArea}
        sx={{
          background: isDarkModeActive
            ? 'rgba(255, 255, 255, 0.2) !important'
            : 'rgba(210, 221, 240, 0.2)  !important',
          borderRadius: '15px',
        }}
      >
        <InfiniteScroll
          dataLength={chats.length}
          next={() => {
            setPage((prev) => prev + 1)
          }}
          hasMore={currentResponse}
          scrollThreshold={'1000px'}
          scrollableTarget={'scrollableTarget2'}
          loader={
            page === 1 ? (
              <LoadMoreButton setPage={setPage} />
            ) : (
              <FallingLinesLoader />
            )
          }
        >
          <SearchEngine
            searchQuery={''}
            searchElements={RepetionRemover(ordered)}
            SearchedElementComponent={ChatListItem}
            SearchedElementComponentProps={{
              isDarkModeActive,
              setChosenChat,
              chosenChat,
              ...pageProps,
              onlineUsers,
            }}
            searchIdtentifiers={[]}
          />
        </InfiniteScroll>
      </List>
    </section>
  )
}

const WrappedChatList = withStyles(styles)(ChatList)

export default WrappedChatList
