import React from 'react'
import withStyles from '@mui/styles/withStyles'
import FolderPaths from './FolderSystemPathComponents/FolderPaths'
import FolderPathInput from './FolderSystemPathComponents/FolderPathInput'
import FolderPathSerachIconToggle from './FolderSystemPathComponents/FolderPathSerachIconToggle'
import FolderPathAlphebeticOrderIcon from './FolderSystemPathComponents/FolderPathAlphebeticOrderIcon'
import WindowSize from '../smallComponents/WindowSize'
import i18next from 'i18next'

const styles = () => ({
  pathContainer: {
    width: '94%',
    marginTop: 10,
    backgroundColor: 'rgba(159, 177, 211, 0.212)',
    backdropFilter: 'blur(2px)',
    borderRadius: 20,
    padding: 10,
    color: 'black',
    display: 'flex',
    position: 'sticky',
    top: 10,
    zIndex: 9,
    flexDirection: 'column',
  },
  pathSearchIcon: {
    height: 20,
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

const FolderSystemPath = ({
  classes,
  path,
  path2,
  path3,
  path4,
  getToDefaultView,
  isMenuClicked,
  DMUSystemProps,
  //
  showSearchBar,
  folderPathProps,
  searchSystemProps,
}) => {
  const { isDarkModeActive } = DMUSystemProps
  const windowSize = WindowSize()
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <article
      className={classes.pathContainer}
      style={{
        marginLeft: !rtl && (windowSize > 600 ? 10 : 0),
        marginRight: rtl && (windowSize > 600 ? 10 : 0),
      }}
    >
      <section className={classes.pathSearchIcon}>
        <FolderPaths
          path={path}
          path2={path2}
          path3={path3}
          path4={path4}
          folderPathProps={folderPathProps}
          searchSystemProps={searchSystemProps}
          getToDefaultView={getToDefaultView}
          isMenuClicked={isMenuClicked}
          isDarkModeActive={isDarkModeActive}
        />
        <section
          style={{
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <FolderPathSerachIconToggle
            searchSystemProps={searchSystemProps}
            path2={path2}
            path3={path3}
            path4={path4}
            isDarkModeActive={isDarkModeActive}
            folderPathProps={folderPathProps}
            showSearchBar={showSearchBar}
          />
          <FolderPathAlphebeticOrderIcon
            path2={path2}
            path3={path3}
            path4={path4}
            searchSystemProps={searchSystemProps}
            folderPathProps={folderPathProps}
            DMUSystemProps={DMUSystemProps}
            showSearchBar={showSearchBar}
          />
        </section>
      </section>
      <FolderPathInput
        searchSystemProps={searchSystemProps}
        folderPathProps={folderPathProps}
        showSearchBar={showSearchBar}
      />
    </article>
  )
}

const WrappedFolderSystemPath = withStyles(styles)(FolderSystemPath)

export default WrappedFolderSystemPath
