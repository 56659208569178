import React from 'react'
import withStyles from '@mui/styles/withStyles'
import i18next from 'i18next'

const styles = () => ({
  feedFooterWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'row',
  },
})

const FeedFooter = ({ classes }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <article className={classes.feedFooterWrapper}>
      <h6
        style={{
          color: '#6e8696',
        }}
      >
        &bull; &bull; {rtl ? 'هذا كل شئ' : 'That is All'} &bull; &bull;
      </h6>
    </article>
  )
}

const WrappedFeedFooter = withStyles(styles)(FeedFooter)

export default WrappedFeedFooter
