import React, { useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import { ListItem } from '@mui/material'
import ForAllPostsNotificationModel from './GeneralNotifListItemComps/ForAllPostsNotificationModel'
import UserProfileNotificationModel from './GeneralNotifListItemComps/UserProfileNotificationModel'
import axios from 'axios'
import CustomCircularProgress from '../../../smallComponents/CustomCircularProgress'
import { useNavigate } from 'react-router-dom'

const styles = () => ({
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    width: '100% !important',
    alignContent: 'center !important',
    alignItems: 'center !important',
    cursor: 'pointer !important',
    '&:hover': {
      opacity: '0.8',
    },
  },
  clickedNotification: {
    backgroundColor: 'rgba(217,224,252, 0.6) !important',
    cursor: 'pointer !important',
  },
})

const GeneralNotifListItem = ({
  classes,
  props,
  element,
  toastDisabledFun,
}) => {
  const {
    isDarkModeActive,
    setChosenNotification,
    chosenNotification,
    notificationDialogOpener,
    sender,
    // defined only for m view
    senders,
    project,
    setGeneralNotifications,
  } = props
  const [notification, setNotification] = useState(element)
  const senderII = senders?.find(
    (se) => se._id === notification?.accompaniedData?.senderId
  )
  const notifProps = {
    notification,
    isDarkModeActive,
    sender: sender ? sender : senderII,
    project,
  }

  const notifModels = [
    {
      associativity: 'post',
      component: <ForAllPostsNotificationModel {...notifProps} />,
    },
    {
      associativity: 'userProfile',
      component: <UserProfileNotificationModel {...notifProps} />,
    },
  ]
  const history = useNavigate()
  return (
    <ListItem
      className={
        element?._id === chosenNotification?._id
          ? classes.clickedNotification
          : classes.listItem
      }
      onClick={async () => {
        if (notification._id && !toastDisabledFun) {
          try {
            const res = await axios.patch(
              `/notifications/${notification._id}`,
              {
                opened: true,
                interactedWith: true,
              }
            )
            setNotification(res.data.notification)
            setGeneralNotifications((notifs) => {
              let oldIndex = notifs?.findIndex(
                (n) => n._id === notification._id
              )
              return notifs.map((p, i) =>
                i === oldIndex ? res.data.notification : p
              )
            })
          } catch (error) {
            console.log(error)
          }
        }
        if (typeof setChosenNotification === 'function')
          setChosenNotification(element)
        if (
          typeof notificationDialogOpener === 'function' &&
          // profile notifications should not open any post
          notification.associativity !== 'userProfile'
        )
          notificationDialogOpener()

        // this will open the profile of the user who followed me for example - need testign
        if (notification.associativity === 'userProfile')
          history(`/profile/${sender?._id || senderII?._id}`)
      }}
      style={{
        color: isDarkModeActive ? 'white' : null,
      }}
    >
      {(sender ? !sender : !senderII) ? (
        <CustomCircularProgress />
      ) : (
        notifModels.find(
          (notif) => notif?.associativity === notification?.associativity
        )?.component
      )}
    </ListItem>
  )
}

const WrappedGeneralNotifListItem = withStyles(styles)(GeneralNotifListItem)

export default WrappedGeneralNotifListItem
