import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import FormsComponents from '../../../../../../../formsSystem/FormsComponents'
import axios from 'axios'
import { AuthContext } from '../../../../../../../../context/AuthContext'
import ToastifyGeneralError from '../../../../../../../smallComponents/ToastifyGeneralError'

const styles = () => ({
  panelWrapper: {
    position: 'absolute !important',
    zIndex: '2 !important',
    width: '100vw !important',
    bottom: '0 !important',
    borderRadius: '0px !important',
    display: 'flex',
    justifyContent: 'space-around !important',
    alignItems: 'center !important',
    alignContent: 'center !important',
    left: 0,
  },
})

const MNewChat = ({
  classes,
  chat,
  isDarkModeActive,
  setChatMessages,
  pageProps,
  disabledInput,
}) => {
  const { user } = useContext(AuthContext)
  const { socket } = pageProps
  const [textContent, setTextContent] = useState('')
  const [chosenHashTagElements, setChosenHashTagElements] = useState([])
  const [images, setImages] = useState([])
  const [playerLink, setPlayerLink] = useState()
  const newMessage = {
    conversationId: chat?._id,
    senderId: user._id,
    text: textContent,
    chosenHashTagElements,
    images,
    playerLink,
  }
  const onSend = async () => {
    if (chat?.members) {
      socket.current.emit('sendMessage', {
        senderId: user._id,
        receiverId: chat?.members.find((id) => id !== user._id),
        newMessage,
      })
      try {
        const res = await axios.post(`/messages`, newMessage)
        setChatMessages((mssgs) => [...mssgs, res.data])
      } catch (error) {
        console.log(error)
        ToastifyGeneralError(error)
      }
    }
  }
  const { t } = useTranslation()
  const formsCompsProps = {
    formComponentIndex: 3,
    chosenHashTagElements,
    setChosenHashTagElements,
    textContent,
    setTextContent,
    placeholder: t('New_Message'),
    images,
    setImages,
    isDarkModeActive,
    onSend,
    pathName: `/chats/${chat?._id}`,
    onKeyDown: (e) => {
      if (e.key === 'Enter') {
        onSend()
        setTextContent('')
        setImages([])
        e.preventDefault()
      }
    },
    link: playerLink,
    setLink: setPlayerLink,
    disabled_chat: disabledInput,
  }
  return (
    <Card
      className={classes.panelWrapper}
      sx={{
        backgroundColor: isDarkModeActive ? 'rgba(49, 48, 53, 0.9)' : 'white',
        color: isDarkModeActive ? 'white' : null,
      }}
    >
      <FormsComponents formsCompsProps={formsCompsProps} />
    </Card>
  )
}

const WrappedMNewChat = withStyles(styles)(MNewChat)

export default WrappedMNewChat
