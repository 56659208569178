import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { TbMessageCircle } from 'react-icons/tb'
import { AuthContext } from '../../../context/AuthContext'
import NotificationCreator from '../../notificationsSystem/NotificationCreator'
import MultiMillify from '../../smallComponents/MultiMillify'
import { useTranslation } from 'react-i18next'

const CommentButtonConfig = ({
  post,
  styles,
  commentsDialogOpener,
  commentSystemCommentIcon,
  isCommentIconClicked,
  setIsCommentIconClicked,
}) => {
  const { user } = useContext(AuthContext)
  const { t } = useTranslation()
  const [commentsCount, setCommentsCount] = useState(0)
  useEffect(() => {
    const fetchCount = async () => {
      try {
        const res = await axios.get(`/comments/commentsCount/${post._id}`)
        setCommentsCount(res.data.commentsCount)
      } catch (error) { }
    }
    if (post._id) fetchCount()
  }, [post._id])
  return {
    icon: (
      <TbMessageCircle
        size={19}
        style={{
          ...styles?.bottomIcon,
        }}
      />
    ),
    icon_text: t('Comment'),
    value: (
      <span
        style={{
          display: commentsCount > 0 ? null : 'none',
          ...styles?.counterValue,
        }}
      >
        {MultiMillify(commentsCount)}
      </span>
    ),
    openFun: () => {
      if (
        commentSystemCommentIcon &&
        typeof setIsCommentIconClicked === 'function'
      )
        setIsCommentIconClicked(!isCommentIconClicked)
      if (
        !commentSystemCommentIcon &&
        typeof commentsDialogOpener === 'function'
      )
        commentsDialogOpener()
      if (post?.feedsSystemCategory === 'home')
        NotificationCreator({
          associativity: 'post',
          relation: 'creator',
          conditionIndex: 2,
          accompaniedData: {},
          category: 'general',
          userId: post.userId,
          currentUser: user._id,
        })
    },
    info: {
      content: <span
        style={{
          display: commentsCount > 0 ? null : 'none',
          ...styles?.counterValue,
        }}
      >
        {MultiMillify(commentsCount)} {t('Comments')}
      </span>
    },
  }
}

export default CommentButtonConfig
