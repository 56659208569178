import React, { useContext } from 'react'
import axios from 'axios'
import { AuthContext } from '../../../../../context/AuthContext'
import ButtonsSystem from '../../../../buttonsSystem/ButtonsSystem'

const NewResourceFormButtons = ({
  newResourceClose,
  isFormDataFilled,
  DCGProps,
  formData,
  setCards,
  images,
  onAdd,
  chosenHashTagElements,
}) => {
  const { user } = useContext(AuthContext)
  const { project } = DCGProps
  const rtl = document.body.dir === 'rtl'
  const newResource = project
    ? {
        ...formData,
        images,
        userId: user?._id,
        projectsIds: project?._id,
        chosenHashTagElements,
      }
    : {
        ...formData,
        images,
        userId: user?._id,
        chosenHashTagElements,
      }
  const buttonsArray = [
    {
      title: rtl ? 'إلغاء' : 'Cancel',
      funs: () => {
        newResourceClose()
      },
    },
    {
      title: rtl ? 'أضف' : '  Add',
      disabled: !isFormDataFilled,
      funs: async () => {
        newResourceClose()
        try {
          const res = await axios.post(`/resources`, newResource)
          if (typeof setCards === 'function')
            setCards((ress) => [res.data.resource, ...ress])
          if (typeof onAdd === 'function') onAdd(res)
        } catch (error) {
          console.log(error)
        }
      },
    },
  ]
  return <ButtonsSystem buttonsArray={buttonsArray} {...DCGProps} />
}

export default NewResourceFormButtons
