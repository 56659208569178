import React from 'react'
import { AiFillHome } from 'react-icons/ai'
import { FcNext, FcPrevious } from 'react-icons/fc'
import withStyles from '@mui/styles/withStyles'
import i18next from 'i18next'

const styles = () => ({
  nextIcon: {
    margin: '0px 3px 0px 3px',
  },
  path: {
    fontWeight: 500,
    fontSize: 15,
    cursor: 'pointer',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    transition: '0.3s',
    color: 'rgb(79, 95, 114)',
    '&:hover': {
      fontSize: 16,
      opacity: 0.5,
    },
  },
  '@media (max-width: 600px)': {
    path: {
      fontWeight: 500,
      fontSize: 12,
      '&:hover': {
        fontSize: 13,
      },
    },
  },
})

const HomePath = ({
  classes,
  folderPathProps,
  searchSystemProps,
  getToDefaultView,
}) => {
  const {
    setSecondLevelFolderPath,
    setThirdLevelFolderPath,
    setFourthLevelFolderPath,
  } = folderPathProps

  const { setShowSearchInput, setSearchQuery } = searchSystemProps
  const lang = i18next.language
  return (
    <>
      {/* home */}
      <AiFillHome
        className={classes.path}
        onClick={() => {
          getToDefaultView()
          setSecondLevelFolderPath('')
          setThirdLevelFolderPath('')
          setFourthLevelFolderPath('')
          setShowSearchInput(false)
          setSearchQuery('')
        }}
      />
      {lang === 'ar' ? (
        <FcPrevious size={14} className={classes.nextIcon} />
      ) : (
        <FcNext size={14} className={classes.nextIcon} />
      )}
    </>
  )
}

const WrappedHomePath = withStyles(styles)(HomePath)

export default WrappedHomePath
