import React from 'react'
import NotifsPopoverCompModel from './NotifsPopoverCompModel'
import ContribNotifListItem from '../../../notificationsSystem/NotificationsComponents/ContributorsNotificationsComponents/ContributorsNotifsDialogLeftComponents/ContribNotifListItem'
import { useTranslation } from 'react-i18next'

const ContribNotifsPopoverComp = (props) => {
  const { t } = useTranslation()
  const {
    setContribPage,
    contribNotifications,
    contribNotificationsSenders,
    contribNotificationsProjects,
    contribPage,
    contribCurrentResponse,
  } = props
  const pps = {
    ...props,
    notificationType: t('Contributors'),
    data: contribNotifications,
    InteriorCardComponent: ContribNotifListItem,
    cardModelIndex: 0,
    notificationComponentIndex: 0,
    senders: contribNotificationsSenders,
    projects: contribNotificationsProjects,
    setPage: setContribPage,
    page: contribPage,
    currentResponse: contribCurrentResponse,
  }
  return <NotifsPopoverCompModel {...pps} />
}

export default ContribNotifsPopoverComp
