import { ListItem } from '@mui/material'
import React from 'react'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  title: {
    fontWeight: 500,
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignText: 'center',
    width: '50%',
  },
  litItem: {
    width: 'auto',
  },
  '@media (max-width: 600px)': {
    title: {
      fontSize: 12,
    },
    litItem: {
      width: 'auto',
    },
  },
})

const InteractionBarModel = ({ classes, button, extra, isDarkModeActive }) => {
  return (
    <ListItem
      button
      className={classes.listItem}
      sx={{
        borderRadius: 30,
        padding: '2px 5px',
        margin: `${extra ? 0 : '10px'} 1px 10px 1px`,
        display: button.displayCondition ? 'flex' : 'none',
        border: `1px solid ${
          isDarkModeActive ? 'rgba(225, 255, 255, 0.2)' : 'rgb(0, 119, 181)'
        }`,
        justifyContent: 'center',
      }}
      onClick={typeof button.openFun === 'function' ? button.openFun : null}
    >
      {button.icon}
      <span className={classes.title}>{button.title}</span>
    </ListItem>
  )
}

const WrappedInteractionBarModel = withStyles(styles)(InteractionBarModel)

export default WrappedInteractionBarModel
