import React, { useContext, useEffect, useState } from 'react'
import PCChatSystem from './ChatSystemComponents/PCChatSystem'
import ModelDialogWrappingII from '../dialogModelUnit/ModelDialogWrappingII'
import axios from 'axios'
import { AuthContext } from '../../context/AuthContext'
import i18next from 'i18next'

const PCChatDialog = ({
  isChatDialogOpen,
  chatDialogClose,
  chat,
  isDarkModeActive2,
  pageProps,
}) => {
  const { setIsChatOpen } = pageProps
  const [isDarkModeActive, setIsDarkModeActive] = useState(isDarkModeActive2)
  const { user } = useContext(AuthContext)
  const [chattedUser, setChattedUser] = useState({})
  const [isDialogFullWidth, setIsDialogFullWidth] = useState(false)
  const lang = i18next.language
  const rtl = lang === 'ar'

  useEffect(() => {
    const chattedUser = async () => {
      try {
        const res = await axios.get(
          `/users/oneUser/${chat?.members.filter((id) => id !== user._id)}`
        )
        let us = res.data.user
        setChattedUser({
          ...us,
          name: us.firstName + ' ' + us.lastName,
          image: us.profilePicture,
        })
      } catch (error) {
        console.log(error)
      }
    }
    if (chat && user?._id && chat?.members) chattedUser()
  }, [chat, user?._id, chat?.members])
  return (
    <ModelDialogWrappingII
      isDarkModeActive={isDarkModeActive}
      setIsDarkModeActive={setIsDarkModeActive}
      isDialogFullWidth={isDialogFullWidth}
      setIsDialogFullWidth={setIsDialogFullWidth}
      dialogsArray={[
        {
          title: rtl ? 'المحادثات' : 'Chats',
          closeFun: () => {
            chatDialogClose()
            setIsChatOpen(false)
          },
          open: isChatDialogOpen,
          content: (
            <PCChatSystem
              isDarkModeActive={isDarkModeActive}
              isDialogFullWidth={isDialogFullWidth}
              chatDialogClose={chatDialogClose}
              chat={{
                ...chat,
                chatter: chattedUser,
              }}
              pageProps={pageProps}
            />
          ),
        },
      ]}
    />
  )
}

export default PCChatDialog
