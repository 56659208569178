import React from 'react'
import NotificationCoverModel from '../../../../NotificationCoverModel'
import i18next from 'i18next'

const TaskAssignmentCover = ({ notification, sender }) => {
 const lang = i18next.language
 const rtl = lang === 'ar'
  const task = notification.accompaniedData?.elements?.find(
    (el) => el.hashTagType === 'task'
  )
  const content = rtl
    ? `قام ${sender?.name} بتعيينك مهمة جديدة`
    : `${sender?.name} has assigned you to
      a new task ${task?.title}`
  return (
    <NotificationCoverModel
      notification={notification}
      notifCoverContent={content}
    />
  )
}

export default TaskAssignmentCover
