import React, { useContext, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Toolbar, Typography, Drawer, Button } from '@mui/material'
import AdsManagementPageSideBar from './AdsManagmentModelComps/AdsManagementPageSideBar'
import { IconButton } from '@material-ui/core'
import { Menu as MenuIcon } from '@material-ui/icons'
import { Home as HomeIcon } from '@material-ui/icons'
import { AuthContext } from '../../context/AuthContext'
import { useNavigate } from 'react-router-dom'
import DeniedAccess from '../../components/smallComponents/DeniedAccess'
import i18next from 'i18next'

const drawerWidth = 250

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    zIndex: theme.zIndex.drawer - 1,
    flexShrink: 0,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  button: {
    textTransform: 'uppercase',
    color: '#fff',
    fontSize: '1rem',
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
  },
}))
const AdsManagmentModel = ({ Component, ComponentProps }) => {
  const classes = useStyles()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const { user } = useContext(AuthContext)
  const navigate = useNavigate()

  const handleDrawerOpen = () => {
    setDrawerOpen(true)
  }

  const handleDrawerClose = () => {
    setDrawerOpen(false)
  }

  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <div className={classes.root}>
      <AppBar position='fixed' className={classes.appBar}>
        <Toolbar>
          {user && (
            <IconButton
              edge='start'
              color='inherit'
              aria-label='menu'
              onClick={handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
          )}
          <div
            style={{
              flexGrow: 1,
              textAlign: 'center',
            }}
          >
            <Typography
              variant='h6'
              style={{
                fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
              }}
            >
              {rtl ? 'إدارة الاعلانات' : 'Ads Management'}
            </Typography>
          </div>
          {user ? (
            <IconButton color='inherit' aria-label='home' href='/home'>
              <HomeIcon />
            </IconButton>
          ) : (
            <>
              <Button
                variant='contained'
                onClick={() =>
                  navigate('/login', {
                    state: { from: window.location.pathname },
                  })
                }
                className={classes.button}
              >
                {rtl ? 'دخول' : 'Login'}
              </Button>
              <Button
                variant='contained'
                onClick={() =>
                  navigate('/register', {
                    state: { from: window.location.pathname },
                  })
                }
                className={classes.button}
              >
                {rtl ? 'انشاء حساب' : 'Sign up'}
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>
      {user ? (
        <>
          <Drawer
            className={classes.drawer}
            variant='temporary'
            anchor={rtl ? 'right' : 'left'}
            open={drawerOpen}
            onClose={handleDrawerClose}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <AdsManagementPageSideBar handleDrawerClose={handleDrawerClose} />
          </Drawer>
          <Component {...ComponentProps} />
        </>
      ) : (
        <DeniedAccess />
      )}
    </div>
  )
}

export default AdsManagmentModel
