
import React, { useContext } from 'react'
import { Card, ListItem } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import GeneralNotifModel from './NotificationCardUnitComponents/GeneralNotifModel'
import DealsNotifiModel from './NotificationCardUnitComponents/DealsNotifiModel'
import { SettingsContext } from '../../../../../context/SettingsContext/SettingsContext'
import DarkModeColorCoder from '../../../../smallComponents/DarkModeColorCoder'

const styles = () => ({
  notificationUnit: {
    margin: '10px',
    borderRadius: '10px !important',
    cursor: 'pointer',
  },
})

const NotificationCardUnit = ({
  classes,
  notification,
  interiorCardComponent,
  clickEeventHandler,
  cardModelIndex,
}) => {
  const props = {
    notification,
    interiorCardComponent,
    clickEeventHandler,
    cardModelIndex,
  }
  const { settings } = useContext(SettingsContext)
  const isDarkModeActive = settings?.Mode === 'Dark'
  const cardModel = [
    <GeneralNotifModel {...props} />,
    <DealsNotifiModel {...props} />,
    // model 2 chat
    <ListItem
      onClick={() => {
        clickEeventHandler()
      }}
    >
      {interiorCardComponent}
    </ListItem>,
  ]
  return (
    <Card
      className={classes.notificationUnit}
      sx={{
        background: isDarkModeActive
          ? DarkModeColorCoder('Grey_1x_light')
          : 'white',
        backgroundColor:
          !notification?.opened &&
          (isDarkModeActive
            ? 'rgba(217,224,252, 0.2)'
            : 'rgba(255,255,255, 0.9)'),
        color: isDarkModeActive ? 'white' : 'black',
      }}
    >
      {cardModel[cardModelIndex]}
    </Card>
  )
}

const WrappedNotificationCardUnit = withStyles(styles)(NotificationCardUnit)

export default WrappedNotificationCardUnit
