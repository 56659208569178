import React, { useContext } from 'react'
import { Card } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import ProfileCommunicationBar from './ProfilePhotoCommunicationComponents/ProfileCommunicationBar'
import ProfilePhotoName from './ProfilePhotoCommunicationComponents/ProfilePhotoName'
import Media from 'react-media'
import MProfilePhotoCommunication from '../../../mobileViewComponents/MProfilePhotoCommunication'
import StateGenerator from '../../../smallComponents/StateGenerator'
import { SettingsContext } from '../../../../context/SettingsContext/SettingsContext'
import DarkModeColorCoder from '../../../smallComponents/DarkModeColorCoder'

const styles = () => ({
  profileImageName: {
    display: 'flex',
    justifyContent: 'center !important',
    width: 280,
    borderRadius: '20px !important',
    alignItems: 'center',
  },
  '@media (max-width: 600px)': {
    profileImageName: {
      width: '100%',
      borderRadius: '0 !important',
    },
  },
})

const ProfilePhotoCommunication = ({ classes, profileProps }) => {
  const { settings } = useContext(SettingsContext)
  const isDarkModeActive = settings?.Mode === 'Dark'
  const profilePropsII = {
    ...StateGenerator({
      chatProps: true,
    }),
    active: true,
    isDarkModeActive,
    ...profileProps,
  }

  const mediaComponents = [
    <Card
      className={classes.profileImageName}
      style={{
        display: 'flex',
        flexDirection: 'column',
        background: isDarkModeActive ? DarkModeColorCoder('Grey_light') : null,
        color: isDarkModeActive ? 'white' : 'inherit',
        
      }}
    >
      <ProfilePhotoName profileProps={profilePropsII} />
      <ProfileCommunicationBar profileProps={profilePropsII} />
    </Card>,
    <MProfilePhotoCommunication profileProps={profilePropsII} />,
  ]
  return (
    <Media query='(max-width: 600px)'>
      {(matches) => {
        return matches ? mediaComponents[1] : mediaComponents[0]
      }}
    </Media>
  )
}

const WrappedProfilePhotoCommunication = withStyles(styles)(
  ProfilePhotoCommunication
)

export default WrappedProfilePhotoCommunication
