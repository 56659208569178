import React, { useContext, useState } from 'react'
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import MenuIcon from '@material-ui/icons/Menu'
import ModelDialogWrappingII from '../../../dialogModelUnit/ModelDialogWrappingII'
import { SettingsContext } from '../../../../context/SettingsContext/SettingsContext'
import { useTranslation } from 'react-i18next'
import SettingsDialogContent from '../../../settings/SettingsDialogContent'
import { AuthContext } from '../../../../context/AuthContext'
import cookies from 'js-cookie'
import { Settings, MenuBook } from '@material-ui/icons'
import { useNavigate } from 'react-router-dom'
import { MdDarkMode } from 'react-icons/md'
import { ExitToApp } from '@mui/icons-material'
import i18next from 'i18next'

const MQuickAccess = () => {
  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState(false)
  const settingsDialogOpener = () => {
    setIsSettingsDialogOpen(true)
  }
  const settingsDialogClose = () => {
    setIsSettingsDialogOpen(!isSettingsDialogOpen)
  }

  const { settings } = useContext(SettingsContext)
  const lang = i18next.language
  const rtl = lang === 'ar'
  const [isDarkModeActive, setIsDarkModeActive] = useState(
    settings?.Mode === 'Dark'
  )
  const history = useNavigate()
  const [clickAwayState, setClickAwayState] = useState(false)
  const { t } = useTranslation()
  const [isSwipeableOpen, setIsSwipeableOpen] = useState(false)

  const dialogsArray = [
    {
      title: t('Settings'),
      open: isSettingsDialogOpen,
      closeFun: () => settingsDialogClose(),
      icon: (
        <span>
          <IconButton
            edge='start'
            color='inherit'
            aria-label='open drawer'
            onClick={() => setIsSwipeableOpen(true)}
            sx={{ marginLeft: rtl && '10px' }}
          >
            <MenuIcon />
          </IconButton>
        </span>
      ),
      content: (
        <SettingsDialogContent
          isDarkModeActive={isDarkModeActive}
          isSwipeableOpen={isSwipeableOpen}
          setIsSwipeableOpen={setIsSwipeableOpen}
        />
      ),
    },
  ]

  const { dispatch, user } = useContext(AuthContext)
  const handleLogOut = () => {
    localStorage.removeItem('hutupia_settings')
    localStorage.removeItem('hutupia_user')
    cookies.remove('user', {
      domain: '.hutupia.com',
      expires: 7,
      secure: true,
    })
    dispatch({ type: 'LOGOUT' })
    history('/login')
  }

  const iconStyle = {
    fontSize: 25,
  }

  const menu = [
    {
      name: t('Settings'),
      icon: <Settings style={iconStyle} />,
      fun: settingsDialogOpener,
    },
    {
      name: t('Log_Out'),
      icon: <ExitToApp style={iconStyle} />,
      fun: handleLogOut,
    },
    {
      name: t('Library'),
      icon: <MenuBook style={iconStyle} />,
      fun: () => {
        window.location.href = 'https://library.hutupia.com'
      },
    },
  ]
  return (
    <>
      <List>
        {menu.map((item, i) => (
          <ListItem key={i} button onClick={item?.fun}>
            <ListItemIcon
              sx={{
                color: isDarkModeActive && 'white',
              }}
            >
              {item.icon}
            </ListItemIcon>
            <ListItemText>
              <span
                style={{
                  fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
                  fontWeight: 500,
                  fontSize: 18,
                }}
              >
                {item.name}
              </span>
            </ListItemText>
          </ListItem>
        ))}
      </List>
      <ModelDialogWrappingII
        inactivateClickAway={clickAwayState}
        dialogsArray={dialogsArray}
        setIsDarkModeActive={setIsDarkModeActive}
        isDarkModeActive={isDarkModeActive}
      />
    </>
  )
}

export default MQuickAccess
