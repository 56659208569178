import { Card, IconButton } from '@mui/material'
import React, { useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import { MdArrowLeft, MdArrowRight } from 'react-icons/md'
import Media from 'react-media'
import AlertMessg from '../../confirmationSystem/AlertMessg'
import i18next from 'i18next'

const styles = () => ({
  backIcon: {
    fontSize: 30,
    cursor: 'pointer',
  },

  buttonWrapper: {
    display: 'flex',
    width: '94.5%',
    overflowX: 'none',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: 30,
  },

  '@media (max-width: 600px)': {
    buttonWrapper: {
      // display: 'block',
      width: '100%',
      // position: 'sticky',
      bottom: 0,
      height: 20,
      // backgroundColor: 'white',
      // alignItems: 'center',
      marginBottom: 30,
      zIndex: 1,
    },
  },
  buttonCardWrapper: {
    display: 'flex',
    overflowX: 'none',
    justifyContent: 'space-between !important',
    position: 'absolute !important',
    alignContent: 'center !important',
    alignItems: 'center !important',
    width: '100% !important',
    bottom: 0,
    height: 50,
    backgroundColor: 'rgba(255, 255, 255, 0.7) !important',
    zIndex: 1,
  },
})

const StepperBottom = ({
  classes,
  handleEdit,
  activeLevel,
  setpperContent,
  handleNext,
  isDarkModeActive,
}) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false)

  const mediaComponents = [
    // pc view
    <span className={classes.buttonWrapper}>
      <IconButton
        edge='start'
        color='white'
        onClick={handleEdit}
        disabled={activeLevel === 0}
        style={{
          marginLeft: !rtl && 10,
          marginRight: rtl && 20,
          background: isDarkModeActive ? 'white' : 'gray',
        }}
      >
        {rtl ? (
          <MdArrowRight className={classes.backIcon} />
        ) : (
          <MdArrowLeft className={classes.backIcon} />
        )}
      </IconButton>
      <IconButton
        edge='end'
        color='white'
        onClick={() => {
          setpperContent[activeLevel].stepCondition
            ? handleNext()
            : setIsAlertDialogOpen(true)
        }}
        disabled={activeLevel === setpperContent.length}
        style={{
          marginLeft: rtl && 10,
          marginRight: !rtl && 20,
          background: isDarkModeActive ? 'white' : 'gray',
        }}
      >
        {rtl ? (
          <MdArrowLeft className={classes.backIcon} />
        ) : (
          <MdArrowRight className={classes.backIcon} />
        )}
      </IconButton>
    </span>,
    // mob
    <Card className={classes.buttonCardWrapper}>
      <IconButton
        edge='start'
        color='inherit'
        onClick={handleEdit}
        disabled={activeLevel === 0}
        style={{
          marginLeft: !rtl && 10,
          marginRight: rtl && 20,
          background: isDarkModeActive ? 'white' : 'gray',
        }}
      >
        {rtl ? (
          <MdArrowRight className={classes.backIcon} />
        ) : (
          <MdArrowLeft className={classes.backIcon} />
        )}
      </IconButton>
      <IconButton
        edge='end'
        color='inherit'
        onClick={() => {
          setpperContent[activeLevel].stepCondition
            ? handleNext()
            : setIsAlertDialogOpen(true)
        }}
        disabled={activeLevel === setpperContent.length}
        style={{
          marginLeft: rtl && 10,
          marginRight: !rtl && 20,
          background: isDarkModeActive ? 'white' : 'gray',
        }}
      >
        {rtl ? (
          <MdArrowLeft className={classes.backIcon} />
        ) : (
          <MdArrowRight className={classes.backIcon} />
        )}
      </IconButton>
    </Card>,
  ]
  return (
    <>
      <Media query='(max-width: 600px)'>
        {(matches) => {
          return matches ? mediaComponents[1] : mediaComponents[0]
        }}
      </Media>
      <AlertMessg
        alertText={
          rtl
            ? 'يجب ان تنهى الخطوة الحالية اولا'
            : 'You have to complete this step first'
        }
        setIsAlertDialogOpen={setIsAlertDialogOpen}
        isAlertDialogOpen={isAlertDialogOpen}
        isDarkModeActive={isDarkModeActive}
      />
    </>
  )
}

const WrappedStepperBottom = withStyles(styles)(StepperBottom)

export default WrappedStepperBottom
