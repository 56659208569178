import React, { useContext, useEffect, useState } from 'react'
import { BsFillChatFill } from 'react-icons/bs'
import PCChatDialog from '../chatSystem/PCChatDialog'
import MChatDialog from '../mobileViewComponents/MLeftbarComponents/ChatNotificationsIconsBarComponents/MChatComponents/MChatDialog'
import Media from 'react-media'
import { AuthContext } from '../../context/AuthContext'
import axios from 'axios'

const UserProfileChatIcon = (props) => {
  const {
    chatDialogOpener,
    isDarkModeActive,
    chattedUserId,
    isChatDialogOpen,
    pageProps,
  } = props
  const { setIsChatOpen } = pageProps
  const { user: currentUser } = useContext(AuthContext)
  const [currentChat, setCurrentChat] = useState()

  useEffect(() => {
    const fetchConversation = async () => {
      const res = await axios.get(
        `/conversations/usersConversation/${currentUser?._id}/${chattedUserId}`
      )
      setCurrentChat(res.data.conversation)
    }
    if (currentUser?._id && chattedUserId) fetchConversation()
  }, [currentUser?._id, chattedUserId, isChatDialogOpen])

  const mediaComponents = [
    <PCChatDialog
      {...props}
      isDarkModeActive2={isDarkModeActive}
      chat={currentChat}
    />,
    <MChatDialog
      {...props}
      isDarkModeActive2={isDarkModeActive}
      chat={currentChat}
    />,
  ]
  return (
    <>
      <BsFillChatFill
        style={{
          fontSize: 21,
          padding: 5,
          cursor: 'pointer',
        }}
        onClick={() => {
          chatDialogOpener()
          setIsChatOpen(true)
        }}
      />
      <Media query='(max-width: 600px)'>
        {(matches) => {
          return matches ? mediaComponents[1] : mediaComponents[0]
        }}
      </Media>
    </>
  )
}

export default UserProfileChatIcon
