import React, { Suspense, useContext, useEffect, useRef, useState } from 'react'
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import { AuthContext } from './context/AuthContext'
import BrokenLink from './pages/BrokenLink'
import LazyHutupiaCover from './pages/LazyHutupiaCover'
import ScrollToUp from './pages/ScrollToUp'
import DefaultLanguageSetter from './pages/DefaultLanguageSetter'
import DefaultLocationSetter from './pages/DefaultLocationSetter'
import PageDirection from './pages/PageDirection'
import moment from 'moment'
import { io } from 'socket.io-client'
import axios from 'axios'
import { SettingsContext } from './context/SettingsContext/SettingsContext'
import i18next from 'i18next'
import TranslationFilesNamesList from './pages/TranslationFilesNamesList'
import UserProjects from './pages/UserProjects'
import UserContributors from './pages/UserContributors'
import DarkModeColorCoder from './components/smallComponents/DarkModeColorCoder'
import PostAdsManagement from './pages/AdsManagementPageComps/PostAdsManagement'
import AdsExplorer from './pages/AdsManagementPageComps/AdsExplorer'
import AdsManagementHome from './pages/AdsManagementPageComps/AdsManagementHome'
import PrivacyPolicy from './pages/PrivacyPolicy'
import TermsOfService from './pages/TermsOfService'


const UserIdeas = React.lazy(() => import('./pages/UserIdeas'))
const UserGlobals = React.lazy(() => import('./pages/UserGlobals'))
const UserInquiries = React.lazy(() => import('./pages/UserInquiries'))
const UserResources = React.lazy(() => import('./pages/UserResources'))

const HomePage = React.lazy(() => import('./pages/HomePage'))
const ProjectsNewsPage = React.lazy(() => import('./pages/ProjectsNewsPage'))
const InvestmentsPage = React.lazy(() => import('./pages/InvestmentsPage'))
const GlobalInquiryFeed = React.lazy(() => import('./pages/GlobalInquiryFeed'))
const VisionsAndInquiriesFeed = React.lazy(() =>
  import('./pages/VisionsAndInquiriesFeed')
)
const SharedResourcesFeed = React.lazy(() =>
  import('./pages/SharedResourcesFeed')
)
const JobsPage = React.lazy(() => import('./pages/JobsPage'))
const Register = React.lazy(() => import('./pages/RegisterPage'))
const Login = React.lazy(() => import('./pages/LoginPage'))
const Profile = React.lazy(() => import('./pages/ProfilePage'))
const ExternalLinkPage = React.lazy(() => import('./pages/ExternalLinkPage'))
const VerifyEmailPage = React.lazy(() => import('./pages/VerifyEmailPage'))
const ResetPasswordPage = React.lazy(() => import('./pages/ResetPasswordPage'))
const ProjectProfilePage = React.lazy(() =>
  import('./pages/ProjectProfilePage')
)


const lang = i18next.language
const rtl = lang === 'ar'

async function arabic() {
  axios.defaults.baseURL = 'https://hutupia-backend.onrender.com/api/v1'

  if (rtl || document.body.dir === 'rtl') {
    await import('moment/locale/ar')
  } else if (!rtl || document.body.dir === 'ltr') {
    await moment.updateLocale('en')
  }
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['en', 'ar'],
    fallbackLng: 'en',
    detection: {
      // caches: ['cookie'],
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'path',
        'navigator',
        'subdomain',
        'htmlTag',
      ],
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/{{ns}}.json',
    },
    ns: TranslationFilesNamesList,
    defaultNS: 'translation',
  })

function App() {
  DefaultLanguageSetter()
  PageDirection()
  // RedirectToIntro
  // if the user in hitting '/' , !user and the user is not coming from somehwer (first visitor) then i will redirect to intro.hutupia.com
  const redirectToIntro = () => {
    if (pathname === '/' && !(location.state && location.state.from) && !user)
      window.location.href = `https://intro.hutupia.com`
  }

  const { user } = useContext(AuthContext)
  DefaultLocationSetter()
  const pathname = useLocation().pathname
  const navigate = useNavigate()
  const location = useLocation()
  const defaultHome = () => {
    if (pathname === '/' && !(location.state && location.state.from))
      navigate('/home')
  }

  useEffect(() => {
    defaultHome()
    redirectToIntro()
  }, [pathname])

  useEffect(() => {
    arabic()
  }, [document.body.dir, lang])

  const socket = useRef()
  useEffect(() => {
    socket.current = io('https://hutupia-socket.onrender.com')
  }, [user?._id])

  const { settings } = useContext(SettingsContext)
  const isDarkModeActive = settings?.Mode === 'Dark'

  useEffect(() => {
    document.body.style.background = isDarkModeActive
      ? '#18191A'
      : 'rgb(235, 240, 243)'
    document.body.style.color = isDarkModeActive ? DarkModeColorCoder('Text_white') : 'black'
  }, [isDarkModeActive])

  // keeping the previous url for anything rather than external link page
  const [previousURL, setPreviousURL] = useState()
  useEffect(() => {
    if (location.pathname !== '/login' && location.pathname !== '/register') {
      setPreviousURL(location.pathname)
    }
  }, [location.pathname])

  const UnAuthorised = <Navigate to='/login' />

  const props = {
    socket,
    isDarkModeActive,
    previousURL,
  }

  if (location.pathname !== '/sitemap.xml')
    return (
      <>
        <Suspense fallback={<LazyHutupiaCover />}>
          <Routes>
            <Route
              exact
              path='/home'
              element={user ? <HomePage {...props} /> : UnAuthorised}
            />
            {/* this will be intro page - tp be part of the system */}
            <Route
              exact
              path='/'
              element={user ? <HomePage {...props} /> : UnAuthorised}
            />
            <Route
              exact
              path='/login'
              element={
                user ? (
                  location.state && location.state.from ? (
                    <Navigate to={location.state.from} />
                  ) : previousURL ? (
                    <Navigate to={previousURL} />
                  ) : (
                    <Navigate to='/home' />
                  )
                ) : (
                  <Login {...props} />
                )
              }
            />
            {/* <Route
              exact
              path='/my-projects/:userId'
              element={user ? <UserProjects {...props} /> : UnAuthorised}
            /> */}
            {/* <Route
              exact
              path='/contributors/:userId'
              element={user ? <UserContributors {...props} /> : UnAuthorised}
            /> */}
            <Route exact path='/register' element={<Register {...props} />} />
            {/* limit the access and condition if the current user is the same as the userId otherwise it will be a private preview */}
            {/* <Route
              exact
              path='/ideas/:userId'
              element={user ? <UserIdeas {...props} /> : UnAuthorised}
            />
            <Route
              exact
              path='/inquiries/:userId'
              element={user ? <UserInquiries {...props} /> : UnAuthorised}
            />
            <Route
              exact
              path='/resources/:userId'
              element={user ? <UserResources {...props} /> : UnAuthorised}
            /> */}
            {/* <Route
              exact
              path='/globals/:userId'
              element={user ? <UserGlobals {...props} /> : UnAuthorised}
            /> */}
            <Route
              exact
              path='/profile/:userId'
              element={user ? <Profile {...props} /> : UnAuthorised}
            />
            <Route
              exact
              path='/ProjectProfile/:projectId'
              element={user ? <ProjectProfilePage {...props} /> : UnAuthorised}
            />
            <Route
              exact
              path='/jobs'
              element={user ? <JobsPage {...props} /> : UnAuthorised}
            />
            <Route
              exact
              path='/investments'
              element={user ? <InvestmentsPage {...props} /> : UnAuthorised}
            />
            <Route
              exact
              path='/globalVision'
              element={user ? <GlobalInquiryFeed {...props} /> : UnAuthorised}
            />
            <Route
              exact
              path='/VisionsAndInquiriesFeed'
              element={
                user ? <VisionsAndInquiriesFeed {...props} /> : UnAuthorised
              }
            />
            <Route
              exact
              path='/sharedResourcesFeed'
              element={user ? <SharedResourcesFeed {...props} /> : UnAuthorised}
            />
            <Route
              exact
              path='/projectsNews'
              element={user ? <ProjectsNewsPage {...props} /> : UnAuthorised}
            />
            <Route
              exact
              path='/reset-password/:token'
              element={<ResetPasswordPage {...props} />}
            />
            <Route
              exact
              path='/verify-email/:userId/:verificationCode'
              element={<VerifyEmailPage {...props} />}
            />
            <Route
              exact
              path='/ads_management/home'
              element={<AdsManagementHome {...props} />}
            />
            <Route
              exact
              path='/privacy-policy'
              element={<PrivacyPolicy {...props} />}
            />
            <Route
              exact
              path='/terms-of-service'
              element={<TermsOfService {...props} />}
            />
            <Route
              exact
              path='/ads_management/manage-ads/:adCampaignId'
              element={<PostAdsManagement {...props} />}
            />
            <Route
              exact
              path='/ads_management/manage-ads'
              element={<AdsExplorer {...props} />}
            />
            <Route
              exact
              path='/elements/:title/:elementId/:hashTagType'
              element={<ExternalLinkPage {...props} />}
            />
            <Route
              exact
              path='*'
              element={
                user ? <BrokenLink {...props} /> : <Register {...props} />
              }
            />
          </Routes>
        </Suspense>
        <ScrollToUp />
      </>
    )
}

export default App
