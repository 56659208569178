import React, { useEffect, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import { Link } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import FreeBar from '../../../skeleton/SkeletonComponents/FreeBar'
import DefaultImageGrapper from '../../../smallComponents/DefaultImageGrapper'
import { Card } from '@mui/material'
import i18next from 'i18next'

const styles = () => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
  },
  cardWrapper: {
    borderRadius: '10px !important',
    display: 'flex',
    justifyContent: 'space-around',
    alignContent: 'center',
    alignItems: 'center',
    margin: '6px !important',
    height: 200,
  },
  cardImage: {
    objectFit: 'cover',
    opacity: 0.4,
    width: '100%',
  },
  textWrapper: {
    zIndex: 1,
    position: 'absolute',
    fontSize: 20,
    backdropFilter: 'blur(5px)',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  '@media (max-width: 600px)': {
    textWrapper: {
      fontSize: 20,
    },
  },
})

const ProfileZeroPostModel = ({ postBodyProps, classes }) => {
  const { post, isDarkModeActive } = postBodyProps
  const lang = i18next.language
  const rtl = lang === 'ar'
  const [project, setProject] = useState()
  const [ceo, setCEO] = useState()
  useEffect(() => {
    const fetchNewProject = async () => {
      try {
        const res = await axios.get(`/projects/${post?.projectId}`)
        setProject({
          name: res.data.project.ProjectName,
          image: res.data.project.projectPhoto,
          userId: res.data.project.userId,
          _id: res.data.project._id,
          createdAt: res.data.project.createdAt,
        })
        if (res.data.project) {
          const resCEO = await axios.get(
            `/users/oneUser/${res.data.project?.userId}`
          )
          setCEO(resCEO.data.user)
        }
      } catch (error) {
        console.log(error)
      }
    }

    if (post?.projectId) fetchNewProject()
  }, [post?.projectId])
  const [profUser, setProfUser] = useState()
  useEffect(() => {
    const fetchProf = async () => {
      try {
        const res = await axios.get(`/users/oneUser/${post?.userId}`)
        setProfUser(res.data.user)
      } catch (error) {
        console.log(error)
      }
    }
    if (!post?.projectId) fetchProf()
  }, [post?.userId, post?.projectId])
  const loader = (
    <span style={{ width: 20 }}>
      <FreeBar height={20} />
    </span>
  )
  const content = [
    <span>
      <strong>
        {profUser?.firstName
          ? profUser?.firstName + ' ' + profUser?.lastName
          : loader}
      </strong>{' '}
      <span>
        {rtl
          ? `انضم لـ هيوتيوبيا ${moment(profUser?.createdAt).format(
              'MMM Do YYYY'
            )}`
          : `Joined Hutupia ${moment(profUser?.createdAt).format(
              'MMM Do YYYY'
            )}`}
      </span>
    </span>,
    <>
      <strong>{project?.name ? project?.name : loader}</strong>
      <span
        style={{
          fontStyle: 'oblique',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {rtl
          ? `تم الإنشاء ${moment(project?.createdAt).format(
              'MMM Do YYYY'
            )} بواسطة`
          : `has been established ${moment(project?.createdAt).format(
              'MMM Do YYYY'
            )} by`}{' '}
      </span>
      <Link
        to={`/profile/${ceo?._id}`}
        style={{
          color: isDarkModeActive ? 'white' : 'black',
          cursor: 'pointer',
        }}
      >
        <strong>
          {ceo?.firstName ? ceo?.firstName + ' ' + ceo?.lastName : loader}
        </strong>
      </Link>
    </>,
  ]
  return (
    <article className={classes.container}>
      <Card
        className={classes.cardWrapper}
        sx={{
          backgroundColor: isDarkModeActive
            ? 'rgba(255, 255, 255, 0.192)'
            : 'inherit',
          color: isDarkModeActive ? 'white' : 'inherit',
        }}
      >
        <img
          src={
            project?.image ||
            profUser?.coverPicture ||
            DefaultImageGrapper('noCover')
          }
          className={classes.cardImage}
          alt='Overview Image'
        />
        <span className={classes.textWrapper}>
          {post?.projectId ? content[1] : content[0]}
        </span>
      </Card>
    </article>
  )
}

const WrappedProfileZeroPostModel = withStyles(styles)(ProfileZeroPostModel)

export default WrappedProfileZeroPostModel
