import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { useState, useEffect, useRef } from 'react'
import Media from 'react-media'
import FolderPathComponent from './DialogModelUnitComponents/FolderPathComponent'
import DialogModelMobVersion from './DialogModelUnitComponents/DialogModelMobVersion'
import DialogModelPCVersion from './DialogModelUnitComponents/DialogModelPCVersion'
import WindowSize from '../smallComponents/WindowSize'
import i18next from 'i18next'

const styles = () => ({
  DialogModelUnitWrapper: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'row',
  },

  DialogModelUnitContent: {
    display: 'flex',
    flex: '4',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  DialogModelUnitDetails: {
    display: 'flex',
    flex: '10',
    borderRadius: '10px !important',
    padding: '2px 10px 10px 10px',
  },

  '@media (max-width: 600px)': {
    DialogModelUnitDetails: {
      display: 'block',
      borderRadius: '10px !important',
      padding: 0,
      height: '100%',
      overflowX: 'hidden',
      width: 'auto',
    },
    DialogModelUnitWrapper: {
      display: 'block',
    },
    DialogModelUnitContent: {
      display: 'flex',
      overflowX: 'hidden',
    },
  },
})

const DialogModelUnit = ({
  classes,
  DMUSystemProps,
  folderPathProps,
  searchSystemProps,
}) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const windowSize = WindowSize()
  const {
    DialogModelUnitMenu,
    DialogModelUnitContentArray,
    setMenuItemId,
    isDarkModeActive,
    hideDialogModelUnitMenu,
  } = DMUSystemProps

  const { showSearchBar, setSearchQuery, setSearchInputValue } =
    searchSystemProps
  const { secondLevelFolderPath, thirdLevelFolderPath, fourthLevelFolderPath } =
    folderPathProps
  // path directory title
  const [path, setPath] = useState('')
  const [DialogModelUnitMenuItemId, setDialogModelUnitMenuItemId] = useState(
    DialogModelUnitContentArray.length - 1
  )
  // control the scrolling bar
  const scrollRef = useRef(null)

  useEffect(() => {
    scrollRef.current?.scrollTo(0, 0)
  }, [
    DialogModelUnitMenuItemId,
    path,
    secondLevelFolderPath,
    thirdLevelFolderPath,
    fourthLevelFolderPath,
  ])

  const dialogModelUnitContentDirector = (event) => {
    setDialogModelUnitMenuItemId(Number(event.currentTarget.id))
    setPath(DialogModelUnitMenu[Number(event.currentTarget.id)])
  }
  useEffect(() => {
    if (typeof setMenuItemId === 'function')
      setMenuItemId(DialogModelUnitMenuItemId)
  }, [DialogModelUnitMenuItemId])
  useEffect(() => {
    if (typeof setSearchQuery === 'function') setSearchQuery('')
  }, [DialogModelUnitMenuItemId])
  useEffect(() => {
    if (typeof setSearchInputValue === 'function') setSearchInputValue('')
  }, [DialogModelUnitMenuItemId])

  const mediaComponents = [
    <DialogModelMobVersion
      DMUSystemProps={DMUSystemProps}
      DialogModelUnitMenuItemId={DialogModelUnitMenuItemId}
      dialogModelUnitContentDirector={dialogModelUnitContentDirector}
    />,
    <DialogModelPCVersion
      DMUSystemProps={DMUSystemProps}
      DialogModelUnitMenu={DialogModelUnitMenu}
      DialogModelUnitMenuItemId={DialogModelUnitMenuItemId}
      dialogModelUnitContentDirector={dialogModelUnitContentDirector}
    />,
  ]

  return (
    <article className={classes.DialogModelUnitWrapper}>
      <Media query='(max-width: 1000px)'>
        {(matches) => {
          return matches ? mediaComponents[0] : mediaComponents[1]
        }}
      </Media>
      <section
        className={classes.DialogModelUnitContent}
        id={'dialog-model-unit'}
        ref={scrollRef}
      >
        {!hideDialogModelUnitMenu && (
          <FolderPathComponent
            path={path}
            folderPathProps={folderPathProps}
            searchSystemProps={searchSystemProps}
            //
            setDialogModelUnitMenuItemId={setDialogModelUnitMenuItemId}
            DialogModelUnitMenuItemId={DialogModelUnitMenuItemId}
            DialogModelUnitContentArray={DialogModelUnitContentArray}
            // this conditioning is to remove the search bar in the default view, in the sections where the search bar appears in the 2nd folder path, not the file system for example
            showSearchBar={
              DialogModelUnitMenuItemId ===
              DialogModelUnitContentArray.length - 1
                ? false
                : showSearchBar
            }
            DMUSystemProps={DMUSystemProps}
          />
        )}
        <div
          className={classes.DialogModelUnitDetails}
          style={{
            color: isDarkModeActive ? 'white' : 'inherit',
            margin:
              windowSize > 600
                ? rtl
                  ? '2px 2px 10px 15px'
                  : '2px 15px 10px 2px'
                : 0,
          }}
        >
          {DialogModelUnitContentArray[DialogModelUnitMenuItemId]}
        </div>
      </section>
    </article>
  )
}

const WrappedDialogModelUnit = withStyles(styles)(DialogModelUnit)

export default WrappedDialogModelUnit
