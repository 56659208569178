import React from 'react'
import { useNavigate } from 'react-router-dom'
import i18next from 'i18next'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  logoTitle: {
    fontSize: '2rem',
    fontWeight: 'bold',
    fontFamily: "'Inter', sans-serif",
    color: 'rgb(0, 119, 181)',
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer',
    margin: 'auto 20px',
  },
}))

const MTopBarLogo = ({ isDarkModeActive }) => {
  const lang = i18next.language
  const history = useNavigate()
  const classes = useStyles()
  return (
    <div
      className={classes.logoTitle}
      style={{ color: isDarkModeActive ? 'white' : 'rgb(0, 119, 181)' }}
      onClick={() => history('/home')}
    >
      {lang === 'ar' ? 'هيوتيوبيا' : 'hutupia'}
    </div>
  )
}

export default MTopBarLogo
