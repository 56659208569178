import { Card, Grid } from '@mui/material'
import React, { useContext } from 'react'
import withStyles from '@mui/styles/withStyles'
import { SettingsContext } from '../../../../../context/SettingsContext/SettingsContext'
import DarkModeColorCoder from '../../../../smallComponents/DarkModeColorCoder'

const styles = () => ({
  cardContainer: {
    height: '50%',
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  cardWrapper: {
    width: '100% !important',
    height: '97% !important',
    borderRadius: '15px !important',
    display: 'flex',
    justifyContent: 'center !important',
    alignContent: 'center !important',
    alignItems: 'center !important',
  },
  cardImage: {
    width: '100%',
    objectFit: 'cover',
    opacity: 0.5,
  },
  content: {
    zIndex: 1,
    color: 'white',
    position: 'absolute',
  },
  '@media (max-width: 600px)': {
    cardWrapper: {
      width: '100% !important',
      height: '50px!important',
      borderRadius: '0px !important',
      display: 'flex',
      justifyContent: 'center !important',
      alignContent: 'center !important',
      alignItems: 'center !important',
    },
  },
})

const OverviewCardModel = ({ card, classes }) => {
  const PF = process.env.REACT_APP_PUBLIC_FOLDER
  const { settings } = useContext(SettingsContext)
  const isDarkModeActive = settings?.Mode === 'Dark'
  return (
    <Grid item xs={6} className={classes.cardContainer}>
      <Card
        className={classes.cardWrapper}
        sx={{
          margin: card.margins,
        }}
        style={{
          background: isDarkModeActive
            ? DarkModeColorCoder('Grey_light')
            : DarkModeColorCoder('Blue_gradient_transp'),
        }}
      >
        <img
          src={`${PF}introVector/expand1.avif`}
          className={classes.cardImage}
          alt='Overview Image'
        />
        <span className={classes.content}>{card.content}</span>
      </Card>
    </Grid>
  )
}

const WrapepdOverviewCardModel = withStyles(styles)(OverviewCardModel)

export default WrapepdOverviewCardModel
