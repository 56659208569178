import React from 'react'
import { Button } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import i18next from 'i18next'

const styles = () => ({
  button: {
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    fontWeight: '550 !important',
    width: '100% !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
})

const NewProjectButtons = ({ classes, createProjectHandler, isLoading }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <Button
      disabled={isLoading}
      className={classes.button}
      variant='outlined'
      onClick={createProjectHandler}
    >
      {isLoading
        ? rtl
          ? 'يتم الانشاء...'
          : 'Creating...'
        : rtl
        ? 'انشئ'
        : 'Create'}
    </Button>
  )
}

const WrappedNewProjectButtons = withStyles(styles)(NewProjectButtons)

export default WrappedNewProjectButtons
