import { Grid } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import ImageViewer from '../../../../../../../postSystem/ImagesSystemComponents/ImageViewer'
import BlocksLoader from '../../../../../../../smallComponents/BlocksLoader'
import WindowProps from '../../../../../../../windowViewer/WindowProps'

const ChatImages = ({ chat, isDarkModeActive }) => {
  const { isWindowOpen, windowOpener, windowCloser } = WindowProps()
  const [chatUrls, setChatUrls] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const fetchUrls = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(`/urls/chat/${chat?._id}/?gallery=true`)
        setChatUrls(res.data.urls)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (chat?._id) fetchUrls()
  }, [chat?._id])
  
  return isLoading ? (
    <BlocksLoader isDarkModeActive={isDarkModeActive} />
  ) : (
    <Grid container>
      {chatUrls?.map((image, i) => (
        <Grid item lg={6} key={image}>
          <img
            src={image}
            alt='chat'
            onClick={windowOpener}
            style={{ objectFit: 'cover', width: '95%' }}
          />
        </Grid>
      ))}
      <ImageViewer
        imageViewerDialogClose={windowCloser}
        isImageViewerDialogOpen={isWindowOpen}
        imagesArray={chatUrls}
        isDarkModeActive={isDarkModeActive}
        viewedImageIndex={0}
      />
    </Grid>
  )
}

export default ChatImages
