import React, { useState } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import withStyles from '@mui/styles/withStyles'
import SearchSystem from '../../searchSystem/SearchSystem'
import FormsComponents from '../../formsSystem/FormsComponents'
import { useTranslation } from 'react-i18next'

const styles = () => ({
  searchBar: {
    width: '100%',
    height: 30,
    backgroundColor: 'transparent',
    borderRadius: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    opacity: 1,
    '&:hover': {
      opacity: 0.8,
    },
    alignContent: 'center',
  },
  searchIcon: {
    fontSize: '20px !important',
    margin: '2px 10px -2px 10px',
    cursor: 'pointer',
  },
  searchInput: {
    border: 'none',
    width: '75%',
    background: 'transparent',
    '&:focus': {
      outline: 'none',
      opacity: 0.8,
    },
    '&::placeholder': {
      color: 'rgb(26, 23, 23)',
      // fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
      fontWeight: 200,
      fontSize: 13.2,
    },
  },
})

const Search = ({ classes, searchMargins, pageProps, isDarkModeActive }) => {
  const { t } = useTranslation()
  const [searchQuerry, setSearchQuerry] = useState('')

  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false)
  const searchDialogCOpener = () => {
    setIsSearchDialogOpen(true)
  }

  const searchDialogClose = () => {
    setIsSearchDialogOpen(false)
  }

  const formsCompsProps = {
    formComponentIndex: 2,
    textContent: searchQuerry,
    setSearchQuery: setSearchQuerry,
    setTextContent: setSearchQuerry,
    searchInputClass: classes.searchInput,
    isDarkModeActive,
    placeholder: t('general_search_placeholder_v1'),
    onKeyDown: (e) => {
      if (e.key === 'Enter') searchDialogCOpener()
    },
  }

  return (
    <>
      <div className={classes.searchBar} style={{ margin: searchMargins }}>
        <SearchIcon
          className={classes.searchIcon}
          onClick={searchDialogCOpener}
          style={{ color: isDarkModeActive && 'black' }}
        />
        <FormsComponents formsCompsProps={formsCompsProps} />
      </div>
      <SearchSystem
        isSearchDialogOpen={isSearchDialogOpen}
        searchDialogClose={searchDialogClose}
        searchQuerry={searchQuerry}
        placeholder={t('general_search_placeholder_v1')}
        {...pageProps}
      />
    </>
  )
}

const WrappedSearch = withStyles(styles)(Search)

export default WrappedSearch
