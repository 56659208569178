import React from 'react'
import NotifsPopoverCompModel from './NotifsPopoverCompModel'
import { useTranslation } from 'react-i18next'
import ContractNotifListItem from '../../../notificationsSystem/NotificationsComponents/ContractsNotificationsComponents/ContractsNotifsDialogLeftComponents/ContractNotifListItem'

const ContractsNotifsPopoverComp = (props) => {
  const { t } = useTranslation()
  const {
    dealsNotificationsSenders,
    setContractPage,
    dealsNotifications,
    dealsNotificationsProjects,
    contractPage,
    contractsCurrentResponse,
  } = props
  const pps = {
    ...props,
    notificationType: t('Contracts_and_Deals'),
    data: dealsNotifications,
    InteriorCardComponent: ContractNotifListItem,
    cardModelIndex: 1,
    notificationComponentIndex: 1,
    senders: dealsNotificationsSenders,
    projects: dealsNotificationsProjects,
    setPage: setContractPage,
    page: contractPage,
    currentResponse: contractsCurrentResponse,
  }
  return <NotifsPopoverCompModel {...pps} />
}

export default ContractsNotifsPopoverComp
