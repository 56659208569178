import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import React, { useContext, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Settings, ExitToApp } from '@material-ui/icons'
import { MdDarkMode } from 'react-icons/md'
import PopoverModel from '../../../smallComponents/PopoverModel'
import cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../../../context/AuthContext'
import SettingsDialogContent from '../../../settings/SettingsDialogContent'
import ModelDialogWrappingII from '../../../dialogModelUnit/ModelDialogWrappingII'
import { SettingsContext } from '../../../../context/SettingsContext/SettingsContext'

const useStyles = makeStyles(() => ({
  contentWrapper: {
    width: '350px',
    height: '500px',
    overflowY: 'auto',
  },
}))

const TopBarProfileAvatarPopOver = (props) => {
  const { settings } = useContext(SettingsContext)
  const [isDarkModeActive, setIsDarkModeActive] = useState(
    settings?.Mode === 'Dark'
  )
  const [clickAwayState, setClickAwayState] = useState(false)
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useNavigate()
  const { dispatch, user } = useContext(AuthContext)

  const handleLogOut = () => {
    localStorage.removeItem('hutupia_settings')
    localStorage.removeItem('hutupia_user')
    cookies.remove('user', {
      domain: '.hutupia.com',
      expires: 7,
      secure: true,
    })
    dispatch({ type: 'LOGOUT' })
    history('/login')
  }

  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState(false)
  const settingsDialogOpener = () => {
    setIsSettingsDialogOpen(true)
  }
  const settingsDialogClose = () => {
    setIsSettingsDialogOpen(!isSettingsDialogOpen)
  }

  const dialogsArray = [
    {
      title: t('Settings'),
      open: isSettingsDialogOpen,
      closeFun: () => settingsDialogClose(),
      content: (
        <SettingsDialogContent
          isDarkModeActive={isDarkModeActive}
          setClickAwayState={setClickAwayState}
        />
      ),
    },
  ]

  const profileMenuItems = [
    {
      name: t('Settings'),
      icon: <Settings />,
      fun: settingsDialogOpener,
    },
    {
      name: t('Dark_Mode'),
      icon: <MdDarkMode />,
      fun: '',
    },
    {
      name: t('Log_Out'),
      icon: <ExitToApp />,
      fun: handleLogOut,
    },
  ]

  // it will open the settings in the same view or notice how facebook does it
  return (
    <>
      <PopoverModel
        {...props}
        PopoverComponent={
          <div className={classes.contentWrapper}>
            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <Avatar src={user?.profilePicture} />
              </ListItemIcon>
              <ListItemText>
                <Typography variant='subtitle1'>{`${user.firstName} ${user.lastName}`}</Typography>
              </ListItemText>
            </ListItem>
            <Divider />
            <List>
              {profileMenuItems.map((item, index) => (
                <ListItem
                  key={index}
                  button
                  className={classes.listItem}
                  onClick={item.fun}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText
                    style={{
                      fontFamily:
                        '"Segoe UI Historic", Segoe UI, Helvetica, Arial, sans-serif',
                    }}
                  >
                    {item.name}
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </div>
        }
        anchorOriginVertical='bottom'
        anchorOriginHorizontal='left'
        transformOriginVertical='bottom'
        transformOriginHorizontal='left'
      />
      <ModelDialogWrappingII
        dialogsArray={dialogsArray}
        setIsDarkModeActive={setIsDarkModeActive}
        isDarkModeActive={isDarkModeActive}
        inactivateClickAway={clickAwayState}
      />
    </>
  )
}

export default TopBarProfileAvatarPopOver
