import React, { useContext, useEffect, useState } from 'react'
import GlobalInquiryProfile from './MyGlobalInquriesComponents/GlobalInquiryProfile'
import NewGlobalInquiryForm from './MyGlobalInquriesComponents/NewGlobalInquiryForm'
import GeneralPortalSystem from '../../../../../generalPortalSystem/GeneralPortalSystem'
import { AuthContext } from '../../../../../../context/AuthContext'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

const MyGlobalInquries = (props) => {
  const { user } = useContext(AuthContext)
  const [sharedPostConfigs, setSharedPostConfigs] = useState(null)
  const lang = i18next.language
  const rtl = lang === 'ar'
  useEffect(() => {
    const addNewGlobalInquiryPost = async () => {
      try {
        await axios.patch(
          `/globalInquiries/globalInquiryPosts/addSharedPost/${sharedPostConfigs?.element?._id}`,
          { postId: sharedPostConfigs?.postId }
        )
      } catch (error) {
        console.log(error)
      }
    }
    if (sharedPostConfigs?.element?._id && sharedPostConfigs?.postId)
      addNewGlobalInquiryPost()
  }, [sharedPostConfigs?.element?._id, sharedPostConfigs?.postId])
  const { t } = useTranslation()
  const propsII = {
    ...props,
    elementProfileProps: { hideCreator: true },
    noFSPanelPropsFirstView: {},
    cardsHeaderText: t('My_Global_Inquiries'),
    noFSPanelPropsSecondView: {},
    placeholderValue: t('Global_Inquiries'),
    NewElementForm: NewGlobalInquiryForm,
    ElementProfile: GlobalInquiryProfile,
    dialogTitle: t('Global_Inquiry'),
    cardMarkProp: 'globalInquiryStatus',
    noFSPanelPropsSecondView: {
      showDeleteIcon: true,
      showPDFDownloader: false,
      deleteTippy: `${t('Delete')} ${t('Global_Inquiry')}`,
      confirmmationTitles: [
        { title: `${t('Global_Inquiry')} ${t('has_been_deleted')}` },
      ],
    },
    setSharedPostConfigs,
    deletePath: 'globalinquiries',
    cardsPropName: 'globalInquiries',
    getPath: `globalinquiries/userGlobalInquiries/${user._id}`,
    sect_sub_ind: 'global inquiry',
    initialContent: rtl
      ? 'فى قسم "رؤاى العالمية" يمكنك التحكم فى جميع مواردك. كما يمكنك الاضافة و التحديث أو المسح بالإضافة للتعامل مع طلبات المشاركة'
      : 'In "My Global Inquiries" section you can control all your global inquiries, like creating new ones, deleting or updating others. You may also accept contribution requests.',
  }
  return <GeneralPortalSystem {...propsII} />
}

export default MyGlobalInquries
