import React from 'react'
import withStyles from '@mui/styles/withStyles'
import DarkModeColorCoder from '../../../../smallComponents/DarkModeColorCoder'

const styles = () => ({
  suggestCardWrapper: {
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
})

const ProjectsChartsNavigatorMob = ({ classes, units, isDarkModeActive }) => {
  return (
    <section
      className={classes.suggestCardWrapper}
      sx={{
        color: isDarkModeActive ? 'white' : null,
        background: isDarkModeActive ? DarkModeColorCoder('Grey_light') : null,
      }}
    >
      {units}
    </section>
  )
}

const WrappedProjectsChartsNavigatorMob = withStyles(styles)(
  ProjectsChartsNavigatorMob
)

export default WrappedProjectsChartsNavigatorMob
