import React, { useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import { MdClose } from 'react-icons/md'
import { Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

const styles = () => ({
  wrapper: {
    width: '100%',
    margin: '5px auto',
    padding: 10,
    borderRadius: '0px 10px 10px 10px',
    backgroundColor: 'rgba(41, 65, 74, 0.336)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  closeIcon: {
    display: 'flex',
    alignSelf: 'flex-end',
    cursor: 'pointer',
    fontSize: 20,
    '&:hover': {
      fontSize: 25,
    },
  },
})

const ElementInfoCard = ({ classes, sect_sub_ind, initialContent }) => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const [hide, setHide] = useState(false)
  const { t } = useTranslation()
  const directToLibrary = () => {
    window.location.href = `https://library.hutupia.com/${sect_sub_ind}`
  }
  return (
    <article
      className={classes.wrapper}
      style={{ display: hide ? 'none' : 'flex' }}
    >
      <Tooltip title={rtl ? 'إخفاء' : 'Hide'} placement='top'>
        <span className={classes.closeIcon}>
          <MdClose onClick={() => setHide(true)} />
        </span>
      </Tooltip>
      <p>
        {initialContent}
        <span
          style={{
            marginLeft: !rtl && 5,
            marginRight: rtl && 5,
            cursor: 'pointer',
          }}
        >
          <strong
            style={{ pointer: 'cursor' }}
            onClick={() => directToLibrary()}
          >
            {t('Learn_More')}
          </strong>
        </span>
      </p>
    </article>
  )
}

const WrappedElementInfoCard = withStyles(styles)(ElementInfoCard)

export default WrappedElementInfoCard
