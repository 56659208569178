import React, { useContext, useEffect, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import ImageUpdator from '../../uploadImageSystem/ImageUpdator'
import PostProps from '../../homeFeed/homeFeedComponents/HomeFeedPostModelComponents/PostProps'
import ImagesSystem from '../../postSystem/ImagesSystem'
import { AuthContext } from '../../../context/AuthContext'
import axios from 'axios'
import CustomCircularProgress from '../../smallComponents/CustomCircularProgress'

const styles = () => ({
  profileUserImage: {
    width: '150px',
    height: '150px',
    borderRadius: '50%',
    objectFit: 'cover',
    border: '2px solid white',
    margin: '10px 10px 0 10px',
    position: 'relative',
    display: 'block',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0px 0px 4px 3px rgba(105, 112, 112, 0.2)',
    },
  },
})

const MProfilePhoto = ({ classes, user, isDarkModeActive }) => {
  const PF = process.env.REACT_APP_PUBLIC_FOLDER
  const { user: currentUser, dispatch } = useContext(AuthContext)
  const [image, setImage] = useState(
    user?.profilePicture || `${PF}person/noAvatar.png`
  )
  const {
    imageViewerDialogOpener,
    imageViewerDialogClose,
    isImageViewerDialogOpen,
  } = PostProps(
    {},
    {
      imageViewerProps: true,
    }
  )

  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    const updateProfilePicture = async () => {
      setIsLoading(true)
      try {
        const res = await axios.patch(`/users/${user?._id}`, {
          profilePicture: image,
          userId: currentUser?._id,
        })
        dispatch({
          type: 'UPDATE',
          payload: res.data.user,
        })
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (image && image !== user?.profilePicture) updateProfilePicture()
  }, [image])
  const ResizerProps = {
    maxWidth: 1000,
  }
  return (
    <span
      style={{
        position: 'absolute',
        top: 160,
      }}
    >
      <ImageUpdator
        setImage={setImage}
        bottom={20}
        right={20}
        pathName={`/users/${user?._id}`}
        hide={user?._id !== currentUser?._id}
        ResizerProps={ResizerProps}
        updateProps={{
          path: `/users/${user?._id}`,
          payload: { profilePicture: '', userId: user?._id },
        }}
        remImage={image}
      />
      <img
        className={classes.profileUserImage}
        src={image}
        alt={user?.firstName}
        onClick={imageViewerDialogOpener}
      />
      <ImagesSystem
        imageViewer={true}
        imagesArray={[image]}
        imageViewerDialogClose={imageViewerDialogClose}
        isImageViewerDialogOpen={isImageViewerDialogOpen}
        isDarkModeActive={isDarkModeActive}
      />
    </span>
  )
}

const WrappedMProfilePhoto = withStyles(styles)(MProfilePhoto)

export default WrappedMProfilePhoto
