import React, { useContext } from 'react'
import NoChatMessagesDefaultView from '../../../../../../../chatSystem/ChatSystemComponents/NoChatMessagesDefaultView'
import CustomCircularProgress from '../../../../../../../smallComponents/CustomCircularProgress'
import RepetionRemover from '../../../../../../../smallComponents/RepetionRemover'
import MUserMessage from './MUserMessage'
import MReplyMessage from './MReplyMessage'
import { AuthContext } from '../../../../../../../../context/AuthContext'

const MMessagesFlow = ({
  chat,
  isDarkModeActive,
  pageProps,
  isLoading,
  chatMessages,
  page,
  mssgScroll,
}) => {
  const { socket } = pageProps
  const { user } = useContext(AuthContext)
  const isMainUser = (msg) => {
    return msg?.senderId === user?._id
  }
  return isLoading && page === 1 ? (
    <CustomCircularProgress />
  ) : chatMessages.length > 0 ? (
    RepetionRemover(chatMessages)
      ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      .map((msg, msgIndex) =>
        isMainUser(msg) ? (
          <MUserMessage
            msg={msg}
            key={msgIndex}
            isDarkModeActive={isDarkModeActive}
            mssgScroll={mssgScroll}
            socket={socket}
          />
        ) : (
          <MReplyMessage
            msg={msg}
            key={msgIndex}
            isDarkModeActive={isDarkModeActive}
            mssgScroll={mssgScroll}
            socket={socket}
          />
        )
      )
  ) : (
    <NoChatMessagesDefaultView
      isDarkModeActive={isDarkModeActive}
      pageProps={pageProps}
      chat={chat}
    />
  )
}

export default MMessagesFlow
