import React from 'react'
import PostProps from '../../../../homeFeed/homeFeedComponents/HomeFeedPostModelComponents/PostProps'
import ImagesSystem from '../../../../postSystem/ImagesSystem'
import TextEngine from '../../../../textProcessingEngine/TextEngine'
import LinksPlayerSystem from '../../../../postSystem/LinksPlayerSystem'
import WindowSize from '../../../../smallComponents/WindowSize'

const MessageCore = ({ isDarkModeActive, msg, socket }) => {
  const windowSize = WindowSize()
  const {
    imageViewerDialogOpener,
    imageViewerDialogClose,
    isImageViewerDialogOpen,
  } = PostProps({}, { imageViewerProps: true })
  return (
    <>
      <TextEngine
        textContent={msg?.msgContent || msg?.text}
        textPackage={msg}
        textFuns=''
        isDarkModeActive={isDarkModeActive}
        socket={socket}
      />
      <div
        style={{
          width: msg?.playerLink && (windowSize > 600 ? '30vw' : '70vw'),
        }}
      >
        <LinksPlayerSystem url={msg?.playerLink} />
      </div>
      <ImagesSystem
        imagesArray={msg?.msgImages || msg?.images}
        openFun={() => {
          if (typeof imageViewerDialogOpener === 'function')
            imageViewerDialogOpener()
        }}
      />
      <ImagesSystem
        imageViewer={true}
        imageViewerDialogClose={imageViewerDialogClose}
        isImageViewerDialogOpen={isImageViewerDialogOpen}
        imagesArray={msg?.msgImages}
        commentingSystemView={true}
        isDarkModeActive={isDarkModeActive}
      />
    </>
  )
}

export default MessageCore
