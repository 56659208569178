import React, { useState } from 'react'
import ChatAttachSwitchingButtons from './ChatAttatchmentsDialogContentComps/ChatAttachSwitchingButtons'
import ChatImages from './ChatAttatchmentsDialogContentComps/ChatImages'
import ChatElements from './ChatAttatchmentsDialogContentComps/ChatElements'

const ChatAttatchmentsDialogContent = (props) => {
  const [viewIndex, setViewIndex] = useState(0)
  return (
    <div>
      <ChatAttachSwitchingButtons
        viewIndex={viewIndex}
        setViewIndex={setViewIndex}
      />
      <section style={{ width: '100%', flexFlow: 'row wrap', display: 'flex' }}>
        {viewIndex === 0 ? (
          <ChatImages {...props} />
        ) : (
          <ChatElements {...props} />
        )}
      </section>
    </div>
  )
}

export default ChatAttatchmentsDialogContent
