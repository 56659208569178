import React from 'react'

const LeftbarLinks = () => {
  return (
    <div>
      <ul>
        <li>Library/Guide</li>
        <li>About Us </li>
        <li>Contact Us </li>
        <li>Terms of Use and Privacy</li>
      </ul>
    </div>
  )
}

export default LeftbarLinks
