import { Button, Card } from '@mui/material'
import React from 'react'
import withStyles from '@mui/styles/withStyles'
import i18next from 'i18next'

const styles = () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  accessCard: {
    width: '45%',
    margin: '2.5%',
    padding: '2.5%',
  },
  instructionsCard: {
    width: '45%',
    margin: '2.5%',
    padding: '2.5%',
    backgroundColor: 'rgba(71, 71, 124, 0.226) !important',
  },
  instText: {
    fontSize: 15,
  },
  button: {
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    fontWeight: '550 !important',
    width: '100% !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
  '@media (max-width: 600px)': {
    wrapper: {
      flexDirection: 'column',
    },
    accessCard: {
      width: '90%',
    },
    instructionsCard: {
      width: '90%',
    },
  },
})

const NewProjectFrontView = ({ classes, StepperComponent, props }) => {
  const { cardId, setCardId } = props
  const lang = i18next.language
  const rtl = lang === 'ar'
  const instructions = [
    'Being able to represent your idea as actual project',
    'To be able to get invetment and development',
    'To organise your team or to build one',
    'To manage your tasks and products',
    'To create your business plan',
    'To create your business model',
    'To manage your project departments',
    'To create new business channels with other projects',
    'To establish new contracts for new resources and development',
    'To build a great portfolio for your passion',
    'To be able to share the progress of your project and sales',
    'To manage even members who are no-connected to HUtupia',
  ]

  const instructions_ar = [
    'القدرة على تمثيل فكرتك كمشروع حقيقي',
    ' لتكون قادرًا على الحصول على الاستثمار و التطوير',
    'لتنظيم فريقك أو لبناء فريق',
    'لإدارة مهامك ومنتجاتك',
    'لإنشاء خطة عملك',
    'لإنشاء نموذج عملك',
    'لإدارة أقسام المشروع',
    'لإنشاء قنوات عمل جديدة مع مشاريع أخرى',
    'لتأسيس عقود جديدة للموارد الجديدة والتنمية',
    'لبناء محفظة كبيرة من أجل شغفك',
    ' لتكون قادرًا على مشاركة تقدم مشروعك ومبيعاتك',
    'لإدارة حتى الأعضاء غير المرتبطين بـ HUtupia',
  ]

  return (
    <>
      <article className={classes.wrapper}>
        <Card className={classes.accessCard}>
          <Button
            variant={'contained'}
            onClick={() => setCardId(1)}
            className={classes.button}
          >
            {rtl ? 'ابدأ في بناء مشروعك' : 'Start Building Your Project'}
          </Button>
          <h6>
            {rtl
              ? 'إن إنشاء مشروع جديد هو الهدف النهائي لـ هيوتيوبيا ، سيسمح لك المشروع بالقيام بما يلي:'
              : ' Creating a new Project is the ultimate goal of Hutupia, a project will allow you to do the following:'}
          </h6>
          <ul>
            {(rtl ? instructions_ar : instructions)
              .slice(0, 4)
              .map((inst, index) => (
                <li className={classes.instText} key={index}>
                  {inst}
                </li>
              ))}
          </ul>
        </Card>
        <Card className={classes.instructionsCard} sx={{ color: 'white' }}>
          <ul>
            {(rtl ? instructions_ar : instructions)
              .slice(5, 11)
              .map((inst, index) => (
                <li className={classes.instText} key={index}>
                  {inst}
                </li>
              ))}
          </ul>
        </Card>
      </article>
    </>
  )
}

const WrappedNewProjectFrontView = withStyles(styles)(NewProjectFrontView)

export default WrappedNewProjectFrontView
