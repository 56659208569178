import React from 'react'

const GlassLayer = ({ style, GlassComponent, onClick, active }) => {
  return (
    <section
      style={{
        zIndex: 1,
        height: '100%',
        width: '100%',
        opacity: 0,
        position: 'absolute',
        bottom: 0,
        display: active ? null : 'none',
        ...style,
      }}
      onClick={onClick}
    >
      {GlassComponent}
    </section>
  )
}

export default GlassLayer
