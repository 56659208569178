import React, { useContext } from 'react'
import { Card } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { useState } from 'react'
import ProjectDepartments from './NewProjectMembersComponents/ProjectDepartmentsArrays'
import { Tooltip } from '@mui/material'
import NewProjectMultiSelectComp from './NewProjectMembersComponents/NewProjectMultiSelectComp'
import { useEffect } from 'react'
import { AuthContext } from '../../../../context/AuthContext'
import axios from 'axios'
import i18next from 'i18next'

const styles = () => ({
  boardWrapper: {
    width: '100%',
    height: 'auto',
  },
  departmentCard: {
    display: 'flex',
    height: 'auto',
    flexDirection: 'column',
    width: 'auto',
    padding: '10px',
    margin: '10px',
    borderRadius: '10px !important',
    backgroundColor: 'rgba(217,224,252, 0.2) !important',
  },

  departmentName: {
    display: 'flex',
    fontSize: 16,
    fontWeight: 500,
    alignSelf: 'center',
    margin: 10,
  },
})

const NewProjectMembers = ({
  classes,
  setProjectContributors,
  projectContributors,
  dataHistory,
  isDarkModeActive,
}) => {
  useEffect(() => {
    if (dataHistory?.current)
      dataHistory.current.projectContributors = projectContributors
  }, [projectContributors?.length])
  const { user } = useContext(AuthContext)
  const [contributors, setContributors] = useState([])
  useEffect(() => {
    const fetchUserContributors = async () => {
      try {
        const res = await axios.get(
          `/users/interactions/validContributors/${user?._id}`
        )
        setContributors(res.data.validContributors)
      } catch (error) {}
    }
    fetchUserContributors()
  }, [user?._id])
  const lang = i18next.language
  const rtl = lang === 'ar'

  const [selectedProductionContributors, setSelectedProductionContributors] =
    useState([])
  const [selectedMarketingContributors, setSelectedMarketingContributors] =
    useState([])
  const [
    selectedAdminstrationContributors,
    setSelectedAdminstrationContributors,
  ] = useState([])
  const [selectedFinancialContributors, setSelectedFinancialContributors] =
    useState([])
  const [selectedDevelopContributors, setSelectedDevelopContributors] =
    useState([])
  const [selectedLegalContributors, setSelectedLegalContributors] = useState([])
  useEffect(() => {
    setProjectContributors([
      { departmentId: 0, contributors: selectedProductionContributors },
      { departmentId: 1, contributors: selectedMarketingContributors },
      { departmentId: 2, contributors: selectedAdminstrationContributors },
      { departmentId: 3, contributors: selectedDevelopContributors },
      { departmentId: 4, contributors: selectedFinancialContributors },
      { departmentId: 5, contributors: selectedLegalContributors },
    ])
  }, [
    selectedProductionContributors,
    selectedMarketingContributors,
    selectedAdminstrationContributors,
    selectedFinancialContributors,
    selectedLegalContributors,
  ])

  const departmentStates = [
    {
      setSelectedContributors: setSelectedProductionContributors,
      selectedContributors: selectedProductionContributors,
      restContributors: selectedMarketingContributors.concat(
        selectedAdminstrationContributors,
        selectedFinancialContributors,
        selectedDevelopContributors,
        selectedLegalContributors
      ),
    },
    {
      setSelectedContributors: setSelectedMarketingContributors,
      selectedContributors: selectedMarketingContributors,
      restContributors: selectedProductionContributors.concat(
        selectedAdminstrationContributors,
        selectedFinancialContributors,
        selectedDevelopContributors,
        selectedLegalContributors
      ),
    },
    {
      setSelectedContributors: setSelectedAdminstrationContributors,
      selectedContributors: selectedAdminstrationContributors,
      restContributors: selectedProductionContributors.concat(
        selectedMarketingContributors,
        selectedFinancialContributors,
        selectedDevelopContributors,
        selectedLegalContributors
      ),
    },
    {
      setSelectedContributors: setSelectedFinancialContributors,
      selectedContributors: selectedFinancialContributors,
      restContributors: selectedProductionContributors.concat(
        selectedMarketingContributors,
        selectedAdminstrationContributors,
        selectedDevelopContributors,
        selectedLegalContributors
      ),
    },
    {
      setSelectedContributors: setSelectedDevelopContributors,
      selectedContributors: selectedDevelopContributors,
      restContributors: selectedProductionContributors.concat(
        selectedMarketingContributors,
        selectedAdminstrationContributors,
        selectedFinancialContributors,
        selectedLegalContributors
      ),
    },
    {
      setSelectedContributors: setSelectedLegalContributors,
      selectedContributors: selectedLegalContributors,
      restContributors: selectedProductionContributors.concat(
        selectedMarketingContributors,
        selectedAdminstrationContributors,
        selectedFinancialContributors,
        selectedDevelopContributors
      ),
    },
  ]

  return (
    <article className={classes.boardWrapper}>
      {ProjectDepartments().map((department, departmentIndex) => {
        return (
          <span key={departmentIndex}>
            <Card
              className={classes.departmentCard}
              key={department}
              sx={{ color: isDarkModeActive && 'white' }}
            >
              <Tooltip title={department.tippyContent} placement='top'>
                <span className={classes.departmentName}>
                  {department.name}
                </span>
              </Tooltip>
              <NewProjectMultiSelectComp
                isDarkModeActive={isDarkModeActive}
                contributors={contributors?.filter(
                  (cont) =>
                    !departmentStates[departmentIndex].restContributors?.find(
                      (el) => el?._id === cont?._id
                    )
                )}
                departmentStates={departmentStates}
                departmentIndex={departmentIndex}
                voidElementsAlertText={
                  rtl
                    ? 'You do not have any contributors'
                    : 'ليس لديك أي مشاركين ، يجب إضافة بعضهم والمحاولة مرة أخرى'
                }
              />
            </Card>
          </span>
        )
      })}
    </article>
  )
}

const WrappedNewProjectMembers = withStyles(styles)(NewProjectMembers)

export default WrappedNewProjectMembers
