import i18next from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FcMindMap, FcSportsMode, FcRadarPlot } from 'react-icons/fc'

const ControlPanelMenuArray = () => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const style = rtl
    ? { marginLeft: 10 }
    : {
        marginRight: 10,
      }
  const { t } = useTranslation()
  return [
    [
      {
        title: t('Followers'),
        icon: <FcRadarPlot style={style} />,
      },
      {
        title: t('Followings'),
        icon: <FcSportsMode style={style} />,
      },
      {
        title: t('Contributors'),
        icon: <FcMindMap style={style} />,
      },
    ],
    [t('Resources'), t('Channels')],
    [t('My_Ideas'), t('My_Inquiries'), t('My_Global_Inquiries')],
    [t('Projects'), t('Contracts'), t('Issues')],
  ]
}

export default ControlPanelMenuArray
