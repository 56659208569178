import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Rightbar from './PageBodyComps/Rightbar'
import Leftbar from './PageBodyComps/Leftbar'
import DynamicLeftBar from './DynamicLeftBar/DynamicLeftBar'
import CardsPageModel from './CardsPageModel/CardsPageModel'
import i18next from 'i18next'
import WindowSize from './smallComponents/WindowSize'

const useStyles = makeStyles((theme) => ({
  container: {
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  rightbarWrapper: {
    position: 'fixed',
    top: '55px',
    width: '20%',
  },
  leftbarWrapper: {
    position: 'fixed',
    top: '55px',
    width: '20%',
  },
  bodywrapper: {
    // marginLeft: '12%',
    // marginRight: '12%',
    // marginTop: '16px',
    // padding: '0px 15%',
    // width: '100%',
  },
  '@media (max-width: 600px)': {
    rightbarWrapper: {
      display: 'none',
    },
    leftbarWrapper: {
      display: 'none',
    },
    bodywrapper: {
      margin: 0,
      padding: 0,
      width: '100vw',
    },
  },
}))

const PageBody = ({
  MainComponent,
  MainComponentProps,
  pageProps,
  cardsPage,
  dynamicMenuArray,
  projectMode,
}) => {
  const classes = useStyles()
  const lang = i18next.language
  const rtl = lang === 'ar'
  const wz = WindowSize()

  return (
    <div className={classes.container}>
      <div
        className={classes.rightbarWrapper}
        style={{
          right: !rtl && 0,
          left: rtl && 0,
          // display: cardsPage && 'none',
          display: projectMode && 'none',
        }}
      >
        <Rightbar {...pageProps} />
      </div>
      <div
        className={classes.bodywrapper}
        style={{
          marginLeft: wz > 600 ? (projectMode ? '4%' : '12%') : 0,
          marginRight: wz > 600 ? (projectMode ? '4%' : '12%') : 0,
          marginTop: wz > 600 && !projectMode ? '16px' : 0,
          padding: wz > 600 ? '0px 15%' : 0,
          width: wz > 600 ? '100%' : '100vw',
        }}
      >
        {cardsPage ? (
          <CardsPageModel {...pageProps} {...MainComponentProps} />
        ) : (
          <MainComponent {...pageProps} {...MainComponentProps} />
        )}
      </div>
      <div
        className={classes.leftbarWrapper}
        style={{
          left: !rtl && 0,
          right: rtl && 0,
          display: projectMode && 'none',
        }}
      >
        {/* {cardsPage ? (
          <DynamicLeftBar dynamicMenuArray={dynamicMenuArray} />
        ) : ( */}
        <Leftbar {...pageProps} />
        {/* )} */}
      </div>
    </div>
  )
}

export default PageBody
