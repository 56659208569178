import React, { useState } from 'react'
import MNotificationsListModel from './MNotificationsListModel'
import GeneralNotifListItem from '../../../notificationsSystem/NotificationsComponents/GeneralNotificationsComponents/GeneralNotifListItem'
import NotificationSystem from '../../../notificationsSystem/NotificationSystem'
import StateGenerator from '../../../smallComponents/StateGenerator'

const MGeneralNotificationsList = ({ isDarkModeActive, pageProps }) => {
  const {
    generalNotifications,
    generalNotificationsSenders,
    socket,
    setGeneralPage,
    generalPage,
  } = pageProps
  const { isGeneralDialogOpen, GeneralDialogOpener, GeneralDialogClose } =
    StateGenerator({ GeneralProps: true })
  const [chosenNotification, setChosenNotification] = useState()
  return (
    <>
      <MNotificationsListModel
        data={generalNotifications}
        NotificationListItem={GeneralNotifListItem}
        isDarkModeActive={isDarkModeActive}
        setChosenNotification={setChosenNotification}
        notificationDialogOpener={GeneralDialogOpener}
        socket={socket}
        senders={generalNotificationsSenders}
        projects={[]}
        setPage={setGeneralPage}
        page={generalPage}
        {...pageProps}
        pageProps={pageProps}
      />
      <NotificationSystem
        isNotificationCardOpen={isGeneralDialogOpen}
        notificationCardDialogClose={GeneralDialogClose}
        notification={chosenNotification}
        isDarkModeActive={isDarkModeActive}
        notificationComponentIndex={2}
        socket={socket}
        senders={generalNotificationsSenders}
        {...pageProps}
      />
    </>
  )
}

export default MGeneralNotificationsList
