import { IconButton } from '@mui/material'
import React, { useContext, useState } from 'react'
import ModelDialogWrappingII from '../../../dialogModelUnit/ModelDialogWrappingII'
import MenuIcon from '@material-ui/icons/Menu'
import withStyles from '@mui/styles/withStyles'
import MControlPanelButtonModel from './MControlButtonsComponents/MControlPanelButtonModel'
import { SettingsContext } from '../../../../context/SettingsContext/SettingsContext'
import i18next from 'i18next'

const styles = () => ({
  wrapper: {
    display: 'flex',
    flexFlow: 'row wrap',
  },
})

const MControlButtons = ({
  classes,
  modelCardsContentArray,
  DialogsComponentContent,
  profileProps,
}) => {
  const [isSwipeableOpen, setIsSwipeableOpen] = useState(false)
  const { settings } = useContext(SettingsContext)
  const [isDarkModeActive, setIsDarkModeActive] = useState(
    settings?.Mode === 'Dark'
  )
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <div className={classes.wrapper}>
      {modelCardsContentArray.map((button, buttonIndex) => (
        <div key={buttonIndex} style={{ width: '50vw' }}>
          <MControlPanelButtonModel button={button} openFun={button.openFun} />
          {button.open && (
            <ModelDialogWrappingII
              setIsDarkModeActive={setIsDarkModeActive}
              isDarkModeActive={isDarkModeActive}
              dialogsArray={[
                {
                  title: button.name,
                  open: button.open,
                  closeFun: button.closeFun,
                  icon: (
                    <>
                      <IconButton
                        edge='start'
                        color='inherit'
                        aria-label='open drawer'
                        onClick={() => setIsSwipeableOpen(true)}
                        sx={{ marginLeft: rtl && '10px' }}
                      >
                        <MenuIcon className={classes.menuIcon2} />
                      </IconButton>
                      {button.icon}
                    </>
                  ),
                  content: (
                    <DialogsComponentContent
                      cardIndex={buttonIndex}
                      modelCardsContentArray={modelCardsContentArray}
                      isSwipeableOpen={isSwipeableOpen}
                      setIsSwipeableOpen={setIsSwipeableOpen}
                      isDarkModeActive={isDarkModeActive}
                      profileProps={profileProps}
                    />
                  ),
                },
              ]}
            />
          )}
        </div>
      ))}
    </div>
  )
}

const WrappedMControlButtons = withStyles(styles)(MControlButtons)

export default WrappedMControlButtons
