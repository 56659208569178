import React from 'react'
import TitleHeader from '../../smallComponents/Header'
import NavigatorGenerator from '../../navigatorCardsModel/NavigatorGenerator'
import { Button } from '@mui/material'
import ElementInfoCard from '../ElementInfoCard'
import i18next from 'i18next'

const InternalComponent = (props) => {
  const {
    navigatorsObject,
    cardsHeaderText,
    setCards,
    setPage,
    fetchUserCards,
    currentResponse,
    searchQuery,
    cards,
    setSearchQuery,
    setSearchInputValue,
    setIsUpdated,
    hideInfoCard,
  } = props
  const navigatorsArray =
    typeof navigatorsObject === 'function' ? [navigatorsObject(cards)] : []
  const unitsArray =
    typeof navigatorsObject === 'function'
      ? navigatorsObject(cards)?.unitsArray
      : []
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <>
      <Button
        sx={{
          borderRadius: '10px',
          // margin: '2px',
          textTransform: 'none',
          fontWeight: '550',
          width: '100%',
          fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
        }}
        style={{
          display: (!searchQuery || currentResponse) && 'none',
        }}
        onClick={() => {
          setCards([])
          setPage(1)
          fetchUserCards()
          setSearchQuery('')
          setSearchInputValue('')
          setIsUpdated((st) => !st)
        }}
      >
        {rtl ? 'إعادة تهيئة' : 'Reset'}
      </Button>
      {!searchQuery && !hideInfoCard && <ElementInfoCard {...props} />}
      {!searchQuery && (
        <article
          style={{
            width: '100%',
            display: unitsArray?.length === 0 && 'none',
          }}
        >
          <NavigatorGenerator {...props} navigatorsArray={navigatorsArray} />
          <TitleHeader headerText={cardsHeaderText} />
        </article>
      )}
    </>
  )
}

export default InternalComponent
