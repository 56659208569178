import React, { useContext, useEffect, useState } from 'react'
import UsersContributorProfile from './UsersContributorProfile'
import MembersPortalModel from '../../../../../projectProfile/ProjectProfileComponents/MembersPortalModel'
import axios from 'axios'
import { AuthContext } from '../../../../../../context/AuthContext'
import BlocksLoader from '../../../../../smallComponents/BlocksLoader'
import i18next from 'i18next'

const UserFollowers = (props) => {
  const noFSPanelPropsSecondView = {
    showDownloadIcon: false,
  }
  const [followers, setFollowers] = useState([])
  const { user } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const fetchPosts = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(`/users/followers/${user?._id}`)
        setFollowers(res.data.followersList)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    fetchPosts()
  }, [user?._id])

  const modFollowers = followers.map((fol) => ({
    ...fol,
    name: fol.firstName + ' ' + fol.lastName,
    id: fol._id,
    image: fol.profilePicture,
  }))

  const lang = i18next.language
  const rtl = lang === 'ar'
  return isLoading ? (
    <BlocksLoader {...props?.DCGProps} />
  ) : (
    <MembersPortalModel
      {...props}
      placeholderValue={rtl ? 'متابعينى' : 'My Followers'}
      cards={modFollowers}
      noFSPanelPropsSecondView={noFSPanelPropsSecondView}
      noFSPanelPropsFirstView={{ hideNoFileSystemPanel: true }}
      CardProfileComponent={UsersContributorProfile}
      hideInfoCard
    />
  )
}

export default UserFollowers
