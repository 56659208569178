import React from 'react'
import { AiOutlineSortAscending, AiOutlineSortDescending } from 'react-icons/ai'
import withStyles from '@mui/styles/withStyles'
import i18next from 'i18next'

const styles = {
  alphIcon: {
    cursor: 'pointer',
    fontSize: 15,
    transition: '0.3s',
    '&:hover': {
      fontSize: 16,
      opacity: 0.5,
    },
  },
}

const FolderPathAlphebeticOrderIcon = ({
  classes,
  DMUSystemProps,
  folderPathProps,
  searchSystemProps,
  showSearchBar,
  path2,
  path3,
  path4,
}) => {
  const { alphabeticOrderDir, setAlphabeticOrderDir, isDarkModeActive } =
    DMUSystemProps

  const { stepBackFun } = folderPathProps
  const { hideSecondPathSearch, hideThirdPathSearch, hideFourthPathSearch } =
    searchSystemProps
  const lang = i18next.language
  const rtl = lang === 'ar'
  return alphabeticOrderDir ? (
    <AiOutlineSortDescending
      onClick={() => setAlphabeticOrderDir(!alphabeticOrderDir)}
      className={classes.alphIcon}
      style={{
        marginLeft: !rtl && 10,
        marginRight: rtl && 10,
        // it will follow exactly the appearnce of the search icon
        display:
          // showSearchBar is for the first path, while hideSecondPathSearch is for the second path
          showSearchBar || (path2 && !stepBackFun) || path3
            ? (hideSecondPathSearch && path2 && !path3) ||
              (hideThirdPathSearch && path3 && !path4) ||
              (hideFourthPathSearch && path4)
              ? 'none'
              : null
            : 'none',
        color: isDarkModeActive ? 'white' : 'inherit',
      }}
    />
  ) : (
    <AiOutlineSortAscending
      onClick={() => setAlphabeticOrderDir(!alphabeticOrderDir)}
      className={classes.alphIcon}
      style={{
        // it will follow exactly the appearnce of the search icon
        display:
          // showSearchBar is for the first path, while hideSecondPathSearch is for the second path
          showSearchBar || (path2 && !stepBackFun) || path3
            ? (hideSecondPathSearch && path2 && !path3) ||
              (hideThirdPathSearch && path3 && !path4) ||
              (hideFourthPathSearch && path4)
              ? 'none'
              : null
            : 'none',
        color: isDarkModeActive ? 'white' : 'inherit',
      }}
    />
  )
}

const WrappedFolderPathAlphebeticOrderIcon = withStyles(styles)(
  FolderPathAlphebeticOrderIcon
)

export default WrappedFolderPathAlphebeticOrderIcon
