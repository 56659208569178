import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { Card, List, ListItem } from '@mui/material'

const styles = () => ({
  DialogModelUnitMenu: {
    display: 'flex',
    flex: '1.5',
    flexDirection: 'column',
    margin: '10px',
    padding: '10px',
    backgroundColor: 'rgb(210, 221, 240) !important',
    borderRadius: '20px !important',
    backdropFilter: 'blur(4px) !important',
  },

  DialogModelUnitMenuItem: {
    padding: '0 10px 0 10px',
    fontSize: '20px',
  },
  ClickedDialogModelUnitMenuItem: {
    padding: '0 10px 0 10px',
    fontSize: '20px',
    background:
      'linear-gradient(75deg, rgb(94, 94, 173) 15%, rgb(105, 113, 122) 100%) !important',
    color: 'white !important',
    borderRadius: 20,
  },

  list: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '100%',
    overflowY: 'auto',
  },
  listItem: {
    borderRadius: '20px !important',
  },

  '@media (max-width: 600px)': {
    DialogModelUnitMenuItem: {
      padding: 0,
    },
    ClickedDialogModelUnitMenuItem: {
      padding: 0,
    },
  },
})

const DialogModelPCVersion = ({
  classes,
  DialogModelUnitMenu,
  DialogModelUnitMenuItemId,
  dialogModelUnitContentDirector,
  DMUSystemProps,
}) => {
  const { isDarkModeActive, hideDialogModelUnitMenu } = DMUSystemProps

  if (!hideDialogModelUnitMenu)
    return (
      <Card
        className={classes.DialogModelUnitMenu}
        sx={{
          filter: isDarkModeActive ? 'grayscale()' : null,
        }}
      >
        <List className={classes.list}>
          {DialogModelUnitMenu?.map((item, itemId) => {
            return (
              <div
                className={
                  DialogModelUnitMenuItemId === itemId
                    ? classes.ClickedDialogModelUnitMenuItem
                    : classes.DialogModelUnitMenuItem
                }
                onClick={(event) => {
                  dialogModelUnitContentDirector(event)
                }}
                id={itemId}
                key={itemId}
              >
                <ListItem button className={classes.listItem}>
                  {typeof item === 'object' ? item.icon : null}
                  {typeof item === 'object' ? item.title : item}
                </ListItem>
              </div>
            )
          })}
        </List>
      </Card>
    )
}

const WrappedDialogModelPCVersion = withStyles(styles)(DialogModelPCVersion)

export default WrappedDialogModelPCVersion
