import React from 'react'
import './DynamicLeftBar.css'
import { ListItem } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../../context/AuthContext'
import { useContext } from 'react'
import { SettingsContext } from '../../context/SettingsContext/SettingsContext'
import i18next from 'i18next'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginTop: '16px',
    position: 'relative',
    overflowY: 'hidden',
    height: '100vh',
    '&:hover': {
      overflowY: 'auto',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  // -----
  leftbarList: {
    padding: 0,
    margin: 0,
    listStyle: 'none',
    height: '100% !important',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center !important',
    justifyContent: 'center !important',
  },
  leftbarItemText: {
    fontFamily: '"Segoe UI Historic", Segoe UI, Helvetica, Arial, sans-serif',
    fontSize: 15,
    cursor: 'pointer',
    fontWeight: 'bold',
    color: '#050505',
  },
  leftbarIcons: {
    fontSize: 25,
    cursor: 'pointer',
  },
}))

const DynamicLeftBar = ({ dynamicMenuArray }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)
  const { settings } = useContext(SettingsContext)
  const isDarkModeActive = settings?.Mode === 'Dark'
  const lang = i18next.language
  const rtl = lang === 'ar'
  const leftbarItemTextCond = rtl ? { marginRight: 15 } : { marginLeft: 15 }
  const pager = dynamicMenuArray?.map((menuItem, i) => ({
    name: (
      <span className={classes.leftbarItemText} style={leftbarItemTextCond}>
        {t(menuItem?.name)}
      </span>
    ),
    icon: <menuItem.icon className={classes.leftbarIcons} />,
    path: menuItem?.path,
    fun: menuItem?.fun,
    url: menuItem?.url,
  }))

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <nav id='nav' role='navigation'>
          <ul className={classes.leftbarList}>
            {pager.map((page, pageId) => {
              return (
                <NavLink
                  to={page.path}
                  exact='true'
                  className='leftbarListItem'
                  key={pageId}
                >
                  <ListItem
                    sx={{ borderRadius: 25 }}
                    style={{ width: '100%', margin: '2px 0' }}
                    button
                  >
                    {page.icon}
                    {page.name}
                  </ListItem>
                </NavLink>
              )
            })}
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default DynamicLeftBar
