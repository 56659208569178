import React, { useContext, useState } from 'react'
import NotifsPopoverCompModel from './NotifsPopoverCompModel'
import { useTranslation } from 'react-i18next'
import ChatInteriorCardComponent from '../../../rightbar/rightbar-Components/ToggleComponents/ChatToggleComponents/ChatInteriorCardComponent'
import { SettingsContext } from '../../../../context/SettingsContext/SettingsContext'
import StateGenerator from '../../../smallComponents/StateGenerator'
import RepetionRemover from '../../../smallComponents/RepetionRemover'
import PCChatDialog from '../../../chatSystem/PCChatDialog'

const ChatsNotifsPopoverComp = (props) => {
  const { t } = useTranslation()
  const {
    handleClose,
    chats,
    setChatPage,
    chatPage,
    chatCurrentResponse,
    lastMessages,
    setIsChatOpen,
    arrivalMessage,
  } = props
  const { settings } = useContext(SettingsContext)
  const { isChatDialogOpen, chatDialogOpener, chatDialogClose } =
    StateGenerator({
      chatProps: true,
    })
  const [chosenChat, setChosenChat] = useState({})
  const isDarkModeActive = settings?.Mode === 'Dark'
  const ordered = lastMessages
    ?.map((message) => {
      const chat = chats?.find((ch) => ch?._id === message?.conversationId)
      return {
        ...chat,
        lastMessage: message,
      }
    })
    ?.sort(
      (a, b) =>
        new Date(b?.lastMessage?.createdAt) - new Date(a.lastMessage?.createdAt)
    )
  const pps = {
    ...props,
    notificationType: t('Chats'),
    handleClose: handleClose,
    data: RepetionRemover(ordered),
    InteriorCardComponent: ChatInteriorCardComponent,
    cardModelIndex: 2,
    clickEeventHandler: () => {
      chatDialogOpener()
      setIsChatOpen(true)
    },
    setChosenChat,
    setPage: setChatPage,
    page: chatPage,
    currentResponse: chatCurrentResponse,
    customOrder: true,
    arrivalMessage,
  }
  return (
    <>
      <NotifsPopoverCompModel {...pps} />
      <PCChatDialog
        isChatDialogOpen={isChatDialogOpen}
        chatDialogClose={chatDialogClose}
        chat={chosenChat}
        pageProps={props}
        isDarkModeActive2={isDarkModeActive}
      />
    </>
  )
}

export default ChatsNotifsPopoverComp
