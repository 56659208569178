import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import FreeSelectComponent from '../../../../formsSystem/FreeSelectComponent'
import ChartBody from '../../RightbarChartComponents/ChartBody'
import NavigatorChartUnitButtons from './NavigatorChartUnitComps/NavigatorChartUnitButtons'
import withStyles from '@mui/styles/withStyles'
import { AuthContext } from '../../../../../context/AuthContext'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

const styles = () => ({
  wrapper: {
    width: '100%',
    height: '100%',
    padding: 10,
  },
  '@media (max-width: 600px)': {
    wrapper: {
      width: 'auto',
    },
  },
})

const NavigatorChartUnit = ({
  classes,
  unit,
  isDarkModeActive,
  modelUnitProps,
}) => {
  const props = { unit, isDarkModeActive, modelUnitProps }
  const { user } = useContext(AuthContext)
  const [parameterId, setParameterId] = useState(0)
  const [contributorsChart, setContributorsChart] = useState()
  const [hashtagsChart, setHashtagsChart] = useState()
  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const contribRes = await axios.get(
          `/projectContributors/online/onlineMembers/${unit?._id}/chart`
        )
        const hashRes = await axios.get(
          `/searchSystems/hashtags/chart/${unit?._id}`
        )
        setContributorsChart(contribRes.data.timeSeriesData)
        setHashtagsChart(hashRes.data.timeSeriesData)
      } catch (error) {
        console.log(error)
      }
    }
    if (unit?._id) fetchChartData()
  }, [unit?._id])
  const parametersDataArray = [hashtagsChart, contributorsChart]
  const { t } = useTranslation()
  return (
    <section className={classes.wrapper}>
      <section
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Link to={`/projectProfile/${unit?._id}`}>
          <h3 style={{ cursor: 'pointer' }}>{unit.name}</h3>
        </Link>
        <span>
          <FreeSelectComponent
            isDarkModeActive={isDarkModeActive}
            option={parameterId}
            setOption={setParameterId}
            menuOptions={[
              {
                title: t('Hashtags'),
              },
              {
                title: t('Contributors'),
              },
            ]}
            defaultValue={0}
          />
        </span>
      </section>
      <ChartBody
        parameterId={parameterId}
        parametersDataArray={parametersDataArray}
        containerHeight={100}
        areaChartHeight={500}
        isDarkModeActive={isDarkModeActive}
      />
      <NavigatorChartUnitButtons
        chartData={parametersDataArray[parameterId]}
        {...props}
      />
    </section>
  )
}

const WrappedNavigatorChartUnit = withStyles(styles)(NavigatorChartUnit)

export default WrappedNavigatorChartUnit
