import React, { useContext, useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { BsFillChatFill } from 'react-icons/bs'
import { MdAdd, MdSearch } from 'react-icons/md'
import Search from '../../rightbar/rightbar-Components/Search'
import withStyle from '@mui/styles/withStyles'
import { IconButton } from '@mui/material'
import axios from 'axios'
import { AuthContext } from '../../../context/AuthContext'
import SearchSystem from '../../searchSystem/SearchSystem'
import { useTranslation } from 'react-i18next'
import WindowProps from '../../windowViewer/WindowProps'
import MTopChatDialog from './MTopChatDialog'

const styles = () => ({
  wrapper: {
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 5px',
    boxShadow: 'rgba(0, 0, 0, 0.10) 1.95px 1.95px 2.6px',
    transition: 'background-color 0.3s ease-in-out', // Add smooth transition
  },
  iconsWrapper: {
    display: 'flex',
    justifyItems: 'space-between',
    margin: 'auto 20px',
  },
  iconsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    transition: 'transform 0.3s ease-in-out, background-color 0.3s ease-in-out', // Add smooth transition
  },
  iconBadge: {
    minWidth: 20,
    height: 20,
    backgroundColor: 'rgb(139, 0, 0)',
    borderRadius: '50%',
    color: 'white',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    fontSize: 11,
    top: 10,
    right: 5,
  },
  iconWrapper: {
    cursor: 'pointer',
    margin: 'auto 3px',
    position: 'relative',
  },
  icon: {
    width: '22px',
    height: '22px',
    borderRadius: '50%',
    padding: '10px',
  },
})

const TitleOppositeIcons = ({ classes, pageProps }) => {
  const { t } = useTranslation()
  const [searchQuerry, setSearchQuerry] = useState('')

  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false)
  const searchDialogCOpener = () => {
    setIsSearchDialogOpen(true)
  }

  const searchDialogClose = () => {
    setIsSearchDialogOpen(false)
  }
  const [chatNotNumb, setChatNotNumb] = useState(0)
  const { user } = useContext(AuthContext)
  const { chats, isDarkModeActive } = pageProps || {}
  const {
    isWindowOpen: isChatDialogOpen,
    windowOpener: chatDialogOpener,
    windowCloser: chatDialogClose,
  } = WindowProps()

  useEffect(() => {
    const fetchChatsCount = async () => {
      try {
        const res = await axios.get(`/conversations/counts/o/${user?._id}`)
        setChatNotNumb(res.data.chatsCount)
      } catch (error) {
        console.log(error)
      }
    }
    fetchChatsCount()
  }, [chats, user?._id])
  const [clickedIconId, setClickedIconId] = useState()

  const icons = [
    // {
    //   icon: <MdAdd size={21} />,
    //   fun: () => {
    //     // this fucntion will open small popover that allows creating new post, idea, inquiry, project ,,,, etc
    //   },
    //   Component: 'popover conent may be',
    // },
    {
      icon: <BsFillChatFill size={21} />,
      notiNumb: chatNotNumb > 99 ? '99+' : chatNotNumb || 0,
      fun: async () => {
        chatDialogOpener()
        setChatNotNumb(Number(clickedIconId) === 1 && 0)
        try {
          await axios.patch(`/conversations/userConversations/${user._id}`, {
            shown: true,
          })
        } catch (error) {
          console.log(error)
        }
      },
      Component: 'chat comp',
    },
    {
      icon: <MdSearch size={21} />,
      fun: () => {
        searchDialogCOpener()
      },
    },
  ]
  return (
    <>
      <div className={classes.iconsWrapper}>
        {icons?.map((item, index) => (
          <span
            key={index}
            className={classes.iconWrapper}
            onClick={(e) => {
              setClickedIconId(index)
            }}
          >
            <IconButton
              edge='start'
              color='inherit'
              aria-label='open drawer'
              onClick={item.fun}
            >
              <div
                className={classes.icon}
                style={{
                  background: !isDarkModeActive && 'rgba(200, 208, 219, 0.6)',
                }}
              >
                {item.icon}
              </div>
              <div
                className={classes.iconBadge}
                style={{ display: item.notiNumb !== 1 ? 'none' : null }}
              >
                {item.notiNumb}
              </div>
            </IconButton>
          </span>
        ))}
      </div>
      <SearchSystem
        isSearchDialogOpen={isSearchDialogOpen}
        searchDialogClose={searchDialogClose}
        searchQuerry={searchQuerry}
        placeholder={t('general_search_placeholder_v1')}
        {...pageProps}
      />
      <MTopChatDialog
        isChatDialogOpen={isChatDialogOpen}
        chatDialogClose={chatDialogClose}
        pageProps={pageProps}
      />
    </>
  )
}

const WrappedTitleOppositeIcons = withStyle(styles)(TitleOppositeIcons)

export default WrappedTitleOppositeIcons
