import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Grid } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import AccessCardGenerator from '../../../../../../../notificationsSystem/NotificationsComponents/ContractsNotificationsComponents/ContractsNotifsDialogRightComponents/NotificationBodyWithAccessCardComps/AccessCardGenerator'
import BlocksLoader from '../../../../../../../smallComponents/BlocksLoader'
import RepetionRemover from '../../../../../../../smallComponents/RepetionRemover'

const styles = () => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexFlow: 'row wrap',
  },
})

const ChatElements = ({ chat, classes, isDarkModeActive, pageProps }) => {
  const { socket } = pageProps
  const [elements, setElements] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const fetchElements = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(`/conversations/attachment/${chat?._id}`)
        setElements(res.data.elements)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (chat?._id) fetchElements()
  }, [chat?._id])
  return isLoading ? (
    <BlocksLoader isDarkModeActive={isDarkModeActive} />
  ) : (
    <Grid container className={classes.wrapper}>
      {RepetionRemover(elements.map((e) => ({ ...e, _id: e?.id })))?.map(
        (element) => {
          return (
            <AccessCardGenerator
              key={element?.id}
              element={element}
              modelProps={{
                profileProps: {},
              }}
              isDarkModeActive={isDarkModeActive}
              socket={socket}
            />
          )
        }
      )}
    </Grid>
  )
}

const WrappedChatElements = withStyles(styles)(ChatElements)

export default WrappedChatElements
