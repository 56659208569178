import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { Grid } from '@mui/material'
import OverviewCardModel from './ProfileOverviewComponents/OverviewCardModel'
import UserPortfolio from './ProfileOverviewComponents/UserPortfolio'
import { useTranslation } from 'react-i18next'
import MultiMillify from '../../../smallComponents/MultiMillify'
import WindowSize from '../../../smallComponents/WindowSize'
import i18next from 'i18next'

const styles = () => ({
  overViewContainer: {
    flex: 6,
    borderRadius: '15px !important',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'space-between',
  },
  cardContentWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backdropFilter: 'blur(4px)',
  },
  cardContent: {
    margin: 2,
  },
  numeric: {
    fontWeight: 500,
  },
  '@media (max-width: 600px)': {
    overViewContainer: {
      width: '100%',
      borderRadius: '0 !important',
    },
  },
})

const ProfileOverview = ({ classes, profileProps }) => {
  const { user } = profileProps
  const { t } = useTranslation()
  const windowSize = WindowSize()
  const lang = i18next.language
  const rtl = lang === 'ar'
  const cards = [
    {
      margins: rtl ? '0 0 2px 2px' : '0 2px 2px 0',
      content: (
        <span className={classes.cardContentWrapper}>
          <h4 className={classes.cardContent}>{t('Contributors')} </h4>
          <span className={classes.numeric}>
            {MultiMillify(user?.contributors?.length)}
          </span>
        </span>
      ),
    },
    {
      margins: rtl ? '0 2px 2px 0' : '0 0 2px 2px',
      content: (
        <span className={classes.cardContentWrapper}>
          <h4 className={classes.cardContent}>{t('Followers')}</h4>
          <span className={classes.numeric}>
            {MultiMillify(user?.followers?.length)}
          </span>
        </span>
      ),
    },

    {
      margins: rtl ? '0 0 0 2px' : '0 2px 0 0',
      content: (
        <span className={classes.cardContentWrapper}>
          <h4 className={classes.cardContent}>{t('Followings')}</h4>
          <span className={classes.numeric}>
            {MultiMillify(user?.followings?.length)}
          </span>
        </span>
      ),
    },
    {
      margins: rtl ? '0 2px 0 0' : '0 0 0 2px',
      content: <UserPortfolio profileProps={profileProps} />,
    },
  ]

  return (
    <article
      className={classes.overViewContainer}
      style={{
        margin:
          windowSize > 600
            ? rtl
              ? '0 10px 0 0'
              : '0 0 0 10px'
            : '10px auto auto auto',
      }}
    >
      <Grid container style={{ height: '100%', width: '100%' }}>
        {cards.map((card, cardIndex) => (
          <OverviewCardModel card={card} key={cardIndex} />
        ))}
      </Grid>
    </article>
  )
}

const WrappedProfileOverview = withStyles(styles)(ProfileOverview)

export default WrappedProfileOverview
