import React, { useContext } from 'react'
import { Paper } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import DarkModeColorCoder from '../../../../smallComponents/DarkModeColorCoder'
import { SettingsContext } from '../../../../../context/SettingsContext/SettingsContext'

const styles = () => ({
  wrapper: {
    display: 'flex',
    flexFlow: 'row wrap',
  },
  card: {
    textAlign: 'center',
    cursor: 'pointer',
    borderRadius: '10px !important',
    width: 'calc(100% - 22px) !important',
    padding: '5px',
    margin: '5px',
  },
})

const MControlPanelButtonModel = ({ classes, button, openFun }) => {
  const { settings } = useContext(SettingsContext)
  const isDarkModeActive = settings?.Mode === 'Dark'
  return (
    <Paper
      sx={{
        background: 'rgba(255,255,255,0.1)',
        borderRadius: '10px',
        color: isDarkModeActive && 'white',
        padding: '5px',
      }}
      className={classes.card}
      onClick={() => {
        if (typeof openFun === 'function') openFun()
      }}
    >
      {button.icon}
      <div>{button.name}</div>
    </Paper>
  )
}

const WrappedMControlPanelButtonModel = withStyles(styles)(
  MControlPanelButtonModel
)

export default WrappedMControlPanelButtonModel
