import React from 'react'
import { Toolbar } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'
import i18next from 'i18next'

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: theme.spacing(3),
  },
  header: (props) => ({
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
    fontFamily: 'Arial, sans-serif',
    textAlign: props.rtl ? 'right' : 'left',
  }),
  paragraph: (props) => ({
    fontSize: '1.2rem',
    lineHeight: '1.5',
    marginBottom: '1rem',
    fontFamily: 'Arial, sans-serif',
    textAlign: props.rtl ? 'right' : 'left',
  }),
}))

const AdsManagementPageMain = () => {
  const lang = i18next.language
  const rtl = lang === 'ar'
  const classes = useStyles({ rtl })

  return (
    <div className={classes.container}>
      <Toolbar />
      <h1 className={classes.header}>
        {rtl ? 'إدارة الحملات الإعلانية' : 'Campaign Management'}
      </h1>
      <p className={classes.paragraph}>
        {rtl
          ? 'مرحبًا بك في صفحة إدارة الحملات الإعلانية! هنا ، يمكنك إنشاء وتحرير ومراقبة حملات الإعلانات الخاصة بك. يمكنك أيضًا عرض التقارير والتحليلات لتتبع أداء حملتك واتخاذ قرارات قائمة على البيانات حول كيفية تحسين إعلاناتك.'
          : "Welcome to the campaign management page! Here, you can create, edit, and monitor your ad campaigns. You can also view reports and analytics to track your campaign's performance and make data-driven decisions about how to optimize your ads."}
      </p>
      <p className={classes.paragraph}>
        {rtl
          ? 'للبدء ، انقر على علامة التبويب " تصفح الحملات" في شريط التنقل لعرض حملاتك الحالية. من هناك ، يمكنك تخصيص استهداف إعلاناتك ، وتعيين ميزانيتك. بمجرد أن تصبح حملتك نشطة ، يمكنك مراقبة أدائها وإجراء التعديلات اللازمة.'
          : 'To get started, click on the "Campaigns Explorer" tab in the navigation bar to view your existing campaigns. From there, you can customize your ad targeting, set your budget. Once your campaign is live, you can monitor its performance and make adjustments as needed.'}
      </p>
    </div>
  )
}

export default AdsManagementPageMain
