import React, { useContext, useEffect, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import ImageUpdator from '../../uploadImageSystem/ImageUpdator'
import ImageUploaderFrame from '../../uploadImageSystem/ImageUploaderFrame'
import WindowSize from '../../smallComponents/WindowSize'
import axios from 'axios'
import { AuthContext } from '../../../context/AuthContext'
import DefaultImageGrapper from '../../smallComponents/DefaultImageGrapper'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import Skeleton from '../../skeleton/Skeleton'
import i18next from 'i18next'

const styles = () => ({
  profileCoverWrapper: {
    height: 170,
    margin: '0 10px 10px 10px',
    borderRadius: 15,
    position: 'relative',
  },
  wallpaperImage: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    position: 'relative',
    borderRadius: 15,
  },

  '@media (max-width: 600px)': {
    profileCoverWrapper: {
      margin: 0,
      borderRadius: 0,
    },
    wallpaperImage: {
      borderRadius: 0,
    },
  },
})

const ProfileCover = ({ classes, isDarkModeActive, profileProps }) => {
  const windowSize = WindowSize()
  const { user } = profileProps
  const { user: currentUser } = useContext(AuthContext)
  const [image, setImage] = useState(user?.coverPicture)
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    const updateCover = async () => {
      setIsLoading(true)
      try {
        await axios.patch(`/users/${user?._id}`, {
          coverPicture: image,
          userId: user?._id,
        })
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (image && image !== user?.coverPicture) updateCover()
  }, [image])
  const ResizerProps = {
    maxWidth: 1000,
  }
  const lang = i18next.language
  const rtl = lang === 'ar'
  return (
    <div
      className={classes.profileCoverWrapper}
      style={{
        height: windowSize < 600 && image && '190px',
        marginLeft: windowSize > 600 && '-12%',
        marginRight: windowSize > 600 && '-12%',
      }}
    >
      {isLoading ? (
        <Skeleton type={'ProfileCover'} />
      ) : image ? (
        <>
          {user?._id === currentUser?._id && (
            <ImageUpdator
              setImage={setImage}
              bottom={10}
              right={!rtl && 20}
              left={rtl && 20}
              pathName={`/users/${user?._id}`}
              ResizerProps={ResizerProps}
              updateProps={{
                path: `/users/${user?._id}`,
                payload: { coverPicture: '', userId: user?._id },
              }}
              remImage={image}
            />
          )}
          <LazyLoadImage
            effect='blur'
            src={image}
            alt={`${user?.firstName}'s Cover`}
            className={classes.wallpaperImage}
            width={'100%'}
            height={'100%'}
          />
        </>
      ) : user?._id === currentUser?._id ? (
        <ImageUploaderFrame
          setImage={setImage}
          isDarkModeActive={isDarkModeActive}
          height={155}
          pathName={`/users/${user?._id}`}
          ResizerProps={ResizerProps}
        />
      ) : (
        <LazyLoadImage
          effect='blur'
          src={DefaultImageGrapper('noCover')}
          alt={`${user?.firstName}'s Cover`}
          className={classes.wallpaperImage}
          width={'100%'}
          height={'100%'}
        />
      )}
    </div>
  )
}

const WrappedProfileCover = withStyles(styles)(ProfileCover)

export default WrappedProfileCover
