import React from 'react'
import MyProjectsDefaultView from './UserControlComponents/MyProjectsDefaultView'
import MyResources from './UserControlComponents/MyResources'
import MyChannels from './UserControlComponents/MyChannels'

const UserControl = (props) => {
  return [
    <MyResources {...props} />,
    <MyChannels {...props} />,
    <MyProjectsDefaultView {...props} />,
  ]
}

export default UserControl
