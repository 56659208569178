import axios from 'axios'
import i18next from 'i18next'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../../../../../../context/AuthContext'
import GeneralPortalSystem from '../../../../../generalPortalSystem/GeneralPortalSystem'
import IdeaProfile from './MyIdeasComponents/IdeaProfile'
import NewIdeaForm from './MyIdeasComponents/NewIdeaForm'

const MyIdeas = (props) => {
  const { user } = useContext(AuthContext)
  const [sharedPostConfigs, setSharedPostConfigs] = useState(null)
  const [contributedIdeas, setContributedIdeas] = useState([])
  // contributed ideas to be calculated
  const elementsImages = contributedIdeas.map((idea) => ({
    id: idea.id,
    image: idea.images[0],
  }))

  useEffect(() => {
    const addNewIdeaPost = async () => {
      try {
        await axios.patch(
          `/ideas/ideaPosts/addSharedPost/${sharedPostConfigs?.element?._id}`,
          { postId: sharedPostConfigs?.postId }
        )
      } catch (error) {
        console.log(error)
      }
    }
    if (sharedPostConfigs?.element?._id && sharedPostConfigs?.postId)
      addNewIdeaPost()
  }, [sharedPostConfigs?.element?._id, sharedPostConfigs?.postId])

  useEffect(() => {
    const fetchContributedIdeas = async () => {
      try {
        const res = await axios.get(
          `/ideas/contributors/contributedIdeas/${user?._id}`
        )
        setContributedIdeas(res.data.ideas)
      } catch (error) {
        console.log(error)
      }
    }
    if (user?._id) fetchContributedIdeas()
  }, [user?._id])

  const { t } = useTranslation()
  const lang = i18next.language
  const propsII = {
    ...props,
    elementProfileProps: { hideCreator: true },
    noFSPanelPropsFirstView: {},
    cardsHeaderText: t('Ideas'),
    placeholderValue: t('Ideas'),
    NewElementForm: NewIdeaForm,
    ElementProfile: IdeaProfile,
    navigatorsObject: (ideas) => {
      return {
        headerText: lang === 'ar' ? 'افكار متشاركة' : `Contributed Ideas`,
        unitsArray: contributedIdeas,
        elementsImages,
      }
    },
    cardMarkProp: 'ideaStatus',
    dialogTitle: lang === 'ar' ? 'فكرة جديدة' : 'New Idea',
    noFSPanelPropsSecondView: {
      showDeleteIcon: true,
      deleteTippy: lang === 'ar' ? 'أزل هذه الفكرة' : 'Remove this Idea',
      confirmmationTitles: [{ title: `${t('Idea')} ${t('has_been_deleted')}` }],
    },
    setSharedPostConfigs,
    deletePath: 'ideas',
    cardsPropName: 'ideas',
    getPath: `ideas/userIdeas/${user._id}`,
    sect_sub_ind: 'idea',
    initialContent:
      lang === 'ar'
        ? 'فى قسم "أفكارى" يمكنك التحكم فى جميع الافكار المضافة أو إضافة المزيد كما يمكنك تحديث أو مسح غيرهم. بالإضافة للتعامل مع طلبات  المشاركة و التحكم فى درجة الامان '
        : 'In "My Ideas" section you can control all your ideas, like creating new ones, deleting or updating others. You may also accept contribution requests or manage its security',
  }
  return <GeneralPortalSystem {...propsII} />
}

export default MyIdeas
