import React, { useRef, useState } from 'react'
import deleteFile from '../../firebase/deleteFile'
import StepperSystem from '../stepperSystem/StepperSystem'
import {
  NewBusinessProject,
  NewProjectFrontCards,
} from './NewProjectComponents'
import i18next from 'i18next'

const NewProject = (props) => {
  const { isNewProjectDialogOpen, newProjectDialogClose } = props
  const [cardId, setCardId] = useState(undefined)
  const lang = i18next.language
  const rtl = lang === 'ar'
  const dataHistory = useRef({})
  return (
    <StepperSystem
      isStepperDialogOpen={isNewProjectDialogOpen}
      stepperDialogClose={async () => {
        newProjectDialogClose()
        if (dataHistory?.current?.image)
          await deleteFile(dataHistory?.current?.image)
      }}
      stepperStepsContentArray={NewBusinessProject({ ...props, dataHistory })}
      stepperTitle={rtl ? 'مشروع جديد' : 'New Project'}
      FrontViewComponent={NewProjectFrontCards}
      FrontViewComponentProps={{
        cardId,
        setCardId,
      }}
      showFrondView={cardId === undefined ? true : false}
      setShowFrontView={setCardId}
      {...props}
    />
  )
}

export default NewProject
